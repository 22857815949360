import React from "react";
import { Link } from "react-router-dom";
import "./OpenPositions.css";
import LazyAnimation from "./LazyAnimation";

function OpenPositions() {
  return (
    <>
      <div className="open-pos-bg">
        <div id="open" className="open-pos-container">
          <div className="open-pos-hero">
            <h1 className="open-pos-title">Open Positions</h1>
            <LazyAnimation />
            <div className="open-pos-description">
              Do you want to be part of an innovation that will disrupt the way
              we game, exercise, work, meet, and live? Be at the forefront of
              the current wave of virtual reality innovation and help design the
              future of entertainment!
            </div>
            <div className="open-pos-job-container">
              <div className="open-pos-job">
                Unreal Engine Technical Animator
              </div>
              <div className="open-pos-job-category">
                Department: Game Development
              </div>
              <Link
                to="/unreal-engine-technical-animator"
                className="open-pos-job-link"
              >
                More information{" "}
                <img
                  src="/images/arrow-icon-now.svg"
                  alt="arrow"
                  class="open-pos-right-arrow"
                />
              </Link>
            </div>
            <div className="open-pos-job-container">
              <div className="open-pos-job">
                Environment Artist
              </div>
              <div className="open-pos-job-category">
                Department: Game Development
              </div>
              <Link
                to="/environment-artist"
                className="open-pos-job-link"
              >
                More information{" "}
                <img
                  src="/images/arrow-icon-now.svg"
                  alt="arrow"
                  class="open-pos-right-arrow"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OpenPositions;
