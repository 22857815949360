import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./components/pages/HomePage/Home";
import AboutUs from "./components/pages/AboutUs/AboutUs";
import Careers from "./components/pages/Careers/Careers";
import Patents from "./components/pages/Patents/Patents";
import Developers from "./components/pages/Developers/Developers";
import Investors from "./components/pages/Investors/Investors";
import Press from "./components/pages/Press/Press";
import Press2 from "./components/pages/Press/Press2";
import Press3 from "./components/pages/Press/Press3";
import NewsArticle12 from "./components/pages/NewsArticles/NewsArticle12";
import NewsArticle13 from "./components/pages/NewsArticles/NewsArticle13";
import Footer from "./components/pages/Footer/Footer";
import TermsOfUse from "./components/pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./components/pages/PrivacyPolicy/PrivacyPolicy";
import SdkTerms from "./components/pages/SdkTerms/SdkTerms";
import Legal from "./components/pages/Legal/Legal";
import CommunityStandards from "./components/pages/CommunityStandards/CommunityStandards";
import ContentSubmissionTerms from "./components/pages/ContentSubmissionTerms/ContentSubmissionTerms";
import OmniOneSdkEula from "./components/pages/OmniOneSdkEula/OmniOneSdkEula";
import OnlineMemberTerms from "./components/pages/OnlineMemberTerms/OnlineMemberTerms";
import CaPrivacyNotice from "./components/pages/CaPrivacyNotice/CaPrivacyNotice";
import OmniArenaSelect from "./components/pages/OmniArenaSelect/OmniArenaSelect";
import OmniArenaResources from "./components/pages/OmniArenaResources/OmniArenaResources";
import OpResourcesOmniverse from "./components/pages/OmniverseResources/OpResourcesOmniverse";
import OpResourcesNonOmniverse from "./components/pages/OmniverseResources/OpResourcesNonOmniverse";
import ConsumerGames from "./components/pages/OmniverseResources/ConsumerGames";
import RequiredThirdParty from "./components/pages/OmniverseResources/RequiredThirdParty";
import TermsOfSale from "./components/pages/TermsOfSale/TermsOfSale";
import OmniOneStoreTerms from "./components/pages/OmniOneStoreTerms/OmniOneStoreTerms";
import HealthAndSafety from "./components/pages/HealthAndSafety/HealthAndSafety";
import LimitedWarranty from "./components/pages/LimitedWarranty/LimitedWarranty";
import BetaAgreement from "./components/pages/BetaAgreement/BetaAgreement";
import DownloadsMessage from "./components/pages/Download/DownloadsMessage";
import TechAnimator from "./components/pages/JobPostings/TechAnimator";
import EnvArtist from "./components/pages/JobPostings/EnvArtist";
import AccountDeletion from "./components/pages/AccountDeletion/AccountDeletion";

function App() {

  return (
    <>
    <Helmet>
      <meta name="description" content="Experience full freedom of movement in VR with the Omni, the first omni-directional treadmill that lets players walk and run in 360 degrees inside video games and other virtual worlds." data-react-helmet="true" />
    </Helmet>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/who-we-are" component={AboutUs} />
          <Route path="/careers" component={Careers} />
          <Route path="/patents" component={Patents} />
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/terms-of-sale" component={TermsOfSale} />
          <Route path="/developers" component={Developers} />
          <Route path="/investors" component={Investors} />
          <Route path="/press" component={Press} />
          <Route path="/press-2" component={Press2} />
          <Route path="/press-3" component={Press3} />
          <Route path="/virtuix-unveils-omni-one-launch-games" component={NewsArticle12} />
          <Route path="/virtuix-expands-omni-one-game-lineup-to-over-50-games" component={NewsArticle13} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/sdk-terms-and-conditions" component={SdkTerms} />
          <Route path="/unreal-engine-technical-animator" component={TechAnimator} />
          <Route path="/environment-artist" component={EnvArtist} />
          <Route path="/legal" component={Legal} />
          <Route path="/community-standards" component={CommunityStandards} />
          <Route path="/content-submission-terms" component={ContentSubmissionTerms} />
          <Route path="/omni-one-sdk-eula" component={OmniOneSdkEula} />
          <Route path="/omni-online-membership-terms" component={OnlineMemberTerms} />
          <Route path="/omni-one-store-terms" component={OmniOneStoreTerms} />
          <Route path="/health-and-safety-warnings-and-instructions" component={HealthAndSafety} />
          <Route path="/ca-privacy-notice" component={CaPrivacyNotice} />
          <Route path="/limited-warranty" component={LimitedWarranty} />
          <Route path="/omni-one-beta-agreement" component={BetaAgreement} />
          <Route path="/omni-arena" component={OmniArenaSelect} />
          <Route path="/omni-arena-resources" component={OmniArenaResources} />
          <Route path="/operator-resources-ov-cnz7h23moq" component={OpResourcesOmniverse} />
          <Route path="/operator-resources-non-ov-6wrz1emhn9" component={OpResourcesNonOmniverse} />
          <Route path="/consumer-games-85gn1qda03" component={ConsumerGames} />
          <Route path="/required-third-party-products" component={RequiredThirdParty} />
          <Route path="/download" component={DownloadsMessage} />
          <Route path="/account-deletion-request" component={AccountDeletion} />
          <Route path='/wp-content/uploads/2019/06/Omni%20Arena%20%E2%80%93%20Brochure.pdf' component={() => { 
            window.location.href = 'https://www.virtuix.com/wp-content/uploads/2019/06/Omni%20Arena%20%E2%80%93%20Brochure.pdf'; 
            return null;
          }}/>
          <Redirect to="/" />
        </Switch>
        <Footer />
      </Router>
      </>        
  );
}

export default App;
