import React from "react";
import "./OpResourcesOmniverseCopy.css";

function DownloadsMessageCopy() {
  return (
    <>
      <div className="ov-resources-copy-container">
        <h2 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
        Receiving a download link
        </h2>
        <p className="ov-resources-copy-body" style={{marginTop: 15 + "px"}}>
        Thank you for purchasing an Omni Pro system! You should have received a website link from your distributor to a private download page that contains Omni software and games. If you did not receive this link, please contact us at <a className="ov-resources-copy-link" href="mailto:support@virtuix.com">support@virtuix.com</a>. 
        </p>
        <img className="ov-resources-copy-download-img" src="/images/omni-pro-training-ops.jpg" alt="Player playing Training Ops" />
      </div>
    </>
  );
}

export default DownloadsMessageCopy;
