import React from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from 'react-router-dom';
import "./RequestModal.css";

const RequestModal = props => {
    
    const styleObjWidth22 = {
        width: "22px",
      }

    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div className="modal-header">
                      <h4 className="modal-title">{props.title}</h4>
                      <Link className='close-modal' to='/developers#'>                    
                      <img src="/images/x-close-green.svg" style={styleObjWidth22} alt='' onClick={props.onClose} /></Link>
                    </div>
                    <div className="background-green-modal">&nbsp;</div>
                    <div className="modal-body">
                        {props.children}
                    </div>
                    <div className="modal-footer">
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export default RequestModal