import React from "react";
import { Link } from "react-router-dom";
import "./CareersSection2.css";
import { Button } from "./Button";
import LazyAnimation from "./LazyAnimation";

function CareersSection2() {
  return (
    <div className="careers-two-dark-bg">
      <div className="careers-two-container">
        <div className="careers-two-flex ">
          <div className="careers-two-col-02">
            <LazyAnimation />
            <h1 className="careers-two-heading">Austin, TX</h1>
            <div className="careers-two-text">
            Located near The Domain in North Austin, we have quick access to many great restaurants, bars, and activities. Enjoy good food and
              live music or take the short drive into the Texas hill country for
              adventurous hikes and sight-seeing. Austin is a hot spot for tech
              and culture, so there's never a shortage of new experiences to
              try.
            </div>
          </div>
          <img
            className="careers-two-img careers-two-col-01"
            src="/images/careers_austin_01.jpg"
            alt="Team working"
          />
        </div>
      </div>
    </div>
  );
}

export default CareersSection2;
