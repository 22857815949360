import React from 'react';
// import ReactDOM from 'react-dom/client';
import { render } from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Roobert/Roobert-Regular.otf';
import './fonts/Roobert/Roobert-Bold.otf';
import './fonts/Roobert/Roobert-Medium.otf';
import './fonts/DM_Sans/DMSans-Bold.ttf'
import './fonts/DM_Sans/DMSans-BoldItalic.ttf'
import './fonts/DM_Sans/DMSans-Italic.ttf'
import './fonts/DM_Sans/DMSans-Medium.ttf'
import './fonts/DM_Sans/DMSans-MediumItalic.ttf'
import './fonts/DM_Sans/DMSans-Regular.ttf'
import removeFbclid from "remove-fbclid";
import { BrowserRouter } from 'react-router-dom';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <App />
// );

const root = document.getElementById("root");
render(<App />, root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
