import React from "react";
import "./PatentsList.css";

function PatentsList() {
  return (
    <>
      <div className="patents-list-bg">
        <div id="open" className="patents-list-container">
          <div className="patents-list-hero">
            <div className="patents-list-number-container">
              <div className="patents-list-number">
                Issued Patents
              </div>
              <div className="patents-list-number-category">
              U.S. Patent No. US 9,329,681
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US 9,785,230
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US 10,635,162
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US D766,239
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US D789,368
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US D787,516
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US 10,286,313
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US 10,065,114
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US D863,737
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US D863,738
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US D879,417
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US D878,012
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US D887,684
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US 10,751,622
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US 11,247,126
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US D948,076
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US 11,301,032
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US 11,557,094
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US 10,933,320
              </div>
            </div>
            <div className="patents-list-number-container">
              <div className="patents-list-number-category">
              U.S. Patent No. US 11,648,473
              </div>
            </div>
            <div className="patents-list-number-container-last">
              <div className="patents-list-number-category">
              Other patents pending.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatentsList;
