import React from "react";
import "./OpResourcesOmniverseCopy.css";
import ReactPlayer from "react-player";
import { Button } from "./Button";

function ConsumerGamesCopy() {
  return (
    <>
      <div className="ov-resources-copy-container">
      <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
        PC Requirements
        </h1>
        <div className="ov-resources-flex-no-pad">
            <p className="ov-resources-copy-body">
                Minimum:
                <ul className="ov-resources-copy-list-disc copy-list-indent">
                    <li>Operating System: Windows 10</li>
                    <li>Processor: Intel Core i5-6600K @ 3.5 GHz</li>
                    <li>Memory: 8 GB RAM</li>
                    <li>Graphics: NVIDIA GeForce GTX 1070 8GB</li>
                    <li>Hard Drive: 1TB (SSD or HDD)</li>
                </ul>
            </p>
            <p className="ov-resources-copy-body">
            Recommended:
                <ul className="ov-resources-copy-list-disc copy-list-indent">
                    <li>Operating System: Windows 10</li>
                    <li>Processor: Intel i7-6700</li>
                    <li>Memory: 16 GB RAM</li>
                    <li>Graphics: NVIDIA Geforce RTX 2070</li>
                    <li>Hard Drive: 1TB (SSD or HDD)</li>
                </ul>
            </p>
        </div>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
        Omni Connect
        </h1>
        <p className="ov-resources-copy-body">
        Before you begin, download and install Omni Connect on your system. The Omni Connect application is required to operate your Omni. Additional information about Omni Connect and Omni Online is available in your Owner’s Manual.
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/q2lfwwz0s3nkwm8/Omni_Connect_1_3_3_0.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Omni Connect
            </Button>
          </a>
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
        Omni Calibration Application
        </h1>
        <p className="ov-resources-copy-body">
        The Omni calibration application enables you to switch between the Omni’s default movement mode (move in the direction of your waist) and the Omni’s secondary “head-guided” movement mode (move in the direction of your HMD) in Omni games and applications. The application is required to calibrate your Omni using the HTC Vive headmounted display. If you own the Omni Controller Mount, this application will also automatically complete the Vive “Room Setup” calibration.
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/kc86ff9yq2ikwuo/OmniCalibration_v1.1_Setup.exe?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Omni Calibration Application
            </Button>
          </a>
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
        Omni Training Game
        </h1>
        <p className="ov-resources-copy-body">
        We created a brief training experience in VR that teaches new users how to walk on the Omni in less than a minute via an interactive VR game. Download this standalone Omni training game via the button below.
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/41fo14tjcp779p1/OmniTraining_v1.1_Setup.exe?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Download Omni Training Game
            </Button>
          </a>
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
          Games
        </h1>
        <h2 className="ov-resources-copy-header">
        Omni Arena
        </h2>
        <p className="ov-resources-copy-body">
        Survive waves of robots by shooting the attackers before they destroy the various Power Cores in the Arena. The game includes both single and multiplayer gameplay (note: multiplayer via LAN only).
        </p>
        <div className="ov-resources-flex-no-pad">
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/omniarena_game_screen_01.jpg"
            alt="Omni Arena Screenshot 01"
          />
        </p>
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/omniarena_game_screen_02.jpg"
            alt="Omni Arena Screenshot 02"
          />
        </p>
        </div>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/i7z6i6jtebm7qd1/OmniArena_vNO1.2_Setup.exe?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Omni Arena for HTC Vive
            </Button>
          </a>
        </p>
        <h2 className="ov-resources-copy-header">
        TRAVR: Training Ops
        </h2>
        <p className="ov-resources-copy-body">
        Compete for the highest score in 10 obstacle-course levels by shooting targets and establishing a record time.
        </p>
        <div className="ov-resources-flex-no-pad">
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/trainingops_game_screen_01.jpg"
            alt="Training Ops Screenshot 01"
          />
        </p>
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/trainingops_game_screen_02.jpg"
            alt="Training Ops Screenshot 02"
          />
        </p>
        </div>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/bu1ftpho25suwkh/TravrTrainingOps_vNO1.2_Setup.exe?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              TRAVR: Training Ops for HTC Vive
            </Button>
          </a>
        </p>
        <h2 className="ov-resources-copy-header">
        TRAVR: Shadow Ops
        </h2>
        <p className="ov-resources-copy-body">
        Work your way through a dark underground laboratory and shoot zombies as a TRAVR secret agent in this linear first-person shooter horror experience.
        </p>
        <div className="ov-resources-flex-no-pad">
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/shadowops_game_screen_01.jpg"
            alt="Shadow Ops Screenshot 01"
          />
        </p>
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/shadowops_game_screen_02.jpg"
            alt="Shadow Ops Screenshot 02"
          />
        </p>
        </div>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/qlubq19pkztnvtz/TravrShadowOps_vNO1.2_Setup.exe?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              TRAVR: Shadow Ops for HTC Vive
            </Button>
          </a>
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
        Resource Links
        </h1>
        <div className="ov-resources-flex">
        <p className="ov-resources-copy-body">
          <a
            href="/uploads/Omni-Connect-Quick-Start-Guide-v1.5.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Omni Connect Quick Start Guide
            </Button>
          </a>
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="/uploads/Virtuix-Omni-Owner-Manual.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
            Omni Owner's Manual
            </Button>
          </a>
        </p>
        </div>
      </div>
    </>
  );
}

export default ConsumerGamesCopy;
