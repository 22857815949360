import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./DevelopersDownloads.css";
import { Button } from "./Button";
import { NavHashLink } from "react-router-hash-link";
import RequestModal from "./RequestModal";
import { SDKForm } from "./SDKForm";

function DevelopersDownloads() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const [show, setShow] = useState(false);

  return (
    <div className="dev-dl-dark-bg">
      <div className="dev-dl-container">
        <h1 className="dev-dl-heading">Resource Links</h1>
        <div className="dev-dl-subtitle">
          Download the following resources to develop your Omni project.
        </div>
        <div className="dev-dl-btn-container">
          <NavHashLink to="#request" onClick={() => setShow(true)}>
            <Button buttonSize="btn-wide-fixed" buttonColor="green">
              Request Omni Pro SDK
              <div className="button-right-arrow">
                <img src="/images/arrow-icon-now.svg" alt="arrow" />
              </div>
            </Button>
          </NavHashLink>
          <a href="https://dl.dropboxusercontent.com/s/ntzn18524xt34ue/Omni%20Application%20Design%20Guide.pdf?dl=1" target="_blank" rel="noreferrer">
            <Button buttonSize="btn-wide-fixed" buttonColor="green">
              Omni Design Guide
              <div className="button-right-arrow">
                <img src="/images/arrow-icon-now.svg" alt="arrow" />
              </div>
            </Button>
          </a>
          <a href="https://dl.dropboxusercontent.com/s/41fo14tjcp779p1/OmniTraining_v1.1_Setup.exe?dl=1" target="_blank" rel="noreferrer">
            <Button buttonSize="btn-wide-fixed" buttonColor="green">
              Omni Training Game
              <div className="button-right-arrow">
                <img src="/images/arrow-icon-now.svg" alt="arrow" />
              </div>
            </Button>
          </a>
          <a href="https://dl.dropboxusercontent.com/s/kc86ff9yq2ikwuo/OmniCalibration_v1.1_Setup.exe?dl=1" target="_blank" rel="noreferrer">
            <Button buttonSize="btn-wide-fixed" buttonColor="green">
              Calibration Application
              <div className="button-right-arrow">
                <img src="/images/arrow-icon-now.svg" alt="arrow" />
              </div>
            </Button>
          </a>
        </div>
        <img
          className="dev-dl-img-large"
          src="/images/developers_omni_players.jpg"
          alt="Team working"
        />
        <RequestModal
          title="Request SDK"
          onClose={() => setShow(false)}
          show={show}
        >
          <p>
            By submitting this form you agree to the{" "}
            <a
              className="developers-copy-link"
              href="https://virtuix.com/sdk-terms-and-conditions"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>{" "}
            of the SDK.
          </p>
          <SDKForm />
        </RequestModal>
      </div>      
      <hr className="footer-hr" />
    </div>
  );
}

export default DevelopersDownloads;
