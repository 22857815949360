import React from "react";
import { Link } from "react-router-dom";
import "../../Copy.css";

function PrivacyPolicy() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Virtuix Privacy Policy</h1>
        <h3 className="copy-subheader">1. PURPOSE</h3>
        <p className="copy-body">
        Welcome to our Privacy Policy! Virtuix Inc. together with our affiliates (“Virtuix”, “we”, or “us”) have adopted this Privacy Policy to let you know how we treat the information that we collect from you online and offline, the kinds of information we may collect, how that information is used, with whom we share it and how you can opt-out of or use or correct or change such information. This Privacy Policy supplements and forms a part of the Virtuix <Link className="copy-link-to-gray" to="/terms-of-use">Terms of Use</Link> (the “Terms”). Capitalized terms not defined in Section 13 or otherwise in this Privacy Policy have the meaning given to them in the Terms.
        </p>
        <h3 className="copy-subheader">2. SCOPE</h3>
        <p className="copy-body">
        This Privacy Policy applies to Personal Information that is Processed by Virtuix in the course of our business, including via the Virtuix websites, Virtuix hardware and software products, any Virtuix platform on which users can download, interact with and submit content and obtain services related to or in connection with our products, our mobile apps, forums, social media accounts, blogs, games, content, Omni Online services, and other online or offline offerings (together with any and all future online and offline offerings operated by or on behalf of Virtuix, the “Services”). All individuals whose responsibilities include the Processing of Personal Information on behalf of Virtuix are expected to protect that data by adherence to this Privacy Policy.
        </p>
        <p className="copy-body">
        Residents of California should be aware that California law affords them certain rights regarding their Personal Information. Residents of California should review the California Privacy Notice that supplements and forms a part of this Privacy Policy. The California Privacy Notice is available at <Link to="/ca-privacy-notice" className="copy-link-to-gray">
            https://virtuix.com/ca-privacy-notice
          </Link>
          .
        </p>
        <p className="copy-body">
        This Privacy Policy does not apply to Personal Information of Virtuix employees Processed in the employment context, but employees who elect to use the Services will be treated as users in accordance with this Privacy Policy with respect to Personal Information Processed as users of our Service.
        </p>
        <h3 className="copy-subheader">
          3. PERSONAL INFORMATION WE COLLECT AND HOW WE USE IT
        </h3>
        <p className="copy-body">
        The types of Personal Information we may collect (directly from you or from third party sources) and our privacy practices depend on the nature of the relationship you have with Virtuix and the requirements of applicable law. Some of the ways that Virtuix may collect Personal Information include:
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
            You may provide Personal Information directly to Virtuix through interacting with the Services, participating in surveys, during events, and requesting services or information.
            </li>
            <li>
            As you navigate the Services, certain passive information may also be collected about your visit, including through cookies and similar technologies as described below.
            </li>
          </ul>
        </p>
        <p className="copy-body">
        It is our goal to collect only that information which is relevant for the purposes of Processing. Below are the ways we collect Personal Information and how we use it.
        </p>
        <h3 className="copy-subheader">
          3.1 TYPES OF PERSONAL INFORMATION WE COLLECT
        </h3>
        <p className="copy-body">
        Virtuix collects Personal Information from its current, prospective, and former customers, members, users, visitors, and guests (collectively “Individuals”).
        </p>
        <p className="copy-body">
          <i>Information You Provide Directly to Us.</i> When you use the Services, contact us directly, or engage in certain activities, such as registering for an account with Virtuix, purchasing Virtuix products or Services from us, or using our Services, we may ask you to provide some or all of the following types of information:
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
              Purchases. When you choose to purchase Virtuix products or Services from us, we may collect information from you including Personal Information, such as your name, email address, mailing address, phone number, payment information, such as a credit card number and/or other related information that may be required from you to complete your purchase (such payment information, “Financial Information”). Unless we tell you otherwise at the time of your purchase or application for financing, your Financial Information is processed by our third party processors and we do not collect, store or maintain your Financial Information. For more information on how your information is shared in these instances, please see Section 3.5 (“Third-Party Payment Processing”) below. We may also collect a username and password in connection with the purchase of Virtuix products or Services. 
            </li>
            <li>
              Public User Profiles. When you use Virtuix products or subscribe to any Services, you will be given the opportunity to create a “User Profile” and when you do so we may ask you to provide information about yourself such as your name, username, password, email address, weight, height, age, location, birthday, phone number and image or avatar. Once your User Profile is created, anytime you log in and play, we will collect additional information and add it to your User Profile, such as the total number of steps taken, miles run, calories burned, gameplay duration and frequency, game performance (including game scores and other stats, leaderboard ranks, rewards status, and achievements earned), your friends and other users you’ve played with, saved game information, and other information related to your usage of the Virtuix products and Services. You acknowledge that your User Profile information may be personal to you, and by creating an account and providing such information through the use of our Services, you allow others, including Virtuix, to identify you and therefore you may not be anonymous. Information included in your User Profile, including any achievements and gameplay stats, may be displayed to other users. Virtuix User Profiles are set to public by default, which means that other registered Virtuix users will also be able to view your User Profile, including your performance history (as described above), your Virtuix username, your location, and other information, and those users can contact you through the Services. You have the option to set certain information of your profile to “Private.”
            </li>
          </ul>
        </p>
        <p className="copy-body">
          <i>Voice, Likeness, and Gameplay Footage.</i> We may capture your voice and gameplay footage when you use the Services. Additionally, your visual image, likeness and voice recording during use of the Services may be captured by video at events, including marketing events or esports tournaments.  We may also record and store member support and sales calls to train support and sales agents to help resolve member questions, for quality purposes and as required by law. 
        </p>
        <p className="copy-body">
          <i>Other User Information.</i> Virtuix may also collect other information from you as a result of your use of the Services, such as error and crash logs, purchase history, and fitness and health related information. We may associate this information with other Personal Information that you have provided in order to improve and provide the Services and as otherwise described in this Privacy Policy.
        </p>
        <p className="copy-body">
          <i>Communications with Us.</i> We may collect Personal Information from you such as your email address, phone number or mailing address, when you choose to request information about our Services, visit our retail showrooms or Omni Arena venues, register to receive Virtuix communications over email or text messaging, participate in promotions, or loyalty programs that we may offer from time to time, request to receive member or technical support or otherwise communicate with us.
        </p>
        <p className="copy-body">
          <i>Surveys and Reviews.</i> From time to time, we (or third parties acting on our behalf) may contact you to participate in research, surveys or beta testing or to provide reviews and testimonials. If you decide to participate, you may be asked to provide certain information, which may include Personal Information. All information collected from your participation in our research, surveys, reviews or testimonial process, or beta testing is provided by you voluntarily. We may use such information to improve our products, our Site and/or Services, and in any manner consistent with this Privacy Policy.
        </p>
        <p className="copy-body">
          <i>Posting on the Virtuix or Third Party Services.</i> Virtuix may offer publicly accessible blogs, social media pages, product review pages, private messages, video chat or community forums. You should be aware that, when you disclose information about yourself on Virtuix’s blogs, social media pages, product review pages, private messages, community forums, and within video chat sessions, Virtuix will collect the information you provide in such submissions, including any Personal Information. If you choose to submit content, including reviews, to any public area of the Services or on any public sites operated by third parties, such content will be considered “public” and will not be subject to the privacy protections provided in this Privacy Policy.
        </p>
        <p className="copy-body">
          <i>Registration for Esports Contests.</i> Occasionally, Virtuix may run esports contests. We may ask those who enter esports contests to provide contact information (e.g., a phone number or an email address). If you participate in a contest, your contact information may be used to reach you about the contest, and, to the extent permitted by law, for other promotional, marketing and business purposes. In compliance with the laws of your jurisdiction, as a participant you may have the opportunity to opt-out of any communications that are not related to awarding prizes.
        </p>
        <p className="copy-body">
          <i>Location Information.</i> Based on your interaction with the Services, the type of device you use to access the Services, and your device’s connectivity, we may collect location-based information including your Internet protocol (IP) address, GPS location, longitude/latitude, city, county, zip code and region, and your location and your smart device’s proximity to “beacons,” Bluetooth networks and/or other proximity systems. We may use this type of information to enhance your user experience (such as using location information for the purposes of displaying the geographic distribution of Virtuix customers), to better understand your interaction with our products and Services, or to enable you to track your activities. Unless you provide us with your consent to do otherwise, this information will only be used by us and our third party service providers to provide you with the Services you request or in an aggregated and anonymized format that does not identify you.
        </p>
        <p className="copy-body">
          <i>Automatic Data Collection.</i> We may collect certain information automatically through our Services or other methods of web analysis, such as your IP address, cookie identifiers, mobile carrier, mobile advertising identifiers, MAC address, IMEI, Advertiser ID, and other device identifiers that are automatically assigned to your computer or device when you access the Internet, browser type and language, geo-location information, hardware type, operating system, Internet service provider, pages that you visit before and after using the Services, the date and time of your visit, the amount of time you spend on each page, information about the links you click and pages you view within the Services, and other actions taken through use of the Services such as preferences. For more information on Automatic Data Collection, please see Section 3.3.
        </p>
        <p className="copy-body">
          <i>Information Submitted Via Services.</i> You agree that Virtuix is free to use the content of any communications submitted by you via the Services, including any ideas, inventions, concepts, techniques, or know-how disclosed as part of that content, for any purpose including developing, manufacturing and/or marketing goods or Services. 
        </p>
        <p className="copy-body">
          <i>Information from Other Sources.</i> We may receive information about you from other sources, including through third party services and organizations, to supplement information provided by you. For example, if you access our Services through a third party application, such as the Apple App Store or Google Play App Store (together with any similar applications, “App Stores”) or social media sites such as Facebook, we may collect information about you from that third party application that you have made public via your privacy settings. Information we collect through App Stores or social media accounts may include your name, your social media site user identification number, your user name, location, gender, birth date, email, profile picture and your social media contacts. This supplemental information allows us to verify information that you have provided to Virtuix and to enhance our ability to provide you with information about our business, products and Services.
        </p>
        <p className="copy-body">
          <i>Information provided via Kickstarter and SeedInvest.</i> If you participated in our 2013 Kickstarter campaign or subsequent SeedInvest campaigns, when you provided information through Kickstarter or SeedInvest that information was and is subject to their data collection practices and privacy policy. Our treatment of the information you provided to them, which they provided to us, is subject to the terms of this Privacy Policy.
        </p>
        <h3 className="copy-subheader">3.2 HOW WE USE YOUR INFORMATION</h3>
        <p className="copy-body">
          <i>To Provide Products, Services or Information Requested.</i> Virtuix may use information about you to fulfill requests for products, Services or information, including information about potential or future services, including to:
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>Generally manage Individual information and accounts;</li>
            <li>Respond to questions, comments, and other requests;</li>
            <li>
            Provide access to certain areas, functionalities, and features of Virtuix’s Services;
            </li>
            <li>
            Assist with monitoring your gameplay performance, achievements, rewards, fitness and health related statistics, and usage trends;
            </li>
            <li>
            Personalize activity goals for you based on your settings and your historical activity data;
            </li>
            <li>
            Communicate with you about logistical matters, including product deliveries and product servicing visits;
            </li>
            <li>Provide services to you;</li>
            <li>
            Review and contact you to answer requests for member support or technical support; and
            </li>
            <li>
            Allow you to register for esports contests and other events.
            </li>
          </ul>
        </p>
        <p className="copy-body">
          <i>Administrative Purposes.</i> Virtuix may use Personal Information about you for its administrative purposes, including to:
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>Measure interest in Virtuix’s Services; </li>
            <li>Develop new products and Services; </li>
            <li>Ensure quality control; </li>
            <li>Verify Individual identity; </li>
            <li>
            Communicate about Individual accounts and activities on Virtuix’s Services and systems, and, in Virtuix’s discretion, changes to any Virtuix policy;
            </li>
            <li>
            Send emails or text messages to the email address or telephone number you provide to us to verify your account and for informational and operational purposes, such as account management, customer service, delivery updates, or system maintenance;
            </li>
            <li>Process payment(s) for products or services purchased; </li>
            <li>Process applications and transactions; </li>
            <li>Prevent potentially prohibited or illegal activities;</li>
            <li>Maintain and administer our Services; and</li>
            <li>Enforce our Terms.</li>
          </ul>
        </p>
        <p className="copy-body">
          <i>Marketing Virtuix Products and Services.</i> Virtuix may use Personal Information to provide you with materials about offers, products and Services that may be of interest, including new content or services. Virtuix may provide you with these materials by phone, postal mail, text or email, as permitted by applicable law. Such uses include:
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>To tailor content, advertisements and offers;</li>
            <li>
              To notify you about offers, products and services that may be of
              interest to you or about which you have previously expressed an
              interest;
            </li>
            <li>To provide Services to you;</li>
            <li>
              For other purposes disclosed at the time that Individuals provide
              Personal Information; or
            </li>
            <li>Otherwise with your consent.</li>
          </ul>
        </p>
        <p className="copy-body">
        Virtuix may also use your gameplay footage, including your gameplay recording, voice recording, visual images, and likeness (“Gameplay Footage”) for marketing purposes. You grant us permission to use Gameplay Footage, and such permission extends into perpetuity. For a valuable consideration, receipt of which is hereby acknowledged, you hereby grant us all ownership rights and the absolute and irrevocable right and permission to copyright, use and publish your Gameplay Footage which has been obtained pursuant to this paragraph. The Gameplay Footage may be copyrighted, used and/or published individually or in conjunction with other photography or video works, and in any medium and for any lawful purpose, including without limitation, trade, exhibition, illustration, promotion, publicity, advertising and electronic publication. 
        </p>
        <p className="copy-body">
        You may contact us at any time to opt-out of the use of your Personal Information for marketing purposes, as further described in Sections  5 and 6 below.
        </p>
        <p className="copy-body">
          <i>Research and Development.</i> Virtuix may use Personal Information to create non-identifiable information that we may use alone or in the aggregate with information obtained from other sources, in order to help us improve our existing products and Services or develop new products and Services. From time to time, Virtuix may perform research (online and offline) via surveys. We may engage third party service providers to conduct such surveys on our behalf. All survey responses are voluntary, and the information collected will be used for research and reporting purposes to help us better serve Individuals by learning more about their needs and the quality of the products and services we provide. The survey responses may be utilized to determine the effectiveness of our Services, various types of communications, advertising campaigns and/or promotional activities. If an Individual participates in a survey, the information given will be used along with that of other study participants. We may share anonymous Individual and aggregate data for research and analysis purposes.
        </p>
        <p className="copy-body">
          <i>Direct Mail, Email and Outbound Telemarketing.</i> Individuals who provide us with Personal Information, or whose Personal Information we obtain from third parties, may, as permitted by law, receive periodic emails, newsletters, mailings, text messages or phone calls from us with information on Virtuix’s or our business partners’ products and services or upcoming special offers/events that we believe may be of interest. We offer the option to decline these communications at no cost to the Individual by following the instructions in Section 5 below.
        </p>
        <p className="copy-body">
          <i>Services via Apps and other Devices.</i> From time to time, Virtuix may provide Services that are specifically designed to be compatible and used on third party devices, such as mobile phones, smart TVs, and tablets. Virtuix will collect certain information that your device sends when you use such Services, like a device identifier, user settings, location information, carrier and the operating system of your device. These versions of Virtuix’s Services may require that users log in with an account. In such cases, information about use of these versions of the Services may be associated with accounts. In addition, Virtuix may enable you to download an application, widget or other tool that can be used on mobile, tablet or other devices. Some of these tools may store information on your devices. These tools may transmit Personal Information to Virtuix to enable you to access accounts and to enable Virtuix to track use of these tools. Some of these tools may enable users to export reports and other information from the tool. Virtuix may use personal or non-identifiable information transmitted to Virtuix to enhance these tools, to develop new tools, for quality improvement and as otherwise described in this Privacy Policy or in other notices Virtuix provides.
        </p>
        <p className="copy-body">
          <i>Anonymous and Aggregated Information Use.</i> Virtuix may use Personal Information and other information about you to create anonymized and aggregated information, such as de-identified demographic information, de-identified location information, performance and use information, information about the computer or device from which you access Virtuix’s Services or other analyses we create. Anonymized and aggregated information is used for a variety of functions, including the measurement of visitors’ interest in and use of various portions or features of the Services. Anonymized or aggregated information is not Personal Information, and Virtuix may use such information in a number of ways, including research, internal analysis, analytics and any other legally permissible purposes. We may share this information within Virtuix and with third parties for our or their purposes in an anonymized or aggregated form that is designed to prevent anyone from identifying you.
        </p>
        <p className="copy-body">
          <i>Sharing Content with Friends or Colleagues.</i> Virtuix’s Services may offer various tools and functionalities. For example, Virtuix may allow you to provide information about your friends through our referral services, such as “Refer a Friend.” Our referral services may allow you to forward or share certain content with a friend or colleague, such as an email inviting your friend to use our Services. Email addresses that you provide for a friend or colleague will be used to send your friend or colleague the content or link you request, but will not be used by Virtuix for any other purpose.
        </p>
        <p className="copy-body">
          <i>Other Uses.</i> Virtuix may use Personal Information for which we have a legitimate interest, such as fraud protection or compliance with legal obligations, or any other purpose disclosed to you at the time you provide Personal Information or with your consent.
        </p>
        <h3 className="copy-subheader">
          3.3 COOKIES AND SIMILAR TRACKING TECHNOLOGIES
        </h3>
        <p className="copy-body">
        We, as well as third parties that provide content, advertising or other functionality on our Services, may use cookies, pixel tags, local storage and other technologies including but not limited to the technologies listed below (“Technologies”) to automatically collect information through the Services. We use Technologies that are essentially small data files placed on your computer, tablet, mobile phone or other devices that allow us to record certain pieces of information whenever you visit or interact with our website, services, applications, messaging and tools, and to recognize you across devices.
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
              <i>Cookies.</i> Cookies are small text files placed in visitors’ computer browsers to store their preferences. Most browsers allow you to block and delete cookies. However, if you do that, the Site may not work properly.
            </li>
            <li>
              <i>Pixel Tags/Web Beacons.</i> A pixel tag (also known as a web beacon) is a piece of code embedded on the Site that collects information about users’ engagement on that web page. The use of a pixel allows us to record, for example, that a user has visited a particular web page or clicked on a particular advertisement.
            </li>
            <li>
              <i>Social Media Widgets.</i> The Site includes social media features such as links to Facebook, Instagram, Twitter, YouTube and LinkedIn (that might include widgets, such as, the share this button or other interactive mini-programs). These features may collect your IP address, which page you are visiting on the Site and may set a cookie to enable the feature to function properly. These social media features are either hosted by a third party or hosted directly on the Site. Your interactions with these features are governed by the privacy policy of the company providing it.
            </li>
            <li>
              <i>Analytics.</i> We may also use Google Analytics and Google Analytics Demographics and Interest Reporting to collect information regarding visitor behavior and visitor demographics on some of our Services and to develop website content. This analytics data is not tied to any Personal Information. For more information about Google Analytics, please visit www.google.com/policies/privacy/partners/. You can opt-out of Google’s collection and Processing of data generated by your use of the Services by going to <a
                href="https://tools.google.com/dlpage/gaoptout"
                className="copy-link-gray"
                target="_blank"
                rel="noreferrer"
              >
                https://tools.google.com/dlpage/gaoptout
              </a>
              .
            </li>
          </ul>
        </p>
        <p className="copy-body">
          Our uses of such Technologies fall into the following general
          categories:
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
              <i>Operationally Necessary.</i> We may use Technologies that are necessary to the operation of our Site, Services, applications, and tools. This includes Technologies that allow you access to our Site, Services, applications, and tools; that are required to identify irregular site behavior, prevent fraudulent activity and improve security; or that allow you to make use of functions such as shopping carts, saved search or similar functions;
            </li>
            <li>
              <i>Performance Related.</i> We may use Technologies to assess the performance of our Site, applications, Services and tools, including as part of our analytic practices to help us understand how our visitors use our Site, determine if you have interacted with our messaging, determine whether you have viewed an item or link, or to improve our website content, applications, services or tools;
            </li>
            <li>
              <i>Functionality Related.</i> We may use Technologies that allow us to offer you enhanced functionality when accessing or using our Sites, Services, applications or tools. This may include identifying you when you sign into our Sites or keeping track of your specified preferences, interests or past items viewed so that we may enhance the presentation of content on our Sites;
            </li>
            <li>
              <i>Advertising or Targeting Related.</i> We may use first-party or third party cookies and web beacons to deliver content, including ads relevant to your interests, on our Sites or on third party sites. This includes using technologies to understand the usefulness to you of the advertisements and content that has been delivered to you, such as whether you have clicked on an advertisement.
            </li>
          </ul>
        </p>
        <p className="copy-body">
        If you would like to opt-out of the Technologies we employ on our Sites, Services, applications or tools, you may do so by blocking, deleting or disabling them as your browser or device permits. See Section 5 for additional opt-out options. Please note that blocking, deleting, or disabling these Technologies will affect the functionality of our Sites, Services, applications and tools, and may prevent you from being able to access certain features.
        </p>
        <h3 className="copy-subheader">
          3.4 THIRD-PARTY WEBSITES AND SOFTWARE DEVELOPMENT KITS
        </h3>
        <p className="copy-body">
        The Services may contain links to other websites and other websites may reference or link to the Virtuix Site or other Services. These other domains and websites are not controlled by us, and Virtuix does not endorse or make any representations about third party websites or social media platforms. We encourage you to read the privacy policies of each and every website and application with which you interact. We do not endorse, screen or approve, and are not responsible for the privacy practices or content of such other websites or applications. Visiting other websites or applications is at your own risk.
        </p>
        <p className="copy-body">
        Virtuix’s Services may include publicly accessible blogs, community forums, message boards, private messaging or video chat features. The Services may also contain links and interactive features with various social media or music platforms (e.g., widgets). If you already use these platforms, their cookies may be set on your device when using the Services. You should be aware that Personal Information that you voluntarily include and transmit online in a publicly accessible blog, video chat session, social media platform or otherwise online, or that you share in an open forum, may be viewed and used by others without any restrictions. We are unable to control such uses of your information when you interact with a third party platform, and by using such services you assume the risk that the Personal Information provided by you may be viewed and used by third parties for any number of purposes.
        </p>
        <p className="copy-body">
        We may use third party software development kits (“SDKs”) as part of the functionality of our Services, and Virtuix products may contain wireless data transfer capabilities such as near field communication (or NFC) to facilitate pairing to devices using these SDKs and other wearable devices. We may also enable you to link your Virtuix account to certain health-related applications. If you choose to link your Virtuix account with such applications, you decide to share the health information we collect about you while you use the Services, including but not limited to your calories, walking and running distances, and time spent playing. Your interactions with SDKs or other health applications are governed by the privacy policy of the company providing the service.
        </p>
        <h3 className="copy-subheader">3.5 THIRD-PARTY PAYMENT PROCESSING</h3>
        <p className="copy-body">
        When you make purchases through the Services, we Process your payments through a third party application, such as an App Store or, in the case of online or in-person purchases via a Third-Party payment processor. In these instances, the third party application may collect certain Financial Information from you to Process a payment on behalf of Virtuix, including your name, email address, address and other billing information in which case the use and storage of your Financial Information is governed by the third party app’s terms, conditions and privacy policies. If you apply for or otherwise take part in a financing offer through one of our financing partners, you will be asked to provide information (which may include Financial Information) to that financing partner, either directly or through the Services. The use of that information by the financing partner will be subject to the financing partner’s terms, conditions and privacy policies. Our treatment of any Financial Information that we may Process on your behalf, or that we receive from our financing partners or payment processors, is subject to this Privacy Policy. For more information on our use of Third-Party payment processors and financing partners, including the name(s) of the entities that we partner with, please email <a href="mailto:info@virtuix.com" className="copy-link-gray">
            info@virtuix.com
          </a>
          .
        </p>
        <h3 className="copy-subheader">
          4. WHO WE SHARE YOUR PERSONAL INFORMATION WITH
        </h3>
        <h3 className="copy-subheader">4.1 INFORMATION WE SHARE</h3>
        <p className="copy-body">
        We may share your information as described in this Privacy Policy (e.g., with our third party service providers; to comply with legal obligations; to enhance the Services, to protect and defend our rights and property) or with your permission.
        </p>
        <p className="copy-body">
          <i>We Use Vendors and Service Providers.</i> We may share any information we receive with vendors and service providers. The types of service providers to whom we entrust Personal Information include service providers for:
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>provision of IT, analytics and related services;</li>
            <li>provision of information and services you have requested;</li>
            <li>payment processing;</li>
            <li>
              customer service activities such as deliveries and member support;
              and
            </li>
            <li>
            in connection with the provision of the Services. Virtuix’s policy is to execute appropriate contracts with the service providers that prohibit them from using or sharing Personal Information except as necessary to perform the contracted services on our behalf or to comply with applicable legal requirements, and this Privacy Policy.
            </li>
          </ul>
        </p>
        <p className="copy-body">
          <i>Content Developers.</i> We make certain data related to your User Profile available to our content development partners through an API. At a minimum, your username is accessible this way. Depending on which services are implemented in the game it may also include leaderboard information, your rewards progress, saved game information, achievements you have completed, your multiplayer game matchmaking information, your location, your friends list, date of birth, in-game items, and other information needed to operate the game and provide support for it.
        </p>
        <p className="copy-body">
          <i>Business Partners.</i> Virtuix may share Personal Information with our business partners and affiliates for our and our affiliates’ internal business purposes or to provide you with a product or service that you have requested. Virtuix may also provide Personal Information to business partners with whom we may jointly offer products or services, or whose products or services we believe may be of interest to you. Your Personal Information will be used in accordance with this Privacy Policy and only as far as this is necessary for providing such third-party products or services, including but not limited to marketing contests and events, or other events or promotions organized by third party providers.
        </p>
        <p className="copy-body">
          <i>Displaying to Other Users.</i> The content you post to the Services may be displayed on the Services. Other users of the Services may be able to see some information about you, such as the information of your User Profile, or your name if you submit a review or provide a testimonial to our Site. We are not responsible for the privacy practices of the other users who view and use posted information.
        </p>
        <p className="copy-body">
          <i>Interest-Based Advertising and Third-Party Marketing.</i> Virtuix may allow third party advertising partners to set tracking tools (e.g., cookies) to collect information regarding your activities on our Sites (e.g., your IP address, page(s) visited, time of day). We may also share such de-identified information as well as selected Personal Information (such as demographic information and past purchase history) we have collected with third party advertising partners. These advertising partners may use this information (and similar information collected from other companies) for purposes of delivering targeted advertisements to you when you visit non-Virtuix related websites within their networks. This practice is commonly referred to as “interest-based advertising” or “online behavioral advertising”. We may allow access to other data collected by the Site to facilitate transmittal of information that may be useful, relevant, valuable or otherwise of interest to you. If you prefer that we do not share your Personal Information with third party advertising partners, you may opt-out of such sharing at no cost by following the instructions in Section 5 below.
        </p>
        <p className="copy-body">
          <i>
            Disclosures to Protect Us or Others (e.g., as Required by Law and
            Similar Disclosures).
          </i>{" "}
          We may access, preserve and disclose your Personal Information, other account information and content if we believe doing so is required or appropriate:
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
            to comply with law enforcement or national security requests and legal process, such as, a court order or subpoena;
            </li>
            <li>to respond to your requests;</li>
            <li>
              to protect yours, ours or others’ rights, property or safety;
            </li>
            <li>to enforce Virtuix policies or contracts;</li>
            <li>to collect amounts owed to Virtuix;</li>
            <li>to comply with records retention policies;</li>
            <li>
            when we believe access, preservation, or disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation or prosecution of suspected or actual illegal activity; or
            </li>
            <li>
            if we, in good faith, believe that access, preservation, or disclosure is otherwise necessary or advisable. In addition, from time to time, server logs may be reviewed for security purposes – e.g., to detect unauthorized activity on the Services. In such cases, server log data containing IP addresses may be shared with law enforcement bodies in order to allow them to identify users in connection with their investigation of the unauthorized activities.
            </li>
          </ul>
        </p>
        <p className="copy-body">
          <i>
            Investors and Potential Buyers (and their agents and advisors).
          </i>{" "}
          We may share your personal information with our investors and actual or potential buyers (and their agents and advisors) in connection with any actual or proposed purchase, merger, acquisition, financing, reorganization, bankruptcy, receivership, sale of company assets or transition of service to another provider, provided that we inform the buyer or transferee that it must use your personal information only for the purposes disclosed in this Privacy Policy.
        </p>
        <p className="copy-body">
          <i>Merger, Sale, or Other Asset Transfers.</i> If we are involved in a merger, acquisition, financing, reorganization, bankruptcy, receivership, sale of company assets or transition of service to another provider, then your information may be sold or transferred as part of such a transaction as permitted by law and/or contract. In such event, Virtuix will endeavor to direct the transferee to use Personal Information in a manner that is consistent with the Privacy Policy in effect at the time such Personal Information was collected.
        </p>
        <h3 className="copy-subheader">4.2 INTERNATIONAL DATA TRANSFERS</h3>
        <p className="copy-body">
        You agree that all Personal Information collected via or by Virtuix may be transferred, Processed and stored anywhere in the world, including but not limited to, the United States, Canada, the European Union, in the cloud, on our servers, on the servers of our affiliates or on the servers of our service providers. Your Personal Information may be accessible to law enforcement or other authorities as described above. By providing information to Virtuix, you explicitly consent to the storage of your Personal Information in these locations.
        </p>
        <h3 className="copy-subheader">5. OPTING-OUT</h3>
        <h3 className="copy-subheader">5.1 GENERAL</h3>
        <p className="copy-body">
        You have the right to object to and opt-out of certain uses and disclosures of your Personal Information. Where you have consented to Virtuix’s Processing of your Personal Information or Sensitive Personal Information, you may withdraw that consent at any time and opt-out to further Processing by submitting a request to <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a>
          .
        </p>
        <p className="copy-body">
        Please note, even if you opt-out, we may still collect and use non-Personal Information regarding your activities on our Service and/or information from the advertisements on third party websites for non-interest based advertising purposes, such as, to determine the effectiveness of the advertisements.
        </p>
        <h3 className="copy-subheader">
          5.2 EMAIL, TEXT AND TELEPHONE COMMUNICATIONS
        </h3>
        <p className="copy-body">
        You can unsubscribe from email communications at any time. To stop receiving our promotional emails, follow the unsubscribe instructions in the email messages you receive from us or contact us as described in Section 11. We will process your request within a reasonable time after receipt, in accordance with applicable laws. Note that you will continue to receive transaction-related emails regarding products or services you have requested. We may also send you certain non-promotional communications regarding Virtuix and our Services and, to the extent permitted by law, you will not be able to opt-out of those communications (e.g., communications regarding updates to our <Link to="/terms-of-use" className="copy-link-to-gray">
            Terms
          </Link>{" "}
          or this Privacy Policy).
        </p>
        <p className="copy-body">
        If you opt in, you may receive text messages from Virtuix. “Virtuix Account” text messages are non-promotional messages from Virtuix alerting you to important information, like the details of your delivery, failed subscription payments and other alerts related to your Virtuix subscription or purchases. To opt-out of receiving “Virtuix Account” text messages, please reply STOP to any of such messages. We will send you one final message to confirm that you have been unsubscribed, and will process your request within a reasonable time after receipt, in accordance with applicable laws. If you experience any issues with text messages from Virtuix, email our Support team at <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a>
          . Carriers are not liable for delayed or undelivered messages. Message frequency varies. Message and data rates may apply.
        </p>
        <p className="copy-body">
        We maintain “do-not-call” and “do-not-mail” lists as mandated by law. We process requests to be placed on do-not-mail, do-not-phone and do-not-contact lists within 60 days after receipt, or such shorter time as may be required by law.
        </p>
        <h3 className="copy-subheader">5.3 MOBILE DEVICES</h3>
        <p className="copy-body">
        Virtuix may occasionally send you push notifications through our Apps with updates, achievements and other notices that may be of interest to you. You may at any time opt-out from receiving these types of communications by changing the settings on your device. Virtuix may also collect location-based information if you use our Apps. You may opt-out of this collection by changing the settings on your device.
        </p>
        <h3 className="copy-subheader">5.4 “DO NOT TRACK”</h3>
        <p className="copy-body">
        Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. DNT is a way for users to inform websites and services that they do not want certain information about their webpage visits collected over time and across websites or online services. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers.
        </p>
        <h3 className="copy-subheader">
          5.5 COOKIES AND INTEREST-BASED ADVERTISING
        </h3>
        <p className="copy-body">
        As noted above, you may stop or restrict the placement of cookies on your computer or remove them from your browser by adjusting your web browser preferences. Please note that cookie-based opt-outs are not effective on mobile applications. However, on many mobile devices, application users may opt-out of certain mobile advertisements via their device settings.
        </p>
        <p className="copy-body">
        The online advertising industry also provides websites from which you may opt-out of receiving targeted ads from our data partners and our other advertising partners that participate in self-regulatory programs. You can access these, and also learn more about targeted advertising and consumer choice and privacy, at www.networkadvertising.org/managing/opt_out.asp, www.youronlinechoices.eu/ or www.youradchoices.ca/, and www.aboutads.info/choices/. You can also choose not to be included in Google Analytics by emailing <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a>
          .
        </p>
        <p className="copy-body">
        To be clear, whether you are using our opt-out or an online industry opt-out, these cookie-based opt-outs must be performed on each device and browser that you wish to have opted-out. For example, if you have opted-out on your computer browser, that opt-out will not be effective on your mobile device. You must separately opt-out on each device. Advertisements on third party websites that contain the AdChoices link, and that link to this Privacy Policy, may have been directed to you based on anonymous, non-Personal Information collected by advertising partners over time and across websites. These advertisements provide a mechanism to opt-out of the advertising partners’ use of this information for interest-based advertising purposes.
        </p>
        <h3 className="copy-subheader">
          6. RIGHTS OF ACCESS, RECTIFICATION, ERASURE, AND RESTRICTION
        </h3>
        <p className="copy-body">
        You have the right to access your Personal Information that we hold about you, i.e., the right to require information whether your Personal Information is retained, access to and/or duplicates of the Personal Information retained. To submit an access request to your Personal Information, or for all other questions related to privacy, please contact us at <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a>
          .
        </p>
        <p className="copy-body">
        Although Virtuix makes good faith efforts to provide Individuals with access to their Personal Information, there may be circumstances in which Virtuix is unable to provide access, including but not limited to: where the information contains legal privilege, would compromise others’ privacy or other legitimate rights, where the burden or expense of providing access would be disproportionate to the risks to the Individual’s privacy in the case in question, where the request is frivolous or extremely impractical, where it is not required by law, or where it is commercially proprietary. If Virtuix determines that access should be restricted in any particular instance, we will provide you with an explanation of why that determination has been made and a contact point for any further inquiries. To protect your privacy, Virtuix will take commercially reasonable steps to verify your identity before granting access to or making any changes to your Personal Information.
        </p>
        <p className="copy-body">
        If we process your Personal Information, we shall endeavor to ensure by implementing suitable measures that your Personal Information is accurate and up-to-date for the purposes for which it was collected. If your Personal Information is inaccurate or incomplete, you can change the information you provided by logging into your user account.
        </p>
        <p className="copy-body">
        You have the right to obtain deletion of Personal Information concerning you if the reason why we could collect it does not exist anymore or if there is another legal ground for its deletion. For individual items of Personal Information please edit them through your user account or request the deletion via email at <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a>
          .
        </p>
        <p className="copy-body">
        When our processing of your Personal Information is based on legitimate interests according to Article 6(1)(f) of the GDPR / Section 2(c) of this Privacy Policy, you have the right to object to this processing. If you object we will no longer process your Personal Information unless there are compelling and prevailing legitimate grounds for the processing as described in Article 21 of the GDPR; in particular if the data is necessary for the establishment, exercise or defense of legal claims.
        </p>
        <p className="copy-body">
        In some circumstances you have the right to restrict the processing of your Personal Information. Those circumstances are as follows: you contest the accuracy of the Personal Information; processing is unlawful but you oppose erasure; we no longer need the Personal Information for the purposes of our processing, but you require Personal Information for the establishment, exercise or defense of legal claims; and you have objected to processing, pending the verification of that objection. Where processing has been restricted on this basis, we may continue to store your Personal Information. However, we will only otherwise process it: with your consent; for the establishment, exercise or defense of legal claims; for the protection of the rights of another natural or legal person; or for reasons of important public interest.
        </p>
        <p className="copy-body">
        If applicable, you have the right to lodge a complaint about Virtuix’s practices with respect to your Personal Information with the relevant supervisory authority of your habitual residence, your place of work or the place of the alleged infringement.
        </p>
        <p className="copy-body">
        We also reserve the right to charge a reasonable fee in light of the administrative costs of providing the information or communication or taking the action requested. In some cases, we may also need to you to provide us with additional information, which may include Personal Information, if necessary to verify your identity and the nature of your request.
        </p>
        <h3 className="copy-subheader">7. DATA RETENTION</h3>
        <p className="copy-body">
        Virtuix retains the Personal Information we receive as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements and comply with applicable laws. When we are no longer required to retain your Personal Information as described above, we will destroy, erase, or de-identify it in accordance with our data retention policies and applicable law. Legal requirements, however, may require us to retain some or all of the Personal Information we hold for a period of time that is longer than that for which we might otherwise hold it.
        </p>
        <h3 className="copy-subheader">8. SECURITY OF YOUR INFORMATION</h3>
        <p className="copy-body">
        We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy. We maintain commercially reasonable administrative, technical and physical safeguards (which vary depending on the sensitivity of the Personal Information) designed to protect against unauthorized use, disclosure or access of Personal Information. Unfortunately, the Internet cannot be guaranteed to be 100% secure, and we cannot ensure or warrant the security of any information you provide to us. We do not accept liability for unintentional disclosure.
        </p>
        <p className="copy-body">
        By using the Services or providing Personal Information to us, you agree that we may communicate with you electronically regarding security, privacy and administrative issues relating to your use of the Services. If we learn of a security breach, we may attempt to notify you electronically by posting a notice on the Services or sending an email to you. You may have a legal right to receive this notice in writing.
        </p>
        <h3 className="copy-subheader">9. NON-U.S. USERS</h3>
        <p className="copy-body">
        By using the Services, you consent to Virtuix’s transfer of your data to the United States. By choosing to visit the Site, use the Services, or otherwise provide information to us, you agree that any dispute over privacy or the terms contained in this Privacy Policy will, to the extent permitted by law, be governed by the laws of the State of Texas, without regard to principles of conflicts of law. Any disputes arising in connection with this Privacy Policy will be handled in accordance with the dispute resolution provisions in the Terms.
        </p>
        <p className="copy-body">
        If you are visiting from the European Union or other regions with laws governing data collection and use, please note that you are agreeing to the transfer of your information to the United States and to the Processing of your data globally. By providing your Personal Information, you consent to any transfer and Processing in accordance with this Privacy Policy.
        </p>
        <h3 className="copy-subheader">10. CHILDREN’S PRIVACY</h3>
        <p className="copy-body">
        Our Services are not directed to children under the age of 13. In addition, you are not permitted to use our Services if you do not meet the minimum age requirement applicable to our Services in your jurisdiction. We do not knowingly collect Personal Information from children under the age of 13. If you learn that your child has provided us with Personal Information without your consent, you may alert us at <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a>
          . If we learn that we have collected Personal Information of a child under the age of 13 (or under the age of 16 in certain jurisdictions, such as EU member countries) we will take steps to delete such information from our files as soon as possible and terminate the child’s account unless we receive verifiable parental consent.
        </p>
        <h3 className="copy-subheader">
          11. REDRESS/COMPLIANCE AND ACCOUNTABILITY
        </h3>
        <p className="copy-body">
        If you have any questions about our privacy practices, this Privacy Policy, or how to submit a complaint with the appropriate authority, please contact Virtuix by email at <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a>{" "}
          or by mail at the address below:
        </p>
        <p className="copy-body">
          Virtuix Inc.
          <br />
          1826 Kramer Lane, Suite H<br />
          Austin, TX 78758
          <br />
          USA
        </p>
        <p className="copy-body">
          We or our Data Protection Officer will address your concerns and
          attempt to resolve any privacy issues in a timely manner.
        </p>
        <h3 className="copy-subheader">
          12. CHANGES TO OUR PRIVACY POLICY AND PRACTICES
        </h3>
        <p className="copy-body">
        This Privacy Policy may change from time to time. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if you use the Services after the updated Privacy Policy is posted on the Services (as reflected in the below Effective Date) at <a href="https://virtuix.com/privacy-policy" className="copy-link-gray">
          https://virtuix.com/privacy-policy</a>. If at any point you do not agree to any portion of the Privacy Policy, you must immediately stop using the Services.
        </p>
        <p className="copy-body">
          <i>Revisions to the Privacy Policy.</i> We may revise this Privacy Policy in our sole discretion, so please review it periodically. If you continue to visit the Site and use the Services after such changes have been made, you thereby provide your consent to the changes.
        </p>
        <p className="copy-body">
          <i>Posting of Revised Privacy Policy.</i> If there are any material changes to this Privacy Policy, Virtuix will notify you by email or as otherwise required by applicable law. We will post any adjustments to the Privacy Policy on this page, and the revised version will be effective immediately when it is posted (or upon notice if applicable). If you are concerned about how your information is used, read this Privacy Policy periodically.
        </p>
        <p className="copy-body">
          <i>New Uses of Personal Information.</i> We may use your Personal Information for any new purpose not originally authorized by you. If so, we will endeavor to provide information regarding the new purpose and give you the opportunity to opt-out, where possible. Where consent of the Individual for the Processing of Personal Information is required by law or contract, Virtuix will endeavor to comply with the law or contract.
        </p>
        <h3 className="copy-subheader">13. DEFINITIONS</h3>
        <p className="copy-body">
          The following capitalized terms have the meanings set forth below.
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
              <i>“Agent”</i> means any Third-Party that Processes Personal Information pursuant to the instructions of, and solely for, Virtuix or to which Virtuix discloses Personal Information for use on its behalf.
            </li>
            <li>
              <i>“Employee”</i> refers to any current, temporary, permanent, prospective or former employee, director, contractor, worker, or retiree of Virtuix or its subsidiaries worldwide.
            </li>
            <li>
              <i>“Personal Information”</i> is any information relating to an identified or identifiable natural person (“Individual”).
            </li>
            <li>
              <i>“Process”</i> or <i>“Processing”</i> means any operation which is performed upon Personal Information, whether or not by automatic means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure, or destruction.
            </li>
            <li>
              <i>“Sensitive Data”</i> or{" "}
              <i>“Sensitive Personal Information”</i> is a subset of Personal Information which, due to its nature, has been classified by law or by policy as deserving additional privacy and security protections, such as certain financial or medical information, and Personal Information regarding EU residents that is classified as a “Special Category of Personal Data” under EU law, such as:
            </li>
            <ol className="copy-list-lower-alpha copy-list-indent">
              <li>race or ethnic origin;</li>
              <li>political opinions;</li>
              <li>religious or philosophical beliefs;</li>
              <li>trade union membership;</li>
              <li>genetic data;</li>
              <li>
                biometric data where Processed to uniquely identify a person;
              </li>
              <li>health information;</li>
              <li>
                sexual orientation or information about the Individual’s sex
                life; or
              </li>
              <li>
                information relating to the commission of a criminal offense.
              </li>
            </ol>
            <li>
              <i>“Third-Party”</i> is any company, natural or legal person, public authority, agency, or body other than the Individual, Virtuix or Virtuix’s Agents.
            </li>
          </ul>
        </p>
        <p id="lastupdated" className="copy-body">
          <b>Last Updated: November 26, 2022</b>
        </p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
