import React from "react";
import "./OmniArenaSelect.css";
import { Button } from "../../Button";

function OmniArenaSelect() {
  return (
    <>
      <div className="omni-arena-select-bg">
        <div className="omni-arena-select-container">
          <div className="omni-arena-select-flex">
            <div className="omni-arena-select-business-img-wrap">
              <div className="omni-arena-select-text-container">
              <div className="omni-arena-select-text-wrap">
                <div className="omni-arena-select-topline">Business Owners</div>
                <h2 className="omni-arena-select-heading">
                  Bring Omni Arena to your entertainment venue
                </h2>
                <a
                  href="https://enterprise.virtuix.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button buttonSize="btn-wide" buttonColor="green">
                    Learn More
                    <div className="button-right-arrow">
                      <img src="/images/arrow-icon-now.svg" alt="arrow" />
                    </div>
                  </Button>
                </a>
              </div>
              </div>
            </div>
            <div className="omni-arena-select-players-img-wrap">
              <div className="omni-arena-select-text-container">
                <div className="omni-arena-select-text-wrap">
                  <div className="omni-arena-select-topline">Players</div>
                  <h2 className="omni-arena-select-heading">
                    Play the most advanced VR system at a location near you
                  </h2>
                  <a
                    href="https://arena.virtuix.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button buttonSize="btn-wide" buttonColor="green">
                      Play Omni Arena
                      <div className="button-right-arrow">
                        <img src="/images/arrow-icon-now.svg" alt="arrow" />
                      </div>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OmniArenaSelect;
