import React from "react";
import { Link } from "react-router-dom";
import "./AboutSection.css";
import { Button } from "./Button";

function AboutSection() {
    return (
        <div className="about-section-dark-bg">
            <div className="about-section-container">
                <h1 className="about-section-heading">
                    Who we are
                </h1>
                <div className="about-section-subtitle">
                Meet the team that is dedicated to making VR locomotion a reality.
                </div>
                <div className="about-section-btn-container">
                    <Link to="/who-we-are">
                <Button buttonSize="btn-wide" buttonColor="green">
                    Learn More 
                    <div className="button-right-arrow">
                      <img src="/images/arrow-icon-now.svg" alt="arrow" />
                    </div>
                  </Button>
                  </Link>
                  <Link to="/careers" className="about-section-join">
                    Join Our Team 
                    <div className="button-right-arrow">
                      <img src="/images/arrow-icon-now-white.svg" alt="arrow" />
                    </div>
                  </Link>
                </div>
                <img className="about-section-img-large" src="/images/oa_enterprise_team_8.jpg" alt="Team working" />
            </div>            
          <hr className="footer-hr" />
        </div>
);
}

export default AboutSection;