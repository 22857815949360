import React from "react";
import { Link } from "react-router-dom";
import "./AboutUs.css";
import { Button } from "../../Button";
import HiringSection from "../../Hiring";
import AboutHeroSection from "../../AboutHeroSection";
import { aboutObjOne, aboutObjTwo, aboutObjThree, aboutObjFour } from './Data';
import AboutHomeHeroSection from "../../AboutHomeHeroSection";
import LazyAnimation from "../../LazyAnimation";

function AboutUs() {
  return (
    <>
      <div className="about-us-hero-bg">
        <div className="about-us-container">
        <div className="about-us-hero">
          <h1 className="about-us-title">Who We Are</h1>
          <LazyAnimation></LazyAnimation>
          <h2 className="about-us-subtitle">
          We're passionate about gaming, technology, and creating the future of immersive entertainment. When we're not hard at work bringing new Omni products to life, we're probably discussing the latest superhero movie or what games we're currently playing.
          </h2>
          <div className="about-img-quote">
            <img className="about-headshot-img" src="/images/JanG_2020.jpg" alt="Jan Goetgeluk, Founder and CEO" />
            <div className="about-quote">
            “We aim to bring our popular gaming experience to millions of homes around the world.”
            </div>
            <div className="about-quote-title">
            Jan Goetgeluk, Founder and CEO
            </div>
          </div>
        </div>
        </div>
      </div>
      <AboutHeroSection {...aboutObjTwo} />
      <AboutHeroSection {...aboutObjThree} />
      <AboutHeroSection {...aboutObjFour} />
      <HiringSection />
    </>
  );
}

export default AboutUs;
