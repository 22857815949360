import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./InvestorsForm.css";

function InvestorsForm() {
  const form = useRef();
  const successMessage = "Request received. Thank you.";

  const [successText, setSuccessText] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [faded, setFaded] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
        "service_20ydkup",
        "template_sb2l4qc",
        form.current,
        "scRRIe4BK7-eS0SnY"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    // e.target.reset();
    setSuccessText(true);
    setIsDisabled(true);
    setFaded(true);
  };

  return (
    <>
      <div className="investors-copy-container">
        <p className="investors-copy-body">
          We make our company financials and other information available to all
          our investors. To access our financial statements, please fill out the
          form below, and a Virtuix team member will get back to you shortly.
        </p>
        <p className="investors-copy-body-note">
            Note: Only current Virtuix investors will receive a response.
        </p>
      </div>
      <div className="investors-form-container">
        <form ref={form} onSubmit={sendEmail}>
          <div className="investors-form-label">First Name (Required)</div>
          <input className={ faded ? 'investors-form-input investors-form-input-faded': 'investors-form-input'} type="text" name="user_firstname" required disabled={isDisabled} />
          <div className="investors-form-label">Last Name (Required)</div>
          <input className={ faded ? 'investors-form-input investors-form-input-faded': 'investors-form-input'} type="text" name="user_lastname" required disabled={isDisabled} />
          <div className="investors-form-label">Email (Required)</div>
          <input className={ faded ? 'investors-form-input investors-form-input-faded': 'investors-form-input'} type="email" name="user_investoremail" required disabled={isDisabled} />        
          <input className={ faded ? 'investors-form-submit-faded': 'investors-form-submit'} type="submit" value="Submit" disabled={isDisabled} />
          {successText && (<div className="investors-form-success">{ successMessage }</div>)}          
        </form>
      </div>
        <div className="investors-form-img-row">
            <img className="investors-form-img" src="/images/virtuix_omniarena_img_001.jpg" alt="Omni Arena" />
            <img className="investors-form-img" src="/images/virtuix_intro_o1_featured.jpg" alt="Player on Omni One" />
            <img className="investors-form-img" src="/images/omniplayer_section4_playerlaugh.jpg" alt="Player having fun at Omni Arena" />
        </div>
    </>
  );
}

export default InvestorsForm;
