import React from "react";
import "./OmniArenaResourcesCopy.css";
import ReactPlayer from "react-player";
import { Button } from "./Button";

function OmniArenaResourcesCopy() {
  return (
    <>
      <div className="oa-resources-copy-container">
        <h1 className="oa-resources-copy-title">Operating Manuals</h1>
        <p className="oa-resources-copy-body">
        Download the complete Omni Arena Operator Manual or two summary two-pagers that you can keep handy for staff inside the attraction.
        </p>
        <p className="oa-resources-copy-body">
            <ul className="oa-resources-copy-list-disc oa-resources-copy-list-indent">
                <li><a href="https://dl.dropboxusercontent.com/s/2vwdjzt7ulb32wi/Omni%20Arena%20-%20Operator%20Manual.pdf?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Omni Arena Operator Manual</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/quro1w8y9qtyhaf/Omni%20Arena%20-%20Key%20Operating%20Guidelines.pdf?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Two-Pager: Key Operating Guidelines</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/gj76t5cfpwngq0x/Omni%20Arena%20-%20Troubleshooting%20Guide.pdf?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Two-Pager: Troubleshooting</a></li>
                <li><a href="https://virtuix.com/uploads/Omni%20Arena%20-%20Attendant%20Cheat%20Sheet.pdf" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Two-Pager: Attendant Sheet</a></li>
            </ul>
        </p>
        <a href="https://virtuix.com/uploads/Operating%20Manuals.zip" target="_blank" rel="noreferrer"><Button buttonSize="btn-wide" buttonColor="green">
            Download All Manuals
        </Button></a>
        <h1 className="oa-resources-copy-title oa-resources-copy-margin-top">Marketing Guide</h1>
        <p className="oa-resources-copy-body">
        Download our marketing guide with tips on how to promote Omni Arena via social media, SEO, a launch event, and more.
        </p>
        <a href="https://dl.dropboxusercontent.com/s/8q5fn2u4oss4hxe/Omni%20Arena%20-%20Marketing%20Guide%20v1.9.pdf?dl=1" target="_blank" rel="noreferrer"><Button buttonSize="btn-wide" buttonColor="green">
            Download Marketing Guide
        </Button></a>
        <h1 className="oa-resources-copy-title oa-resources-copy-margin-top">On-Site Marketing Assets</h1>
        <p className="oa-resources-copy-body">
            <img className="oa-resources-copy-img-wrapper" src="/images/oaresources_onsitemarket_img_708px.png" alt="" />
        </p>
        <p className="oa-resources-copy-body">
        Download, customize, and print these banners, standees, and other signs that you can set up at your venue to promote Omni Arena.
        </p>
        <p className="oa-resources-copy-body">
            <ul className="oa-resources-copy-list-disc oa-resources-copy-list-indent">
                <li><a href="https://dl.dropboxusercontent.com/s/aq86alt6ifbkuan/Omni%20Arena%20-%20SOLDIERS%20Banner%2033x80.psd?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">“Soldiers” banner</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/b4vkupz3xlxrd2l/Omni%20Arena%20-%20ZOMBIES%20Banner%2033x80.psd?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">“Zombies” banner</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/ousluflcx5bamge/Omni%20Standees.zip?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Omni standees</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/sif0hlcx9uu6lxg/Omni%20Arena%20-%20Ceiling%20Sign%2048x96.pdf?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Ceiling sign</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/joiaygnbqd57dh7/Omni%20Arena%20-%20Floor%20Sign%2024x36.pdf?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Floor sign</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/qihrhgb0mt1xqu5/Omni%20Arena%20-%20Desk%20Signs.zip?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Desk signs</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/33hkm81q4uaefzk/Omni%20Arena%20-%20Game%20Info%20Sheet%20for%20front%20desk.pdf?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Game Info sheet</a></li>
                <li><a href="https://dl.dropboxusercontent.com/scl/fi/exy015gyv51w178zxoixg/Omni%20Arena%20Contest%20Prizes%20and%20Rules%208.5x11.pdf?rlkey=qb8314ib3dyes8h6l53guko36&dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Contest Prizes & Rules sheet</a></li>
                <li><a href="https://dl.dropboxusercontent.com/scl/fi/g92vbrww0xxmtmjzpckj8/Omni-Arena-The-Future-is-Here-Flyer-8.5x11.pdf?rlkey=uekkp2d9pselo6p9a5odo9mv6&dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">The Future is Here flyer</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/t2acay1xb0wb013/Rhythm%20Master%20-%20New%20Game%20-%20Desk%20Sign.pdf?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Rhythm Master “New Game” Desk Sign</a></li>
            </ul>
        </p>
        <a href="https://dl.dropboxusercontent.com/s/b6h0lsbeu62kw58/On-site%20Marketing%20Assets.zip?dl=1" target="_blank" rel="noreferrer"><Button buttonSize="btn-wide" buttonColor="green">
            Download All Marketing Assets
        </Button></a>
        <h1 className="oa-resources-copy-title oa-resources-copy-margin-top">Assets for Social Media and Print</h1>
        <p className="oa-resources-copy-body">
            <img className="oa-resources-copy-img-wrapper" src="/images/oaresources_assetsforsm_img_708px.png" alt="" />
        </p>
        <p className="oa-resources-copy-body">
        Download and customize these assets for social media marketing or for printing out.
        </p>
        <p className="oa-resources-copy-body">
            <ul className="oa-resources-copy-list-disc oa-resources-copy-list-indent">
                <li><a href="https://dl.dropboxusercontent.com/s/salbjyoieonbrcn/Omni%20Arena%20Esports%20Flyer.pdf?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Omni Arena Esports flyer</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/452qwp6s60304sz/Omni%20Arena%20Weekly%20Contests.zip?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Weekly Contest flyers</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/4bkubfrfj3i8erx/Omni%20Arena%20Monthly%20Contests.zip?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Monthly Contest flyers</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/hw5zxeonlbyt75e/Omni%20Arena%20-%20Napkin%20Holder%206.5x5.5.pdf?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Napkin holder</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/8yxq14xhwskdixo/Blackbeard_PlayToday_Flyer.pdf?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Blackbeard Flyer</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/rh2djbvb0888te8/Blackbeard_PlayToday_SocialPost.jpg?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Blackbeard Image for Social Media</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/wn0dm6ly7i1crrh/Dead%20Zone%20Images%20for%20Social%20Media.zip?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Dead Zone Images for Social Media</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/6vfgvs3uaz3weuu/Paranormal%20Images%20for%20Social%20Media.zip?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Paranormal Images for Social Media</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/c9v4g9uqfx2qym1/Gunslinger%20Images%20for%20Social%20Media.zip?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Gunslinger Images for Social Media</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/cioeejbojgwxmo5/Rhythm%20Master%20Images%20for%20Social%20Media.zip?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Rhythm Master Images for Social Media</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/qaxx7ygxcgyucze/Omni%20Arena%20-%20Groupon%20Images.zip?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Groupon Images</a></li>
            </ul>
        </p>
        <a href="https://dl.dropboxusercontent.com/s/kj7s2soy1rdp2sm/Flyers%20for%20Social%20Media%20and%20Print.zip?dl=1" target="_blank" rel="noreferrer"><Button buttonSize="btn-wide" buttonColor="green">
            Download All Assets
        </Button></a>
        <h1 className="oa-resources-copy-title oa-resources-copy-margin-top">Promo Videos</h1>
        <div className="oa-resources-copy-player-container">
        <div className="player-wrapper">
        <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=BTVtL1TVa-I"
            width="100%"
            height="100%"
          />
          </div>
          </div>
        <p className="oa-resources-copy-body">
          Download these videos for display at your venue or for social media marketing.
        </p>
        <p className="oa-resources-copy-body">
            <ul className="oa-resources-copy-list-disc oa-resources-copy-list-indent">
                <li><a href="https://dl.dropboxusercontent.com/s/qivzxwhpb2hhfsk/Omni%20Arena%20-%20Gameplay%20and%20Trailers%20Montage.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Omni Arena Gameplay Montage</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/vc57namrouwil2q/Omni%20Arena%20-%20Game%20Art%20and%20Screens%20Montage.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Omni Arena Game Art Montage</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/ql0gazhfjh1lpsr/Omni%20Arena.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Omni Arena Promo Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/yvsvn1xonj668js/Omni%20Arena%20-%20Instagram%20Video.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Omni Arena Instagram Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/bbbe23xz969ayzs/Omniverse%20Esports%20Instagram%20Video.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Omniverse ESPORTS Instagram Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/hbzl1eab677jk6a/Omniverse%20ESPORTS%20Promo%20Video.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Omniverse ESPORTS Promo Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/zojforym4yckzhq/Omni%20Arena%20-%20Elite%20Force.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Elite Force Trailer Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/c2bmvv1pysly22v/Omni%20Arena%20%E2%80%93%20Elite%20Force%20Gameplay%20Video.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Elite Force Gameplay Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/6bnrc793ryaqas5/Omni%20Arena%20-%20Elite%20Force%20Gameplay%20Video%2030%20Seconds.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Elite Force Gameplay Instagram Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/8kblhvv515na19t/Omni%20Arena%20-%20Blackbeard%20Trailer.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Blackbeard Trailer</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/mgfwk80v41bc2s3/Omni%20Arena%20-%20Blackbeard%20Gameplay%20Video.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Blackbeard Gameplay Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/o7nfpe891931j6h/Omni%20Arena%20-%20Blackbeard%20Gameplay%20Video%20%2830%20Seconds%29.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Blackbeard Gameplay Instagram Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/fbi0ihchus9wu5b/Omni%20Arena%20-%20Dead%20Zone%20Trailer.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Dead Zone Trailer</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/ww3bv4adveislse/Omni%20Arena%20-%20Dead%20Zone%20Gameplay%20Video.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Dead Zone Gameplay Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/vccwp4b33z4u518/Omni%20Arena%20-%20Dead%20Zone%20Gameplay%20Video%2030%20Seconds.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Dead Zone Gameplay Instagram Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/p8l6s02sx1ddibw/Omni%20Arena%20-%20Paranormal.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Paranormal Trailer</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/z26i8kj26f1wblg/Omni%20Arena%20-%20Paranormal%20Gameplay%20Video.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Paranormal Gameplay Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/1dncwfzy0wtltb6/Omni%20Arena%20-%20Paranormal%20Gameplay%20Video%20Instagram.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Paranormal Gameplay Instagram Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/xdvqnbcmzke2utl/Omni%20Arena%20-%20Gunslinger.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Gunslinger Trailer</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/d9u1x32m7nv6zq7/Omni%20Arena%20-%20Gunslinger%20Gameplay%20Video.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Gunslinger Gameplay Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/zrqbrmilghug4yg/Omni%20Arena%20-%20Gunslinger%20Gameplay%20Video%20Instagram.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Gunslinger Gameplay Instagram Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/76j1l5qe5v0ntzl/Omni%20Arena%20-%20Rhythm%20Master.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Rhythm Master Trailer</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/byeea40vm66w0ki/Omni%20Arena%20-%20Rhythm%20Master%20Gameplay%20Video.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Rhythm Master Gameplay Video</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/i6mmdkhe35pl4uf/Omni%20Arena%20-%20Rhythm%20Master%20Gameplay%20Video%20Instagram.mp4?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Rhythm Master Gameplay Instagram Video</a></li>
            </ul>
        </p>
        <a href="https://dl.dropboxusercontent.com/s/tz30s6lf6s594gg/Promo%20Videos.zip?dl=1" target="_blank" rel="noreferrer"><Button buttonSize="btn-wide" buttonColor="green">
            Download All Promo Videos
        </Button></a>
        <h1 className="oa-resources-copy-title oa-resources-copy-margin-top">Product Pictures and Renderings</h1>
        <p className="oa-resources-copy-body">
            <img className="oa-resources-copy-img-wrapper" src="/images/oaresources_players_001.jpg" alt="" />
        </p>
        <p className="oa-resources-copy-body">
        Use these pictures and renderings to create your own marketing materials.
        </p>
        <p className="oa-resources-copy-body">
            <ul className="oa-resources-copy-list-disc oa-resources-copy-list-indent">
                <li><a href="https://dl.dropboxusercontent.com/s/qcyk1k019shtfgw/Omni%20Action%20Shots.zip?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Omni action pictures</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/pktlr9sk4rfr0ut/Omni%20Arena.zip?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Omni Arena images</a></li>
                <li><a href="https://dl.dropboxusercontent.com/s/vwvxv2b267kr6ie/Logos.zip?dl=1" className="oa-resources-copy-link-gray" target="_blank" rel="noreferrer">Omni Arena logos</a></li>
            </ul>
        </p>
        <a href="https://dl.dropboxusercontent.com/s/wfm52w8tp6zlq4d/Operating%20Manuals.zip?dl=1" target="_blank" rel="noreferrer"><Button buttonSize="btn-wide" buttonColor="green">
            Download All Product Pictures
        </Button></a>
        <h1 className="oa-resources-copy-title oa-resources-copy-margin-top">Omni Arena Esports</h1>
        <p className="oa-resources-copy-body">
        All players that play Omni Arena at your venue can participate in the weekly and monthly Omni Arena esports contests. Player scores will automatically be added to the contest leaderboard if a player or team plays the contest game at your venue during the contest period.
        </p>
        <p className="oa-resources-copy-body">
        To keep track of all upcoming contests and leaderboards, you and your guests can visit our Omni Arena player hub at <a className="oa-resources-copy-link-gray" href="https://arena.virtuix.com/" target="_blank" rel="noreferrer">https://arena.virtuix.com/</a>.
        </p>
        <p className="oa-resources-copy-body">
        Want to embed the Omni Arena leaderboards on your website? Download instructions to do so <a className="oa-resources-copy-link-gray" href="https://virtuix.com/uploads/Embedding%20Omni%20Arena%20Leaderboards%20to%20Your%20Website.pdf" target="_blank" rel="noreferrer">here</a>.
        </p>
        <p className="oa-resources-copy-body">
        You can find the Player Agreement for Omni Arena players <a className="oa-resources-copy-link-gray" href="https://arena.virtuix.com/omni-arena-player-agreement" target="_blank" rel="noreferrer">here</a>.
        </p>
        <p className="oa-resources-copy-body">
        You can find the complete set of official rules governing the Omni Arena prize contests <a className="oa-resources-copy-link-gray" href="https://arena.virtuix.com/contest-rules" target="_blank" rel="noreferrer">here</a>.
        </p>
        <p className="oa-resources-copy-body">
        You can find game specific contest rules <a className="oa-resources-copy-link-gray" href="https://arena.virtuix.com/game-rules" target="_blank" rel="noreferrer">here</a>.
        </p>
        <p className="oa-resources-copy-body">
            <img className="oa-resources-copy-img-wrapper" src="/images/oaresources_playerpage_700px_border.jpg" alt="" />
        </p>
        <h1 className="oa-resources-copy-title oa-resources-copy-margin-top">Omniverse Credits and Stats</h1>
        <p className="oa-resources-copy-body">
        Omni Arena is connected in real-time to our online Omniverse database. Via your Omniverse account, you can track your Omni Arena gameplay stats and Omniverse credits balance.
        </p>
        <p className="oa-resources-copy-body">
        To access Omniverse online, visit <a className="oa-resources-copy-link-gray" href="http://shop.omniverse.global/" target="_blank" rel="noreferrer">http://shop.omniverse.global/</a> and log in with your credentials provided by Virtuix.
        </p>
        <p className="oa-resources-copy-body">
        If you run low on Omniverse gameplay credits, click the “Buy Credits” button at the top of the page to purchase more credits and replenish your balance.
        </p>
        <p className="oa-resources-copy-body">
            <img className="oa-resources-copy-img-wrapper" src="/images/oaresources_shop_backend_700px_border.jpg" alt="" />
        </p>
        <h1 className="oa-resources-copy-title oa-resources-copy-margin-top">Player Account Data</h1>
        <p className="oa-resources-copy-body">
        70% of Omni Arena players create a player account and provide their first and last names, email address, and date of birth, which can be used for highly effective email marketing campaigns. This data is available to you for download at <a className="oa-resources-copy-link-gray" href="http://shop.omniverse.global/" target="_blank" rel="noreferrer">http://shop.omniverse.global/</a> (tab “Player Data”).
        </p>
        <p className="oa-resources-copy-body">
            <img className="oa-resources-copy-img-wrapper" src="/images/oaresources_playerdata_700px_border.jpg" alt="" />
        </p>
      </div>
    </>
  );
}

export default OmniArenaResourcesCopy;
