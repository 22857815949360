import React from "react";
import { Link } from "react-router-dom";
import "../../Copy.css";

function OnlineMemberTerms() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">California Privacy Notice</h1>
        <p className="copy-body">
        California law requires us to disclose certain information related to our privacy practices. This California Privacy Notice is intended to supplement our Privacy Policy in order to meet our disclosure requirements pursuant to California law. 
        </p>
        <p className="copy-body">
        To understand our privacy practices, and your rights and obligations as a customer of Virtuix, you should refer to our Virtuix <Link className="copy-link-to-gray" to="/terms-of-use">
            Terms of Use
          </Link>{" "}
          and our{" "}
          <Link className="copy-link-to-gray" to="/privacy-policy">
            Privacy Policy
          </Link>
          , which apply to and govern your use of the Services, in addition to this supplement applicable to California Residents.
        </p>
        <p className="copy-body">
        This California Privacy Notice applies to Personal Information, as defined below, that is processed by Virtuix in the course of our business, including via the Virtuix websites, Virtuix hardware and software products, any Virtuix platform on which users can download, interact with and submit content and obtain services related to or in connection with our products, our websites, mobile apps, forums, social media accounts, blogs, games, content, Omni Online services, and other online or offline offerings (together with any and all future online and offline offerings operated by or on behalf of Virtuix, the “Services”).
        </p>
        <p className="copy-body">
        This California Privacy Notice is not intended to apply to Personal Information we collect about our employees, applicants for employment, or contractors in the employment context.
        </p>
        <p className="copy-body">
        The definition of “Personal Information” under California law differs from the definition used in our Privacy Policy. As used in this California Privacy Notice only, “Personal Information” refers to “information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household.”
        </p>
        <h3 className="copy-subheader">WHAT PERSONAL INFORMATION WE COLLECT</h3>
        <p className="copy-body">
        The table below identifies the categories of Personal Information we collect and provides examples of Personal Information in such categories, along with information on the third parties with whom such categories of information are shared. For more information about the personal information we collect, please see section 3.1 of the Virtuix <Link className="copy-link-to-gray" to="/privacy-policy">
            Privacy Policy
          </Link>
          .
        </p>
        <p className="copy-body">
          <table className="copy-table-container copy-table-data">
            <tr>
              <th>Category</th>
              <th>Examples of Personal Information in this Category</th>
              <th>Third Party Recipients</th>
            </tr>
            <tr>
              <td>Identifiers</td>
              <td>
              Name, email address, mailing address, shipping address, phone number, username, physical characteristics, birthday, location, images of you
              </td>
              <td>
              Vendors and Service Providers, Business Partners , Other Virtuix Users, Third Party Advertising Partners
              </td>
            </tr>
            <tr>
              <td>Protected characteristics</td>
              <td>Age, gender</td>
              <td>
              Vendors and Service Providers, Other Virtuix Users, Business Partners, Third Party Advertising Partners
              </td>
            </tr>
            <tr>
              <td>Commercial information</td>
              <td>
              Purchase history, use history (total number of games played, the dates you played those games, game performance history, including leaderboard rank, achievements earned, game stats, rewards, total steps, distance and calories burned), and your friends
              </td>
              <td>
              Vendors and Service Providers, Business Partners , Other Virtuix Users, Third Party Advertising Partners
              </td>
            </tr>
            <tr>
              <td>Geolocation data</td>
              <td>
              Internet protocol (IP) address, GPS location, longitude/latitude, city, county, zip code and region, and your location and your smart device’s proximity to “beacons,” Bluetooth networks and/or other proximity systems
              </td>
              <td>
              Vendors and Service Providers, Business Partners Third Party Advertising Partners
              </td>
            </tr>
            <tr>
              <td>Internet or other electronic network activity information</td>
              <td>
              IP address, cookie identifiers, mobile carrier, mobile advertising identifiers, MAC address, IMEI, Advertiser ID, and other device identifiers that are automatically assigned to your computer or device when you access the Internet, browser type and language, geo-location information, hardware type, operating system, Internet service provider, pages that you visit before and after using the Services, the date and time of your visit, the amount of time you spend on each page, information about the links you click and pages you view within the Services, and other actions taken through use of the Services such as preferences
              </td>
              <td>
              Vendors and Service Providers, Business Partners, Third Party Advertising Partners
              </td>
            </tr>
            <tr>
              <td>Financial information</td>
              <td>
              Payment information, such as credit card number, status of loan application
              </td>
              <td>Vendors and Service Providers, Business Partners</td>
            </tr>
            <tr>
              <td>Audio, electronic, visual, thermal, olfactory, or similar information</td>
              <td>
              Your visual image, likeness and voice recording (e.g., via photographs and/or video); recordings of member support and sales calls
              </td>
              <td>Vendors and Service Providers</td>
            </tr>
            <tr>
              <td>
                Inferences drawn from any of the above information categories
              </td>
              <td>Inferences about the type of games you prefer.</td>
              <td>
                Vendors and Service Providers, Third Party Advertising Partners
              </td>
            </tr>
          </table>
        </p>
        <p className="copy-body">
        We may also share each of the above listed categories of Personal Information with government entities if we believe doing so is necessary: (i) to comply with law enforcement or national security requests and legal process, such as, a court order or subpoena; (ii) to protect yours, ours or others’ rights, property or safety; or (iii) to enforce Virtuix policies or contracts. Additionally, if we are involved in a merger, acquisition, financing, reorganization, bankruptcy, receivership, sale of company assets or transition of service to another provider, then each of the above listed categories of Personal Information may be shared with the involved investors or corporate entities and their professional advisors, representatives and agents s as part of such a transaction as permitted by law and/or contract. 
        </p>
        <p className="copy-body">
        The sources of the Personal Information we collect above include Personal Information that you directly provide to us (for example, on our Site or our Apps, and at events or by telephone), Personal Information that we gather from the devices you use to access our Services (for example, mobile applications or websites), Personal Information we receive from third party partners (for example, our financing partners if you finance your purchase, or certain marketing, research or survey partners), and Personal Information that we generate internally (for example, we may generate identifiers internally that we associate with you).
        </p>
        <h3 className="copy-subheader">
        HOW WE USE PERSONAL INFORMATION
        </h3>
        <p className="copy-body">
        Our{" "}
          <Link className="copy-link-to-gray" to="/privacy-policy">
            Privacy Policy
          </Link>{" "}
          describes our uses of Personal Information (see section 3.2).
        </p>
        <h3 className="copy-subheader">
        WHAT PERSONAL INFORMATION WE SHARE
        </h3>
        <p className="copy-body">
        In addition to the table above, the Virtuix Privacy Policy provides additional information regarding the categories of third parties with whom we share Personal Information (see section 4.1).
        </p>
        <p className="copy-body">
        California law requires that we also list the categories of Personal Information that we have disclosed to third parties for business purposes in the last 12 months. In the last 12 months, we have disclosed all of the categories of Personal Information we describe in the “What Personal Information We Collect” section for business purposes. For example, we may share your IP address or device ID with service providers that provide crash monitoring and reporting services to us.
        </p>
        <p className="copy-body">
        California residents have the right to opt out of disclosures of Personal Information to third parties for valuable consideration (which may be considered “sales” under California law even if no money is exchanged). We currently do not “sell” your information as we understand this term. However, Virtuix respects and understands that you may still want to minimize sharing of your information with third parties for marketing purposes. If you are a California resident and you would like to minimize sharing of your information with third parties for marketing purposes, please contact us at <a href="mailto:help@virtuix.com" className="copy-link-gray">help@virtuix.com</a>.
        </p>
        <p className="copy-body">
        We also do not sell the Personal Information of minors under 16 years of age with actual knowledge.
        </p>
        <h3 className="copy-subheader">
        YOUR RIGHTS
        </h3>
        <p className="copy-body">
        As a resident of California, you have certain rights, such as:
        </p>
        <p className="copy-body">
            <ul className="copy-list-disc copy-list-indent">
                <li>
                <i>Right to data portability/access.</i> You may be entitled to receive the specific pieces of personal information we have collected in the 12 months preceding your request, including, where applicable, in an electronic and readily-usable format.
                </li>
                <li>
                <i>Right to know.</i> You may be entitled to receive information regarding the categories of Personal Information we collected, the sources from which we collected Personal Information, the purposes for which we collected and shared Personal Information, the categories of Personal Information that we sold and the categories of third parties to whom the Personal Information was sold, and the categories of Personal Information that we disclosed for a business purpose in the 12 months preceding your request.
                </li>
                <li>
                <i>Right to deletion.</i> You may be entitled to request that we delete the Personal Information that we have collected from you. We will use commercially reasonable efforts to honor your request, in compliance with applicable laws. Please note, however, that we may need to keep such information, such as for our legitimate business purposes or as required to comply with applicable law.
                </li>
            </ul>
        </p>
        <p className="copy-body">
        You may freely exercise these rights without fear of being denied goods or services. We may, however, provide a different level of service or charge a different rate reasonably relating to the value of your personal information.
        </p>
        <p className="copy-body">
        If you are a California resident and would like to exercise one of your rights, please contact us at <a href="mailto:help@virtuix.com" className="copy-link-gray">help@virtuix.com</a>. 
        </p>
        <p className="copy-body">
        California law requires us to verify the requests we receive from you when you exercise certain of the rights listed above. We (or third parties we engage to assist us) may ask you to provide certain information to us in order for us to verify your request, including your name, email address, username, physical address, date of birth and phone number (the “Verification Information”). This information will be used to generate a brief quiz with personalized questions to verify your identity. If you fail the identity verification quiz twice, our third-party verification partner will ask you to verify your identity by submitting a copy of state-issued ID and a photograph of yourself.
        </p>
        <p className="copy-body">
        You may also designate an authorized agent to exercise your rights on your behalf. If you have provided an authorized agent with a power of attorney pursuant to California Probate Code, we will work directly with your authorized agent to complete your request. Where you have not provided an authorized agent with a power of attorney pursuant to the California Probate Code, we will reach out to you directly to confirm the authorized agent’s authority and collect the Verification Information.
        </p>
        <p className="copy-body">
        In addition to these rights, pursuant to California’s “Shine the Light” law, California residents who share personal information with us have the right to request and obtain from us once per year, free of charge, a list of the third parties to whom we have disclosed their Personal Information (if any) for direct marketing purposes in the prior calendar year, as well as the type of Personal Information disclosed to those parties. If you would like to exercise this right, please use the contact information listed in this section to contact us.
        </p>
        <p id="lastupdated" className="copy-body">
          <b>Last Updated: November 26, 2022</b>
        </p>
      </div>
    </>
  );
}

export default OnlineMemberTerms;
