import React from "react";
import "./OmniArenaResources.css";
import LazyAnimation from "../../LazyAnimation";
import OmniArenaResourcesCopy from "../../OmniArenaResourcesCopy";
import { Helmet } from "react-helmet";

function OmniArenaResources() {
  return (
    <>
    <Helmet>
        <meta name="robots" content="noindex" />
    </Helmet>
      <div className="oa-resources-hero-bg">
        <div className="oa-resources-container">
        <div className="oa-resources-hero">
          <h1 className="oa-resources-title">Omni Arena Resources</h1>
          <LazyAnimation></LazyAnimation>
          <h2 className="oa-resources-subtitle">
          Thank you for being a Omni Arena operator! Below are download links to operating materials, instructions, and marketing assets that may be helpful to you when operating and promoting Omni Arena.
          </h2>
        </div>
        </div>
      </div>
      <OmniArenaResourcesCopy />
    </>
  );
}

export default OmniArenaResources;
