import React from "react";
import "./Legal.css";
import LazyAnimation from "../../LazyAnimation";
import LegalList from "../../LegalList";

function Legal() {
  return (
    <>
      <div className="legal-hero-bg">
        <div className="legal-container">
        <div className="legal-hero">
          <h1 className="legal-title">Legal Documents</h1>
          <LazyAnimation></LazyAnimation>
          <h2 className="legal-subtitle">
          Please find Virtuix’s legal documents below. We reserve the right to change or modify these on a go-forward basis at any time and at our sole discretion. Your continued use of Virtuix services will confirm your acceptance of any revised terms.
          </h2>
        </div>
        </div>
      </div>      
      <LegalList />
    </>
  );
}

export default Legal;
