import React from "react";
import "../OmniArenaResources/OmniArenaResources.css";
import "../../OpResourcesOmniverseCopy.css";
import LazyAnimation from "../../LazyAnimation";
import RequiredThirdPartyCopy from "../../RequiredThirdPartyCopy";
import { Helmet } from "react-helmet";

function RequiredThirdParty() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="oa-resources-hero-bg">
        <div className="oa-resources-container">
          <div className="oa-resources-hero">
            <h1 className="oa-resources-title">Required Third-Party Products</h1>
            <LazyAnimation></LazyAnimation>
            <h2 className="oa-resources-subtitle">
            In addition to the Omni and accessories, customers will need the following products to complete their Omni setup.
            </h2>
          </div>
        </div>
      </div>
      <RequiredThirdPartyCopy />
    </>
  );
}

export default RequiredThirdParty;
