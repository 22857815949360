import React, { useState } from "react";
import "./ProductsDropDown.css";
import "./pages/Footer/Footer.css";
import { Link } from "react-router-dom";

function ProductsDropDown() {

  const [prodDropdown, setProdDropdown] = useState(false);


  return (
    <>
      <ul className={prodDropdown ? "products-dropdown clicked" : "products-dropdown"} onClick={() => setProdDropdown(!prodDropdown)}>
      <a className="products-dropdown-link" href="/omni-arena" onClick={() => setProdDropdown(false)}>
        <li className="products-dropdown-item">
            Omni Arena
        </li>
        </a>
        <a className="products-dropdown-link" href="https://omni.virtuix.com/" target="_blank" rel="noreferrer" onClick={() => setProdDropdown(false)}>
        <li className="products-dropdown-item">
            Omni One
        </li>
        </a>
      </ul>
    </>
  );
}

export default ProductsDropDown;
