import React from "react";
import "./DropDown.css";
import "./pages/Footer/Footer.css";
import Dropdown, {
  DropdownContent,
  DropdownTrigger,
} from "react-simple-dropdown";
import { Link } from "react-router-dom";

function DropDown() {
  return (
    <Dropdown>
      <DropdownTrigger className="footer-subdomain-links footer-display-768-border footer-text-padding">
        Virtuix{" "}
        <img
          className="footer-v-arrow-down"
          src="/images/v_arrow_down.svg"
          alt="Click Arrow Down"
        />
      </DropdownTrigger>
      <DropdownContent>
        <ul className="footer-display-768">
          <li>
            <div className="footer-page-links">
              <a href="https://virtuix.com" className="footer-link-gray">
                Home
              </a>
            </div>
          </li>
          <li>
            <div className="footer-page-links">
              <a href="https://virtuix.com/who-we-are" className="footer-link-gray">
                Who We Are
              </a>
            </div>
          </li>
          <li>
            <div className="footer-page-links">
              <a
                href="https://virtuix.com/careers"
                className="footer-link-gray"
              >
                Careers
              </a>
            </div>
          </li>
          <li>
            <div className="footer-page-links">
              <a
                href="https://virtuix.com/legal"
                className="footer-link-gray"
              >
                Legal
              </a>
            </div>
          </li>
          <li>
            <div className="footer-page-links">
              <a
                href="https://virtuix.com/investors"
                className="footer-link-gray"
              >
                Investors
              </a>
            </div>
          </li>
          <li>
            <div className="footer-page-links">
              <a href="https://virtuix.com/press" className="footer-link-gray">
                Press
              </a>
            </div>
          </li>
        </ul>
      </DropdownContent>
    </Dropdown>
  );
}

export default DropDown;
