import React from "react";
import { Link } from "react-router-dom";
import "./DesiredPosition.css";
import { Button } from "./Button";

function DesiredPosition() {
    return (
        <div className="desired-pos-dark-bg">
            <div className="desired-pos-container">
                <h1 className="desired-pos-heading">
                    Don't see your desired position listed?
                </h1>
                <div className="desired-pos-subtitle">
                Send us your resume at <a className="desired-pos-link" href="mailto:careers@virtuix.com">careers@virtuix.com</a>
                </div>
            </div>            
            <hr className="footer-hr" />
        </div>
);
}

export default DesiredPosition;