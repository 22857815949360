import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./SDKForm.css";

export const SDKForm = () => {
  const form = useRef();
  const successMessage = "Request received. Thank you.";

  const [ successText, setSuccessText ] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [faded, setFaded] = useState(false);
  

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
        "service_20ydkup",
        "template_1jl1h8k",
        form.current,
        "scRRIe4BK7-eS0SnY"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

      // e.target.reset();
      setSuccessText(true);
      setIsDisabled(true);
      setFaded(true);
  };

  return (
    <>
      <div className="sdk-form-container">
        <form ref={form} onSubmit={sendEmail}>
          <div className="sdk-form-label">Name (Required)</div>
          <input className={ faded ? 'sdk-form-input sdk-form-input-faded': 'sdk-form-input'} type="text" name="user_name" required disabled={isDisabled} />
          <div className="sdk-form-label">Email (Required)</div>
          <input className={ faded ? 'sdk-form-input sdk-form-input-faded': 'sdk-form-input'} type="email" name="user_email" required disabled={isDisabled} />
          <div className="sdk-form-label">Company</div>
          <input className={ faded ? 'sdk-form-input sdk-form-input-faded': 'sdk-form-input'} type="text" name="user_company" disabled={isDisabled} />
          <div className="sdk-form-label">Country (Required)</div>
          <input className={ faded ? 'sdk-form-input sdk-form-input-faded': 'sdk-form-input'} type="text" name="user_country" required disabled={isDisabled} />          
          <input className={ faded ? 'sdk-form-submit-faded': 'sdk-form-submit'} type="submit" value="Submit" disabled={isDisabled} />
          {successText && (<div className="sdk-form-success">{ successMessage }</div>)}          
        </form>
      </div>
    </>
  );
};
