import React from "react";
import "./NewsCards.css";

function NewsCards({
    newsImg,
    newsDate,
    newsHeadline,
    newsBy,
    newsLink,
    newsAlt,
    newsMore
}) {
  return (
    <>
    <div className="news-card-container">
    <a className="news-card-link-text" href={ newsLink } target="_blank" rel="noreferrer">      
        <img src={ newsImg } className="news-card-img" alt={ newsAlt } />
        <div className="news-card-date">
            { newsDate }
        </div>
        <div className="news-card-headline">
            { newsHeadline }
        </div>
        <div className="news-card-byline">
            by { newsBy }
        </div>
        <div className="news-card-link-container">
            <div className="news-card-link">
                { newsMore }
            </div>
        </div>      
      </a>
      </div>
    </>
  );
}

export default NewsCards;