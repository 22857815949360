import React from "react";
import "../../Copy.css";

function TermsOfSale() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Virtuix Terms of Sale</h1>
        <h3 className="copy-subheader">1. SCOPE AND APPLICATION</h3>
        <p className="copy-body">
          These Terms of Sale (“TOS”) apply to all offers, sales, and purchases
          of the "Omni" omni-directional treadmill and related products and
          accessories (the “Product” or “Products”) sold through virtuix.com or
          affiliated websites (the “Site”). We reserve the right to amend or
          update these TOS at any time by posting such amended or updated TOS on
          the Site. These TOS supplement and form a part of the Virtuix{" "}
          <a href="https://virtuix.com/terms-of-use" className="copy-link-gray">
            Terms of Use
          </a>{" "}
          (the “Terms”). Capitalized terms not defined in these TOS have the
          meaning given to them in the Terms.
        </p>
        <p className="copy-body">
          References to “Virtuix”, “us”, “we” or “our” shall be construed to
          mean Virtuix Inc. and its affiliates; and references to “you” or
          “your” shall be construed to mean the purchaser of the Products
          through the Site.
        </p>
        <p className="copy-body">
          This is a legal agreement. By placing an order with Virtuix, you are
          accepting and agreeing to these Terms on behalf of yourself and any
          entity you represent in connection with the purchase. You represent
          and warrant that you have the right, authority, and capacity to accept
          and agree to these Terms on behalf of yourself and any such entity you
          represent. You represent that you are of sufficient legal age in your
          jurisdiction to purchase and use the Products and to enter into this
          agreement. If you do not agree with any of the provisions of these
          Terms, you must not purchase the Products.
        </p>
        <h3 className="copy-subheader">
          2. PERSONAL, NON-COMMERCIAL USE ONLY AND OTHER PROHIBITIONS.
        </h3>
        <p className="copy-body">
          All purchases through the Site are for end-users only and for personal
          and non-commercial use of the Products. The Products are intended
          solely for users who are aged 13 years or older. For any non-personal
          or commercial use of any Products, you must execute a separate
          commercial use agreement with Virtuix. Additionally, resale of any
          Products is prohibited, and any unauthorized resale does not convey
          any rights under these Terms (including but not limited to any
          warranty provided with the Products) to any unauthorized or additional
          purchaser.
        </p>
        <p className="copy-body">
          The Products are not offered or intended for sale outside of the
          United States. You agree that you will take acceptance of and use the
          Products in the United States. To the extent permitted by applicable
          law, we will accept no responsibility or liability associated with any
          loss, damage, or other injury caused by use of your Product outside of
          the United States.
        </p>
        <p className="copy-body">
          In furtherance of our policy of not collecting personal information
          from persons under the age of 13, you are not allowed to give us the
          personal information of any persons under the age of 13 for delivery
          or shipping purposes or any other reason. In order to process your
          purchase, we must collect and use your personal data as described in
          our Privacy Policy, which is available{" "}
          <a
            href="https://virtuix.com/privacy-policy"
            className="copy-link-gray"
          >
            here
          </a>
          .
        </p>
        <h3 className="copy-subheader">3. PURCHASES ARE FINAL.</h3>
        <p className="copy-body">
          All purchases of the Product through the Site are final,
          non-cancelable and non-refundable except as specified in the
          applicable cancellation policy, refund policy, and exchange policy set
          forth below.
        </p>
        <h3 className="copy-subheader">4. OFFER AND ACCEPTANCE.</h3>
        <p className="copy-body">
          (a) Any prices, quotations and descriptions made or referred to on
          this Site with respect to the Products are subject to the Product’s
          availability and do not constitute an offer. Also, any offer made may
          be withdrawn or revised at any time prior to Virtuix’s express
          acceptance of your order (as described below).
        </p>
        <p className="copy-body">
          (b) While we make every effort to ensure that items appearing on the
          Site are available, we cannot guarantee that all items are in stock,
          immediately available, or available for distribution or delivery to
          your proposed delivery location when you submit your order. We may
          reject or deny your order without liability if we are unable to
          process or fulfill it for any reason. If this is the case, we will
          refund any prior payment that you have made for that Product.
        </p>
        <p className="copy-body">
          (c) An order submitted by you constitutes an offer by you to Virtuix
          to purchase the Product on these Terms and is subject to Virtuix’s
          subsequent acceptance.
        </p>
        <p className="copy-body">
          (d) Prior to acceptance by Virtuix, an automatic e-mail
          acknowledgement of your order may be generated. Please note that any
          such automatic acknowledgement you receive does not constitute a
          formal acceptance of your order.
        </p>
        <p className="copy-body">
          (e) Our acceptance of your order takes effect and the contract
          concluded at the point where such offer is expressly accepted by us
          dispatching your order and accepting your credit card or other payment
          in full for both the Product and shipment of the Product
          (“Acceptance“). Your order is not complete until you, as the
          purchaser, receive an express acceptance, and full payment for the
          Product (including the shipment cost) has been received by us.
        </p>
        <h3 className="copy-subheader">5. CANCELLATION.</h3>
        <p className="copy-body">
          After Acceptance by Virtuix, you may not modify or cancel your order
          without our prior written consent; however we may cancel your order at
          any time after Acceptance and prior to shipment for any reason in our
          sole discretion, and in such event, we will issue you a refund. If you
          placed a pre-order for a Product, you may cancel your pre-order at any
          time prior to Acceptance of such order, and in such event, we will
          issue you a refund for any amount you have paid when placing such
          pre-order. (the foregoing collectively constitutes our{" "}
          <b>
            <i>"Cancellation Policy"</i>
          </b>
          ).
        </p>
        <h3 className="copy-subheader">6. PRODUCT PRICE AND PAYMENT TERMS.</h3>
        <p className="copy-body">
          (a) Prices payable for the Product are those in effect at the time of
          dispatch or delivery, unless otherwise expressly agreed and confirmed
          in writing by Virtuix. Prices indicated are in U.S. dollars. Prices
          may be indicated on this Site or in an order acknowledgement but the
          authoritative price in the event of any discrepancy is the price that
          is notified to you in our Acceptance sent to you.
        </p>
        <p className="copy-body">
          (b) We also have the right at any time prior to our Acceptance to
          withdraw any discount and/or to revise prices to take into account
          increases in costs including, without limitation, costs of any
          materials, shipping, labor or the increase or imposition of any tax,
          duty or other levy and any variation in foreign currency exchange
          rates. We also reserve the right to notify you of any errors in
          pricing prior to Product shipment. In the event we do so, we will
          continue with fulfillment of the order upon receipt of your
          acknowledgment that the Product will be provided in accordance with
          the corrected price.
        </p>
        <p className="copy-body">
          (c) Unless otherwise specified, the prices quoted are exclusive of:
          (a) the costs of shipping or carriage to the agreed place of delivery;
          and (b) the sales tax and any other tax or duty which must be added to
          the price payable where applicable. You agree to pay for taxes and
          shipping of the Product prior to shipment. By your purchase, you agree
          to indemnify and hold Virtuix harmless from and against any
          liabilities, interest, penalties or fees arising from a failure to pay
          any such charges or taxes.
        </p>
        <p className="copy-body">
          (d) Payment for both the Product and shipment of the Product shall be
          made prior to shipment. Virtuix reserves the right to verify and
          confirm payment prior to or following Acceptance of any and all
          purchases under these Terms.
        </p>
        <p className="copy-body">
          (e) When you purchase Products, you (a) agree to pay the price for
          such Products as set forth on the Site, all applicable shipping and
          handling charges and all applicable taxes in connection with your
          purchase (the “Full Purchase Amount”), and (b) authorize us to charge
          your credit card or other payment method for the Full Purchase Amount.
          We will refund you in the event we cancel your order after charging
          your credit card or other payment method. The Site may allow you to
          purchase Products and designate them to be delivered or provided at a
          future date. In such instances, you acknowledge and agree that we may
          charge your credit card or other payment method for the Full Purchase
          Amount on the date of purchase, rather than on the ultimate date of
          delivery or provision of the applicable Product. Unless otherwise
          noted, all currency references are in U.S. Dollars. All fees and
          charges are payable in accordance with payment terms in effect at the
          time the fee or the charge becomes payable.
        </p>
        <p className="copy-body">
          (f) Digital gift cards containing stored money value may be offered by
          us for the purchase of Products (“Gift Cards”). You acknowledge that
          Virtuix does not make any warranties with respect to your Gift Card
          balance and is not responsible for any unauthorized access to, or
          alteration, theft, or destruction of a Gift Card or Gift Card code
          that results from any action by you or a third party. You also
          acknowledge that we may suspend or prohibit use of your Gift Card if
          your Gift Card or Gift Card code has been reported lost or stolen, or
          if we believe your Gift Card balance is being used suspiciously,
          fraudulently, or in an otherwise unauthorized manner. If your Gift
          Card code stops working, your only remedy is for us to issue you a
          replacement Gift Card code. By purchasing a Gift Card, you represent
          and warrant that your possession and use of the Gift Card, and the
          possession and use of the Gift Card by any person you permit to
          possess or use the Gift Card, will in each case comply with these
          Terms and all applicable laws, rules and regulations, and you will not
          use, or permit any other person to use, any Gift Card in any manner
          that is misleading, deceptive, unfair or otherwise harmful to
          consumers. Gift Cards cannot be used to purchase other gift cards,
          resold, used for the purchase of Products outside of the Services,
          used for unauthorized marketing, sweepstakes, advertising, or other
          promotional purposes, redeemed for more than face value, transferred
          for value, redeemed for cash, or returned for a cash refund (except to
          the extent required by law).
        </p>
        <p className="copy-body">
          (g) We may from time to time offer certain promotional codes, referral
          codes, discount codes, coupon codes or similar offers (“Promotional
          Codes”) that may be redeemed for discounts on certain of the Products,
          subject to any additional terms that Virtuix establishes. You agree
          that Promotional Codes: (a) must be used in a lawful manner; (b) must
          be used for the intended audience and purpose; (c) may not be
          duplicated, sold or transferred in any manner, or made publicly
          available by you (whether posted to a public forum, coupon collecting
          service, or otherwise), unless expressly permitted by Virtuix; (d) may
          be disabled or have additional conditions applied to them by Virtuix
          at any time for any reason without liability to Virtuix; (e) may only
          be used pursuant to the specific terms that Virtuix establishes for
          such Promotional Code; (f) are not valid for cash or other credits or
          points.
        </p>
        <p className="copy-body">
          (h) When you purchase an Omni Online Membership (“Membership”) through
          the Site, you agree to the Omni Online{" "}
          <a
            href="https://virtuix.com/omni-online-membership-terms"
            className="copy-link-gray"
          >
            Membership Terms
          </a>
          , which supplement and form a part of these Terms. When you purchase a
          prepaid period of one or more months of Membership, you will receive a
          redemption code via email to activate your prepaid Membership when
          you’re ready to do so. By purchasing a Product, you may also receive a
          free trial of Omni Online (“Trial”). The terms and conditions of your
          Trial are described in the{" "}
          <a
            href="https://virtuix.com/omni-online-membership-terms"
            className="copy-link-gray"
          >
            Membership Terms
          </a>
          .
        </p>
        <h3 className="copy-subheader">7. TERMS OF DELIVERY.</h3>
        <p className="copy-body">
          (a) Delivery timeframes specified on this Site or in any order
          acknowledgement, in an Acceptance or elsewhere, are estimates only.
          While we endeavor to meet delivery timeframes, we do not undertake to
          dispatch the Product by a particular date or dates and shall not be
          liable to you in respect of any delays or failure to do so.
        </p>
        <p className="copy-body">
          (b) Delivery shall be to a valid address submitted by you and subject
          to Acceptance (“Delivery Address“). You must verify the Delivery
          Address on any acknowledgement or Acceptance we provide and notify us
          without delay of errors or omissions. We reserve the right to charge
          you for any extra costs arising from changes you make to the Delivery
          Address after you submit an order.
        </p>
        <p className="copy-body">
          (c) Except to the extent required as a result of any mandatory rights
          you have as a consumer under applicable law, you shall not be entitled
          to reject the Product in whole or in part by reason of short delivery.
          For purposes of these Terms, a short delivery is a delivery of less
          than the total number of Products ordered.
        </p>
        <p className="copy-body">
          (d) Except as otherwise expressly provided in these Terms, risk of
          loss of, and/or damage to the Product, passes to you when placed in
          your possession.
        </p>
        <p className="copy-body">
          (e) If you purchase the “white glove” delivery and assembly service
          for your Product, you agree to allow and provide Virtuix or its
          designees access to your designated location of installation of the
          Product for purposes of installing, maintaining, repairing, or
          uninstalling and removing the Product as necessary if and to the
          extent that Virtuix agrees to undertake such activities. Virtuix is
          not responsible for choice of location of installation and is not
          responsible for damage or injury resulting from installation at an
          improper location, such as one without adequate structural support or
          adequate distance to walls or other obstacles. You are solely
          responsible for determining whether the Product is appropriate for
          your home, and you accept that inability to install the Product or
          improper Product function as the result of your choice of location of
          installation are not valid claims under the Virtuix Limited Warranty
          and do not establish a basis for receiving a refund after the 30-day
          Refund Policy described below. Although the Virtuix-approved
          installers shall use commercially reasonable efforts to limit damage
          during any installation, maintenance, repair, or removal, you
          acknowledge and agree that there may be damage to walls or floors and
          that you are fully responsible for any such damage. Virtuix is not
          responsible for any such damage, nor is Virtuix responsible for any
          damage or injury caused by unauthorized or self-installation,
          maintenance, repair, or removal.
        </p>
        <h3 className="copy-subheader">
          8. REJECTION, DAMAGE OR LOSS IN TRANSIT; EXCHANGE POLICY; WARRANTY
          POLICY.
        </h3>
        <p className="copy-body">
          (a){" "}
          <span className="copy-underline">Statement of Exchange Policy</span>.
          Except as set forth below and subject to any rights you have under
          applicable law that cannot be excluded or limited by these Terms:
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
              we shall not be liable and you shall not be entitled to reject the
              Product, except for damage to or loss of Product or any part
              thereof in transit (where the Product is carried by our own
              transport or by a carrier on our behalf) where we are notified of
              such damage or loss within five (5) business days of the receipt
              of the Product (the foregoing constitutes our{" "}
              <b>"Exchange Policy"</b>) in accordance with the Exchange Policy
              Procedures set forth below;
            </li>
            <li>
              we shall not be liable for any damage or losses arising from: (i)
              defective installation or use of the Product; (ii) the use of the
              Product in connection with other defective, unsuitable or
              defectively installed equipment; or (iii) your negligence,
              improper use or use in any manner inconsistent with our or any
              other manufacturer’s specifications or instructions;
            </li>
            <li>
              where these is a shortage or failure to deliver, or any damage to
              the Product, we may, in our sole discretion, at our option: (i) in
              the case of Product shortage or non-delivery, make good on any
              such shortage or non-delivery; and/or (ii) in the case of damage
              to the Product and in accordance with the Exchange Policy,
              replace, exchange or repair the Product upon your return of the
              Product in accordance with the Exchange Policy procedures set
              forth below. In the event of (ii), Virtuix, in its sole
              discretion, also has the option to refund your money as your only
              remedy.
            </li>
          </ul>
        </p>
        <p className="copy-body">
          (b) <span className="copy-underline">Exchange Policy Procedures</span>
          . If the event of damage to the Product meeting the terms and
          conditions of the Exchange Policy, you should contact support and
          arrange for pick-up and return of the damaged Product (which must
          include all original packaging, hardware, accessories materials, and
          documentation). Upon receipt by Virtuix of the returned Product
          (including all original packaging, hardware, accessories materials,
          and documentation) and verification of the damage and subject to the
          alternative options set forth above, Virtuix may ship out a
          replacement Product as soon as reasonably possible. Alternatively, if
          appropriate, Virtuix may provide parts to you, or repair options
          through an authorized third party, or refund your money as your only
          remedy.
        </p>
        <p className="copy-body">
          (c){" "}
          <span className="copy-underline">Exchange Policy Limitations</span>.
          Your rights of repair or replacement of the Product or any part or
          parts thereof as part of the Exchange Policy will in all cases be
          negated or rendered void where: (a) the Product has been sold,
          purchased, used, or licensed in any manner prohibited by this
          Agreement, including commercial use of the Product; (b) the Product
          has been modified or altered by persons other than Virtuix or any
          authorized dealer; (c) the Product has not been returned together with
          full details in writing of the alleged damage in accordance with the
          Exchange Policy; (d) the damage to the Product is due (wholly or
          partially) to mistreatment, improper use or storage or maintenance or
          installation, or failure to observe any instructions or other
          directions issued or made available by us in connection with the
          delivered Product, and/or (e) the Product has been moved out of the
          United States.
        </p>
        <p className="copy-body">
          (d) <span className="copy-underline">Limited Warranty Policy</span>.
          In addition to the Exchange Policy, we extend a Limited Warranty on
          all Products commencing from your date of receipt of the Product for
          the period specified for each Product component in the Limited
          Warranty Statement and subject to you fulfilling all obligations of
          these Terms, including but not limited to the indemnification
          obligations set forth in the Terms. The Limited Warranty Statement is
          incorporated into these Terms and specifically referenced as a
          condition of these Terms. Our Limited Warranty is limited solely to
          those Products utilized exclusively for personal and non-commercial
          use in the country of original delivery of the Products and the
          Limited Warranty only extends to the original purchaser. Any
          commercial use of the Product, or use of the Product outside of the
          country of original delivery, or any use other than the personal and
          non-commercial use by the original purchaser, shall terminate and
          extinguish any and all existing warranty rights under these Terms
          including but not limited to extinguishing the Limited Warranty to the
          Products, unless you have executed a separate commercial use agreement
          with Virtuix, in which case the warranty terms as set forth in the
          commercial use agreement will prevail. All issues related to Virtuix’s
          Limited Warranty, the obligations regarding service, support, repair,
          or replacement of the Product or any parts of the Product shall be
          governed exclusively by the terms of the Limited Warranty Statement, a
          copy of which can be found{" "}
          <a href="/limited-warranty" className="copy-link-gray">
            here
          </a>
          . Other important restrictions and conditions which apply are
          referenced in the Limited Warranty Statement.
        </p>
        <p className="copy-body">
          (e) <span className="copy-underline">Territorial Limitations</span>.
          Any repair, replacement, or support of the Products provided under the
          Limited Warranty shall be restricted to and provided in the country of
          original delivery of the Products. Any other support or assistance
          provided outside or after the expiration of any Limited Warranty shall
          be restricted to and provided within the country of original delivery
          only. Virtuix does not provide warranty, out-of-warranty, or
          after-market support of any kind outside the country of original
          delivery.
        </p>
        <h3 className="copy-subheader">9. REFUND POLICY.</h3>
        <p className="copy-body">
          If for any reason you are not satisfied with the Product you can
          return the Product within 30 days of delivery of the Product for a
          full refund. Proof of purchase must accompany the return. No refunds
          will be issued 30 days after the date of delivery of the Product.
        </p>
        <p className="copy-body">
          To initiate a refund and to arrange for a return, contact Virtuix. You
          will receive a refund of the price and taxes you paid for the Product,
          including charges paid for delivery of the Product. If you financed
          your purchase, Virtuix will refund your paid installments less any
          interest paid. If the Product is returned in damaged condition or with
          missing parts, you may be charged an additional fee. We will process
          the refund due to you as soon as possible.
        </p>
        <h3 className="copy-subheader">10. THIRD-PARTY PRODUCTS.</h3>
        <p className="copy-body">
          We may market and offer for sale Products which are provided by a
          third-party manufacturer or supplier (
          <b>
            <i>"Third-party Products"</i>
          </b>
          ). Any warranty, damages or indemnity claims against us in relation to
          such Third-party Products are expressly excluded, except as set forth
          in our Limited Warranty Statement.
        </p>
        <h3 className="copy-subheader">11. TERMS OF SOFTWARE LICENSE.</h3>
        <p className="copy-body">
          Where the Product supplied includes or embodies any software (the{" "}
          <b>
            <i>“Software“</i>
          </b>
          ), the Software is licensed (and not sold) to you by us or by the
          relevant licensor/owner subject to the Terms and the relevant end-user
          license agreement or other license terms included with the Product
          and/or on the Site (the{" "}
          <b>
            <i>“License Terms“</i>
          </b>
          ). Except to the extent expressly provided by us in writing or under
          the License Terms, the Software is provided “AS IS” without any
          warranties, terms or conditions as to quality, fitness for purpose,
          non-infringement, performance or correspondence with description and
          we do not offer any warranties or guarantees in relation to the
          Software installation, configuration or error/defect correction. You
          are advised to refer to the Terms and the License Terms for further
          information regarding the license and use of the Software.
        </p>
        <p className="copy-body">
          You agree not to download and/or install any third-party software
          and/or applications that are not expressly authorized by Virtuix on
          any Products. The unauthorized downloading and/or installing of any
          such third-party software and/or applications is at your own risk and
          may void any applicable warranty, including the Product’s Limited
          Warranty, and Virtuix disclaims all responsibility for any damage or
          injury that may result.
        </p>
        <h3 className="copy-subheader">
          12. OWNERSHIP AND INTELLECTUAL PROPERTY.
        </h3>
        <p className="copy-body">
          The Products are protected by intellectual property, copyright,
          trademark and other laws of the United States and foreign countries.
          You will not remove, alter or obscure any copyright, trademark,
          service mark or other proprietary rights notices incorporated in or
          accompanying the Products.
        </p>
        <p className="copy-body">
          Virtuix, Virtuix Omni, Omniverse, Omni Connect, Omni VR, Omni One,
          Omni Online and the Virtuix and Omni logos ("Virtuix Marks") are
          trademarks or registered trademarks of Virtuix. The Virtuix Marks and
          those of its affiliates may not be copied, imitated or used, in whole
          or in part, without prior written permission. All other trademarks,
          service marks, logos, trade names and any other proprietary
          designations are the trademarks or registered trademarks of their
          respective owners and may not be used without permission of the
          applicable trademark holder.
        </p>
        <p className="copy-body">
          You may submit questions, comments, suggestions, ideas, plans, notes,
          drawings, original or creative materials or other feedback about us
          and our Products (collectively, "Submissions"). The Submissions do not
          include any content submitted via the Developer Portal, which is
          subject to the Content Submission Terms.
        </p>
        <p className="copy-body">
          Any and all Submissions, and all improvements, updates, modifications,
          or enhancements, whether made, created or developed by you or Virtuix
          or otherwise relating to the Products (collectively Revisions), are
          and will remain the property of Virtuix. You acknowledge and expressly
          agree that any contribution of Submissions or Revisions does not and
          will not give or grant you any right, title, or interest in the
          Products or in any such Submissions or Revisions. All Submissions and
          Revisions become the sole and exclusive property of Virtuix, and
          Virtuix may use and disclose Submissions and/or Revisions in any
          manner and for any purpose whatsoever, without further notice or
          compensation to you and without retention by you of any proprietary or
          other right or claim. You hereby assign to Virtuix any and all right,
          title, and interest (including, but not limited to, any patent,
          copyright, trade secret, trademark, show-how, know-how, moral rights,
          and any and all other intellectual property right) that you may have
          in, and to any and all, Submissions and Revisions. At Virtuix’s
          request, you will execute any document, registration, or filing
          required to give effect to the foregoing assignment.
        </p>
        <h3 className="copy-subheader">13. PRODUCT SPECIFICATIONS.</h3>
        <p className="copy-body">
          (a) The Products are to be used solely for personal and non-commercial
          use and as demonstrated and intended, and in accordance with its
          authorized or accompanying instruction materials, including its
          requirements regarding weight and height of the user of the Products.
          We shall bear no responsibility for any incidents or injuries arising
          from any unauthorized use of the products.
        </p>
        <p className="copy-body">
          (b){" "}
          <b>
            THERE ARE IMPORTANT HEALTH AND SAFETY WARNINGS AND INSTRUCTIONS (THE
            “INSTRUCTIONS”) THAT YOU MUST READ BEFORE USING THE PRODUCTS. BY
            USING THE PRODUCTS, YOU REPRESENT AND WARRANT THAT YOU HAVE READ AND
            UNDERSTAND THE INSTRUCTIONS, WHICH YOU CAN FIND{" "}
            <a
              href="https://virtuix.com/health-and-safety-warnings-and-instructions"
              className="copy-link-gray"
            >
              HERE
            </a>
            . IF YOU PERMIT ANY HOUSEHOLD MEMBERS OR GUESTS OR ANYONE ELSE OTHER
            THAN YOU (“GUEST USERS”) TO USE THE PRODUCTS, YOU REPRESENT AND
            WARRANT THAT YOU WILL INFORM THEM OF THESE INSTRUCTIONS AND ENFORCE
            THAT THEY ADHERE TO THEM.
          </b>
        </p>
        <p className="copy-body">
          For the avoidance of doubt, you agree that you or any Guest Users will
          not use the Product if you or they are under the age of 13, pregnant,
          intoxicated, epileptic, or recovering from major surgery, illness, or
          a heart attack, and you acknowledge that the Product is intended only
          for healthy users over the age of 13 who have a height of between 4’4”
          (132 cm) and 6’4” (192 cm) and weigh not more than 250 lbs (113 kg).
          You agree that you or any Guest Users will not use the Product if you
          or they do not meet the height and weight requirements, or if you or
          they are not healthy enough to engage in moderate to heavy physical
          exercise. You or they should consult a physician or general
          practitioner before beginning to use the Product in any way.
        </p>
        <p className="copy-body">
          You represent, warrant, and agree to never allow other people
          (particularly children) and pets in the immediate vicinity of the
          Product while you or any Guest Users are using the Product. You
          understand that they may be struck with a handheld controller or with
          the rotating arm of the Omni One treadmill, or they may attempt to
          climb onto the platform.
        </p>
        <p className="copy-body">
          Virtuix may from time to time recommend or even require that you
          replace certain parts of the Product when reaching certain usage
          milestones. You agree to replace those parts promptly per Virtuix’s
          instructions, and you acknowledge that not doing so will void any
          product warranties and may result in an unsafe and malfunctioning
          Product, or may even cause bodily harm.
        </p>
        <p className="copy-body">
          (c) All Product specifications, illustrations, drawings, particulars,
          dimensions, performance data and other information on the Site or made
          available by us are intended to represent no more than a general
          illustration of the Product and its features and do not constitute a
          warranty or representation by us that the Product will conform with
          the same. You understand and accept that all representations or images
          of the Products on the Site are of a preliminary nature and these
          items are intended to serve as representative renderings and are
          subject to change. The finished delivery product may vary
          significantly in appearance, dimensions and/or weight.
        </p>
        <p className="copy-body">
          (d) You are prohibited from rebranding or attempting to pass the
          product off as originating from any source other than Virtuix.
        </p>
        <h3 className="copy-subheader">14. ACKNOWLEDGMENT.</h3>
        <p className="copy-body">
          By purchasing the Product, you acknowledge that you have read these
          TOS and the Terms and agree to be bound by them.
        </p>
        <p className="copy-body">
          <b>Last Updated: December 15, 2023</b>
        </p>
      </div>
    </>
  );
}

export default TermsOfSale;
