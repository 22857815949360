export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headline: 'Step Into the Game',
    subTitle:
      'Experience full freedom of movement in virtual reality.',
    copy: '',
    buttonLabel: '',
    imgStart: '',
    img: '',
    alt: 'Omni One'
  };
  
  export const homeObjTwo = {
    link: 'https://enterprise.virtuix.com/',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: false,
    topLine: 'Business Owners',
    headline: 'Omni Arena',
    description:
      'Meet the four-player esports attraction that takes out-of-home entertainment to the next level.',
    buttonLabel: 'Discover Omni Arena',
    imgStart: 'start',
    img: '/images/virtuix_omniarena_img_001.jpg',
    alt: 'Runner'
  };

  export const homeObjThree = {
    link: 'https://arena.virtuix.com/',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    extraPad: false,
    topLine: 'Players',
    headline: 'Play and Win',
    description:
      'Try the most advanced VR system at a location near you.',
    buttonLabel: 'Play Omni Arena',
    imgStart: '',
    img: '/images/omniplayer_section4_playerlaugh.jpg',
    alt: 'VR Headset'
  };

  export const homeObjFour = {
    link: 'https://omni.virtuix.com/',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: false,
    headline: 'Introducing Omni One',
    description:
      'Full-body VR is coming to your home.',
    buttonLabel: 'Learn More',
    imgStart: 'start',
    img: '/images/virtuix_intro_o1_featured.jpg',
    alt: 'Player running on Omni One, 360 degree VR treadmill'
  };