import React from "react";
import "../../Copy.css";

function BetaAgreement() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Agreement of Voluntary Participation in the 
Omni One Beta Program
(“Beta Agreement”)
</h1>
        <p className="copy-body">
        This Beta Agreement describes the terms and conditions of your (<b>also known throughout this Beta Agreement as “You”</b>) voluntary participation in the Beta Program of Omni One (<b>“Beta Program”</b>). The number of participants is limited, and Virtuix appreciates your willingness to participate in this voluntary Beta Program and to provide Submissions (as defined in this Beta Agreement) to Virtuix Inc. and/or its affiliates (<b>also known throughout this Beta Agreement as “Virtuix”, “We”, or “Us”</b>). Your participation in the Beta Program is available to you conditioned on your affirmative acceptance without modification of all of the terms, conditions, and notices contained herein, including all terms of any links included in this Beta Agreement. Please read this Beta Agreement very carefully, including all of the obligations on your part, and keep a copy for your reference.  
        </p>
        <p className="copy-body">
        You acknowledge by your signature and by your voluntary participation in this Beta Program that the Omni One system you are receiving and its associated software (together, the <b>“Beta Product”</b>) is an experimental product in beta form, and that your participation and the participation of others may result in changes being made to Omni One prior to the time it is commercially released to other customers. 
        </p>
        <h3 className="copy-subheader">
          1. Participation Requirements
        </h3>
        <p className="copy-body">
        The Beta Program is open only to individuals that are 18 and above. By your participation you indicate that you are at least 18 years old, and as a participant, you may be required to provide proof of your age. If you are under 18, you may not participate in the Beta Program.  
        </p>
        <p className="copy-body">
        As a participant in the Beta Program, you will be required to purchase the Beta Product pursuant to our Terms of Sale, which are available <a href="/terms-of-sale" className="copy-link-gray" target="_blank" rel="noreferrer">here</a> and are incorporated by reference into this Beta Agreement.   
        </p>
        <p className="copy-body">
        You agree that you will take acceptance of and use the Beta Product in the United States, and that you will not ship or otherwise export the Beta Product to a destination outside of the United States.   
        </p>
        <h3 className="copy-subheader">2. Termination of the Agreement </h3>
        <p className="copy-body">
        Your participation in the Beta Program, and the grant of the licenses herein, may be terminated by Virtuix at any time, for any reason or for no reason, in our sole and absolute discretion, by providing written or emailed notice to you. Upon termination of the Beta Program or your participation in the Beta Agreement for any reason, all license rights granted hereunder shall terminate immediately. 
        </p>
        <h3 className="copy-subheader">3. Acceptance and Responsibility </h3>
        <p className="copy-body">
        As a participant in the Beta Program, you will receive your purchased Beta Product prior to the time that Omni One is released as a commercial product to the general public. The purchase of the Beta Product is subject to the Terms of Sale but with the modifications as set forth herein in this Beta Agreement, specifically excluding any refund policy and any warranty associated with the Beta Product. IN FURTHER CONSIDERATION FOR YOUR USE OF THE BETA PRODUCT BEFORE COMMERCIAL RELEASE AND YOUR PARTICIPATION IN THE BETA PROGRAM AND BY SIGNING THIS AGREEMENT, YOU AGREE TO ACCEPT THIS BETA PRODUCT “AS IS” WITHOUT ANY WARRANTY AND WITHOUT REFUND POLICY.
        </p>
        <p className="copy-body">
        As a participant in the Beta Program, you will also be required to create an Omni One user account (<b>“User Account”</b>), subject to the Virtuix <a href="/terms-of-use" className="copy-link-gray" target="_blank" rel="noreferrer">Terms of Use</a>. The Terms of Use are incorporated by reference into this Beta Agreement. In the event of a conflict between the terms of this Beta Agreement and the Terms of Sale or the  Terms of Use, the Beta Agreement terms will prevail.  You are responsible for all activity that occurs in association with your User Account. Virtuix is not liable for any loss or damages caused by your failure to maintain the confidentiality of your User Account credentials, or from any third party interference with your User Account.
        </p>
        <p className="copy-body">
        As a participant in the Beta Program, you agree to enroll in a free trial of Omni Online, Omni One’s membership service for playing online multiplayer games, pursuant to the <a href="/omni-online-membership-terms" className="copy-link-gray" target="_blank" rel="noreferrer">Omni Online Membership Terms</a>. You may opt not to renew your membership at the end of the free trial.
        </p>
        <p className="copy-body">
        Should the Beta Program result in major changes or modifications to Omni One, we reserve the right to provide these changes and/or modifications to you, but shall only provide changes, modifications and/or updates to the Beta Product that we, in our sole discretion, agree to provide. By your participation in the Beta Program, you agree to accept the Beta Product pursuant to the <a href="/terms-of-sale" className="copy-link-gray" target="_blank" rel="noreferrer">Terms of Sale</a> as modified herein, and acknowledge that you may not receive any further changes, modifications and/or updates and that the Beta Product you received may differ from the ultimate commercially available Omni One.  
        </p>
        <p className="copy-body">
        You must keep the Beta Product in your control and take reasonable efforts to prevent others from using it during the Beta Program, except as set forth below. You are responsible for any loss or damage to your Beta Product or to Virtuix’s intellectual property rights in it, including loss or damage to Virtuix  resulting from the disclosure of the Beta Product. You will immediately notify Virtuix via email at support@virtuix.com if you become aware that the Beta Product provided to you is stolen or inoperable in any way, and you must use your best efforts to help recover the Beta Product and to prevent any further loss of disclosure of all confidential information or intellectual rights associated with the Beta Product.
        </p>
        <p className="copy-body">
        Any household members, guests, or anyone else other than you (“Guest Users”) are permitted to use your Beta Product under the same terms and conditions as set forth in this Beta Agreement, but a condition of such use by Guest Users is that you are responsible that your Guest Users are made aware of and follow the terms and conditions contained within this Beta Agreement and the Terms of Use.
        </p>
        <p className="copy-body">
        Notwithstanding and in addition to the above conditions of use, you agree that the Beta Product is for personal and non-commercial use only and is not intended or authorized for commercial use.
        </p>
        <p className="copy-body">
        Resale of the products is prohibited, and any unauthorized use or resale does not convey any rights under the Terms of Sale or this Beta Agreement to any unauthorized user or buyer. ALL USE OF THE BETA PRODUCT, INCLUDING ANY AUTHORIZED USE OF THE BETA PRODUCT, IS SUBJECT TO THE PROVISIONS OF SECTIONS 9, 10, AND 11 HEREIN.
        </p>
        <p className="copy-body">
        Please read Omni One’s important <a href="/health-and-safety-warnings-and-instructions" className="copy-link-gray" target="_blank" rel="noreferrer">Health and Safety Warnings and Instructions</a> (“Instructions”) before using the Beta Product. By using the Beta Product, you represent and warrant that you have read and understand these Instructions. If you permit any Guest Users to use your Beta Product, you represent and warrant that you will inform them of these Instructions and enforce that they adhere to them.
        </p>
        <p className="copy-body">
        For the avoidance of doubt, you agree that you or any Guest Users will not use the Beta Product if you or they are pregnant, intoxicated, epileptic, or recovering from major surgery, illness, or a heart attack, and you acknowledge that the Beta Product is intended only for healthy users over the age of 13 who have a height of between 4’4” (132 cm) and 6’4” (192 cm) and weigh not more than 250 lbs (113 kg). You agree that you or any Guest Users will not use the Beta Product if you or they are do not meet the height and weight requirements, or if you or they are not healthy enough to engage in moderate to heavy physical exercise.
        </p>
        <p className="copy-body">
        Exercise can present a health risk. The Beta Product should not be used for longer than 30 minutes at a time. You or any Guest Users should consult a physician before using the Beta Product. Players who feel faint or dizzy should immediately discontinue use of the Beta Product. Some people may have a severe reaction to certain video games or virtual reality environments, including dizziness, seizures, epileptic seizures, or blackouts triggered by light flashes or patterns, even if they have no prior history of seizures or blackouts. If you have had a seizure, loss of awareness, or other symptoms that may be linked to an epileptic disorder, you should consult a physician before using the Beta Product.
        </p>
        <p className="copy-body">
        You represent, warrant, and agree to never allow other people (particularly children) and pets in the immediate vicinity of the Beta Product while you or any Guest Users are using the Beta Product. You understand that they may be struck with a handheld controller or with the rotating arm of the Omni One treadmill, or they may attempt to climb onto the platform.
        </p>
        <p className="copy-body">
        You agree to follow all instructions and guidelines provided to you by Virtuix before or during your use of the Beta Product, and you acknowledge that failure to follow these instructions may result in serious bodily injury.
        </p>
        <p className="copy-body">
        Certain parameters are measured in the Beta Program, and as a condition of participation you will be required to provide certain metrics, including but not limited to height, weight, age, gender, waist size, shoe size, and geographic location. All such measured parameters will be provided by you to Virtuix and will be accepted by Virtuix for use solely in the improvements to Omni One and any associated materials. There is no intent on the part of Virtuix to disclose such measured information; however, if there is inadvertent or accidental disclosure of any information that is provided by you, then you waive any claims you may have to the disclosure by Virtuix of such personal information you have provided as a condition of you or your Guest Users’ participation in this Beta Program.
        </p>
        <h3 className="copy-subheader">
            4. Limited License
        </h3>
        <p className="copy-body">
        Virtuix grants to you a limited, non-exclusive, non-transferable, revocable license to use the software associated with the Beta Product, only during the Term of this Beta Agreement (<b>“Omni One Software”</b>) and such limited license for the Omni One Software is for use only with the Beta Product. Virtuix reserves all rights in the Beta Product or any Omni One Software not explicitly granted to you in this license, including rights to all intellectual property contained in the Beta Product. This limited license does not include the right to, and you agree not to (a) rent, lease or sublicense the Omni One Software or make it available on a network or otherwise to other users; (b) modify, adapt, translate, reverse engineer, decompile or disassemble the Omni One Software; (c) create derivative works from the Omni One Software; or (d) copy, publicly perform or broadcast the Beta Product or the Omni One Software in a manner not set forth in this Beta Agreement.
        </p>
        <p className="copy-body">
        Further, as a condition of your use of the Omni One Software, you warrant to Virtuix that you will not use the Omni One Software for any purpose that is unlawful, not allowed, or prohibited by this Beta Agreement. You may not use the virtuix.com website and affiliated webpages (“Site”) in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of Virtuix and/or our licensors or licensees except as expressly authorized by this Beta Agreement. 
        </p>
        <h3 className="copy-subheader">
            5. Trademarks
        </h3>
        <p className="copy-body">
        The Virtuix name, the terms Virtuix, Virtuix Omni, Omniverse, Omni Connect, Omni Online, Omni Arena, Omni One, the Virtuix logo and all related names, logos, product and service names, designs and slogans are trademarks of Virtuix. You must not use such marks without the prior written permission of Virtuix, except to publish or disseminate information including Submissions about those experiences with the Beta Product, as allowed in this Beta Agreement. 
        </p>
        <h3 className="copy-subheader">
        6. Submissions and Confidential Nature of the Beta Product 
        </h3>
        <p className="copy-body">
        As a participant and in consideration of your participation in the Beta Program of the Beta Product, we will request comments and suggestions on the Beta Product. We will send you inquiries or may request phone calls, and we will request your comments regarding your experience with the Beta Product and the Omni One Software. You agree to respond to any surveys or call requests sent by Virtuix, to be completed in the requested amount of time, about the Beta Product and all products and services offered in connection therewith. 
        </p>
        <p className="copy-body">
        YOU AGREE THAT ANY FEEDBACK, EXPERIENCES, RESPONSES, CREATIVE SUGGESTIONS, IDEAS, NOTES, DRAWINGS, CONCEPTS, OR OTHER INFORMATION VIA EMAIL, VIA USER FORUMS, SURVEYMONKEY OR VIA ANY OTHER MEANS OF COMMUNICATION (COLLECTIVELY, THE "SUBMISSIONS") SHALL BE DEEMED, AND SHALL REMAIN, VIRTUIX INTELLECTUAL PROPERTY AND/OR VIRTUIX PROPERTY AND YOU SHALL HAVE NO OWNERSHIP OR RIGHTS TO THE SUBMISSIONS EXCEPT THE USE AS SET FORTH HEREIN AND YOU AGREE THAT YOU HAVE RECEIVED CONSIDERATION IN THE FORM OF THE LICENSE TO USE THE BETA PRODUCT BEFORE COMMERCIAL RELEASE AND YOUR PARTICIPATION IN THE BETA PROGRAM IN RETURN FOR YOUR SUBMISSIONS. 
        </p>
        <p className="copy-body">
        FOR THE AVOIDANCE OF DOUBT, ANY AND ALL OF YOUR SUBMISSIONS SHALL BE THE SOLE AND EXCLUSIVE PROPERTY OF VIRTUIX INC. AND ITS AFFILIATES AND/OR ITS LICENSORS OR LICENSEES, AND YOU HEREBY ASSIGN ALL OF YOUR RIGHT, TITLE AND INTEREST IN THE SUBMISSIONS, AND ALL INTELLECTUAL PROPERTY RIGHTS RELATED THERETO TO VIRTUIX AS SET FORTH HEREIN. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, YOU AGREE THAT VIRTUIX INC. AND ITS AFFILIATES AND/OR ITS LICENSORS OR LICENSEES SHALL HAVE THE PERPETUAL AND IRREVOCABLE RIGHT TO USE, MODIFY, AND OTHERWISE EXPLOIT ALL OR PART OF YOUR SUBMISSIONS OR ANY DERIVATIVE THEREOF IN ANY MANNER OR MEDIA NOW KNOWN OR HEREAFTER DEVISED WITHOUT ANY REMUNERATION, COMPENSATION OR CREDIT TO YOU. You hereby represent and warrant that you have the right to assign and grant the rights set forth herein, and that any Submission which is provided by you hereunder is original work made solely by you and does not infringe any third party intellectual property rights.
        </p>
        <p className="copy-body">
        We encourage you and provide you with a limited license to discuss your experience regarding the Beta Product with third parties and publish or disseminate information about those experiences, but such descriptions shall not contain disparagements of the Beta Product. We welcome all communications including any negative Submissions on the Beta Product but require that your or your Guest Users’ negative comments be directed to Virtuix for consideration and possible improvement of the Beta Product, the Omni One Software and for other users’ improved experiences. 
        </p>
        <p className="copy-body">
        Certain parts or components of your Beta Product, including its software, may still be confidential. You are not permitted to publish images, videos, drawings, or other visual representations of your Beta Product (including its packaging) without our explicit permission. You are not permitted to display your Beta Product publicly outside of your home.
        </p>
        <p className="copy-body">
        In any discussion of your or your Guest Users’ experiences with the Beta Product allowed under this Beta Agreement, the submitting person will acknowledge that:
        </p>
        <p className="copy-body copy-indent">
            <ol className="copy-list-lower-alpha copy-indent">
                <li>The Beta Product and any information regarding your use of the Beta Product, whether shared with Virtuix or not, is owned by Virtuix;</li>
                <li>you will not transfer, distribute or disclose any materials, as opposed to discussions of your experience with the Beta Product, to any third party, including through a public exhibition or display;</li>
                <li>the Beta Product is not thoroughly tested and includes pre-release materials that are not intended for public release;</li>
                <li>the features provided in the Beta Product you are testing may not be available in the final release.</li>
            </ol>
        </p>
        <p className="copy-body">
        You agree and acknowledge that this Beta Agreement and the contents herein is Virtuix’s confidential information and shall not be disclosed by you.
        </p>
        <p className="copy-body">
        For either your failure to fully participate in the Beta Program or should you or any Guest Users publish confidential comments or materials about the Beta Product or Omni One Software you agree that Virtuix may take all possible recourse. You acknowledge and agree that monetary damages would not be a sufficient remedy for any breach of this <span className="copy-underline">Section 6</span>, and that any such breach may cause immediate and irreparable harm to Virtuix. Accordingly, you agree that, in the event of any breach or threatened breach of this Section 6 by you, Virtuix shall be entitled, in addition to all remedies otherwise available at law or in equity, to seek injunctive relief.
        </p>
        <h3 className="copy-subheader">
        7. Internet Connection
        </h3>
        <p className="copy-body">
        Some Beta Product features may require an internet connection, which you must provide at your expense. You are responsible for all costs and fees charged by your internet service provider related to the download and use of the Beta Product.
        </p>
        <h3 className="copy-subheader">
        8. Privacy
        </h3>
        <p className="copy-body">
        Your participation in the Beta Program is subject to Virtuix's <a href="/privacy-policy" className="copy-link-gray" target="_blank" rel="noreferrer">Privacy Policy</a>. Please review our Privacy Policy, which also governs the Site and also informs users of our data collection practices, including the collection practices in this Beta Program. 
        </p>
        <p className="copy-body">
        Our Privacy Policy is incorporated by reference into this Beta Agreement. Your use of the Beta Product is subject to all the terms of our Privacy Policy, so it is your responsibility to read and understand all aspects of our data collection as it relates to your use of the Beta Product. In particular, you agree that Virtuix will collect your personal and gameplay data, and that some of this data may be displayed publicly or shared with third parties for marketing purposes.
        </p>
        <p className="copy-body">
        As stated above, you agree by your participation in the Beta Program that all Submissions, comments and data made by you as a participant will be owned by Virtuix and that we will be free to publish any and all Submissions and/or feedback to us, either fully or as modified by us.
        </p>
        <p className="copy-body">
        You further acknowledge and agree that Virtuix reserves the right to monitor any such content and communications between you and Virtuix and/or third parties regarding your participation in the Beta Program or any use of the Site.
        </p>
        <p className="copy-body">
        We may record and/or use video footage and photographs of you and your Guest Users, including but not limited to gameplay footage, voice recordings, testimonials, unboxing and setup videos, visual images, and likeness (<b>“Video Footage”</b>) for marketing purposes. You grant us permission to use Video Footage, and such permission extends into perpetuity. For a valuable consideration, receipt of which is hereby acknowledged, you hereby grant us all ownership rights and the absolute and irrevocable right and permission to copyright, use and publish your Video Footage which has been obtained pursuant to this paragraph. The Video Footage may be copyrighted, used and/or published individually or in conjunction with other photography or video works, and in any medium and for any lawful purpose, including without limitation, trade, exhibition, illustration, promotion, publicity, advertising and electronic publication.
        </p>
        <h3 className="copy-subheader">
        9. No Warranty
        </h3>
        <p className="copy-body">
        YOU ACKNOWLEDGE THAT THE BETA PRODUCT INCLUDING ANY SOFTWARE OR OTHER MATERIALS PROVIDED HEREUNDER ARE PRE-RELEASE PRODUCTS AND MAY WELL CONTAIN ERRORS AND DEFECTS. THE BETA PRODUCT INCLUDING ANY SOFTWARE AND/OR OTHER MATERIALS PROVIDED AS PART OF THIS BETA AGREEMENT ARE PROVIDED "AS IS" WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OF ANY KIND INCLUDING WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, NONINFRINGEMENT, OR FITNESS FOR ANY PARTICULAR PURPOSE. YOU FURTHER AGREE THAT VIRTUIX INC. HAS NO OBLIGATION TO MAKE THE BETA PRODUCT INCLUDING ANY SOFTWARE PROVIDED AVAILABLE AT ALL OR UNDER ANY TIME TABLE AND THAT THE ONLY REMEDY AVAILABLE SHOULD VIRTUIX INC. NOT MAKE THE BETA PRODUCT INTO A COMMERCIAL PRODUCT IS THE RETURN OF ANY FEES PAID FOR THE BETA PRODUCT.
        </p>
        <h3 className="copy-subheader">
        10. Indemnity
        </h3>
        <p className="copy-body">
        You will indemnify and hold harmless (i) Virtuix, (ii) its manufacturers, suppliers, agents, licensors, licensees, and affiliates, (iii) distributors of Virtuix products, and (iv) for each of the foregoing, their respective officers, directors, employees and agents (“Indemnified Parties”), from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable attorneys’ fees arising out of or in any way connected with any claim(s) (collectively, “Claims”) resulting from (i) any injury to person or damage to or loss of property resulting from your access to or use of or inability to use the Beta Product, whether or not caused by you and/or other users of your Beta Product, including any Guest Users who use your Beta Product, (ii) any acts and/or omission to act in using the Beta Product pursuant to the terms of this Beta Agreement (iii) your Submissions, or (iv) your breach of any warranties or obligations made by you hereunder or your violation of any other provision of this Beta Agreement. We reserve the right to assume control of the defense of any third-party claim that is subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses. For the avoidance of doubt, you acknowledge and agrees that the indemnification provided herein to the Indemnified Parties is full and to the greatest extent allowed by all Applicable Laws. For purposes of this section, “Applicable Laws” means all applicable statutes, laws, rules and regulations of any applicable governmental authority, instrumentality or agency, whether promulgated by any state or governmental entity of an applicable territory or any government in the world.
        </p>
        <p className="copy-body">
        For the avoidance of doubt, you acknowledge and agree that the foregoing indemnification of the Indemnified Parties includes indemnification from and against any Claims resulting from any personal injury to you or anyone else or theft of your property resulting from your use of the Beta Product.
        </p>
        <h3 className="copy-subheader">
        11. No Liability for Damages 
        </h3>
        <p className="copy-body">
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, VIRTUIX INC. AND ITS AFFILIATES SHALL NOT BE LIABLE TO YOU OR ANYONE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING FROM ANY CAUSES OF ACTION WITH RESPECT TO THIS BETA AGREEMENT, THE BETA PRODUCT, ITS SOFTWARE OR ANY OTHER MATERIALS PROVIDED HEREUNDER OR ASSOCIATED WITH THIS BETA AGREEMENT, WHETHER ARISING IN TORT (INCLUDING NEGLIGENCE), CONTRACT, STRICT LIABILITY OR OTHERWISE, WHETHER OR NOT YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. FOR THE AVOIDANCE OF DOUBT, VIRTUIX INC. AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU OR ANYONE FOR ANY AND ALL LOSSES RELATED TO PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, LOSS OF DATA OR ANY OTHER FORM OF DAMAGES (INCLUDING THOSE SET OUT IN THE PRECEDING SENTENCE) FROM ANY CAUSES OF ACTION ARISING OUT OF OR RELATED TO THIS BETA AGREEMENT, THE BETA PRODUCT, ITS SOFTWARE OR ANY OTHER MATERIALS PROVIDED HEREUNDER OR ASSOCIATED WITH THIS BETA AGREEMENT. IN NO EVENT SHALL VIRTUIX INC.’S AND/OR ITS AFFILIATES’ AND/OR ITS LICENSORS’ OR LICENSEES’ AGGREGATE LIABILITY TO YOU UNDER THIS BETA AGREEMENT EXCEED THE PURCHASE PRICE OF THE BETA PRODUCT PAID BY YOU. THIS SECTION AND THE PROVISIONS CONTAINED HEREIN ARE A CONDITION OF THIS BETA AGREEMENT AND OF YOUR PARTICIPATION IN THE BETA PROGRAM AND ARE THE ONLY POSSIBLE LIABILITY OF VIRTUIX INC. AND/OR ITS AFFILIATES AND/OR ITS LICENSORS OR LICENSEES.
        </p>
        <h3 className="copy-subheader">
        12. Survivability
        </h3>
        <p className="copy-body">
        The terms set forth in Sections 4, 5, 6, 8, 9, 10, 11, and 12 shall survive the termination of this Beta Agreement.
        </p>
        <p className="copy-body">
          <b>Last Updated: December 22, 2023</b>
        </p>
      </div>
    </>
  );
}

export default BetaAgreement;
