import React from "react";
import "../OmniArenaResources/OmniArenaResources.css";
import "../../OpResourcesOmniverseCopy.css";
import LazyAnimation from "../../LazyAnimation";
import ConsumerGamesCopy from "../../ConsumerGamesCopy";
import { Button } from "../../Button";
import { Helmet } from "react-helmet";

function ConsumerGames() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="oa-resources-hero-bg">
        <div className="oa-resources-container">
          <div className="oa-resources-hero">
            <h1 className="oa-resources-title">Games and Downloads</h1>
            <LazyAnimation></LazyAnimation>
            <h2 className="oa-resources-subtitle">
            Thank you for your purchase of the Virtuix Omni! Use the links below to download everything you need to enjoy your Omni.
            </h2>
          </div>
        </div>
      </div>
      <ConsumerGamesCopy />
    </>
  );
}

export default ConsumerGames;
