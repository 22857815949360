import React from "react";
import { Link } from "react-router-dom";
import "./CareersHomeHero.css";
import { Button } from "./Button";

function CareersHomeHero() {
  return (
    <div className="careers-home-hero-dark-bg">
      <div className="careers-home-hero-container">
        <h1 className="careers-home-hero-heading">Join The Team</h1>
        <img
          className="careers-home-hero-img-large"
          src="/images/oa_enterprise_team_3.jpg"
          alt="Team working"
        />
        <div className="careers-home-hero-btn-container">
          <a
            href="/"
            onClick={(e) => {
              let open = document.getElementById("open");
              e.preventDefault(); // Stop Page Reloading
              open && open.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              See Open Positions
              <div className="button-right-arrow">
                <img src="/images/arrow-icon-now.svg" alt="arrow" />
              </div>
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default CareersHomeHero;
