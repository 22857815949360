import React from "react";
import { Link } from "react-router-dom";
import "../../Copy.css";

function CommunityStandards() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Community Standards</h1>
        <p className="copy-body">
        We want everyone to feel safe while they enjoy an immersive virtual experience. These community standards (the “Community Standards”) outline what is and is not allowed when using the Virtuix Services.  These Community Standards supplement and form a part of the Virtuix <Link className="copy-link-to-gray" to="/terms-of-use">
            Terms of Use
          </Link> (the “Terms”). Capitalized terms not defined in these Community Standards have the meaning given to them in the Terms. Virtuix users come from many different backgrounds, so make sure that your conduct, as well as any content created or shared, is appropriate for a diverse audience and does not violate these Community Standards. Do not:
        </p>
        <p className="copy-body">
          <ul className="copy-list-indent copy-list-disc">
            <li>
            Post, transmit or otherwise make available through or in connection with the Services any materials that are or may be: 
            </li>
          </ul>
          <div className="copy-list-indent">
            <ul className="copy-list-indent copy-list-circle">
              <li>
               threatening, harassing, degrading, hateful or intimidating, or otherwise fail to respect the rights and dignity of others; 
              </li>
              <li>defamatory, libelous, fraudulent or otherwise tortious; </li>
              <li>
              obscene, indecent, pornographic or otherwise objectionable; or 
              </li>
              <li>
              protected by copyright, trademark, trade secret, right of publicity or privacy or any other proprietary right, without the express prior written consent of the applicable owner.
              </li>
            </ul>
          </div>
        </p>
        <p className="copy-body">
          <ul className="copy-list-indent copy-list-disc">
            <li>
            Harass or bully other users through conduct, including by:
            </li>
          </ul>
          <div className="copy-list-indent">
            <ul className="copy-list-indent copy-list-circle">
              <li>
              stalking or repeatedly following others against their wishes;
              </li>
              <li>cornering others, blocking normal movement, physically intimidating others or invading personal space without consent;</li>
              <li>
              encouraging intimidation or bullying of others, including threats to SWAT, hack, dox or DDOS.
              </li>
            </ul>
          </div>
        </p>
        <p className="copy-body">
          <ul className="copy-list-indent copy-list-disc">
            <li>
            Conduct yourself in an offensive or abusive way, including:
            </li>
          </ul>
          <div className="copy-list-indent">
            <ul className="copy-list-indent copy-list-circle">
              <li>
              touching someone in a sexual way or making sexual gestures;
              </li>
              <li>sexualising minors in any way. In cases of sexual exploitation of children, we report content to the National Center of Missing & Exploited Children;</li>
              <li>
              supporting or representing hateful ideologies or groups by using symbols or attacking people on the basis of race, ethnicity, national origin, religious affiliation, sexual orientation, caste, sex, gender, gender identity and serious disease or disability;
              </li>
            </ul>
          </div>
        </p>
        <p className="copy-body">
          <ul className="copy-list-indent copy-list-disc">
            <li>
            Post, transmit or otherwise make available through or in connection with the Services any virus, worm, Trojan horse, Easter egg, time bomb, spyware or other computer code, file or program that is or is potentially harmful or invasive or intended to damage or hijack the operation of, or to monitor the use of, any hardware, software or equipment.
            </li>
          </ul>
          </p>
        <p className="copy-body">
          <ul className="copy-list-indent copy-list-disc">
            <li>
            Harass a Virtuix employee, partner, representative or any other real person or encourage other users to do so.
            </li>
          </ul>
          </p>
        <p className="copy-body">
          <ul className="copy-list-indent copy-list-disc">
            <li>
            Harvest or collect information about other users of the Services.
            </li>
          </ul>
          </p>
          <p className="copy-body">
          If we find that you have violated these Community Standards, we may take action on your account, including temporarily restricting or suspending your account. For repeated or egregious offenses, we may permanently disable your account.
          </p>
          <p className="copy-body">
          We reserve the right to change or modify these Community Standards on a go-forward basis at any time and at our sole discretion. If we make changes to these Terms, we may provide notice of such changes as appropriate, such as by sending an email notification to the address you've provided, or we may provide notice by updating the "Last Updated" date at the bottom of these Community Standards, which are available at <a href="#lastupdated" className="copy-link-to-gray">https://virtuix.com/community-standards.</a>
          </p>
          <p className="copy-body">
          Your continued use of the Services will confirm your acceptance of the revised Community Standards. If you do not agree to the revised Community Standards, you must stop using the Services. Please review these Community Standards from time to time to ensure that you understand the terms and conditions that apply to your access to, and use of, the Services.
          </p>
          <p id="lastupdated" className="copy-body">
          <b>Last Updated: November 26, 2022</b>
        </p>
      </div>
    </>
  );
}

export default CommunityStandards;
