import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
// import FooterSubscribe from "../../FooterSubscribe";
import DropDown from "../../DropDown";
import OmniUpdateSubModal from "../../OmniUpdateSubModal";

function Footer() {

  const [showSubModal, setShowSubModal] = useState(false);

  return (
    <div className="footer-background">
      <div className="footer-container">
        <div className="footer-row">
          <div className="footer-column-25">
            <div className="footer-subdomain-col">
              <div className="footer-subdomain-links">
                <a
                  href="https://omni.virtuix.com"
                  className="footer-link-white"
                >
                  Omni One
                </a>
              </div>
              <div className="footer-subdomain-links">
                {" "}
                <a
                  href="https://virtuix.com/omni-arena"
                  className="footer-link-white"
                >
                  Omni Arena
                </a>
              </div>
              <div className="footer-subdomain-links">
                {" "}
                <a
                  href="https://virtuix.com/developers"
                  className="footer-link-white"
                >
                  Developers
                </a>
              </div>
              <div className="footer-subdomain-links footer-display-1280">
                <a href="https://virtuix.com" className="footer-link-white">
                  Virtuix
                </a>
              </div>
              <DropDown></DropDown>
              <div className="footer-copyright">
                Copyright © Virtuix ™ 2024. All rights reserved.
              </div>
            </div>
          </div>
          <div className="footer-column-25">
            <div className="footer-page-links-col footer-display-1280">
              <div className="footer-page-links">
                <a
                  href="https://virtuix.com/who-we-are"
                  className="footer-link-gray"
                >
                  Who We Are
                </a>
              </div>
              <div className="footer-page-links">
                <a
                  href="https://virtuix.com/careers"
                  className="footer-link-gray"
                >
                  Careers
                </a>
              </div>
              <div className="footer-page-links">
                <a
                  href="https://virtuix.com/legal"
                  className="footer-link-gray"
                >
                  Legal
                </a>
              </div>
              <div className="footer-page-links">
                <a
                  href="https://virtuix.com/investors"
                  className="footer-link-gray"
                >
                  Investors
                </a>
              </div>
              <div className="footer-page-links">
                <a
                  href="https://virtuix.com/press"
                  className="footer-link-gray"
                >
                  Press
                </a>
              </div>
            </div>
          </div>
          <div className="footer-column-50">
            <div className="footer-sign-up-header">
              Get our latest news and updates
            </div>
            <div className="footer-subscribe" onClick={() => setShowSubModal(true) }>
              Sign Up <img className="footer-subscribe-img" src="/images/arrow-icon-now-gray.svg" alt="" />
            </div>
            <hr className="footer-subscribe-hr-line"></hr>
            <OmniUpdateSubModal title="Sign Up" onClose={() => setShowSubModal(false)} show={showSubModal} >
              <p>What news would you like to receive?</p>
            </OmniUpdateSubModal>
            {/* <FooterSubscribe /> */}
            <div className="footer-row">
              <div className="footer-column-66">
                <div className="footer-sign-up-terms">
                  By signing up you are agreeing to the Virtuix <a className="footer-link-white" href="https://virtuix.com/terms-of-use">Terms of Use </a> 
                  and Virtuix <a className="footer-link-white" href="https://virtuix.com/privacy-policy">Privacy Policy</a>. You may unsubscribe at any time.
                </div>
              </div>
              <div className="footer-column-33">
                <div className="footer-sign-up-social">
                  <Link
                    className="footer-social-icon-link"
                    to="//www.facebook.com/VirtuixOmni"
                    target="_blank"
                    aria-label="Home"
                  >
                    <img
                      className="footer-social-icon-img"
                      src="/images/facebook_now_icon.svg"
                      alt=""
                    />
                  </Link>
                  <Link
                    className="footer-social-icon-link"
                    to="//www.instagram.com/virtuixomni/"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <img
                      className="footer-social-icon-img"
                      src="/images/instagram_now_icon.svg"
                      alt=""
                    />
                  </Link>
                  <Link
                    className="footer-social-icon-link"
                    to={"//www.youtube.com/user/VirtuixOmni?view_as=subscriber"}
                    target="_blank"
                    aria-label="Youtube"
                  >
                    <img
                      className="footer-social-icon-img"
                      src="/images/youtube_now_icon.svg"
                      alt=""
                    />
                  </Link>
                  <Link
                    className="footer-social-icon-link"
                    to="//twitter.com/virtuixomni"
                    target="_blank"
                    aria-label="Twitter"
                  >
                    <img
                      className="footer-social-icon-img"
                      src="/images/twitter_now_icon.svg"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
