import React from "react";
import "./JobPostings.css";
import ReactPlayer from "react-player";

function EnvArtist() {
  return (
    <>
      <div className="job-postings-container">
        <h1 className="job-postings-title">Environment Artist</h1>
        <p className="job-postings-body">
          <b>Job Title:</b> Environment Artist
        </p>
        <p className="job-postings-body">
          <b>Status:</b> Full-time
        </p>
        <h2 className="job-postings-header">Company Background</h2>
        <p className="job-postings-body">
          As a pioneer in virtual reality gaming systems, Virtuix Inc. continues
          to break ground with cutting-edge VR technologies. Virtuix has created
          two entertainment products that have become global hits: Omni Pro, the
          commercial version of the Omni, and Omni Arena, a multiplayer
          configuration for entertainment centers like Dave and Buster's. Thanks
          to the Omni's popularity at over 500 venues on 5 continents, we have
          built a large and devoted player community (300,000+ registered
          players... and counting).
        </p>
        <p className="job-postings-body">
          In response to demand from Omni fans, earlier this year we started
          shipping "Omni One," a consumer version of the Omni that's optimized
          for home use - light, easy to store, and with unmatched freedom of
          movement (incl. crouching and jumping). Omni One is a complete gaming
          platform. Its online game store sells games developed by Virtuix and
          top titles licensed from third parties.
        </p>
        <p className="job-postings-body">
          Learn more about Omni One here:{" "}
          <a
            className="job-postings-link"
            href="https://omni.virtuix.com"
            target="_blank"
            rel="noreferrer"
          >
            https://omni.virtuix.com
          </a>
        </p>
        <p className="job-postings-body">
          The thrill of walking around inside video games has blown the minds of
          players at our commercial venues. We plan to take our success in
          commercial entertainment and scale it into the home. Join our mission
          to bring our popular gaming experience to millions of homes around the
          world.
        </p>
        <h2 className="job-postings-header">Job Description</h2>
        <p className="job-postings-body">
          The Virtuix game development team is looking for a full-time
          Environment Artist at our Austin, TX headquarters to create, improve,
          and optimize visual assets for our first-party games. You must be able
          to create new 3D assets, or adjust existing 3D assets, to fit within a
          consistent visual design and be highly performant for VR headsets.
        </p>
        <h2 className="job-postings-header">Responsibilities</h2>
        <p className="job-postings-body job-postings-list-indent">
          <ul className="job-postings-list-disc">
            <li className="job-postings-margin">
              Create realistic and game-ready set pieces, props, textures, and
              materials for Unreal Engine
            </li>
            <li className="job-postings-margin">
              Ensure the assets you create adhere to technical specifications
              required for performance
            </li>
            <li className="job-postings-margin">
              Adjust pre-existing assets to fit within a consistent visual
              design
            </li>
            <li className="job-postings-margin">
              Iterate on and improve work based on feedback and optimization
              needs
            </li>
            <li className="job-postings-margin">
              Work with level designers and technical artists to create
              environments that fit game needs and create visual storytelling
            </li>
            <li className="job-postings-margin">
              Regularly test environments in VR space
            </li>
          </ul>
        </p>
        <h2 className="job-postings-header">Requirements</h2>
        <p className="job-postings-body job-postings-list-indent">
          <ul className="job-postings-list-disc">
            <li className="job-postings-margin">
              A portfolio of work must be provided to be considered for this
              position
            </li>
            <li className="job-postings-margin">
              Desire to work in-office in a collaborative and creative
              environment
            </li>
            <li className="job-postings-margin">
              Experience with modeling and UV mapping in Maya, Blender or
              equivalent
            </li>
            <li className="job-postings-margin">
              Experience with Substance Painter or equivalent texturing software
            </li>
            <li className="job-postings-margin">
              Experience creating environments in Unreal Engine, with an
              excellent understanding of the environment art pipeline
            </li>
            <li className="job-postings-margin">
              Ability to create assets that match an existing visual style
            </li>
            <li className="job-postings-margin">
              Ability to adapt and cope with change amid approaching project
              deadlines
            </li>
            <li className="job-postings-margin">
              Must be willing to learn and improve
            </li>
          </ul>
        </p>
        <h2 className="job-postings-header">Nice to have</h2>
        <p className="job-postings-body job-postings-list-indent">
          <ul className="job-postings-list-disc">
            <li className="job-postings-margin">
              Experience baking texture maps in Marmoset, Substance Painter, or
              equivalent
            </li>
            <li className="job-postings-margin">
              Knowledge of ZBrush or equivalent software and experience
              topologizing meshes
            </li>
            <li className="job-postings-margin">
              Familiarity with current virtual reality systems and games
            </li>
          </ul>
        </p>
        <p className="job-postings-body">
          Our ideal candidate has all the above skills, but if you are highly
          motivated and have most of them, please apply anyway! We will consider
          any enthusiastic candidate interested in joining our team.
        </p>
        <h2 className="job-postings-header">We Offer</h2>
        <p className="job-postings-body job-postings-list-indent">
          <ul className="job-postings-list-disc">
            <li className="job-postings-margin">
              Competitive compensation package
            </li>
            <li className="job-postings-margin">Virtuix stock options</li>
            <li className="job-postings-margin">
              Health, dental, vision, and life insurance
            </li>
            <li className="job-postings-margin">Open vacation policy</li>
            <li className="job-postings-margin">
              Paid maternity and paternity leave
            </li>
            <li className="job-postings-margin">Fully stocked kitchen</li>
          </ul>
        </p>
        <h2 className="job-postings-header">More About Virtuix</h2>
        <p className="job-postings-body">
          Moving around virtual worlds by pushing buttons on a controller feels
          unnatural and static. To experience true VR, you need to walk around
          virtual worlds as you do in real life - using your own feet. That's
          why we developed "Omni."
        </p>
        <p className="job-postings-body">
          Backed by over $35MM from investors, including Mark Cuban and 12
          venture capital funds, Virtuix's Omni is the first omni-directional
          treadmill that lets players walk and run in 360 degrees inside VR
          games and other virtual worlds. The Omni's immersive experience takes
          VR to the next level. The system was even portrayed in Steven
          Spielberg's Ready Player One.
        </p>
        <p className="job-postings-body">
          Virtuix is blazing the market trail for ultimate immersive gaming. If
          working at the forefront of tech and gaming excites you, then Virtuix
          is the place to be.
        </p>
        <p className="job-postings-body">
          Please send a cover letter and your resume to{" "}
          <a
            className="job-postings-link"
            href="mailto:cameron.slayter@virtuix.com"
          >
            cameron.slayter@virtuix.com
          </a>
          .
        </p>
        <p className="job-postings-body">
          <em>
            Virtuix is an Equal Employment Opportunity Employer. All qualified
            applicants will receive consideration for employment without regard
            to race, color, religion, sex, age, sexual orientation, gender
            identity, national origin, disability, protected veteran status, or
            any other characteristic protected by law.
          </em>
        </p>
      </div>
    </>
  );
}

export default EnvArtist;
