export const aboutObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headline: 'Who We Are',
    subTitle:
      "We're passionate about gaming, technology, and creating the future of immersive entertainment. When we're not hard at work bringing new Omni products to life, we're probably discussing the latest superhero movie or what games we're currently playing.",
    copy: '',
    buttonLabel: '',
    imgStart: '',
    img: '',
    alt: 'Omni One'
  };
  
  export const aboutObjTwo = {
    link: '',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: true,
    topLine: '',
    headline: 'How it all began',
    description:
      'Virtuix was founded in April 2013 and was one of the pioneers of the recent wave of Virtual Reality (“VR”) innovation. The launch of the Microsoft Kinect in November 2010 prompted Virtuix Founder and CEO Jan Goetgeluk to believe that virtual reality was about to make a come-back. He started working on the "Omni" omni-directional treadmill in 2011, driven by a desire to walk naturally in VR instead of pushing buttons on a gamepad or keyboard while sitting on a chair. After two years of research and development, Goetgeluk launched the Omni Kickstarter campaign in June 2013, which raised more than $1.1MM and became one of the most successful Kickstarter campaigns at that time.',
    buttonLabel: '',
    imgStart: 'start',
    img: 'images/jan_ks_img_001.jpg',
    alt: 'Runner'
  };

  export const aboutObjThree = {
    link: '',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: false,
    topLine: '',
    headline: 'Commercial success',
    description:
      "In 2016, Virtuix transitioned from focusing on the consumer market to selling “Omni Pro” systems to the commercial market, primarily to location-based entertainment venues through the company's global distribution network. Virtuix has shipped more than 4,000 Omni Pro units to date, and distributors have installed systems in over 500 entertainment venues in 45 countries, making Omni Pro the most widely distributed VR hardware besides headsets. In November 2018, Virtuix unveiled Omni Arena, a turnkey esports attraction for high-end entertainment centers that comprises four Omni Pro units.",
    buttonLabel: '',
    imgStart: '',
    img: 'images/omniplayer_section6_playerkid_faceleft.jpg',
    alt: 'VR Headset'
  };

  export const aboutObjFour = {
    link: '',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: false,
    headline: 'Coming home',
    description:
      'In October 2020, Virtuix unveiled Omni One, an Omni system optimized for the home, returning the company to its original vision. Omni One is a complete entertainment system that includes a standalone VR headset and its own game store. Compared to Omni Pro, Omni One no longer has a support ring. So it allows players unmatched freedom of movement, including crouching, kneeling, backing up, strafing, and jumping.',
    buttonLabel: '',
    imgStart: 'start',
    img: 'images/virtuix_o1_crouched.jpg',
    alt: 'Crouch'
  };