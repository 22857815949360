import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import "./OmniUpdateSubModal.css";
import OmniOneSubModal from "./OmniOneSubModal";
import OmniArenaSubModal from "./OmniArenaSubModal";
import OmniEsportsSubModal from "./OmniEsportsSubModal";

const OmniUpdateSubModal = (props) => {
  const styleObjWidth22 = {
    width: "22px",
  };

  const [showOneSubModal, setShowOneSubModal] = useState(false);
  const [showOaSubModal, setShowOaSubModal] = useState(false);
  const [showOesportsSubModal, setShowOesportsSubModal] = useState(false);
  const [hideDisplay, setHideDisplay] = useState();

  const handleChangeOmniOne = () => {
    setTimeout(() => {
    setShowOneSubModal(true);
    setHideDisplay(true);
    }, 600);
  };

  const handleChangeOmniArena = () => {
    setTimeout(() => {
    setShowOaSubModal(true);
    setHideDisplay(true);
    }, 600);
  };

  const handleChangeOmniEsports = () => {
    setTimeout(() => {
    setShowOesportsSubModal(true);
    setHideDisplay(true);
    }, 600);
  };

  const pageReload = () => {
    window.location.reload();
  };

  return (
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div
        className={`omni-update-sub-modal ${props.show ? "show" : ""}`}
        onClick={pageReload}
      >
        <div
          // className="omni-update-sub-modal-content"
          className={
            hideDisplay
              ? "omni-update-sub-modal-content-none"
              : "omni-update-sub-modal-content"
          }
          onClick={(e) => e.stopPropagation()}
        >
          <div className="omni-update-sub-modal-header">
            <h4 className="omni-update-sub-modal-title">{props.title}</h4>
            <Link className="close-omni-update-sub-modal" to="#">
              <img
                src="/images/x-close-green.svg"
                style={styleObjWidth22}
                alt=""
                onClick={props.onClose}
              />
            </Link>
          </div>
          <div className="background-green-omni-update-sub-modal">&nbsp;</div>
          <div className="omni-update-sub-modal-body">{props.children}</div>
          <div className="omni-update-sub-modal-footer">
            <div className="omni-update-sub-modal-radio-container">
              <div className="omni-update-sub-modal-radio-option">
            <label>
              <input
                type="radio"
                name="select-news"
                value="option1"
                className="omni-update-sub-modal-input"
                onChange={handleChangeOmniOne}
              />
              <div className="omni-update-sub-modal-radio-label">
              Omni One updates
              </div>
            </label>
            </div>
            <div className="omni-update-sub-modal-radio-option">
            <label>
              <input
                type="radio"
                name="select-news"
                value="option2"
                className="omni-update-sub-modal-input"
                onChange={handleChangeOmniArena}
              />
              <div className="omni-update-sub-modal-radio-label">
              Omni Arena updates (for operators)
              </div>
            </label>
            </div>
            <div className="omni-update-sub-modal-radio-option">
            <label>
              <input
                type="radio"
                name="select-news"
                value="option3"
                className="omni-update-sub-modal-input"
                onChange={handleChangeOmniEsports}
              />
              <div className="omni-update-sub-modal-radio-label">
              Omni Arena esports contests (for players)
              </div>
            </label>
            </div>
          </div>
          </div>
        </div>
        <OmniOneSubModal
          title="Sign Up for Omni One News"
          onClose={() => setShowOneSubModal(false)}
          show={showOneSubModal}
        >
          <p>Enter your email address below.</p>
        </OmniOneSubModal>
        <OmniArenaSubModal
          title="Sign Up for Omni Arena News"
          onClose={() => setShowOaSubModal(false)}
          show={showOaSubModal}
        >
          <p>Enter your email address below.</p>
        </OmniArenaSubModal>
        <OmniEsportsSubModal
          title="Sign Up for Omni Arena Esports News"
          onClose={() => setShowOesportsSubModal(false)}
          show={showOesportsSubModal}
        >
          <p>Enter your email address below.</p>
        </OmniEsportsSubModal>
      </div>
    </CSSTransition>
  );
};

export default OmniUpdateSubModal;
