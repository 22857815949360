import React from "react";
import "./HeroSection.css";
import "./AboutHeroSection.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import LazyAnimation from "./LazyAnimation";

function AboutHeroSection({
  link,
  lightBg,
  lightText,
  lightTextDesc,
  extraPad,
  topLine,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
}) {
  return (
    <>
      <div className={extraPad ? "hero-section-text-extra-padding" : ""}>
        <div
          className={
            lightBg
              ? "hero-section-text"
              : "hero-section-text hero-section-gray-bg"
          }
        >
          <div className="hero-section-container">
            <div
              className="hero-section-row"
              style={{
                display: "flex",
                flexDirection: imgStart === "start" ? "row-reverse" : "row",
              }}
            >
              <div className="about-hero-section-col">                
              <div className={
                        lightText
                          ? "hero-section-topline"
                          : "hero-section-topline hero-section-text-dark"
                      }>{topLine}</div>
                <LazyAnimation></LazyAnimation>
                <Fade bottom cascade duration={800}>
                  <div className="about-hero-section-text-wrapper">
                    <h1
                      className={
                        lightText
                          ? "hero-section-heading"
                          : "hero-section-heading hero-section-text-dark"
                      }
                    >
                      {headline}
                    </h1>
                    <p
                      className={
                        lightTextDesc
                          ? "about-hero-section-subtitle"
                          : "about-hero-section-subtitle about-hero-section-text-dark"
                      }
                    >
                      {description}
                    </p>
                    <a href={link} target="_blank" rel="noreferrer">
                  {/* <Button buttonSize="btn-wide" buttonColor="green">
                    {buttonLabel}
                    <div className="button-right-arrow">
                      <img src="/images/arrow-icon-now.svg" alt="arrow" />
                    </div>
                  </Button> */}
                </a>
                  </div>
                </Fade>
              </div>
              <div className="hero-section-col">
                <div className="hero-section-img-wrapper">
                  <img src={img} alt={alt} className="hero-section-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutHeroSection;
