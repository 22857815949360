import React from "react";
import { Link } from "react-router-dom";
import "./WorkingAt.css";
import LazyAnimation from "./LazyAnimation";

function WorkingAt() {
  return (
    <>
      <div className="working-at-bg">
        <div className="working-at-container">
        <div className="working-at-hero">
          <h1 className="working-at-title">Working at Virtuix</h1>
          <LazyAnimation />
          <div className="working-at-description">
          At Virtuix, we look for problem solvers who take ownership of their work from start to finish. We foster a culture of excellence and getting things done. We avoid bureaucracy or intercompany politics, we have few rules, and we offer flexibility and accountability. 
          </div>
        </div>
        <div className="working-at-img-row">
            <img className="working-at-img" src="/images/oa_enterprise_team_15.jpg" />
            <img className="working-at-img" src="/images/oa_enterprise_team_18.jpg" />
            <img className="working-at-img" src="/images/oa_enterprise_team_17.jpg" />
        </div>
        </div>
      </div>
    </>
  );
}

export default WorkingAt;