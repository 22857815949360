import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { render } from "react-dom";
import "./Developers.css";
import { Button } from "../../Button";
import LazyAnimation from "../../LazyAnimation";
import DevelopersCopy from "../../DevelopersCopy";
import DevelopersDownloads from "../../DevelopersDownloads";
import { Helmet } from "react-helmet";

function Developers() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="The Omni SDK is the development gateway to full-body VR experiences with the Omni."
        />
      </Helmet>
      <div className="developers-hero-bg">
        <div className="developers-container">
          <div className="developers-hero">
            <h1 className="developers-title">Developer Resource Center</h1>
            <LazyAnimation></LazyAnimation>
            <h2 className="developers-subtitle">
              Welcome to our Developer Resource Center! On this page you'll find
              information regarding the Omni Pro SDK and helpful links to
              additional developer resources. If you'd need the Omni One SDK,
              contact our Developer Relations team at developers@virtuix.com.
            </h2>
          </div>
        </div>
      </div>
      <DevelopersCopy />
      <DevelopersDownloads />
    </>
  );
}

export default Developers;
