import React from "react";
import { Link } from "react-router-dom";
import "./LegalList.css";

function LegalList() {
  return (
    <>
      <div className="legal-list-bg">
        <div id="open" className="legal-list-container">
          <div className="legal-list-hero">
            <div className="legal-list-number-container">
              <Link className="legal-list-number-link" to="/terms-of-use">
                <div className="legal-list-number-category">Terms of Use</div>
              </Link>
            </div>
            <div className="legal-list-number-container">
              <Link className="legal-list-number-link" to="/terms-of-sale">
                <div className="legal-list-number-category">Terms of Sale</div>
              </Link>
            </div>
            <div className="legal-list-number-container">
              <Link className="legal-list-number-link" to="/privacy-policy">
                <div className="legal-list-number-category">Privacy Policy</div>
              </Link>
            </div>
            <div className="legal-list-number-container">
            <Link className="legal-list-number-link" to="/ca-privacy-notice">
              <div className="legal-list-number-category">
                CA Privacy Notice
              </div>
              </Link>
            </div>
            <div className="legal-list-number-container">
            <Link className="legal-list-number-link" to="/limited-warranty">
              <div className="legal-list-number-category">
                Limited Warranty
              </div>
              </Link>
            </div>
            <div className="legal-list-number-container">
            <Link className="legal-list-number-link" to="/community-standards">
              <div className="legal-list-number-category">
                Community Standards
              </div>
              </Link>
            </div>
            <div className="legal-list-number-container">
            <Link className="legal-list-number-link" to="/content-submission-terms">
              <div className="legal-list-number-category">
                Content Submissions Terms
              </div>
              </Link>
            </div>
            <div className="legal-list-number-container">
            <Link className="legal-list-number-link" to="/health-and-safety-warnings-and-instructions">
              <div className="legal-list-number-category">
                Health and Safety Warnings and Instructions
              </div>
              </Link>
            </div>
            <div className="legal-list-number-container">
            <Link className="legal-list-number-link" to="/omni-online-membership-terms">
              <div className="legal-list-number-category">
                Omni Online Membership Terms
              </div>
              </Link>
            </div>
            <div className="legal-list-number-container">
            <Link className="legal-list-number-link" to="/omni-one-store-terms">
              <div className="legal-list-number-category">
                Omni One Store Terms
              </div>
              </Link>
            </div>
            <div className="legal-list-number-container">
            <Link className="legal-list-number-link" to="/omni-one-sdk-eula">
              <div className="legal-list-number-category">
                Omni One SDK EULA
              </div>
              </Link>
            </div>
            <div className="legal-list-number-container">
              <a href="https://arena.virtuix.com/omni-arena-player-agreement" className="legal-list-number-link">
              <div className="legal-list-number-category">
                Omni Arena Player Agreement
              </div>
              </a>
            </div>
            <div className="legal-list-number-container">
            <a href="https://arena.virtuix.com/omni-arena-privacy-policy" className="legal-list-number-link">
              <div className="legal-list-number-category">
                Omni Arena Privacy Policy
              </div>
              </a>
            </div>
            <div className="legal-list-number-container">
            <a href="https://arena.virtuix.com/contest-rules" className="legal-list-number-link">
              <div className="legal-list-number-category">
                Omni Arena Contest Rules
              </div>
              </a>
            </div>
            <div className="legal-list-number-container">
            <a href="https://arena.virtuix.com/game-rules" className="legal-list-number-link">
              <div className="legal-list-number-category">
                Omni Arena Game Rules
              </div>
              </a>
            </div>
            <div className="legal-list-number-container-last">
            <Link className="legal-list-number-link" to="/patents">
              <div className="legal-list-number-category">
                Patents
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LegalList;
