import React from "react";
import "./PerksSection.css";
import Fade from "react-reveal/Fade";

function PerksSection() {
  return (
    <>
      <div className="perks-section-bg">
        <div className="perks-section-container">
          <div className="perks-section-heading">Perks and Benefits</div>
          <div className="perks-section-grid-container">
          <Fade bottom cascade duration={800}>
            <div className="perks-grid-icon">
              <img
                className="perks-checkmark"
                src="/images/circle_check_green.svg"
                alt="Checkmark"
              />
            </div>
            <div className="perks-grid-text">
              <div className="perks-title">Compensation package</div>
              <div className="perks-description">
              We offer a competitive compensation package including Virtuix stock options.
              </div>
            </div>
            <div className="perks-grid-icon-2">
              <img
                className="perks-checkmark"
                src="/images/circle_check_green.svg"
                alt="Checkmark"
              />
            </div>
            <div className="perks-grid-text-2">
              <div className="perks-title">Medical and 401(k)</div>
              <div className="perks-description">
              Full benefits package including free life insurance.
              </div>
            </div>
            </Fade>
            <Fade bottom cascade duration={800}>
            <div className="perks-grid-icon">
              <img
                className="perks-checkmark"
                src="/images/circle_check_green.svg"
                alt="Checkmark"
              />
            </div>
            <div className="perks-grid-text">
              <div className="perks-title">Open vacation policy</div>
              <div className="perks-description">
              No need to accrue time off. You choose your number of vacation days.
              </div>
            </div>
            <div className="perks-grid-icon-2">
              <img
                className="perks-checkmark"
                src="/images/circle_check_green.svg"
                alt="Checkmark"
              />
            </div>
            <div className="perks-grid-text-2">
              <div className="perks-title">Maternity and paternity leave</div>
              <div className="perks-description">
              Paid maternity and paternity leave so that you can spend time with the new addition to your family.
              </div>
            </div>
            </Fade>
            <Fade bottom cascade duration={800}>
            <div className="perks-grid-icon">
              <img
                className="perks-checkmark"
                src="/images/circle_check_green.svg"
                alt="Checkmark"
              />
            </div>
            <div className="perks-grid-text">
              <div className="perks-title">Fast-paced, yet casual and fun </div>
              <div className="perks-description">
              A fast-paced work environment with new challenges each day.
              </div>
            </div>
            <div className="perks-grid-icon-2">
              <img
                className="perks-checkmark"
                src="/images/circle_check_green.svg"
                alt="Checkmark"
              />
            </div>
            <div className="perks-grid-text-2">
              <div className="perks-title">Team breakfast and lunches</div>
              <div className="perks-description">
                We provide lunch or breakfast for the team once a week.
              </div>
            </div>
            </Fade>
            <Fade bottom cascade duration={800}>
            <div className="perks-grid-icon">
              <img
                className="perks-checkmark"
                src="/images/circle_check_green.svg"
                alt="Checkmark"
              />
            </div>
            <div className="perks-grid-text">
              <div className="perks-title">Events &amp; holiday parties</div>
              <div className="perks-description">
              We regularly hold fun team events both for our team members and their families.
              </div>
            </div>
            <div className="perks-grid-icon-2">
              <img
                className="perks-checkmark"
                src="/images/circle_check_green.svg"
                alt="Checkmark"
              />
            </div>
            <div className="perks-grid-text-2">
              <div className="perks-title">Fully stocked kitchen</div>
              <div className="perks-description">
              If you're feeling hungry or thirsty throughout the day, we've got you covered!
              </div>
            </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
}

export default PerksSection;
