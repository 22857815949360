import React from "react";
import "../../Copy.css";

function SdkTerms() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Virtuix Software Development Kit</h1>
        <h2 className="copy-header">IMPORTANT: READ BEFORE COPYING, INSTALLING OR USING</h2>
        <p className="copy-body">
        Do not use or load the Omni Software Development Kit and/or any associated components or materials provided by Virtuix Inc. or on Virtuix’s website (collectively, the “Omni SDK”) until You have carefully read the following terms and conditions. By loading or using the Omni SDK, You agree to fully comply with the terms and conditions of this license to the Omni Software Development Kit by and between Virtuix Inc., a Delaware corporation having its principal place of business at 826 Kramer Ln. Suite H, Austin, TX 78758 U.S.A. (“Virtuix”), and You. If You do not wish to so agree, do not review, install or use the Omni SDK.
        </p>
        <p className="copy-body">
        Subject to the terms and conditions of this License Agreement (the “License”), Virtuix hereby grants to You, or a third-party on Your behalf, a perpetual, worldwide, non-exclusive, no-charge, royalty-free, sublicenseable copyright license to use, reproduce, redistribute (redistribution rights only subject to restrictions set forth below in Sections 2 and 3), modify, and improve the software contained in the Omni SDK, as set forth below as the License. This License is limited in its scope and subject to the terms and conditions set forth herein.
        </p>
        <h3 className="copy-subheader">1. LICENSE</h3>
        <p className="copy-body">
        1.1. This License includes the non-exclusive license and right (i) to use the Omni SDK to make Developer Content, as the Developer Content is defined further in Section 1.2; and (ii) to use the Omni SDK to create derivative works of the Omni SDK itself (“Omni SDK Derivatives”), as Omni SDK Derivatives are further defined in Section 1.3. If You wish to have a third party consultant or subcontractor (“Contractor”) perform work on Your behalf which involves access to or use of the Omni SDK, You shall direct them to download a copy of this License, or, alternatively, you indemnify Virtuix for any misuse of the Omni SDK or breach of this License by your Contractor and you shall assist Virtuix in all Contractors’ assignments of the Omni SDK Derivatives to Virtuix.
        </p>
        <p className="copy-body">
        1.2. Developer Content is further defined as engines, utilities, applications, content, games or demos developed using this Omni SDK in whole or in part in binary or object code that work with the Virtuix hardware and software products (“Virtuix Products”).
        </p>
        <p className="copy-body">
        1.3. Omni SDK Derivatives are further defined as source, binary or object code derived exclusively from the Omni SDK, by You, including third party software and which can be either (i) an adaptation of a utility or piece of code from the Omni SDK to improve efficiency; or (ii) an addition of code or improvement to the Omni SDK that adds functionality; provided, however, that Omni SDK Derivatives do not include the Developer Content. You may not use the Omni SDK for any purpose not expressly permitted by this License. You may not decompile, reverse engineer, disassemble or attempt to derive the source code of the Omni SDK or any part of the Omni SDK, or any other software or firmware provided to you by Virtuix.
        </p>
        <p className="copy-body">
        1.4. For the sake of clarification when You use the Omni SDK (including Omni SDK Derivatives) in or with Developer Content, You retain all rights to Your Developer Content, and You have no obligations to share or license Your Developer Content (including Your source and object code) to Virtuix or any third party; provided, however, Virtuix retains all rights to the Omni SDK and the Omni SDK Derivatives that may be incorporated into Your Developer Content.
        </p>
        <p className="copy-body">
        1.5. Virtuix reserves all licenses to the Omni SDK or any components of the Omni SDK, or a component thereof, or any other applications You receive through this License or on www.Virtuix.com, except those expressly set out in this Section 1.
        </p>
        <h3 className="copy-subheader">2. OMNI SDK DERIVATIVE ASSIGNMENTS AND ACKNOWLEDGEMENTS</h3>
        <p className="copy-body">
        You, the recipient and user of the Omni SDK, hereby agree and accept that Virtuix shall own all right, title and interest to the intellectual property rights, including, but not limited to copyright, trademark and patent rights, to any Omni SDK Derivatives that You may create, and You hereby assign any and all such rights to such Omni SDK Derivatives to Virtuix. You further acknowledge any and all restrictions set forth herein, including but not limited to Sections 2.1-2.3.
        </p>
        <p className="copy-body">
        2.1 We hereby grant to You the a fully paid up, no-charge, royalty-free, world-wide, in perpetuity, non-exclusive right and license back to use the Omni SDK Derivatives created by You, solely in conjunction with the Omni SDK (or any components of the Omni SDK) and/or Developer Content and using Virtuix Products as set forth herein.
        </p>
        <p className="copy-body">
        2.2 Furthermore, for the sake of clarification, Virtuix and its assignees and licensees shall be free to use such Omni SDK Derivatives without any approval from You and without compensation or attribution to You.
        </p>
        <p className="copy-body">
        2.3 You also agree upon Virtuix’s request to provide the source and binary code of any Omni SDK Derivatives to Virtuix. FAILURE TO COMPLY WITH THIS REQUEST IS THE BASIS FOR AUTOMATIC TERMINATION OF THIS LICENSE BY VIRTUIX AS WELL AS ALL OTHER MATTERS, LEGAL AND OTHERWISE, THAT VIRTUIX MAY TAKE.
        </p>
        <h3 className="copy-subheader">3. SUBLICENSE AND REDISTRIBUTION RIGHTS OF OMNI SDK AND OMNI SDK DERIVATIVES</h3>
        <p className="copy-body">
        Subject to the terms and conditions of this License, Your License to redistribute and sublicense the OMNI SDK and Omni SDK Derivatives is also expressly made subject to the conditions set forth in this Section 3.
        </p>
        <p className="copy-body">
        3.1. You may sublicense and redistribute the source, binary, or object code of the Omni SDK in whole or in part in conjunction with Your Developer Content, either for no charge or as part of a for-charge piece of Your Developer Content, but You may not sublicense the Omni SDK itself. If You license, sublicense or redistribute Omni SDK Derivatives in and of themselves (not as a part of a piece of Developer Content) then You may only do that solely with and in conjunction with the Omni SDK and with Virtuix Products. For the avoidance of doubt, the Omni SDK, any Omni SDK Derivatives, and any Developer Content must be used with Virtuix Products.
        </p>
        <p className="copy-body">
        3.2. You must include with all such redistributed or sublicensed Omni SDK or Omni SDK Derivative code the following copyright notice: “Copyright © Virtuix™ 2016. All rights reserved” and include the list of conditions contained in this Section 3, including the full text of the disclaimer in Section 3.6 below.
        </p>
        <p className="copy-body">
        3.3. Neither the name of Virtuix nor any affiliates of Virtuix, may be used to endorse or promote products derived from this Omni SDK License without specific prior written permission of Virtuix.
        </p>
        <p className="copy-body">
        3.4. You must give any other recipients of the Omni SDK or the Omni SDK Derivatives, a copy of this License as such recipients, licensees or sublicensees may only use the Omni SDK or any Omni SDK Derivatives or any elements thereof subject to the terms of this License, and such recipients, licensees or sublicenses agreement and acceptance of this License with Virtuix (which will convey all rights to the recipients’ or licensees’ or any sublicensees’ Omni SDK Derivatives to Virtuix), and You must cause any modified files to carry prominent notices stating that You changed the files.
        </p>
        <p className="copy-body">
        3.5. If the Omni SDK or a specific element thereof includes a “LICENSE” text file as part of its distribution (the “License Notice”), then any Omni SDK Derivatives that You distribute with the License in whole or in part must include a readable copy of such attribution notices as are contained within the applicable License Notice file (excluding those notices that do not pertain to any part of the Omni SDK Derivatives), in at least one of the following places: within a License Notice text file distributed as part of the Omni SDK Derivatives; within the source form or documentation, if provided along with the Omni SDK Derivatives; or, within a display generated by the Omni SDK Derivatives, if and wherever such third-party notices normally appear. You must also include in the License Notice file for all Omni SDK Derivatives a copy of all notices. The contents of the License Notice file are for informational purposes only and do not modify the License. You may add Your own attribution notices within Omni SDK Derivatives that You distribute, alongside or as an addendum to the License Notice text from the Omni SDK or any part thereof, provided that such additional attribution notices cannot and will not be construed as modifying the Omni SDK.
        </p>
        <p className="copy-body">
        3.6. THIS OMNI SDK LICENSE AND ANY COMPONENT THEREOF IS PROVIDED BY VIRTUIX AND ITS AFFILIATES “AS IS” AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL VIRTUIX AS THE COPYRIGHT OWNER OR ITS AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS OMNI SDK OR THE OMNI SDK DERIVATIVES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
      </div>
    </>
  );
}

export default SdkTerms;
