import React from "react";
import { Link } from "react-router-dom";
import "../../Copy.css";

function LimitedWarranty() {
    return (
      <>
      <div className="copy-container">
        <h1 className="copy-title">Omni One Limited Warranty Statement</h1>
        <p className="copy-body">
        Virtuix Inc. ("Virtuix") extends the following Limited Warranty, which applies only to non-commercial, in-home, indoor use of a new Omni One virtual reality treadmill (“Treadmill”) and related accessories sold directly by Virtuix (“Products”). Any other use of the Products shall void this Limited Warranty. During the applicable Limited Warranty periods described below, the covered components of the Products will be free of manufacturing defects during normal use. Certain exclusions apply, as further described in this Limited Warranty.
        </p>
        <h2 className="copy-header">What is covered by this Limited Warranty?</h2>
        <p className="copy-body">
        The Limited Warranty applies for the following components of the Products only, for the time periods indicated. The Limited Warranty applies only against manufacturing defects discovered within the applicable Limited Warranty period.
        </p>
        <h3 className="copy-subheader">TREADMILL COMPONENTS: 12 MONTHS</h3>
        <p className="copy-body">
        Virtuix warrants the original Treadmill components other than the plastic base and vest against defects in workmanship for a period of 12 months from the date of original delivery.
        </p>
        <p className="copy-body">
        Inspect the Treadmill regularly for any damage, loose parts or other signs of wear and tear that could cause breakdowns or part failures. If any Treadmill components show signs of wear or damage, stop using the Treadmill immediately and contact Virtuix at help@virtuix.com. 
        </p>
        <h3 className="copy-subheader">TREADMILL BASE: 3 MONTHS</h3>
        <p className="copy-body">
        Virtuix warrants the plastic Treadmill base, whether included with the Treadmill or purchased separately, against defects in workmanship for a period of 3 months from the date of original delivery.
        </p>
        <p className="copy-body">
        The plastic Treadmill base is not warranted against normal wear and tear, and it is important that you maintain the base by following Omni One’s maintenance instructions, including but not limited to instructions for regularly cleaning the Treadmill base.  
        </p>
        <p className="copy-body">
        Please refer to the Omni One manual on maintaining your base and other components, available on Virtuix’s website.
        </p>
        <h3 className="copy-subheader">VEST: 3 MONTHS</h3>
        <p className="copy-body">
        Virtuix warrants the vest, whether included with the Treadmill or purchased separately, against defects in workmanship for a period of 3 months from the date of original delivery. 
        </p>
        <p className="copy-body">
        The vest is not warranted against normal wear and tear, and it is important that you inspect the vest regularly for any damage, loose parts or other signs of wear and tear that could cause breakdowns or part failures.
        </p>
        <h3 className="copy-subheader">FOOTWEAR: 3 MONTHS</h3>
        <p className="copy-body">
        Virtuix warrants the Omni One footwear, whether included with the Treadmill or purchased separately, against defects in workmanship for a period of 3 months from the date of original delivery. 
        </p>
        <p className="copy-body">
        Footwear is not warranted against normal wear and tear. Any footwear that shows signs of wear or damaged parts should be removed immediately. 
        </p>
        <h3 className="copy-subheader">HEAD-MOUNTED DISPLAY, CONTROLLERS, AND TRACKERS: 12 MONTHS</h3>
        <p className="copy-body">
        Virtuix warrants that the head-mounted display, hand controllers, and foot trackers, whether included with the Treadmill or purchased separately, will, under normal and intended use, function substantially in accordance with their technical specifications and accompanying product documentation for a period of 12 months from the date of original delivery. 
        </p>
        <p className="copy-body">
        Sunlight or UV light exposure or other strong light sources can damage the screen of the head-mounted display. The head-mounted display is not warranted against damage resulting from such exposure. 
        </p>
        <p className="copy-body">
        The head-mounted display, hand controllers, and foot trackers are also not warranted against exposure to liquids, dampness or extreme thermal or environmental conditions, or deterioration of their cosmetic appearance due to normal wear and tear.
        </p>
        <p className="copy-body">
        The following consumable parts of the head-mounted display and hand controllers are not covered by this Limited Warranty: AA batteries, wrist straps, face cushion, headband, earphone hole cap, lens spacer, mounting kit, mounting pad and protective coatings that are reasonably expected to diminish over time.
        </p>
        <h3 className="copy-subheader copy-underline">Exclusions and Limitations</h3>
        <p className="copy-body">
        <i>Who is covered by this Limited Warranty?</i>
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
            	The original owner of the Product. The Product must remain in the possession of the original purchaser, or, for a gifted Product, the owner of the original user account attached to that Product, and must remain in the country in which the Product was delivered. No warranty service will be provided outside of the country of original delivery of the Product. This Limited Warranty is not transferable.
            </li>
          </ul>
        </p>
        <p className="copy-body">
        <i>What is NOT covered by this Limited Warranty?</i>
        </p>
        <p className="copy-body">
        This Limited Warranty does not apply to:
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
            	Any other Virtuix products or services not expressly referred to under the heading “What is covered by this Limited Warranty?” above, non-Virtuix products not approved in advance by Virtuix, Products that are, or that Virtuix reasonably believes to be, stolen, counterfeit, or purchased from an unauthorized distributor or reseller, Products purchased or used or located outside the country of original delivery of the Products, and units missing serial numbers.
            </li>
            <li>
            	Software, even if sold with or embedded in the Products (except to the extent required by applicable law), or internet connectivity. Virtuix does not warrant that the operation of the Products will be uninterrupted or error-free.
            </li>
            <li>
            	Data loss, including any damages or costs related to data recovery, removal, and installation. Virtuix does not warrant that it will be able to repair or replace the Products under this Limited Warranty without risk to or loss of information or data stored on the Products.
            </li>
            <li>
            	Damage or equipment failure due to normal wear and tear, improper or negligent assembly, maintenance, installation, relocation, or repair (other than in each case that is caused by a Virtuix authorized service technician), use of the Products with parts or accessories from third parties, or with parts or accessories not originally intended for or compatible with the Products, or any use contrary to the instructions in the Omni One manual, the technical specifications or other published guidelines relating to the Products.
            </li>
            <li>
            	Damage or equipment failure due to outdoor use of the Products, exposure to liquids or sunlight, dampness or extreme thermal or environmental conditions or a rapid change in such conditions, corrosion or oxidation, and damage caused by sunlight or UV light exposure or other strong light sources.
            </li>
            <li>
            	Damage or equipment failure due to accident, abuse, improper or abnormal use, neglect, discoloration of paint or plastic (or any other change in cosmetic appearance that does not affect performance), theft, vandalism, fire, flood, wind, lightning, freezing or other natural disasters or acts of God of any kind, electrical wiring, power reduction, power fluctuation or power failure from whatever cause, unusual atmospheric conditions, collision, introduction of foreign objects, or modifications that are unauthorized or not recommended by Virtuix.
            </li>
            <li>
            	Incidental or consequential damages; or economic loss, loss of property or profits, or loss of enjoyment or use. To the extent permitted by applicable law, Virtuix is not responsible or liable for indirect, special, incidental or consequential damages, economic loss, loss of property or profits, loss of enjoyment or use, or other consequential damages of any nature whatsoever in connection with the purchase, use, repair or maintenance of equipment or parts, and Virtuix will not provide monetary or other compensation for any such repairs or replacement parts costs, including but not limited to work time lost, cost of substitute equipment, diagnostic visits, maintenance visits or transportation.
            </li>
            <li>
            	Use of the Products for commercial purposes or for any use other than normal use in a single family or household.
            </li>
            <li>
            	Use of the Products outside of the country of original delivery of the Products.
            </li>
            <li>
            	Any attempt to move or repair products creates a risk of injury and property damage. Virtuix is not responsible or liable for any damage or injury incurred during, or as a result of, any move, repair or attempted repair of Products by anyone other than a Virtuix authorized service technician. All moves or repairs attempted by you or your agents are undertaken AT YOUR OWN RISK and Virtuix will have no liability for any injury to persons or property arising from such attempted moves or repairs. 
            </li>
          </ul>
        </p>
        <h3 className="copy-subheader">WHAT DO YOU NEED TO DO TO MAKE A CLAIM UNDER THE LIMITED WARRANTY?</h3>
        <p className="copy-body">
        To make a claim under this Limited Warranty, you must, upon discovering any nonconformity or defect:
        </p>
        <p className="copy-body">
          <ol className="copy-list-indent">
            <li>
            cease using the Product;
            </li>
            <li>
            provide the Virtuix support department with:
            </li>
            <ul className="copy-list-disc copy-list-indent">
            <li>
            the serial number or order number of your Product (if applicable);
            </li>
            <li>
            	a copy of the dated receipt, or other proof of purchase indicating the date purchased;
            </li>
            <li>
            	a description of the nonconformity or defect; and
            </li>
            <li>
            	photographs of the nonconformity or defect where they may assist the Virtuix support department to assess the claim.
            </li>
            </ul>
            </ol>
            </p>
            <p className="copy-body">
            Contact Virtuix if you believe you need service at help@virtuix.com. 
            </p>
            <p className="copy-body">
            Claims must be made within the specified warranty period.
            </p>
            <h3 className="copy-subheader">What happens after you submit a claim?</h3>
            <p className="copy-body">
            <ul className="copy-list-disc copy-list-indent">
            <li>
            	If, within the applicable Limited Warranty period, a defect arises in a warranted component of a Product that is covered by this Limited Warranty and you submit a claim to Virtuix under this Limited Warranty, then without excluding, restricting or modifying any other rights to which you may be entitled under applicable laws, Virtuix will, at its option and as its sole obligation, either replace the defective or malfunctioning Product or component of the same or a comparable model, or repair it, or refund to you an amount equal to the remaining useful life of the Product (calculated as follows: the amount you paid for the Product, divided by the number of months of the Limited Warranty period of the Product, multiplied by the number of months remaining on the Limited Warranty period of the Product). 
            </li>
            <li>
            	Any replacement or repaired component shall be warranted for the remainder of the original Limited Warranty period or 30 days, whichever is longer, or for any additional period that is required by applicable law. Where permitted by law, replacement units, parts and electronic components reconditioned to as-new condition by Virtuix or its vendors may sometimes be supplied as warranty replacement and constitute fulfillment of warranty terms. Goods presented for repair may be replaced by refurbished goods of the same type rather than being repaired. Refurbished parts may be used to repair the goods.
            </li>
            <li>
            	If a successful claim is made for a defect that is covered by this Limited Warranty within the Limited Warranty periods identified above, any shipping costs to return the relevant component to Virtuix or the cost of Virtuix collecting the relevant part(s) will be at Virtuix’s cost.
            </li>
            <li>
            	To the extent that the Products or component is capable of retaining user generated data, you should be aware that repairs may result in the loss of that data.
            </li>
            </ul>
            </p>
            <p className="copy-body">
            Any disputes between you and Virtuix related to this Limited Warranty or the Products will be governed by the then-current dispute resolution procedures in Virtuix’s Terms of Use, available at <a href="/terms-of-use" className="copy-link-gray">www.virtuix.com/terms-of-use</a>. 
            </p>
            <h3 className="copy-subheader">Disclaimer of Warranties, Limitation of Liability</h3>
            <p className="copy-body">
            The limited warranties provided herein are the exclusive warranties given by Virtuix and supersede any prior, contrary or additional representations, whether oral or written. 
            </p>
            <p className="copy-body">
            ANY IMPLIED WARRANTIES, INCLUDING THE WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR TITLE AND NON-INFRINGEMENT THAT APPLY TO ANY PRODUCTS DESCRIBED ABOVE ARE LIMITED IN DURATION TO THE PERIODS OF EXPRESS WARRANTIES GIVEN ABOVE FOR THOSE SAME PRODUCTS. VIRTUIX HEREBY DISCLAIMS AND EXCLUDES THOSE WARRANTIES THEREAFTER, EXCEPT TO THE EXTENT PROHIBITED BY LAW. VIRTUIX ALSO HEREBY DISCLAIMS AND EXCLUDES ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, ARISING BY LAW OR OTHERWISE, WITH RESPECT TO ANY NONCONFORMANCE OR DEFECT IN ANY PRODUCT, INCLUDING BUT NOT LIMITED TO: (A) ANY OBLIGATION, LIABILITY, RIGHT, CLAIM OR REMEDY IN TORT, WHETHER OR NOT ARISING FROM THE NEGLIGENCE OF VIRTUIX OR ITS SUPPLIERS (WHETHER ACTIVE, PASSIVE OR IMPUTED); AND (B) ANY OBLIGATION, LIABILITY, RIGHT, CLAIM OR REMEDY FOR LOSS OF OR DAMAGE TO ANY EQUIPMENT. 
            </p>
            <p className="copy-body">
            This disclaimer and release shall apply even if the express warranty set forth above fails of its essential purpose and regardless of whether damages are sought for breach of warranty, breach of contract, negligence, or strict liability in tort or under any other legal theory.
            </p>
            <p className="copy-body">
            FURTHER, YOU ARE INSTRUCTED AND SHOULD BE AWARE THAT USE OF THE PRODUCTS IS ONLY PURSUANT TO THE PUBLISHED PRODUCT INSTRUCTIONS, THAT THERE ARE RISKS ASSOCIATED WITH THE USE OF THE PRODUCTS, AND YOU SHOULD THEREFORE USE THE PRODUCTS AT YOUR OWN RISK.  
            </p>
            <p className="copy-body">
            IN NO EVENT WILL VIRTUIX, ITS SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH THIS LIMITED WARRANTY OR THE PURCHASE, USE, REPAIR OR MAINTENANCE OF EQUIPMENT OR PRODUCTS, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL DAMAGES, ECONOMIC LOSS, LOSS OF PROPERTY OR PROFITS, LOSS OF ENJOYMENT OR USE, OR OTHER CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY NATURE WHATSOEVER. VIRTUIX SHALL NOT BE LIABLE FOR ANY DAMAGE OR INJURY INCURRED DURING, OR AS A RESULT OF, ANY REPAIR OR ATTEMPTED REPAIR OF PRODUCTS BY ANYONE OTHER THAN AN AUTHORIZED VIRTUIX TECHNICIAN. THE FOREGOING LIMITATIONS DO NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. 
            </p>
            <p className="copy-body">
            You agree that the above limitations of liability together with the other provisions in this Limited Warranty that limit liability are essential terms of this Limited Warranty and that Virtuix would not be willing to grant you the rights set forth in this Limited Warranty but for your agreement to the above limitations of liability; you are agreeing to these limitations of liability to induce Virtuix to grant you the rights set forth in this Limited Warranty. Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation may not apply to you. This Limited Warranty provides you with specific legal rights, but you may also have other rights which vary from state to state.
            </p>
        <p id="lastupdated" className="copy-body">
          <b>Last Updated: April 10, 2024</b>
        </p>
      </div>
      </>
  );
}

export default LimitedWarranty;