import React from "react";
import "../../Copy.css"

function AccountDeletion() {
  return (
    <>
      <div className="copy-container-no-header">
        <h2 className="copy-header">Omni One Account Deletion</h2>
        <p className="copy-body">
            To request deletion of your Omni One account, please send an email to <a className="copy-link-gray" href="mailto:help@virtuix.com?subject=Omni One Account Deletion Request">help@virtuix.com</a>.
        </p>
        <p className="copy-body">
            When sending a request, be sure to include any relevant details regarding your account such as: 
        </p>
        <p className="copy-body copy-list-indent">
            <ul className="copy-list-disc">
                <li>First and last name</li>
                <li>Username</li>
                <li>Email address</li>
            </ul>
        </p>
        <p className="copy-body">
            Our team will reach out to you if any more information is needed to verify and remove your account.
        </p>
      </div>
    </>
  );
}

export default AccountDeletion;