import React from 'react';
import HeroSection from '../../HeroSection';
import HomeHeroSection from '../../HomeHeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import AboutSection from '../../AboutSection';


function Home() {

    return (
        <div>
        <HomeHeroSection {...homeObjOne} />
        <HeroSection {...homeObjTwo} />
        <HeroSection {...homeObjThree} />
        <HeroSection {...homeObjFour} />
        <AboutSection />
        </div>
    )
}

export default Home