import React from "react";
import "./OpResourcesOmniverseCopy.css";
import ReactPlayer from "react-player";
import { Button } from "./Button";

function OpResourcesNonOmniverseCopy() {
  return (
    <>
      <div className="ov-resources-copy-container">
      <h1 className="ov-resources-copy-title">
      Omni Software Development
        </h1>
        <p className="ov-resources-copy-body">
        If you plan to develop your own software application for the Omni, your Omni unit will need to be “unsecured”. Please contact your Virtuix representative to request that your unit be unsecured.
        </p>
        <p className="ov-resources-copy-body">
        <em>Note: unsecured Omni units will not be compatible with our Omniverse content platform.</em>
        </p>
        <p className="ov-resources-copy-body">
        If you need our Omni SDK to develop a software application with Unity or UE4, please visit our developers page <a href="http://www.virtuix.com/developers/" className="ov-resources-copy-link-gray">here</a> to request and download our SDK.
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
          Training Videos and Online Training
        </h1>
        <p className="ov-resources-copy-body">
        The most critical ingredient for a good experience on the Omni is getting used to the walking motion. Use the videos below to train your customers on how to walk properly on the Omni.
        </p>
        <div className="ov-resources-copy-player-container">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=96nbxMFX8MA"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className="ov-resources-copy-player-container">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=IdsRPglqdmI"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/4jbsld243i1izjg/Virtuix%20Omni%20Movement%20Videos.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Download Videos
            </Button>
          </a>
        </p>
        <p className="ov-resources-copy-body">
          It’s important that your Vive tracking base stations are set up
          properly to ensure flawless tracking of the player without hiccups or
          interference. Please watch the video below to ensure your tracking is
          set up correctly.
        </p>
        <div className="ov-resources-copy-player-container">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=m9Rz5_JRkGc"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <p className="ov-resources-copy-body">
          You are required to get your staff certified to operate the Omni
          system via our mandatory online training course. Have your staff take
          the online training here:{" "}
          <a
            href="https://virtuix-training.com/"
            className="ov-resources-copy-link-gray"
            target="_blank"
            rel="noreferrer"
          >
            https://virtuix-training.com/
          </a>
          . At the end of the course, the system will register the name and
          location of the certified employee.
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
        Omni Connect
        </h1>
        <p className="ov-resources-copy-body">
        Before you begin, download and install Omni Connect on your system. Please find important information about configuring Omni Connect in our Operator Manual. <b>If you have multiple Omnis, remember to set a different radio channel for each Omni.</b>
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="http://content.omniverse.global/Omni_Connect_1_3_3_0.exe"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Omni Connect
            </Button>
          </a>
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
        Omni Calibration Application
        </h1>
        <p className="ov-resources-copy-body">
        The Omni calibration application enables you to switch between the Omni’s default movement mode (move in the direction of your waist) and the Omni’s secondary “head-guided” movement mode (move in the direction of your HMD) in Omni games and applications. The application is required to calibrate your Omni using the HTC Vive headmounted display. If you own the Omni Controller Mount, this application will also automatically complete the Vive “Room Setup” calibration.
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/kc86ff9yq2ikwuo/OmniCalibration_v1.1_Setup.exe?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Omni Calibration Application
            </Button>
          </a>
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
        Omni Training Game
        </h1>
        <p className="ov-resources-copy-body">
        We created a brief training experience in VR that teaches new users how to walk on the Omni in less than a minute via an interactive VR game. Download this standalone Omni training game via the button below.
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/41fo14tjcp779p1/OmniTraining_v1.1_Setup.exe?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Download Omni Training Game
            </Button>
          </a>
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
          Games
        </h1>
        <h2 className="ov-resources-copy-header">
        Omni Arena
        </h2>
        <p className="ov-resources-copy-body">
        Survive waves of robots by shooting the attackers before they destroy the various Power Cores in the Arena. The game includes both single and multiplayer gameplay (note: multiplayer via LAN only).
        </p>
        <div className="ov-resources-flex-no-pad">
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/omniarena_game_screen_01.jpg"
            alt="Omni Arena Screenshot 01"
          />
        </p>
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/omniarena_game_screen_02.jpg"
            alt="Omni Arena Screenshot 02"
          />
        </p>
        </div>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/i7z6i6jtebm7qd1/OmniArena_vNO1.2_Setup.exe?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Omni Arena for HTC Vive
            </Button>
          </a>
        </p>
        <h2 className="ov-resources-copy-header">
        TRAVR: Training Ops
        </h2>
        <p className="ov-resources-copy-body">
        Compete for the highest score in 10 obstacle-course levels by shooting targets and establishing a record time.
        </p>
        <div className="ov-resources-flex-no-pad">
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/trainingops_game_screen_01.jpg"
            alt="Training Ops Screenshot 01"
          />
        </p>
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/trainingops_game_screen_02.jpg"
            alt="Training Ops Screenshot 02"
          />
        </p>
        </div>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/bu1ftpho25suwkh/TravrTrainingOps_vNO1.2_Setup.exe?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              TRAVR: Training Ops for HTC Vive
            </Button>
          </a>
        </p>
        <h2 className="ov-resources-copy-header">
        TRAVR: Shadow Ops
        </h2>
        <p className="ov-resources-copy-body">
        Work your way through a dark underground laboratory and shoot zombies as a TRAVR secret agent in this linear first-person shooter horror experience.
        </p>
        <div className="ov-resources-flex-no-pad">
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/shadowops_game_screen_01.jpg"
            alt="Shadow Ops Screenshot 01"
          />
        </p>
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/shadowops_game_screen_02.jpg"
            alt="Shadow Ops Screenshot 02"
          />
        </p>
        </div>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/qlubq19pkztnvtz/TravrShadowOps_vNO1.2_Setup.exe?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              TRAVR: Shadow Ops for HTC Vive
            </Button>
          </a>
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
          Marketing Assets
        </h1>
        <h2 className="ov-resources-copy-header">
        Operator Media Kit
        </h2>
        <p className="ov-resources-copy-body">
        To promote your business and events, we’ve prepared an Operator Media Kit that includes image files for social media or for printing physical signage. Instructions on editing the images with your own branding can be found within the download file.
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/ru9y0qr7iv6ftgj/Virtuix-Omni-Digital-Media.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Operator Media Kit
            </Button>
          </a>
        </p>
        <h2 className="ov-resources-copy-header">
        Omni Product Pictures
        </h2>
        <p className="ov-resources-copy-body">
        Download images of the Omni and accessories, Omniverse, and our Virtuix Omni logo.
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/srppdaotvirbjny/Omni%20Product%20Pics.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Product Pictures
            </Button>
          </a>
        </p>
        <h2 className="ov-resources-copy-header">
        Omni Action Pictures
        </h2>
        <p className="ov-resources-copy-body">
        Please feel free to use our favorite action shots in your marketing materials and presentations.
        </p>
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/developers_omni_players.jpg"
            alt="Competitive Omni Players"
          />
        </p>
        <div className="ov-resources-flex">
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/995oyataevm76nq/Omni%20Action%20Pictures%20-%20PRINT.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Action Pictures for Print
            </Button>
          </a>
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/e7men647bulvmul/Omni%20Action%20Pictures%20-WEB.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
            Action Pictures for Web
            </Button>
          </a>
        </p>
        </div>
        <h2 className="ov-resources-copy-header">
        Omni Promotional Videos
        </h2>
        <p className="ov-resources-copy-body">
        Feel free to loop these videos at your entertainment venue to attract customers.
        </p>
        <div className="ov-resources-copy-player-container">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=1SlZvuhABGk"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className="ov-resources-copy-player-container">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=mBi2icD8gO8"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className="ov-resources-flex">
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/6sy8ngg22nd3vvd/Virtuix%20Promo%20Reel%20FEB%2018.mp4?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Download Omniverse Trailer
            </Button>
          </a>
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/bf7vkmzw7t85rwo/Virtuix%20Omni%20-%20Games%20Reel.mp4?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
            Download Games Reel
            </Button>
          </a>
        </p>
        </div>
        <h1 className="ov-resources-copy-title">
        Third-Party Accessories
        </h1>
        <p className="ov-resources-copy-body">
        In addition to the Omni platform and accessories, you will require a few third-party products (not sold by Virtuix) to complete your Omni setup. We have summarized all required and optional third-party accessories for you at the following page: <a
                href="https://virtuix.com/required-third-party-products/"
                className="ov-resources-copy-link-gray"
                target="_blank"
                rel="noreferrer"
              >
                https://virtuix.com/required-third-party-products/
              </a>
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
        PC Requirements
        </h1>
        <div className="ov-resources-flex">
            <p className="ov-resources-copy-body">
                Minimum:
                <ul className="ov-resources-copy-list-disc copy-list-indent">
                    <li>Operating System: Windows 10</li>
                    <li>Processor: Intel Core i5-6600K @ 3.5 GHz</li>
                    <li>Memory: 8 GB RAM</li>
                    <li>Graphics: NVIDIA GeForce GTX 1070 8GB</li>
                    <li>Hard Drive: 1TB (SSD or HDD)</li>
                </ul>
            </p>
            <p className="ov-resources-copy-body">
            Recommended:
                <ul className="ov-resources-copy-list-disc copy-list-indent">
                    <li>Operating System: Windows 10</li>
                    <li>Processor: Intel i7-6700</li>
                    <li>Memory: 16 GB RAM</li>
                    <li>Graphics: NVIDIA Geforce RTX 2070</li>
                    <li>Hard Drive: 1TB (SSD or HDD)</li>
                </ul>
            </p>
        </div>
        <h1 className="ov-resources-copy-title">
        Resource Links
        </h1>
        <div className="ov-resources-flex">
        <p className="ov-resources-copy-body">
          <a
            href="/uploads/Omni-Connect-Quick-Start-Guide-v1.5.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Omni Connect Quick Start Guide
            </Button>
          </a>
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="/uploads/Virtuix-Omni-Owner-Manual.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
            Omni Owner's Manual
            </Button>
          </a>
        </p>
        </div>
      </div>
    </>
  );
}

export default OpResourcesNonOmniverseCopy;
