import React from "react";
import "./Press.css";
import NewsCards from "../../NewsCards";
import { newsOne, newsTwo, newsThree, newsSixteen, newsTwelve, newsThirteen, newsFourteen, newsTen, newsFifteen } from '../Press/Data';
import LazyAnimation from "../../LazyAnimation";

function Press2() {

  return (
    <>
      <div className="press-hero-bg">
        <div className="press-container">
        <div className="press-hero">
          <h1 className="press-title">In The News</h1>
          <LazyAnimation></LazyAnimation>
          <h2 className="press-subtitle">
          For all press inquiries, please contact press@virtuix.com.
          </h2>
          <a href="/images/Virtuix%20Press%20Kit.zip" download>
          <button class="btn btn-primary btn-wide green">Download Press Kit<div id="news" class="button-right-arrow"><img src="/images/arrow-icon-now.svg" alt="arrow" /></div></button>
          </a>
        </div>
        </div>
      </div>
      <div  className="press-card-container">
        <div className="press-news-cards-display">          
        <NewsCards {...newsSixteen} />
        <NewsCards {...newsFifteen} />
        <NewsCards {...newsFourteen} />
        <NewsCards {...newsThirteen} />
        <NewsCards {...newsTwelve} />        
        <NewsCards {...newsTen} />
        <NewsCards {...newsOne} />
        <NewsCards {...newsTwo} />
        <NewsCards {...newsThree} />
        </div>
        <div className="press-pagination-center">
        <div className="press-pagination">
          {/* <NavHashLink to="/press#news">&laquo;</NavHashLink>
          <NavHashLink to="/press#news">1</NavHashLink>
          <NavHashLink to="/press-2#news" class="active">2</NavHashLink>
          <a href="/#news">&raquo;</a> */}
          <a href="/press#news">&laquo;</a>
          <a href="/press#news">1</a>
          <a href="#news" class="active">2</a>
          <a href="/press-3#news">3</a>
          <a href="/press-3#news">&raquo;</a>
        </div>
        </div>
      </div>
    </>
  );
}

export default Press2;
