import React from "react";
import "./Patents.css";
import LazyAnimation from "../../LazyAnimation";
import PatentsList from "../../PatentsList";

function Patents() {
  return (
    <>
      <div className="patents-hero-bg">
        <div className="patents-container">
        <div className="patents-hero">
          <h1 className="patents-title">Virtuix Patents</h1>
          <LazyAnimation></LazyAnimation>
          <h2 className="patents-subtitle">
          Virtuix's omni-directional treadmill technology is covered by patents and pending patent applications in the United States. Additionally, several international applications are granted or pending. This web page is set up to provide virtual patent marking under 35 U.S.C. § 287(a).
          </h2>
        </div>
        </div>
      </div>      
      <PatentsList />
    </>
  );
}

export default Patents;
