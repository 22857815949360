import React from "react";
import "./Press.css";
import NewsCards from "../../NewsCards";
import { newsTwenty, newsNineteen, newsTwentyFour, newsTwentyFive, newsSeventeen, newsEighteen, newsTwentyThree, newsTwentyTwo, newsTwentyOne } from '../Press/Data';
import LazyAnimation from "../../LazyAnimation";

function Press() {

  return (
    <>
      <div className="press-hero-bg">
        <div className="press-container">
        <div className="press-hero">
          <h1 className="press-title">In The News</h1>
          <LazyAnimation></LazyAnimation>
          <h2 className="press-subtitle">
          For all press inquiries, please contact press@virtuix.com.
          </h2>
          <a href="/images/Virtuix%20Press%20Kit.zip" download>
          <button class="btn btn-primary btn-wide green">Download Press Kit<div id="news" class="button-right-arrow"><img src="/images/arrow-icon-now.svg" alt="arrow" /></div></button>
          </a>
        </div>
        </div>
      </div>
      <div className="press-card-container">
        <div className="press-news-cards-display">
        <NewsCards {...newsTwentyFive} />
        <NewsCards {...newsTwentyFour} />
        <NewsCards {...newsTwenty} />
        <NewsCards {...newsTwentyThree} />
        <NewsCards {...newsTwentyTwo} />
        <NewsCards {...newsTwentyOne} />
        <NewsCards {...newsNineteen} />
        <NewsCards {...newsEighteen} />
        <NewsCards {...newsSeventeen} />
        </div>
        <div className="press-pagination-center">
        <div className="press-pagination">
          {/* <a href="#news">&laquo;</a>
          <NavHashLink to="/press#news" class="active">1</NavHashLink>
          <NavHashLink to="/press-2#news">2</NavHashLink>
          <NavHashLink to="/press-2#news">&raquo;</NavHashLink> */}
          <a href="#news">&laquo;</a>
          <a href="#news" class="active">1</a>
          <a href="/press-2#news">2</a>
          <a href="/press-3#news">3</a>
          <a href="/press-2#news">&raquo;</a>
        </div>
        </div>
      </div>
    </>
  );
}

export default Press;
