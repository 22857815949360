export const newsOne = {
    newsImg: "/images/news_article_feat_img_001.jpg",
    newsDate: "June 8, 2021",
    newsHeadline: "How Virtual Reality Could Change Your Exercise Routine",
    newsBy: "Sascha Brodsky",
    newsLink: 'https://www.lifewire.com/how-virtual-reality-could-change-your-exercise-routine-5188248',
    newsAlt: "Omni One in home",
    newsMore: "Read more..."
};

export const newsTwo = {
    newsImg: "/images/news_article_feat_img_002.jpg",
    newsDate: "May 26, 2021",
    newsHeadline: "Virtuix raises $19 million for Omni One VR treadmill",
    newsBy: "Dean Takahashi",
    newsLink: 'https://venturebeat.com/2021/05/26/virtuix-raises-19-million-for-omni-one-vr-treadmill/',
    newsAlt: "Player crouching on Omni One",
    newsMore: "Read more..."
};

export const newsThree = {
    newsImg: "/images/news_article_feat_img_003.jpg",
    newsDate: "March 2, 2021",
    newsHeadline: "Omni Esports Platform Tops 2 Million Plays",
    newsBy: "Matt Harding",
    newsLink: 'https://www.replaymag.com/omni-esports-platform-tops-2-million-plays/',
    newsAlt: "Players on Omni Pro",
    newsMore: "Read more..."
};

export const newsFour = {
    newsImg: "/images/news_article_feat_img_004.jpg",
    newsDate: "October 22, 2020",
    newsHeadline: "Virtuix Is Levered to an Unprecedented Gaming Paradigm",
    newsBy: "Josh Enomoto",
    newsLink: 'https://www.nasdaq.com/articles/virtuix-is-levered-to-an-unprecedented-gaming-paradigm-2020-10-22',
    newsAlt: "Player jumping on Omni One",
    newsMore: "Read more..."
};

export const newsFive = {
    newsImg: "/images/news_article_feat_img_005.jpg",
    newsDate: "October 7, 2020",
    newsHeadline: "Virtuix Will Return to Home VR Market With Omni One Treadmill",
    newsBy: "Dean Takahashi",
    newsLink: 'https://venturebeat.com/2020/10/07/virtuix-will-return-to-home-vr-market-with-omni-one-treadmill/',
    newsAlt: "Man running on Omni One",
    newsMore: "Read more..."
};

export const newsSix = {
    newsImg: "/images/news_article_feat_img_006.jpg",
    newsDate: "October 7, 2020",
    newsHeadline: "Virtuix Is Developing a Home Version of Its Omni VR Treadmill",
    newsBy: "Kris Holt",
    newsLink: 'https://www.engadget.com/omni-one-vr-treadmill-virtuix-100013958.html',
    newsAlt: "Omni One in living room",
    newsMore: "Read more..."
};

export const newsSeven = {
    newsImg: "/images/news_article_feat_img_007.jpg",
    newsDate: "September 3, 2020",
    newsHeadline: "Virtual Reality Esports Activity Recovering From Pandemic",
    newsBy: "Vending Times",
    newsLink: 'https://www.vendingtimes.com/news/virtual-reality-esports-activity-recovering-from-pandemic/',
    newsAlt: "Omni One in living room",
    newsMore: "Read more..."
};

export const newsEight = {
    newsImg: "/images/news_article_feat_img_008.jpg",
    newsDate: "June 19, 2020",
    newsHeadline: "Virtuix Reports Encouraging Data From Reopened US Entertainment Venues",
    newsBy: "Bryony Andrews",
    newsLink: 'https://www.globalamusementsandplay.com/virtuix-reports-encouraging-data-from-reopened-entertainment-venues/',
    newsAlt: "Graph of games played",
    newsMore: "Read more..."
};

export const newsNine = {
    newsImg: "/images/news_article_feat_img_009.jpg",
    newsDate: "January 24, 2020",
    newsHeadline: "Virtuix Reveals That Esports Encourages Repeat Play",
    newsBy: "Charlotte Coates",
    newsLink: 'https://blooloop.com/play/news/virtuix-esports-repeat-play/',
    newsAlt: "Omni Arena VR attraction",
    newsMore: "Read more..."
};

export const newsTen = {
    newsImg: "/images/news_article_feat_img_010.jpg",
    newsDate: "November 30, 2021",
    newsHeadline: "Omni Arena Has Best Show Ever at Iaapa Expo 2021",
    newsBy: "Becci Knowles",
    newsLink: 'https://www.globalamusementsandplay.com/omni-arena-has-best-show-ever-at-iaapa-expo-2021/',
    newsAlt: "Omni Arena at IAAPA Expo",
    newsMore: "Read more..."
};

export const newsEleven = {
    newsImg: "/images/news_article_feat_img_011.jpg",
    newsDate: "July 13, 2022",
    newsHeadline: "Virtuix Installs 60th Omni Arena",
    newsBy: "Vending Times",
    newsLink: 'https://www.vendingtimes.com/news/virtuiz-installs-60th-omni-arena/',
    newsAlt: "Omni Arena VR attraction",
    newsMore: "Read more..."
};

export const newsTwelve = {
    newsImg: "/images/news_article_feat_img_012.jpg",
    newsDate: "October 18, 2022",
    newsHeadline: "Virtuix Shows Great Success at Jake's Unlimited",
    newsBy: "Matt Harding",
    newsLink: 'https://www.replaymag.com/virtuix-shows-great-success-at-jakes-unlimited/',
    newsAlt: "Omni Arena at Jake's Unlimited",
    newsMore: "Read more..."
};

export const newsThirteen = {
    newsImg: "/images/news_article_feat_img_013.jpg",
    newsDate: "November 2, 2022",
    newsHeadline: 'Virtuix Unveils "Rhythm Master" Game for Omni Arena',
    newsBy: "Amusement Resource Connection",
    newsLink: 'http://www.arcfec.com/a/index.php/industries/virtual-reality/73-virtuix-unveils-rhythm-master-game-for-omni-arena',
    newsAlt: "Rhythm Master Announced",
    newsMore: "Read more..."
};

export const newsFourteen = {
    newsImg: "/images/news_article_feat_img_014.jpg",
    newsDate: "March 14, 2023",
    newsHeadline: 'Virtuix Celebrates 70th Omni Arena Installation',
    newsBy: "RePlay Magazine",
    newsLink: 'https://www.replaymag.com/virtuix-celebrates-70th-omni-arena-installation/',
    newsAlt: "Rhythm Master Announced",
    newsMore: "Read more..."
};

export const newsFifteen = {
    newsImg: "/images/news_article_feat_img_015.jpg",
    newsDate: "March 21, 2023",
    newsHeadline: "Virtuix 'Omni One' VR Treadmill Now Shipping to Early Investors",
    newsBy: "Scott Hayden",
    newsLink: 'https://www.roadtovr.com/virtuix-omni-one-vr-treadmill-price-release-date/',
    newsAlt: "Omni One player",
    newsMore: "Read more..."
};

export const newsSixteen = {
    newsImg: "/images/news_article_feat_img_016.jpg",
    newsDate: "March 21, 2023",
    newsHeadline: 'Virtuix targets Omni One VR treadmills at home consumers',
    newsBy: "Dean Takahashi",
    newsLink: 'https://venturebeat.com/games/virtuix-targets-omni-one-vr-treadmills-at-home-consumers/',
    newsAlt: "Omni One player",
    newsMore: "Read more..."
};

export const newsSeventeen = {
    newsImg: "/images/news_article_feat_img_017.jpg",
    newsDate: "April 3, 2023",
    newsHeadline: 'A decade later, this VR treadmill is finally ready to ship',
    newsBy: "TechCrunch",
    newsLink: 'https://techcrunch.com/2023/04/03/a-decade-later-this-vr-treadmill-is-finally-ready-to-ship/',
    newsAlt: "Omni One player on TechCrunch article",
    newsMore: "Read more..."
};

export const newsEighteen = {
    newsImg: "/images/news_article_feat_img_018.jpg",
    newsDate: "June 30, 2023",
    newsHeadline: 'This Brand-New Omni-Directional VR Treadmill is INSANE!',
    newsBy: "Zulushi",
    newsLink: 'https://www.youtube.com/watch?v=99OuoNGKNqM',
    newsAlt: "Omni One player on TechCrunch article",
    newsMore: "Watch video..."
};

export const newsNineteen = {
    newsImg: "/images/news_article_feat_img_019.jpg",
    newsDate: "July 14, 2023",
    newsHeadline: 'MB2 Shares Huge Earnings with Omni Arena',
    newsBy: "Matt Harding",
    newsLink: 'https://www.replaymag.com/mb2-shares-huge-earnings-with-omni-arena/',
    newsAlt: "Omni Arena at MB2",
    newsMore: "Read more..."
};

export const newsTwenty = {
    newsImg: "/images/omni_game_announce_031324.jpg",
    newsDate: "March 13, 2024",
    newsHeadline: 'Virtuix Unveils 35 Launch Titles for Omni One',
    newsBy: "Virtuix",
    newsLink: '/virtuix-unveils-omni-one-launch-games',
    newsAlt: "Omni One Launch Games Announced",
    newsMore: "Read more..."
};

export const newsTwentyOne = {
    newsImg: "/images/news_article_feat_img_021.jpg",
    newsDate: "July 24, 2023",
    newsHeadline: 'Hands-on: Virtuix Comes Full Circle With an All-In-One VR Treadmill System',
    newsBy: "Road To VR",
    newsLink: 'https://www.roadtovr.com/virtuix-omni-one-vr-treadmill-hands-on-preview/',
    newsAlt: "Hands-on with Omni One",
    newsMore: "Read more..."
};

export const newsTwentyTwo = {
    newsImg: "/images/news_article_feat_img_022.jpg",
    newsDate: "August 7, 2023",
    newsHeadline: 'Virtuix Raises $5 Million For Home VR Treadmill',
    newsBy: "Forbes",
    newsLink: 'https://www.forbes.com/sites/charliefink/2023/08/07/virtulix-raises-5-million-for-home-vr-treadmill/?sh=477e519e34dd',
    newsAlt: "Virtuix Raises $5 Million",
    newsMore: "Read more..."
};

export const newsTwentyThree = {
    newsImg: "/images/news_article_feat_img_023.jpg",
    newsDate: "October 18, 2023",
    newsHeadline: 'Virtuix opens preorders for Omni One VR treadmill',
    newsBy: "Venture Beat",
    newsLink: 'https://venturebeat.com/metaverse/virtuix-opens-preorders-for-omni-one-vr-treadmill/',
    newsAlt: "Virtuix opens preorders for Omni One",
    newsMore: "Read more..."
};

export const newsTwentyFour = {
    newsImg: "/images/omni_otterworldy_042124.jpg",
    newsDate: "April 21, 2024",
    newsHeadline: 'I Tested the UNRELEASED VR Treadmill That Lets You RUN IN ANY GAME!',
    newsBy: "OtterWorldy",
    newsLink: 'https://www.youtube.com/watch?v=jgdkmbJhYC0',
    newsAlt: "OtterWorldly plays Omni One",
    newsMore: "Watch video..."
};

export const newsTwentyFive = {
    newsImg: "/images/news_article_feat_img_025.jpg",
    newsDate: "July 30, 2024",
    newsHeadline: 'Virtuix Expands Omni One Game Lineup to Over 50 Titles',
    newsBy: "Virtuix",
    newsLink: '/virtuix-expands-omni-one-game-lineup-to-over-50-games',
    newsAlt: "Virtuix Expands Omni One Game Lineup",
    newsMore: "Read more..."
};