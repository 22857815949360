import React from "react";
import "../OmniArenaResources/OmniArenaResources.css";
import "../../OpResourcesOmniverseCopy.css";
import LazyAnimation from "../../LazyAnimation";
import OpResourcesOmniverseCopy from "../../OpResourcesOmniverseCopy";
import { Button } from "../../Button";
import { Helmet } from "react-helmet";

function OpResourcesOmniverse() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="oa-resources-hero-bg">
        <div className="oa-resources-container">
          <div className="oa-resources-hero">
            <h1 className="oa-resources-title">Operator Resources</h1>
            <LazyAnimation></LazyAnimation>
            <h2 className="oa-resources-subtitle">
              Thank you for being a Virtuix partner! Below are the download
              links to Omni software, training materials, and marketing assets
              that are included with your Omni purchase. We’ve also created an
              Operator Manual with best practices on how to operate your Omni
              setup successfully and provide players with a great Active VR
              experience. Enjoy!
            </h2>
            <div className="ov-resources-copy-button-hero">
              <a
                href="/uploads/Virtuix Omni - Operator Manual v3.8.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <Button buttonSize="btn-wide" buttonColor="green">
                  Download Operator Manual
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <OpResourcesOmniverseCopy />
    </>
  );
}

export default OpResourcesOmniverse;
