import React from "react";
import "../../Copy.css";

function HealthAndSafety() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Health and Safety Warnings and Instructions</h1>
        <p className="copy-body">
        The Omni One treadmill, virtual reality headset, hand controllers and other accessories (whether used together or separately, “Omni One”) is a physical device. Using Omni One requires physical exercise. By using Omni One, you represent and warrant that you have read and understand these Health and Safety Warnings and Instructions as well as the Assembly and Usage Instructions listed in the Omni One manual (the “Instructions”). You agree to follow all Instructions during assembly and use of Omni One. You also represent and warrant that you will inform any household members or guests or anyone else other than you who use your Omni One (“Guest Users”) of these Instructions. You will enforce that Guest Users follow these Instructions. You understand and acknowledge that failure to follow these Instructions may result in serious bodily injury to you and/or the Guest Users and/or damage to your Omni One and other property. You represent and warrant that you will advise any Guest User that failure to follow these Instructions may result in serious bodily injury and/or damage to Omni One and other property. These Instructions supplement and form a part of the Virtuix <a href="https://virtuix.com/terms-of-use" className="copy-link-gray">Terms of Use</a> (the “Terms”). Capitalized terms not defined in these Instructions have the meaning given to them in the Terms.
        </p>
        <p className="copy-body">
        Exercise can present a health risk. Prolonged use of Omni One may cause dizziness or fatigue. Neither you nor any Guest User should use Omni One for longer than 30 minutes at a time. Players who feel faint or dizzy should immediately discontinue use of Omni One. Some people may have a severe reaction to certain video games or virtual reality environments, including dizziness, seizures, epileptic seizures, or blackouts triggered by light flashes or patterns, even if they have no prior history of seizures or blackouts. If you or a Guest User have had a seizure, loss of awareness, or other symptoms that may be linked to an epileptic disorder, you or the Guest User should consult a physician before using Omni One.
        </p>
        <p className="copy-body">
        We reserve the right to change or modify these Instructions on a go-forward basis at any time and at our sole discretion. If we make changes to these Instructions, we may provide notice of such changes as appropriate, such as by sending an email notification to the address you have provided, or we may provide notice by updating the "Last Updated" date at the bottom of these Instructions, which are available at <a href="#lastupdated" className="copy-link-gray">https://virtuix.com/health-and-safety-warnings-and-instructions</a>.
        </p>
        <p className="copy-body">
        Your continued use of Omni One will confirm your acceptance of the revised Instructions. If you do not agree to or cannot adhere to the revised Instructions, you must stop using Omni One. Please review these Instructions from time to time to ensure that you understand the warnings and instructions that apply to your access to, and use of, Omni One.
        </p>
        <h2 className="copy-header">User Requirements</h2>
        <p className="copy-body">
        You acknowledge and agree that you or any Guest Users will not use Omni One if you or they are:
        </p>
        <p className="copy-body">
          <ol className="copy-list-indent">
            <li>
            Under the age of 13
            </li>
            <li>
            Outside the supported user height and weight requirements of between 4’4” (132cm) and 6’4” (192cm) tall, and max. 250lbs (113kg) in weight
            </li>
            <li>
            Pregnant
            </li>
            <li>
            Intoxicated
            </li>
            <li>
            Epileptic
            </li>
            <li>
            Recovering from major surgery, illness, or a heart-attack
            </li>
            <li>
            Not healthy enough to engage in moderate to heavy physical exercise
            </li>
            <li>
            Incapable of following usage guidelines 
            </li>
            <li>
            Elderly or infirm, without supervision
            </li>
            <li>
            Experience pain, numbness, tingling, or other uncomfortable sensations in joints, muscles or skin, or are feeling dizzy or unwell. Stop whenever you experience these symptoms, and rest every 20 to 30 minutes to rehydrate. 
            </li>
          </ol>
        </p>
        <p className="copy-body">
        You affirm that either (A) all of the following statements are true:
        </p>
        <p className="copy-body">
          <ol className="copy-list-indent">
            <li>
            no physician or general practitioner has ever informed you that you have a heart condition or that you should only do physical activities recommended by a physician or general practitioner;
            </li>
            <li>
            you have never felt chest pain when engaging in physical activity;
            </li>
            <li>
            you have not experienced chest pain when not engaged in physical activity at any time within the past month;
            </li>
            <li>
            you have never lost your balance because of dizziness and you have never lost consciousness;
            </li>
            <li>
            you do not have a bone or joint problem that could be made worse by a change in your physical activity;
            </li>
            <li>
            your physician or general practitioner is not currently prescribing drugs for your blood pressure or heart condition;
            </li>
            <li>
            you do not have a history of high blood pressure, and no one in your immediate family has a history of high blood pressure or heart problems; and
            </li>
            <li>
            you do not know of any other reason you should not exercise; or
            </li>
          </ol>
        </p>
        <p className="copy-body">
        (B) your physician or general practitioner has been specifically consulted by you and has approved of your use of Omni One.
        </p>
        <p className="copy-body">
        You represent and warrant that you have confirmed with any Guest User that they have also affirmed to you that either (A) or (B) above applies to them before you allow them to use the Omni One.
        </p>
        <p className="copy-body">
        THE OMNI ONE SERVICES OFFER HEALTH AND FITNESS INFORMATION AND IS DESIGNED FOR EDUCATIONAL AND ENTERTAINMENT PURPOSES ONLY. YOU SHOULD CONSULT YOUR PHYSICIAN OR GENERAL PRACTITIONER BEFORE BEGINNING A FITNESS PROGRAM WITH OMNI ONE. YOU SHOULD NOT RELY ON THIS INFORMATION AS A SUBSTITUTE FOR, NOR DOES IT REPLACE, PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. IF YOU HAVE ANY CONCERNS OR QUESTIONS ABOUT YOUR HEALTH, YOU SHOULD ALWAYS CONSULT WITH A PHYSICIAN, GENERAL PRACTITIONER OR OTHER HEALTH-CARE PROFESSIONAL. DO NOT DISREGARD, AVOID OR DELAY OBTAINING MEDICAL OR HEALTH RELATED ADVICE FROM YOUR HEALTHCARE PROFESSIONAL BECAUSE OF SOMETHING YOU MAY HAVE READ OR HEARD ON THE OMNI ONE SERVICES. THE USE OF INFORMATION PROVIDED THROUGH THE OMNI ONE SERVICES IS SOLELY AT YOUR OWN RISK AND IS NOT MEDICAL OR HEALTHCARE ADVICE.
        </p>
        <p className="copy-body">
        NOTHING STATED OR POSTED ON OR AVAILABLE THROUGH ANY OMNI ONE SERVICES IS INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, THE PRACTICE OF MEDICAL OR COUNSELING CARE. FOR PURPOSES OF THESE TERMS, THE PRACTICE OF MEDICINE AND COUNSELING INCLUDES, WITHOUT LIMITATION, PSYCHIATRY, PSYCHOLOGY, PSYCHOTHERAPY, OR PROVIDING HEALTH CARE TREATMENT, INSTRUCTIONS, DIAGNOSIS, PROGNOSIS OR ADVICE. THE OMNI ONE SERVICES ARE CONTINUALLY UNDER DEVELOPMENT AND, TO THE FULL EXTENT PERMITTED BY LAW, VIRTUIX MAKES NO WARRANTY OF ANY KIND, IMPLIED OR EXPRESS, AS TO ITS ACCURACY, COMPLETENESS OR APPROPRIATENESS FOR ANY PURPOSE. IN THAT REGARD, DEVELOPMENTS IN MEDICAL RESEARCH MAY IMPACT THE HEALTH, FITNESS AND NUTRITIONAL ADVICE THAT APPEARS ON OMNI ONE SERVICES. NO ASSURANCE CAN BE GIVEN THAT THE ADVICE CONTAINED IN THE OMNI ONE SERVICES WILL ALWAYS INCLUDE THE MOST RECENT FINDINGS OR DEVELOPMENTS WITH RESPECT TO THE PARTICULAR MATERIAL.
        </p>
        <h2 className="copy-header">Usage Warnings and Instructions</h2>
        <p className="copy-body">
          <ol className="copy-list-indent">
            <li>
            Ensure that Omni One is placed in a safe location where full extension of your or a Guest User’s limbs (including any handheld props or devices) cannot contact any walls, ceiling, furniture, lights, fans, or other obstacles.
            </li>
            <li>
            To avoid accidents, wear the hand controllers’ lanyards during use.
            </li>
            <li>
            Set Omni One to the correct height according to your or your Guest User’s body height. Use the indicators on the Omni One arm to set for appropriate body height. Using Omni One on an incorrect height setting can result in bodily injury.
            </li>
            <li>
            The Omni One base is highly slippery. Always engage the anti-slip friction pad of the Omni One footwear before getting on and off the Omni One base. 
            </li>
            <li>
            Do not use the Omni One footwear outside of the Omni One product. Always take off the Omni One footwear after using Omni One.
            </li>
            <li>
            Always strap on and securely tighten the Omni One vest before use. Fasten the Velcro belt around your or your Guest User’s waist, ensuring it fits snugly. Connect the chest strap using the buckle; you should hear a ‘click’ as it locks into place; then tighten for a snug fit.
            </li>
            <li>
            Hold onto the hand grip on top of the Omni One vest when getting on and off the Omni One base.
            </li>
            <li>
            Omni One has a dial on top of its arm to increase or decrease the resistance force when using Omni One. If you or your Guest User are a novice user and need more support or resistance when walking on Omni One, increase the resistance force by rotating the dial to a higher setting.
            </li>
            <li>
            Ensure the Omni One rotation is locked before you or your Guest User get on or off the Omni One base. Only unlock the rotation of Omni One once you or your Guest User are securely strapped into the Omni One vest.  Unlock the rotation of Omni One by pressing the vest switch. 
            </li>
            <li>
            <b>Keep other people (particularly children) and pets away when you or a Guest User are using Omni One. You may accidentally strike them with a handheld controller or with the rotating arm of the Omni One treadmill, or they may attempt to climb onto the platform during use.</b>
            </li>
          </ol>
        </p>
        <p className="copy-body">
        Prolonged use of the Omni One may cause dizziness or fatigue. If you or a Guest User experience discomfort while using Omni One:
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
            Stop usage of the Omni One  immediately if you or the Guest User experience any of the following: seizure; loss of awareness; eye or muscle twitching; involuntary movements; altered, blurred, or double vision; feeling detached from your body or from reality; dizziness; disorientation; disrupted balance; nausea; lightheadedness; discomfort or pain in the eyes or head; drowsiness; fatigue; excessive sweating; itchy or irritated skin; or any symptoms similar to motion sickness.
            </li>
            <li>
            If symptoms persist or recur, you and/or the Guest User should consult a doctor.
            </li>
            <li>
            Do not operate a motor vehicle, operate machinery, or engage in activities that may have potentially serious consequences until you or the Guest User have fully recovered from these symptoms.
            </li>
          </ul>
        </p>
        <p className="copy-body">
        Use of VR headsets may present risks of injury. Follow these instructions specifically with regards to the use of the virtual reality (“VR”) headset:
        </p>
        <p className="copy-body">
          <ol className="copy-list-indent">
            <li>
            If you or a Guest User use the VR headset outside of the Omni One treadmill, ensure you or the Guest User are in an open and safe indoor area, free of any obstructions and tripping, slipping, or other hazards. Remain conscious to the potential confines of your physical area and respect the boundary of your virtual area whenever you see it. The safety boundary system cannot guarantee your or your Guest User’s safety, so always pay attention to your or their surroundings. Make sure that there is enough space around your or your Guest User’s head and body (at least 6ft./2m by 6ft./2m) to stretch arms to avoid damage or injury to yourself, your Guest User, others, and the surroundings. Do not use the VR headset near stairs, windows, heat sources or other hazardous areas. Serious injuries can occur from tripping or running into or striking walls, furniture, objects, or people.
            </li>
            <li>
            Take appropriate steps to prevent others from entering your or your Guest User’s play space, including people (particularly children) or pets.
            </li>
            <li>
            Do not use the VR headset outdoors or when walking, cycling, driving, or other situations that require full visibility. Take caution if using the VR headset as a passenger in a motor vehicle, as irregular movement may increase the risk of motion sickness.
            </li>
            <li>
            Neither you nor a Guest User should use the pass-through camera to navigate the real world, or interact with real-world objects. You and any Guest User should always remove the VR headset for any real-world situation that requires attention or coordination so that you or the Guest User have an unobstructed view.
            </li>
            <li>
            To reduce the risk of discomfort, the inter-pupillary distance (IPD) of the VR headset lenses should be appropriately set for you and any Guest User. 
            </li>
            <li>
            The VR headset is designed to accommodate most prescription glasses. Take care to wear the VR headset in a way the VR headset lenses do not rub or impact your prescription lenses.
            </li>
            <li>
            Do not expose the optical lenses of the VR headset to direct sunlight or other strong light sources. Exposure to direct sunlight may cause permanent yellow spot damage on the screen. Screen damage caused by sunlight exposure or other strong sources of light is not covered by the warranty.
            </li>
            <li>
            Some people may be allergic to plastic, PU, fabric, and other materials used in the VR headset. Long-term contact with skin may result in symptoms such as redness, swelling and inflammation. Stop using the VR headset and Omni One if you or a Guest User experience any of the symptoms listed above and consult a doctor.
            </li>
            <li>
            Do not use high volume for extended periods of time to prevent possible hearing damage. When using headphones, use the minimum volume required to hear the audio so as not to damage your or a Guest User’s hearing. Prolonged exposure to high volume may cause permanent hearing damage.
            </li>
            <li>
            The VR headset is equipped with a non-removable internal battery. Do not attempt to replace the battery, as doing so may cause battery damage, fire or human injury. The battery can only be replaced by Virtuix or Virtuix authorized service providers. Do not disassemble or modify the battery, insert foreign objects, or immerse in water or other liquid. Handling the battery  can cause chemical leakage, overheating, fire, or explosion. If the battery appears to be leaking material, avoid any contact with skin or eyes. In case of material contact with skin or eyes, immediately rinse with clear water and contact your local poison authority. Do not drop, squeeze or puncture the battery. Avoid subjecting the battery to high temperatures or external pressure, which may result in corruption and overheating of the battery.
            </li>
          </ol>
        </p>
        <p className="copy-body">
        Omni One may impact medical devices. Follow these instructions specifically with regards to the use of Omni One near medical devices:
        </p>
        <p className="copy-body">
          <ol className="copy-list-indent">
            <li>
            Comply with the expressly stated prohibition of the use of wireless equipment in medical and healthcare facilities.
            </li>
            <li>
            Radio waves generated by Omni One may affect the normal operation of implantable medical devices or personal medical devices, such as pacemakers, cochlear implants, hearing aids, etc. Please consult the medical device manufacturer about the restrictions on the use Omni One if you or any Guest User use these medical devices.
            </li>
            <li>
            Keep a distance of at least 6in./15cm from the implanted medical devices (such as pacemakers, cochlear implants, etc.) Stop using Omni One if you or a Guest User observe a persistent interference with your or the Guest User’s medical device.
            </li>
          </ol>
        </p>
        <h2 className="copy-header">Installation Warnings and Instructions</h2>
        <p className="copy-body">
          <ol className="copy-list-indent">
            <li>
            Follow all assembly instructions carefully. If possible, have someone assist to help prevent incorrect assembly. 
            </li>
            <li>
            Place Omni One in an area that can be isolated from other people and pets, so that you can keep them away from Omni One when in use.
            </li>
            <li>
            Do not place Omni One where it obstructs access to exits or emergency services, or anywhere people may need to move.
            </li>
            <li>
            Do not place Omni One on a surface that is uneven, shifting, moving, or flexible, or a surface that is not capable of supporting the combined weight of Omni One and a user.
            </li>
            <li>
            Do not use Omni One in dusty, humid, or dirty conditions or near strong magnetic fields. These conditions may cause internal circuit failure of Omni One. Do not use Omni One during thunderstorms. Thunderstorms may cause product failure and increase the risk of electric shock.
            </li>
            <li>
            Operating Temperature: 0-35°C/32-104°F, minimum humidity 5%, maximum humidity 95% RH (non-condensing). Non-Operation (Storage): -20-45°C/ -4-113°F, 85% RH.
            </li>
            <li>
            Do not attempt to install or use Omni One in unsafe locations – for instance: under water, on top of a moving platform or vehicle, densely crowded areas without a cordon, anywhere it is exposed to weather, smoke, fume, or harmful gasses, or anywhere it obstructs traffic.
            </li>
            <li>
            Ensure there is sufficient room around your Omni One so that users cannot strike walls, windows, ceilings, lights, furniture, or other fixtures with their limbs or peripherals while playing. Measure this space accordingly prior to assembly and/or use.
            </li>
            <li>
            The plastic Omni One base is very heavy. To avoid injury, always have two people available and use safe lifting techniques when moving or setting up the Omni One base.
            </li>
            <li>
            Clip the vest cable into the provided cable clips to provide strain relief and avoid any choking hazards during play. Attach the vest switch securely in its attachment clip.
            </li>
            <li>
            CHOKING HAZARD: Do not leave small parts unattended near children or animals during assembly or use of Omni One. Small parts present a choking hazard.
            </li>
            <li>
            When using Omni One with a PC and a tethered head-mounted display, ensure any headset cables are safely and securely rigged away from the user to prevent accidents.
            </li>
            <li>
            Only use power adapters and charging devices provided in the product package or specified as an approved device by Virtuix.  When charging is completed, disconnect the charger from the equipment and unplug the charger from the power outlet. If the charging adapter or cable is damaged, discontinue using to prevent the risk of electric shock or fire. Do not operate the equipment or charger with wet hands to avoid short circuits, failure or electric shock. Do not use chargers if wet.
            </li>
          </ol>
        </p>
        <h2 className="copy-header">Maintenance Warnings and Instructions</h2>
        <p className="copy-body">
          <ol className="copy-list-indent">
            <li>
            Prior to use, inspect all parts to confirm that they are in working order and there are no cracks, tears, bulges, bending, or other signs of wear and tear in the Omni One treadmill and accessories. Check all connections and moving parts, including the connection between the Omni One vest and arm, and confirm that all nuts, bolts, and other connections of Omni One are securely fastened.
            </li>
            <li>
            Virtuix may from time to time recommend or even require that you replace certain parts of the Omni One product when reaching certain usage milestones. Replace those parts promptly per Virtuix’s instructions, as not doing so may result in bodily injury or permanent damage to your product.
            </li>
            <li>
            Maintain the low-friction coefficient of the Omni One base by following the base cleaning instructions listed in the Omni One manual and inside the Omni One step. When the Omni One base becomes too frictionous, users may be able to step off the base, which could result in serious bodily injury or damage to the product. When the base becomes too slippery, usage may become unsafe. 
            </li>
            <li>
            Use only soap, water, or Easy-WalkTM to clean the Omni One base. Do not use harsh chemicals, chemical cleaners, reactive agents, or abrasive cleaning products, as they may damage the Omni One finish. Do not use wet wipes. Be sure Omni One is completely dry before attempting to use after cleaning.
            </li>
            <li>
            When cleaning, only apply one to two sprays of Easy-WalkTM at a time. Too much Easy-WalkTM can render the base too slippery, creating a safety hazard during use.
            </li>
            <li>
            Do not spill or use excessive liquid on the Omni One base, as the base houses electronics underneath.
            </li>
            <li>
            Do not add liquids or other substances to the Omni One base to try to alter the texture or friction coefficient of base. Doing so may damage the product or may cause unsafe usage conditions.
            </li>
            <li>
            To avoid risk of electric shock, keep the cord, plug, and power supply away from heat sources and water. Do not plug in the power cord if it is damaged or wet. Unplug the power cord when not in use, and before cleaning the product. Check that your included power adaptor is suitable for your country’s power supply.
            </li>
            <li>
            Do not submerge the Foot Trackers, VR headset, or hand controllers in liquid, or expose them to shock or heat.
            </li>
            <li>
            Keep the Foot Trackers and hand controllers away from magnetic sources at all times. A magnetic source may damage the sensors inside. 
            </li>
            <li>
            Omni One emits radio frequency (RF) signals. Do not operate around critical electronic equipment (e.g., in hospitals).
            </li>
            <li>
            Do not attempt to repair Omni One yourself, or modify the Omni One footwear, vest, platform or other accessories in any way. Please contact Virtuix if repair or replacement is required and discontinue use of Omni One in the meantime.
            </li>
            <li>
            Only accessories approved by Virtuix, such as power supplies and data cables, can be used with Omni One. The use of unapproved third-party accessories may cause fire, explosion or other damages. The use of unapproved third-party accessories may violate the warranty terms of the product and the relevant regulations of the country where the product is located. For approved accessories, please contact <a href="mailto:help@virtuix.com" className="copy-link-gray">help@virtuix.com</a>.
            </li>
            <li>
            Dispose of your Omni One properly. Do not dispose of Omni One in a fire or incinerator, as its batteries may explode when overheated. Dispose of separately from household waste. Please comply with the local laws and regulations on the disposal of electrical and electronic equipment to dispose of Omni One.
            </li>
            <li>
            Do not store or transport Omni One in the same container as flammable liquids, gases, or substances.
            </li>
          </ol>
        </p>
        <h3 className="copy-subheader">Follow these instructions specifically related to the maintenance of the VR headset:</h3>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
            Lens care:
            </li>
            <ul className="copy-list-circle copy-list-indent">
                <li>Protect your lenses from light. Keep the VR headset away from direct sunlight or ultraviolet rays, such as windowsills and automobile dashboards or other strong light sources.</li>
                <li>During use or storage, please pay attention to avoid hard objects touching the lens to avoid lens scratches.</li>
                <li>Use an optical lens micro-fiber cloth to dip in a little water or use a non-alcoholic disinfectant wipe to clean the lenses. Do not wipe the lenses with alcohol or other harsh or abrasive cleaning solutions as this may lead to damage.</li>
                <li>Do not apply the Easy-Walk<sup className="copy-sup">TM</sup> solution to the lenses or other parts of the VR headset.</li>
            </ul>
          </ul>
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
            Face cushion care:
            </li>
            <ul className="copy-list-circle copy-list-indent">
                <li>Do not hand wash or machine wash the face cushions.</li>
                <li>Use a sterile wipe (alcohol-based ingredients allowed) or a microfiber dry cloth dipped in a small amount of 75% alcohol to gently wipe the surface and surrounding areas in contact with the skin until the surface is slightly wet. Leave to dry before use (Do not expose directly in sunlight).</li>
                <li>Please change a new face cushion if any of the following occur: color change, sticky surface hair, decreased facial comfort.</li>
            </ul>
          </ul>
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
            VR Headset (except the lens, face cushion), controller and accessories care:
            </li>
            <ul className="copy-list-circle copy-list-indent">
                <li>Please use disinfectant wipes (alcohol-based ingredients allowed) or use a microfiber dry cloth to dip in a small amount of 75% alcohol and gently wipe the surface of the product until the surface is wet and wait at least 5 minutes, then dry the surface with a microfiber dry cloth.</li>
                <li>Please avoid getting water into the product when cleaning.</li>
            </ul>
          </ul>
        </p>
        <h2 className="copy-header">Other Warnings and Safety Instructions</h2>
        <p className="copy-body">
        You acknowledge and agree that you or any Guest Users:
        </p>
        <p className="copy-body">
          <ol className="copy-list-indent">
            <li>
            WILL NOT Use bare feet, socks, or any footwear on Omni One other than those supplied with your package or otherwise provided by Virtuix. Using Omni One with your bare feet or with socks or non-approved footwear may cause bodily injury or damage to the platform. Do not attempt to modify the Omni One footwear in any way.
            </li>
            <li>
            WILL NOT Leave the Foot Trackers or other small parts of Omni One where they can be reached by children or animals – these present a choking hazard. The Foot Trackers contain Lithium-Ion batteries and may be harmful when swallowed. Store them inside the storage compartment of the Omni One step.
            </li>
            <li>
            WILL NOT Leave children unattended or appliances running (e.g., baths, cookers, or heaters) while you are immersed in virtual reality - it is easy to lose track of time and awareness of your surroundings.
            </li>
            <li>
            WILL NOT Leave your home unlocked if you or a Guest User are alone and immersed in virtual reality – you or the Guest User will not be aware of anyone sneaking into the home uninvited.
            </li>
            <li>
            WILL NOT Attempt any high-consequence activity such as driving, weight lifting, or operating heavy machinery until you or the Guest User have recovered from the gaming session.
            </li>
            <li>
            WILL NOT Attempt to use incorrect cables for the power supply of Omni One, head-mounted display, or Foot Trackers – this could damage the internal circuits or the connectors.
            </li>
            <li>
            WILL NOT Give out details of your location to people you meet online. Be especially careful when live-streaming that you and the Guest User take every precaution necessary to avoid being ‘SWATTED’. We advise a poster on the door and other similar measures to alert the local authority to this illegal practice, in the event they should be directed to your home while you or a Guest User are using Omni One. We do not recommend the use of a realistic gun peripheral at home, though we may employ such devices in our demonstration videos.
            </li>
          </ol>
        </p>
        <p className="copy-body">
        This product contains a chemical known to the State of California to cause cancer and birth defects or other reproductive harm.
        </p>
        <p id="lastupdated" className="copy-body">
          <b>Last Updated: November 26, 2023</b>
        </p>
      </div>
    </>
  );
}

export default HealthAndSafety;
