import React from "react";
import { Link } from "react-router-dom";
import "../../Copy.css";

function ContentSubmissionTerms() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Omni One Content Submission Terms</h1>
        <p className="copy-body">
          These Omni One Content Submission Terms (
          <b>"Content Submission Terms"</b>) and the Virtuix{" "}
          <Link className="copy-link-to-gray" to="/terms-of-use">
            Terms of Use
          </Link>{" "}
          (altogether, the <b>"Terms"</b>) apply to any game software, pictures, video trailers, descriptions, developer notes, and other digital content related to your game titles (<b>"Digital Content"</b>) that you submit to Virtuix Inc. (<b>"Virtuix"</b>,<b>"we"</b>, <b>"us"</b>{" "}
          or <b>"our"</b>) through the Omni One developer website (the{" "}
          <b>"Site"</b>). By submitting Digital Content to Virtuix, you agree to be bound by these Terms.
        </p>
        <p className="copy-body copy-indent">
          <b>
            <ol>
              <li>Your Intellectual Property Rights</li>
            </ol>
          </b>
        </p>
        <p className="copy-body">
        Virtuix acknowledges that your intellectual property rights (<b>“IPR”</b>) relating to Digital Content are the sole property of either you or your affiliates. Therefore, Virtuix shall take no steps to challenge or impair your intellectual property rights. Virtuix will not reverse engineer, decompile and/or disassemble Digital Content or attempt to acquire source code from such Digital Content. Any feedback, creative suggestions, ideas, notes, drawings, concepts, or other information that Virtuix creates and submits to you shall be deemed, and shall remain, your intellectual property and Virtuix shall have no ownership or rights to them. 
        </p>
        <p className="copy-body">
        Except for IPR supplied by Virtuix to you (including Virtuix Trademarks, licenses in software and hardware granted by an SDK License, or any of Virtuix’s IPR that you may have included in any Digital Content), ownership of which Virtuix retains, your will, as between the parties, own all rights in your Digital Content.
        </p>
        <p className="copy-body copy-indent">
          <b>
            <ol start="2">
              <li>Virtuix Intellectual Property Rights</li>
            </ol>
          </b>
        </p>
        <p className="copy-body">
        You acknowledge that Virtuix’s IPR relating to Omni One’s hardware, software, services, branding, and first-party games, and any other Virtuix products (<b>“Virtuix Products”</b>) are the sole property of either Virtuix or its affiliates. Therefore, you shall take no steps to challenge or impair the intellectual property rights of Virtuix and its affiliates. You will not reverse engineer, decompile and/or disassemble Virtuix Products or attempt to acquire source code from such Virtuix Products, including but not limited to Omni One. 
        </p>
        <p className="copy-body">
        Any feedback, creative suggestions, ideas, notes, drawings, concepts, improvements, updates, modifications, or other information that you create and submit to Virtuix about Virtuix Products or branding of such products (<b>“Submissions”</b>), shall be deemed, and shall remain, Virtuix’s intellectual property and you shall have no ownership or rights to them. You acknowledge and expressly agree that any contribution of Submissions does not and will not give or grant you any right, title, or interest in Virtuix Products or in any such Submissions. All Submissions become the sole and exclusive property of Virtuix, and Virtuix may use and disclose Submissions in any manner and for any purpose whatsoever, without further notice or compensation to you and without retention by you of any proprietary or other right or claim. For the avoidance of doubt, there is no transfer of any intellectual property rights whatsoever from Virtuix to you under this Agreement. You hereby assign to Virtuix any and all right, title, and interest (including, but not limited to, any patent, copyright, trade secret, trademark, show-how, know-how, moral rights, and any and all other intellectual property right) that you may have in, and to any and all, Submissions. At Virtuix’s request, you will execute any document, registration, or filing required to give effect to the foregoing assignment.
        </p>
        <p className="copy-body copy-indent">
          <b>
            <ol start="3">
              <li>Content Requirements</li>
            </ol>
          </b>
        </p>
        <p className="copy-body">
        We want everyone to feel safe while they enjoy an immersive virtual experience. Omni One users come from many different backgrounds, so make sure that your Digital Content is appropriate for a diverse audience. 
        </p>
        <p className="copy-body">
        You acknowledge and agree to not submit Digital Content that is or may be: 
        </p>
        <p className="copy-body">
          <ul className="copy-list-disc copy-list-indent">
            <li>
            threatening, harassing, degrading, hateful or intimidating, or otherwise fail to respect the rights and dignity of others; 
            </li>
            <li>defamatory, libelous, fraudulent or otherwise tortious; </li>
            <li>
              obscene, indecent, pornographic or otherwise objectionable; or
            </li>
            <li>
              protected by copyright, trademark, trade secret, right of publicity or privacy or any other proprietary right, without the express prior written consent of the applicable owner.
            </li>
          </ul>
        </p>
        <p className="copy-body">
        You expressly acknowledge and agree that your Digital Content is free from any virus, worm, Trojan horse, Easter egg, time bomb, spyware or other computer code, file or program that is or is potentially harmful or invasive or intended to damage or hijack the operation of, or to monitor the use of, any hardware, software or equipment.
        </p>
        <p className="copy-body copy-indent">
          <b>
            <ol start="4">
              <li>Confidentiality</li>
            </ol>
          </b>
        </p>
        <p className="copy-body">
        Both you and Virtuix shall keep in strict confidence any confidential information related to Digital Content and shall not disclose such information to any third parties, with the exception of affiliates, unless pursuant to a legal obligation.
        </p>
        <p className="copy-body copy-indent">
          <b>
            <ol start="5">
              <li>Indemnification</li>
            </ol>
          </b>
        </p>
        <p className="copy-body">
        You agree to defend, indemnify and hold harmless Virtuix and our affiliates, independent contractors and service providers, content publishers and licensors, and each of their respective directors, officers, employees and agents (collectively, "Indemnified Parties") from and against any and all claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable lawyers' fees) caused by, arising out of or related to (a) claims that your Digital Content or your IPR, causes or contributes to any injury to person or damage to property whether or not caused by you or other users of the Digital Content or your IPR; (b) any claim that your Digital Content or IPR infringe or violate the rights of any third party; (c) your willful misconduct or gross negligence in the submission or use of your Digital Content or IPR under these Terms, including but not limited to your negligence; (d) your breach of these Terms or any other applicable terms, policies, warnings or instructions provided by Virtuix or a third party in relation to the submission of your Digital Content or IPR; or (e) your violation of any applicable laws or any rights of any third party, or your failure to cause your employees or agents to follow all applicable laws.
        </p>
        <p id="lastupdated" className="copy-body">
          <b>Last Updated: November 26, 2022</b>
        </p>
      </div>
    </>
  );
}

export default ContentSubmissionTerms;
