import {useState} from 'react';
import './OmniSub.css';

function OmniOneKlav() {
  const [email, setEmail] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState('');

  const proxyUrl = 'https://proxy.cors.sh/';
  const targetUrl =
    'https://a.klaviyo.com/api/profile-subscription-bulk-create-jobs/';

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiKey = 'pk_53f0363ca8001ebd9c67c938068f4d3362';
    const revisionDate = '2024-02-15';

    const headers = {
      Authorization: `Klaviyo-API-Key ${apiKey}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Revision: revisionDate,
      'x-cors-api-key': 'live_0c7ecb65bd89ba26c784dcc27b9616208850e0ac3c0017a532ca932dc1a2c584',
    };

    const data = {
      data: {
        type: 'profile-subscription-bulk-create-job',
        attributes: {
          custom_source: 'Omni One Web',
          profiles: {
            data: [
              {
                type: 'profile',
                attributes: {
                  email: email,
                  subscriptions: {
                    email: {
                      marketing: {
                        consent: 'SUBSCRIBED',
                      },
                    },
                  },
                },
              },
            ],
          },
        },
        relationships: {
          list: {
            data: {
              type: 'list',
              id: 'RvKntw',
            },
          },
        },
      },
    };

    try {
      const response = await fetch(`${proxyUrl}${targetUrl}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      // Check if the response has content before parsing it as JSON
      let responseData = {};
      if (
        response.status !== 204 &&
        response.headers.get('content-type')?.includes('application/json')
      ) {
        responseData = await response.json();
      }

      // Handle response data or lack thereof
      console.log(responseData);
      setSubmissionStatus('Thank you for signing up.');
    } catch (error) {
      console.error(
        'There has been a problem with your fetch operation:',
        error,
      );
      setSubmissionStatus('Success! Thank you for signing up.');
    }
  };

  return (
    <div className="omni-sub-container">
      <form onSubmit={handleSubmit}>
        <label>
          <input
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="omni-sub-input omni-sub-margin"
            required
          />
        </label>
        <button className="omni-sub-button" type="submit">
          Sign Up
        </button>
      </form>
      {submissionStatus && <p className="omni-sub-status">{submissionStatus}</p>}
    </div>
  );
}

export default OmniOneKlav;
