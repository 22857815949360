import React from "react";
import { Link } from "react-router-dom";
import "./Careers.css";
import { Button } from "../../Button";
import CareersHomeHero from "../../CareersHomeHero";
import CareersSection1 from "../../CareersSection1";
import PerksSection from "../../PerksSection";
import CareersSection2 from "../../CareersSection2";
import WorkingAt from "../../WorkingAt";
import OpenPositions from "../../OpenPositions";
import DesiredPosition from "../../DesiredPosition";

function Careers() {
  return (
    <>
      <CareersHomeHero />
      <CareersSection1 />
      <PerksSection />
      <CareersSection2 />
      <WorkingAt />
      <OpenPositions />
      <DesiredPosition />
    </>
  );
}

export default Careers;
