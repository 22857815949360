import React from "react";
import "./OpResourcesOmniverseCopy.css";
import ReactPlayer from "react-player";
import { Button } from "./Button";

function OpResourcesOmniverseCopy() {
  return (
    <>
      <div className="ov-resources-copy-container">
        <h1 className="ov-resources-copy-title">
          Training Videos and Online Training
        </h1>
        <p className="ov-resources-copy-body">
          The most critical ingredient for a good experience on the Omni is
          getting used to the walking motion. Use the videos below to train your
          customers on how to walk properly on the Omni.
        </p>
        <div className="ov-resources-copy-player-container">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=96nbxMFX8MA"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className="ov-resources-copy-player-container">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=IdsRPglqdmI"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/4jbsld243i1izjg/Virtuix%20Omni%20Movement%20Videos.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Download Videos
            </Button>
          </a>
        </p>
        <p className="ov-resources-copy-body">
          It’s important that your Vive tracking base stations are set up
          properly to ensure flawless tracking of the player without hiccups or
          interference. Please watch the video below to ensure your tracking is
          set up correctly.
        </p>
        <div className="ov-resources-copy-player-container">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=m9Rz5_JRkGc"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <p className="ov-resources-copy-body">
          You are required to get your staff certified to operate the Omni
          system via our mandatory online training course. Have your staff take
          the online training here:{" "}
          <a
            href="https://virtuix-training.com/"
            className="ov-resources-copy-link-gray"
            target="_blank"
            rel="noreferrer"
          >
            https://virtuix-training.com/
          </a>
          . At the end of the course, the system will register the name and
          location of the certified employee. This link is also accessible via
          the “Employee Training” tab on your Omniverse operator dashboard.
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
        Omniverse
        </h1>
        <p className="ov-resources-copy-body">
        Welcome to Omniverse, your home for more than 15 top VR games optimized for commercial gameplay on the Omni. Your Virtuix representative will provide you with your Omniverse login credentials and next steps on how to install Omniverse.
        </p>
        <p className="ov-resources-copy-body">
        Watch the following demo video of Omniverse to familiarize yourself with its functions:
        </p>
        <div className="ov-resources-copy-player-container">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=jq45z-f4s7I"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <p className="ov-resources-copy-body">
          <a
            href="/uploads/Virtuix - Omniverse Setup Instructions v2.2.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Omniverse Setup Instructions
            </Button>
          </a>
        </p>
        <p className="ov-resources-copy-body">
        Omniverse also includes the Omni Connect software for setting up and connecting the Omni to your PC. Please find important information about configuring Omni Connect in our Operator Manual. If you have multiple Omnis, remember to set a different radio channel for each Omni.
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
          Marketing Assets
        </h1>
        <h2 className="ov-resources-copy-header">
        Operator Media Kit
        </h2>
        <p className="ov-resources-copy-body">
        To promote your business and events, we’ve prepared an Operator Media Kit that includes image files for social media or for printing physical signage. Instructions on editing the images with your own branding can be found within the download file.
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/ru9y0qr7iv6ftgj/Virtuix-Omni-Digital-Media.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Operator Media Kit
            </Button>
          </a>
        </p>
        <h2 className="ov-resources-copy-header">
        Omni Product Pictures
        </h2>
        <p className="ov-resources-copy-body">
        Download images of the Omni and accessories, Omniverse, and our Virtuix Omni logo.
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/srppdaotvirbjny/Omni%20Product%20Pics.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Product Pictures
            </Button>
          </a>
        </p>
        <h2 className="ov-resources-copy-header">
        Omni Action Pictures
        </h2>
        <p className="ov-resources-copy-body">
        Please feel free to use our favorite action shots in your marketing materials and presentations.
        </p>
        <p className="ov-resources-copy-body">
          <img
            className="ov-resources-copy-img-wrapper"
            src="/images/developers_omni_players.jpg"
            alt="Competitive Omni Players"
          />
        </p>
        <div className="ov-resources-flex">
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/995oyataevm76nq/Omni%20Action%20Pictures%20-%20PRINT.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Action Pictures for Print
            </Button>
          </a>
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/e7men647bulvmul/Omni%20Action%20Pictures%20-WEB.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
            Action Pictures for Web
            </Button>
          </a>
        </p>
        </div>
        <h2 className="ov-resources-copy-header">
        Omni Promotional Videos
        </h2>
        <p className="ov-resources-copy-body">
        Feel free to loop these videos at your entertainment venue to attract customers.
        </p>
        <div className="ov-resources-copy-player-container">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=1SlZvuhABGk"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className="ov-resources-copy-player-container">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=mBi2icD8gO8"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <div className="ov-resources-flex">
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/6sy8ngg22nd3vvd/Virtuix%20Promo%20Reel%20FEB%2018.mp4?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Download Omniverse Trailer
            </Button>
          </a>
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/bf7vkmzw7t85rwo/Virtuix%20Omni%20-%20Games%20Reel.mp4?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
            Download Games Reel
            </Button>
          </a>
        </p>
        </div>
        <h1 className="ov-resources-copy-title">
          Games Assets
        </h1>
        <h2 className="ov-resources-copy-header">
        Games Book
        </h2>
        <p className="ov-resources-copy-body">
        Download and print out our “Games Book” PDF to display the available Omniverse games to your potential customers on-site. The Games Book provides for each game an image, a brief description, controls, genre, age rating, and number of players. We recommend you print out the pages and place them in a binder for display to your customers.
        </p>
        <div className="ov-resources-flex">
        <p className="ov-resources-copy-body">
          <a
            href="/uploads/Omniverse Games Book (Letter) - May 2021.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Download Games Book (Letter Size)
            </Button>
          </a>
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="/uploads/Omniverse Games Book (A4) - May 2021.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
            Download Games Book (A4 Size)
            </Button>
          </a>
        </p>
        </div>
        <h2 className="ov-resources-copy-header">
        Game Posters and Banners
        </h2>
        <p className="ov-resources-copy-body">
        We’ve created game posters and banners of our most popular games. These can be downloaded and printed for display to promote the Omni and Omniverse at your venue. These assets come in both poster format (36” x 24”) and large banner format (33” x 86”). They can also be used digitally for social media posts.
        </p>
        <div className="ov-resources-flex">
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/k9htx6a4xzopdfn/Omniverse%20Games%20-%20Posters.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Download Game Posters (36" x 24")
            </Button>
          </a>
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="https://dl.dropboxusercontent.com/s/r76bx7ofsbxmz6y/Omniverse%20Games%20-%20Banners.zip?dl=1"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
            Download Game Banners (33" x 86")
            </Button>
          </a>
        </p>
        </div>
        <h1 className="ov-resources-copy-title">
        Third-Party Accessories
        </h1>
        <p className="ov-resources-copy-body">
        In addition to the Omni platform and accessories, you will require a few third-party products (not sold by Virtuix) to complete your Omni setup. We have summarized all required and optional third-party accessories for you at the following page: <a
                href="https://virtuix.com/required-third-party-products/"
                className="ov-resources-copy-link-gray"
                target="_blank"
                rel="noreferrer"
              >
                https://virtuix.com/required-third-party-products/
              </a>
        </p>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
        PC Requirements
        </h1>
        <div className="ov-resources-flex">
            <p className="ov-resources-copy-body">
                Minimum:
                <ul className="ov-resources-copy-list-disc copy-list-indent">
                    <li>Operating System: Windows 10</li>
                    <li>Processor: Intel Core i5-6600K @ 3.5 GHz</li>
                    <li>Memory: 8 GB RAM</li>
                    <li>Graphics: NVIDIA GeForce GTX 1070 8GB</li>
                    <li>Hard Drive: 1TB (SSD or HDD)</li>
                </ul>
            </p>
            <p className="ov-resources-copy-body">
            Recommended:
                <ul className="ov-resources-copy-list-disc copy-list-indent">
                    <li>Operating System: Windows 10</li>
                    <li>Processor: Intel i7-6700</li>
                    <li>Memory: 16 GB RAM</li>
                    <li>Graphics: NVIDIA Geforce RTX 2070</li>
                    <li>Hard Drive: 1TB (SSD or HDD)</li>
                </ul>
            </p>
        </div>
        <h1 className="ov-resources-copy-title">
        Resource Links
        </h1>
        <div className="ov-resources-flex">
        <p className="ov-resources-copy-body">
          <a
            href="/uploads/Omni-Connect-Quick-Start-Guide-v1.5.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
              Omni Connect Quick Start Guide
            </Button>
          </a>
        </p>
        <p className="ov-resources-copy-body">
          <a
            href="/uploads/Virtuix-Omni-Owner-Manual.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttonSize="btn-wide" buttonColor="green">
            Omni Owner's Manual
            </Button>
          </a>
        </p>
        </div>
      </div>
    </>
  );
}

export default OpResourcesOmniverseCopy;
