import React from "react";
import "./NewsArticles.css";
import ReactPlayer from "react-player";

function NewsArticle13() {
  return (
    <>
      <div className="news-articles-container">
        <img
          className="news-article-featured-img"
          src="/images/news_article_feat_img_025.jpg"
          alt="Omni One library expands to over 50 games"
        />
        <h1 className="news-articles-title">
          Virtuix Expands Omni One Game Lineup to Over 50 Titles
        </h1>
        <h3 className="news-articles-subheader">
          Sniper Elite VR, Amid Evil, and Survival Nation Join Launch Lineup
        </h3>
        <p className="news-articles-byline">by Virtuix | July 30, 2024</p>
        <p className="news-articles-body">
          <b>AUSTIN, TEXAS</b> (July 30, 2024) – Virtuix, the developer of the
          “Omni One” full-body VR gaming system, is excited to unveil its next
          wave of 15 thrilling titles coming to Omni One, pushing the platform's
          game lineup to over 50 games ahead of its highly anticipated launch in
          September 2024. Omni One offers a complete entertainment experience
          with its proprietary 360-degree treadmill, enabling players to
          physically move in every direction within virtual reality games. The
          system includes a customized Pico 4 Enterprise headset and a dedicated
          game store featuring titles optimized for Omni One.
        </p>
        <p className="news-articles-body">
          The 15 new game additions enrich Omni One's extensive library,
          ensuring there's something for everyone. Whether diving into thrilling
          virtual worlds, engaging in intense multiplayer battles, or exploring
          captivating narratives, Omni One promises an unparalleled VR
          experience. The latest announced titles include award-winning games
          such as Sniper Elite VR, Amid Evil, Survival Nation, Genotype, and
          Ilysia, with the full list available below.
        </p>
        <p>
          <img
            className="news-article-img"
            src="/images/omni_one_playing_se_1920x1080.jpg"
            alt="Woman playing Sniper Elite on Omni One"
          />
        </p>
        <p className="news-articles-body">
          "We're excited to announce the expansion of our game lineup to over 50
          titles for Omni One," said Jan Goetgeluk, CEO of Virtuix. "This
          milestone underscores our commitment to offering a diverse and
          compelling range of experiences that showcase the full potential of
          Omni One's freedom of movement in VR."
        </p>
        <p className="news-articles-body">
          "We're thrilled to bring Sniper Elite to the Omni One platform," said
          Jason Kingsley CBE, CEO of Rebellion, developer of Sniper Elite VR.
          "With Virtuix's innovative technology, players can now physically step
          into the intense missions of Sniper Elite, experiencing the thrill of
          precision shooting in a whole new dimension. Be careful out there."
        </p>
        <p className="news-articles-body">
          Virtuix collaborates closely with each game studio to optimize
          gameplay for the Omni One platform, ensuring seamless movement and
          full-body immersion. Virtuix is committed to continuously enriching
          the Omni One experience by introducing additional titles on an ongoing
          basis. Explore Omni One’s full lineup at{" "}
          <a
            href="https://omni.virtuix.com/games"
            className="news-articles-link"
            target="_blank"
            rel="noreferrer"
          >
            omni.virtuix.com/games
          </a>
          .
        </p>
        <p className="news-articles-body">
          Virtuix has shipped hundreds of Omni One units to beta customers who
          are providing valuable feedback. Omni One is scheduled for general
          release in September 2024, with preorders currently available. For
          more information and to preorder your Omni One unit, visit{" "}
          <a
            href="https://omni.virtuix.com/"
            className="news-articles-link"
            target="_blank"
            rel="noreferrer"
          >
            omni.virtuix.com
          </a>
          . Virtuix also released a new trailer for Omni One.
        </p>
        <div className="player-wrapper news-articles-extra-margin-bottom">
          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=Zi8rjwtZ_cs"
            width="100%"
            height="100%"
          />
        </div>
        <p className="news-articles-body">
          <b>Newly Announced Games:</b>
        </p>
        <p className="news-articles-body">
          <ol className="news-articles-list-indent">
            <li className="news-articles-margin">Alvo VR</li>
            <li className="news-articles-margin">Amid Evil </li>
            <li className="news-articles-margin">Drop Dead: The Cabin </li>
            <li className="news-articles-margin">Flock of the Low God</li>
            <li className="news-articles-margin">Genotype</li>
            <li className="news-articles-margin">Ilysia</li>
            <li className="news-articles-margin">Neolithic Dawn</li>
            <li className="news-articles-margin">
              Propagation: Paradise Hotel
            </li>
            <li className="news-articles-margin">Sail</li>
            <li className="news-articles-margin">Sniper Elite VR</li>
            <li className="news-articles-margin">
              Sugar Mess - Let’s Play Jolly Battle
            </li>
            <li className="news-articles-margin">Survival Nation</li>
            <li className="news-articles-margin">The Atlas Mystery</li>
            <li className="news-articles-margin">Tunnels VR</li>
            <li className="news-articles-margin">Undead Citadel</li>
          </ol>
        </p>
        <p className="news-articles-body">
          <b>About Virtuix:</b>
        </p>
        <p className="news-articles-body">
          Virtuix Inc. is the developer of “Omni,” the premier brand of
          omni-directional treadmills that enable players to walk and run in 360
          degrees inside video games and other virtual reality applications.
          With a commitment to innovation, Virtuix continues to push the
          boundaries of VR gaming, delivering immersive experiences to users
          worldwide. Founded in 2013 and headquartered in Austin, Texas, Virtuix
          has raised more than $40 million from individual and institutional
          investors. For more information, visit{" "}
          <a
            href="https://www.virtuix.com/"
            className="news-articles-link"
            target="_blank"
            rel="noreferrer"
          >
            www.virtuix.com
          </a>
          .
        </p>
        <p className="news-articles-body">
          <b>For media inquiries, please contact:</b>
          <br />
          Lauren Premo
          <br />
          Head of Marketing, Virtuix
          <br />
          <a
            href="mailto:press@virtuix.com"
            className="news-articles-link"
            target="_blank"
            rel="noreferrer"
          >
            press@virtuix.com
          </a>
          <br />
          <br />
          Caroline Gill / Carter Dotson
          <br />
          Stride PR for Virtuix
          <br />
          <a
            href="mailto:caroline@stridepr.com"
            className="news-articles-link"
            target="_blank"
            rel="noreferrer"
          >
            caroline@stridepr.com
          </a>{" "}
          /{" "}
          <a
            href="mailto:carter@stridepr.com"
            className="news-articles-link"
            target="_blank"
            rel="noreferrer"
          >
            carter@stridepr.com
          </a>
        </p>
      </div>
    </>
  );
}

export default NewsArticle13;
