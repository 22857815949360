import React from "react";
import { Link } from "react-router-dom";
import "../../Copy.css";

function OmniOneSdkEula() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">
          Omni One Software Development Kit License
        </h1>
        <p className="copy-body">
          IMPORTANT - READ BEFORE DOWNLOADING, COPYING, INSTALLING OR USING THE
          OMNI ONE SDK
        </p>
        <p className="copy-body">
        Do not download, use or load the Omni One Software Development Kit and/or any associated components or materials provided by Virtuix Inc. or on Virtuix’s website (collectively, the "Omni One SDK") until You have carefully read the following terms and conditions. By loading or using the Omni One SDK, You agree to fully comply with the terms and conditions of this Omni One Software Development Kit License (“SDK License”) by and between Virtuix Inc., a Delaware corporation having its principal place of business at 826 Kramer Ln. Suite H, Austin, TX 78758 U.S.A. ("Virtuix"), and You.  This SDK License supplements and forms a part of the Virtuix <Link className="copy-link-to-gray" to="/terms-of-use">
            Terms of Use
          </Link>{" "}
          (the “Terms”). Capitalized terms not defined in this SKD License have the meaning given to them in the Terms. If You do not wish to so agree, do not review, install or use the Omni One SDK.
        </p>
        <p className="copy-body">
        Subject to the terms and conditions of this SDK License, Virtuix hereby grants to You, or a third-party on Your behalf, a perpetual, worldwide, non-exclusive, no-charge, royalty-free, sublicensable copyright license to use, reproduce, redistribute (redistribution rights only subject to restrictions set forth below in Sections 2 and 3), modify, and improve the software contained in the Omni One SDK, as set forth below in the SDK License. This SDK License is limited in its scope and subject to the terms and conditions set forth herein.
        </p>
        <p className="copy-body">
          <b>1. LICENSE</b>
        </p>
        <p className="copy-body">
        1.1. This SDK License includes the non-exclusive license and right (i) to use the Omni One SDK to make Developer Content, as the Developer Content is defined further in Section 1.2; and (ii) to use the Omni One SDK to create derivative works of the Omni One SDK itself (“Omni One SDK Derivatives”), as Omni One SDK Derivatives are further defined in Section 1.3.  If You wish to have a third party consultant or subcontractor (“Contractor”) perform work on Your behalf which involves access to or use of the Omni One SDK, You shall direct them to download a copy of this SDK License, or, alternatively, you indemnify Virtuix for any misuse of the Omni One SDK or breach of this SDK License by your Contractor and you shall assist Virtuix in all Contractors’ assignments of the Omni One SDK Derivatives to Virtuix.
        </p>
        <p className="copy-body">
        1.2.  Developer Content is further defined as engines, utilities, applications, content, games or demos developed using this Omni One SDK in whole or in part in binary or object code that work with the Virtuix hardware and software products (“Virtuix Products”). 
        </p>
        <p className="copy-body">
        1.3. Omni One SDK Derivatives are further defined as source, binary or object code derived exclusively from the Omni One SDK, by You, including third party software and which can be either (i) an adaptation of a utility or piece of code from the Omni One SDK to improve efficiency; or (ii) an addition of code or improvement to the Omni One SDK that adds functionality; provided, however, that Omni One SDK Derivatives do not include the Developer Content.  You may not use the Omni One SDK for any purpose not expressly permitted by this SDK License. You may not decompile, reverse engineer, disassemble or attempt to derive the source code of the Omni One SDK or any part of the Omni One SDK, or any other software or firmware provided to you by Virtuix.
        </p>
        <p className="copy-body">
        1.4. For the sake of clarification when You use the Omni One SDK (including Omni One SDK Derivatives) in or with Developer Content, You retain all rights to Your Developer Content, and You have no obligations to share or license Your Developer Content (including Your source and object code) to Virtuix or any third party; provided, however, Virtuix retains all rights to the Omni One SDK and the Omni One SDK Derivatives that may be incorporated into Your Developer Content.
        </p>
        <p className="copy-body">
        1.5. Virtuix reserves all licenses to the Omni One SDK or any components of the Omni One SDK, or a component thereof, or any other applications You receive through this SDK License or on Virtuix’s websites, except those expressly set out in this Section 1. 
        </p>
        <p className="copy-body">
          <b>2. OMNI ONE SDK DERIVATIVE ASSIGNMENTS AND ACKNOWLEDGEMENTS </b>
        </p>
        <p className="copy-body">
        2.1. You, the recipient and user of the Omni One SDK, hereby agree and accept that Virtuix shall own all right, title and interest to the intellectual property rights, including, but not limited to copyright, trademark and patent rights, to any Omni One SDK Derivatives that You may create, and You hereby assign any and all such rights to such Omni One SDK Derivatives to Virtuix.  You further acknowledge any and all restrictions set forth herein, including but not limited to Sections 2.1-2.4.
        </p>
        <p className="copy-body">
        2.2. We hereby grant to You the fully paid up, no-charge, royalty-free, world-wide, in perpetuity, non-exclusive right and license to use the Omni One SDK Derivatives created by You, solely in conjunction with the Omni One SDK (or any components of the Omni One SDK) and/or Developer Content using Virtuix Products as set forth herein.
        </p>
        <p className="copy-body">
        2.3. Furthermore, for the sake of clarification, Virtuix and its assignees and licensees shall be free to use such Omni One SDK Derivatives without any approval from You and without compensation or attribution to You.
        </p>
        <p className="copy-body">
        2.4. You also agree upon Virtuix’s request to provide the source and binary code of any Omni One SDK Derivatives to Virtuix. FAILURE TO COMPLY WITH THIS REQUEST IS THE BASIS FOR AUTOMATIC TERMINATION OF THIS LICENSE BY VIRTUIX AS WELL AS ALL OTHER MATTERS, LEGAL AND OTHERWISE, THAT VIRTUIX MAY TAKE.
        </p>
        <p className="copy-body">
          <b>
            3. SUBLICENSE AND REDISTRIBUTION RIGHTS OF OMNI ONE SDK AND OMNI ONE
            SDK DERIVATIVES
          </b>
        </p>
        <p className="copy-body">
        Subject to the terms and conditions of this SDK License, Your rights to redistribute and sublicense the Omni One SDK and Omni One SDK Derivatives is also expressly made subject to the conditions set forth in this Section 3.
        </p>
        <p className="copy-body">
        3.1. You may sublicense and redistribute the source, binary, or object code of the Omni One SDK in whole or in part in conjunction with Your Developer Content, either for no charge or as part of a for-charge piece of Your Developer Content, but You may not sublicense the Omni One SDK itself.   If You license, sublicense or redistribute Omni One SDK Derivatives in and of themselves (not as a part of a piece of Developer Content) then You may only do that solely with and in conjunction with the Omni One SDK and with Virtuix Products. For the avoidance of doubt, the Omni One SDK, any Omni One SDK Derivatives, and any Developer Content must be used with Virtuix Products. 
        </p>
        <p className="copy-body">
          3.2. You must include with all such redistributed or sublicensed Omni One SDK or Omni One SDK Derivative code the following copyright notice:  “Copyright © Virtuix<sup className="copy-sup">TM</sup> 2022. All rights reserved” and include
          the list of conditions contained in this Section 3, including the full text of the disclaimer in Section 3.6 below.
        </p>
        <p className="copy-body">
          3.3. The name of Omni, Omni One, Virtuix, or any affiliates of Virtuix may not be used to endorse or promote products derived from this Omni One SDK License without specific prior written permission of Virtuix.
        </p>
        <p className="copy-body">
        3.4. You must give any other recipients of the Omni One SDK or the Omni One SDK Derivatives, a copy of this SDK License as such recipients, licensees or sublicensees may only use the Omni One SDK or any Omni One SDK Derivatives or any elements thereof subject to the terms of this SDK License, and such recipients’, licensees’ or sublicensees’ agreement and acceptance of this SDK License with Virtuix  (which will convey all rights to the recipients or licensees or sublicensees of Omni One SDK Derivatives), and You must cause any modified files to carry prominent notices stating that You changed the files.
        </p>
        <p className="copy-body">
        3.5. If the Omni One SDK or a specific element thereof includes a “LICENSE” text file as part of its distribution (the “License Notice”), then any Omni One SDK Derivatives that You distribute with the SDK License in whole or in part must include a readable copy of such attribution notices as are contained within the applicable License Notice file (excluding those notices that do not pertain to any part of the Omni One SDK Derivatives), in at least one of the following places: within a License Notice text file distributed as part of the Omni One SDK Derivatives; within the source form or documentation, if provided along with the Omni One SDK Derivatives; or, within a display generated by the Omni One SDK Derivatives, if and wherever such third-party notices normally appear. You must also include in the License Notice file for all Omni One SDK Derivatives a copy of all notices. The contents of the License Notice file are for informational purposes only and do not modify the SDK License. You may add Your own attribution notices within Omni One SDK Derivatives that You distribute, alongside or as an addendum to the License Notice text from the Omni One SDK or any part thereof, provided that such additional attribution notices cannot and will not be construed as modifying the Omni One SDK.
        </p>
        <p className="copy-body">
        3.6. THIS OMNI ONE SDK LICENSE AND ANY COMPONENT THEREOF IS PROVIDED BY VIRTUIX AND ITS AFFILIATES “AS IS” AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL VIRTUIX AS THE COPYRIGHT OWNER OR ITS AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS OMNI ONE SDK OR THE OMNI ONE SDK DERIVATIVES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p className="copy-body">
          <b>4.</b> This SDK License does not grant permission to use the trade names, trademarks, service marks, or product names of Virtuix and its affiliates, except as required for reasonable and customary use in describing the origin of the Omni One SDK, or any element thereof, and reproducing the content of the License Notice file. Any further use of the trade names, trademarks, service marks, or product names of Virtuix and its affiliates require approval and written agreement by Virtuix; such approval will be contingent on You providing Virtuix with the proposed marketing and/or marketing campaign of the Developer Content.  Virtuix reserves all rights not expressly granted to You under this SDK License.  
        </p>
        <p className="copy-body">
          <b>5.</b> For the avoidance of doubt, in no event and under no legal theory, whether in tort (including negligence), contract, or otherwise, unless required by applicable law (such as deliberate and grossly negligent acts) or agreed to in writing, shall Virtuix or any affiliated company or suppliers be liable to You or Your licensees or sublicensees for damages, including any direct, indirect, special, incidental, or consequential damages of any character arising as a result of this SDK License or out of the use or inability to use the Omni One SDK or any element thereof or any Omni One SDK Derivatives  or Developer Content (including but not limited to damages for loss of goodwill, work stoppage, computer failure or malfunction, or any and all other commercial damages or losses), even if You or such contributor has been advised of the possibility of such damages.
        </p>
        <p className="copy-body">
          <b>6.</b> As this SDK License is updated for future releases of the Omni One SDK, You agree to abide by and meet all requirements of future updates of this SDK License for those future Omni One SDK releases and the future updates of this SDK License will apply for that future Developer Content that may developed for or with that future Omni One SDK or any element thereof.
        </p>
        <p className="copy-body">
          <b>7.</b> Virtuix reserves the right to terminate this SDK License and all Your rights hereunder in the event You materially breach this SDK License.
        </p>
        <p className="copy-body">
          <b>8.</b> Furthermore, Virtuix also reserves the right to cancel or
          terminate this SDK License for any reasons, including but not limited
          to the following upon notice to You, as set forth below:
        </p>
        <p className="copy-body">
          8.1. Intellectual property infringement by You with Developer Content or Omni One SDK Derivatives created by You that is used with or by the Omni One SDK or any component thereof, or any of the Omni One SDK Derivatives.
        </p>
        <p className="copy-body">
        8.2. Developer Content that violates or infringes upon applicable law.
        </p>
        <p className="copy-body">
        8.3. Failure to comply with this SDK License.  
        </p>
        <p className="copy-body">
        8.4. Use of the Omni One SDK, the Omni One SDK Derivatives, or the Developer Content without use of Virtuix Product(s).
        </p>
        <p className="copy-body">
          8.5. Failure to provide the required License Notices or deliver source code and/or binary of Omni One SDK Derivatives to Virtuix as set forth above.
        </p>
        <p className="copy-body">
          <b>9.</b> This SDK License may be amended by Virtuix, and Your usage of the SDK License after such amendments or changes signifies Your consent to and acceptance of any such amendments or changes on a going forward basis.
        </p>
        <p className="copy-body">
          <b>10.</b> In the event any provision of this SDK License is determined to be invalid, prohibited or unenforceable by a court or other body of competent jurisdiction, this SDK License shall be construed as if such invalid, prohibited or unenforceable provision has been more narrowly drawn so as not to be invalid, prohibited or unenforceable.
        </p>
        <p className="copy-body">
          <b>11.</b> You may not assign any rights or obligations under this SDK License without the advance written consent of Virtuix, which may be withheld in its sole discretion. Virtuix may assign its rights or obligations under this SDK License in its sole discretion.
        </p>
        <p className="copy-body">
          <b>12.</b> Failure of either party at any time to enforce any of the provisions of this SDK License will not be construed as a waiver of such provisions or in any way affect the validity of this SDK License or parts thereof.
        </p>
        <p className="copy-body">
          <b>13.</b> Your remedies under this SDK License shall be limited to the right to collect money damages limited to the amount of fees paid by You to Virtuix, if any, and You hereby waive Your right to injunctive or any other equitable relief.  However, You agree that Virtuix can seek injunctive and/or equitable relief in the event of any breach of this Agreement by You or your assignees, if any, and You waive any bond required by Virtuix in such proceeding(s). 
        </p>
        <p className="copy-body">
          <b>14.</b> The laws of the State of Texas, USA shall govern the construction, validity and performance of this Agreement, without reference to principles of conflict of laws. All disputes between the Parties arising out of or in connection with this Agreement or relating to the performance of the Parties hereunder, other than those for equitable or injunctive relief, shall be finally settled by binding arbitration according to the rules of conciliation and arbitration of the International Chamber of Commerce, by a panel consisting of three arbitrators, one of whom shall be selected by each Party and the third of whom shall be selected by mutual agreement of the first two arbitrators. The panel shall conduct its proceedings in Austin, Texas, in the English language, applying the laws of the State of Texas, USA, excluding its conflicts of law provisions. The costs of arbitration shall be awarded by the arbitration panel to the substantially prevailing Party in such arbitration. Each Party hereby submits to such arbitration and jurisdiction of such panel, and agrees that the decision of a majority of the arbitrators shall be final and binding on the Parties and may be enforced by the prevailing Party in any court having jurisdiction over the person or property of the other party.  Upon request of either Party, the arbitration panel shall provide both Parties with a written decision, including conclusions of fact and conclusions of law. In any action arising out of or relating to this Agreement, the prevailing party may recover its reasonable attorneys’ fees, costs, and other expenses,
        </p>
        <p className="copy-body">
          <b>15.</b> This SDK License and the Terms constitute the entire agreement between You and Virtuix with respect to the Omni One SDK and the license and terms provided herein, and supersedes any and all prior or contemporaneous agreements, understandings, and communication, whether written or oral, except for the <Link className="copy-link-to-gray" to="/content-submission-terms">
            Content Submission Terms
          </Link> and the Omni One Publisher Licensing Agreement that may be executed by You and Virtuix.
        </p>
        <p id="lastupdated" className="copy-body">
          <b>Last Updated: November 26, 2022</b>
        </p>
      </div>
    </>
  );
}

export default OmniOneSdkEula;
