import React from "react";
import "../../Copy.css";

function OmniOneStoreTerms() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Omni One Store Terms</h1>
        <p className="copy-body">
          These Omni One Store Terms and the Virtuix{" "}
          <a href="https://virtuix.com/terms-of-use" className="copy-link-gray">
            Terms of Use
          </a>{" "}
          (altogether, the “Terms”) apply to any offer, order, purchase or
          download that is made, placed, or accessed through the Omni One
          content store, whether on the Omni One website, mobile app, or virtual
          reality application on the Omni One headset (collectively, the "
          <b>Store</b>") and are between you and Virtuix Inc. ("<b>Virtuix"</b>,
          "<b>we</b>", "<b>us</b>" or "<b>our</b>"). By accessing an offer or by
          placing an order for, downloading or making a purchase of products,
          applications or content offered through the Store (the "
          <b>Products</b>"), you agree to be bound by these Terms.
        </p>
        <h3 className="copy-subheader">
          1. ORDER ACCEPTANCE AND ACKNOWLEDGEMENT
        </h3>
        <p className="copy-body">
          You can place an order by clicking the pre-order or purchase button
          located in various sections of the Store with respect to the Products
          of your choice. When placing an order, you may be required to provide
          some or all of the following information: your name, postal address,
          billing information and other information as required by law. If you
          are buying a gift (as defined below), you will also be required to
          provide us with recipient information. You represent and warrant that
          all such information is accurate and complete. You are responsible for
          ensuring that your account information (including your contact and
          delivery information) is kept current in the Store. We will have no
          responsibility or liability for inaccurate or incomplete information,
          or for information that later becomes outdated. Additionally, we have
          no obligation to make efforts to determine your correct contact
          information. Order acceptance will occur automatically once your
          payment has been processed. Download of the Products that you ordered
          will begin once your device is online and any previously queued
          downloads have been completed. You will receive a receipt for your
          Products order via email.
        </p>
        <h3 className="copy-subheader">2. PRE-ORDERS</h3>
        <p className="copy-body">
          In advance of a new Product launch, it may be possible to place
          pre-orders in the Store. The price of the Products you pre-order will
          be quoted to you at the time you submit your pre-order and may not
          include applicable taxes and delivery costs. Placing a pre-order does
          not guarantee delivery of the Products.
        </p>
        <p className="copy-body">
          When the pre-ordered Products are ready for delivery, we will provide
          you with your purchase confirmation (including taxes where
          applicable). This communication will constitute the order acceptance
          for the pre-ordered Products, and payment for the pre-ordered Products
          will be taken at the time of order acceptance.
        </p>
        <h3 className="copy-subheader">3. REFUND POLICY</h3>
        <p className="copy-body">
          All purchases of Products are final, except as required by law or as
          described in our Content Refund Policy.
        </p>
        <h3 className="copy-subheader">
          4. DIGITAL CONTENT, IN-APP PURCHASES, GIFTS AND SUBSCRIPTIONS
        </h3>
        <p className="copy-body">
          You may have the ability to purchase digital content through the Store
          ("<b>Digital Content</b>"), to purchase additional or enhanced
          functionality or media content within certain Products ("
          <b>In-app Purchases</b>"), to purchase certain digital content as gift
          codes that a third-party recipient can redeem through the Store for
          the digital content you select ("<b>Gifts</b>") or to make In-app
          Purchases to access or receive digital content, functionalities or
          services on a subscription basis ("<b>Subscriptions</b>"). All
          purchases of Digital Content, In-app Purchases, Gifts and
          Subscriptions are final except as required by law or as described in
          our Content Refund Policy. Once you have purchased Digital Content or
          an In-App Purchase, we encourage you to download, install and/or
          access it promptly.{" "}
          <b>
            You consent that the delivery of digital content, in-app purchases,
            gifts and subscriptions may begin immediately following the
            completion of your purchase, and you acknowledge that you will
            therefore lose any statutory rights that you may have to withdraw
            and receive a refund.
          </b>{" "}
          The laws of some jurisdictions do not allow the disclaimer or removal
          of certain statutory rights, so this waiver of statutory rights may
          not apply to you. If you are not able to download, install or access
          purchased Digital Content or In-app Purchases, please contact us at
          help@virtuix.com.
        </p>
        <p className="copy-body">
          You can purchase a Gift by providing the Gift recipient's information
          and making a payment in accordance with these Terms. The gift
          recipient will receive an email notifying them that they have been
          sent a gift and with information about how to redeem it. If they are
          not an existing user, they can only redeem the gift by creating a user
          account. Gifts are single-use only. A recipient also has a limited
          time frame to redeem gifts. Gifts will expire on the date expressed at
          the time of purchase. No refunds will be issued for Gifts that expire
          before being redeemed.
        </p>
        <p className="copy-body">
          The recipient will not be able to redeem the gift if they already own
          the digital content or are restricted from doing so by law or any of
          our policies or requirements.
        </p>
        <p className="copy-body">
          If redemption is unsuccessful for any reason, you will receive an
          email and a refund. Once the gift has been purchased, only the
          recipient may request a refund in accordance with our Content Refund
          Policy. Please note that all refunds will be provided to you and not
          the recipient.
        </p>
        <p className="copy-body">
          Gifts are not redeemable by the recipient for cash or store credit or
          eligible for resale.
        </p>
        <p className="copy-body">
          You may have the ability to purchase Subscriptions. Certain
          subscriptions may be available at no charge for a specified free trial
          period, which will begin on the date that you place the subscription
          order. When you place a subscription order without a free trial
          period, we will immediately charge your payment method the price then
          in effect (plus applicable taxes). If the subscription includes a free
          trial period, we will automatically charge your payment method at the
          end of the free trial period. For all Subscriptions (with or without a
          free trial period), these charges will recur at the selected interval
          (e.g., monthly) at the price then in effect (plus applicable taxes),
          unless and until the Subscription is cancelled.
        </p>
        <p className="copy-body">
          Your next billing date will be the last day of your current billing
          period or, if applicable, the last day of your free trial. To avoid
          being charged for the next billing period (e.g. the next month), you
          must cancel at least 24 hours before the next billing date. You can
          view these dates in your account settings.
        </p>
        <p className="copy-body">
          The price of an ongoing Subscription may change. Before any price
          changes take effect, we will give reasonable notice in advance of the
          next billing date.
        </p>
        <p className="copy-body">
          You may cancel Subscriptions, including free trials, at any time in
          your account settings. If you cancel, the benefits of your
          subscription will remain available to you through and until the end of
          the paid-for billing period or the free trial period, as applicable.
          When your Subscription ends, you will lose your right to receive the
          benefits of your Subscription, such as access to additional content,
          functionalities and/or services.
        </p>
        <p className="copy-body">
          You may be able to select your Subscription from among different
          membership tiers and/or billing periods of varying lengths (for
          example, bronze, silver or gold; monthly, quarterly or yearly). You
          may, if offered, change the tier or billing period length of your
          subscription. Unless otherwise indicated or required by applicable
          law, any such tier, price or billing period changes are effective as
          of the beginning of the next billing period (for details on your
          current Subscription period, please see your account settings).
        </p>
        <p className="copy-body">
          There are no refunds for Subscriptions, unless otherwise indicated or
          required by applicable law.
        </p>
        <p className="copy-body">
          We may cancel Subscriptions for any or no reason at the end of the
          then-current billing period by giving reasonable notice in advance. If
          we cannot successfully charge your payment method on a billing date,
          we will notify you. After two (2) more days, we reserve the right to
          cancel or suspend your access to your subscription.
        </p>
        <p className="copy-body">
          You may have the ability to purchase an Omni Online membership (“
          <b>Membership</b>”) through the Store. When you purchase a Membership,
          you agree to the{" "}
          <a
            href="https://virtuix.com/omni-online-membership-terms"
            className="copy-link-gray"
          >
            Omni Online Membership Terms
          </a>, which supplement and
          form a part of these Terms. When you purchase a prepaid period of one
          or more months of Membership, you will receive a redemption code via
          email to activate your prepaid Membership when you’re ready to do so.
          You may also receive a free trial of Omni Online (“Trial”). The terms
          and conditions of your Trial are described in the Omni Online
          Membership Terms.
        </p>
        <p className="copy-body">
          You may be able to redeem a gift code, redemption code, or promotional
          code (“<b>Purchase Code</b>”) to purchase Products or receive a
          discount on Products. You can only redeem a Purchase Code if you have
          a user account. You may have a limited time frame to redeem a Purchase
          Code. Purchase Codes may expire on the date expressed at the time of
          receipt of the Purchase Code. You will not be able to redeem the
          Purchase Code if you already own the digital content or are restricted
          from doing so by law or any of our policies or requirements. Purchase
          Codes are not redeemable for cash or store credit or eligible for
          resale.
        </p>
        <h3 className="copy-subheader">5. VIRTUAL ITEMS</h3>
        <p className="copy-body">
          Your purchase of a virtual item or in-game currency within the
          Products is a payment for a limited, non-assignable license to access
          and use that content or functionality in the Products. Virtual items
          (including characters and character names) or in-game currency
          purchased or available to you in the Products may only be used in
          connection with the Products on which you obtained them or on which
          they were developed by you as a result of gameplay. These items are
          not redeemable or subject to refund and cannot be traded outside the
          Products for money or other items of value. We may modify or
          discontinue virtual items or in-game currency at any time. Except as
          described in these Terms, we have no responsibility for any
          transactions that you enter into with a third party for virtual items
          or in-game currency within the Store, and assume no liability for such
          virtual items or in-game currency that occur within software,
          applications, content or services provided by third parties.
        </p>
        <h3 className="copy-subheader">6. PRICING AND PAYMENT</h3>
        <p className="copy-body">
          We may accept various forms of payment, including credit and debit
          cards, and payments made through online payment services, such as
          Apple Pay, PayPal, etc. Additional terms with your payment provider
          may apply.
        </p>
        <p className="copy-body">
          By submitting an order or pre-order, you acknowledge that you are
          authorized to use the designated payment method and you authorize us
          to charge your order to that payment method. We may utilize an agent,
          subsidiary or affiliate to process payment and delivery.
        </p>
        <p className="copy-body">
          Prices are subject to change without notice (excluding ongoing
          Subscriptions). We reserve the right to refuse or cancel orders at any
          time and at our sole discretion.
        </p>
        <p className="copy-body">
          In the event that the payment method you designate cannot be verified,
          is invalid or is not otherwise acceptable, we may suspend or cancel
          your order. You are responsible for resolving any problems we
          encounter in connection with your order.
        </p>
        <h3 className="copy-subheader">7. DATA PROTECTION</h3>
        <p className="copy-body">
          To the extent that Virtuix processes any of your personal information
          for the purposes of any order, purchase or download described in these
          Terms, Virtuix is the data controller for the Store (and in respect of
          any personal information collected in connection with any order placed
          under these Terms). You authorize us (or a third-party payment
          processor) to process and store your payment and related information.
          For example, in accordance with the{" "}
          <a
            href="https://virtuix.com/privacy-policy"
            className="copy-link-gray"
          >
            Virtuix Privacy Policy
          </a>
          , Virtuix may pass your personal information to a third-party
          processor (including for payment and delivery) or, in the event that
          you submit information or place an order as a guest on the Store,
          Virtuix may store this information for use in connection with
          processing your order, providing support, and any marketing
          communications from Virtuix or its affiliates.
        </p>
        <h3 className="copy-subheader">8. ERRORS</h3>
        <p className="copy-body">
          We attempt to be as accurate as possible and to eliminate errors in
          relation to our Products; however, we do not represent or warrant that
          any Product descriptions or pricing information are accurate,
          complete, reliable, current or error-free. In the event of an error,
          we reserve the right to correct such error and revise your order
          accordingly (which includes charging the correct price) or to cancel
          the order and refund any amount charged. If we discover a pricing or
          other material error related to products that have yet to be
          dispatched or delivered, we will contact you to inform you of this
          error and give you the option of continuing to purchase at the correct
          price or cancelling your order. If we are not able to contact you
          using the contact details you provided during the order process, we
          will treat the order as cancelled.
        </p>
        <h3 className="copy-subheader">9. TAXES</h3>
        <p className="copy-body">
          If your purchase is subject to any type of use, sales, duty or other
          governmental tax or fee ("Taxes"), then we may charge you for those
          Taxes. Depending on the delivery destination, applicable taxes may be
          presented at checkout. You are responsible for any Taxes due with
          respect to your purchase of the Products
        </p>
        <h3 className="copy-subheader">10. OTHER TERMS AND POLICIES</h3>
        <p className="copy-body">
          Use of any Product purchased through the Store may be subject to
          additional terms and policies (some of which may be between you and
          Virtuix or its other affiliates), including, as applicable, the{" "}
          <a href="https://virtuix.com/terms-of-use" className="copy-link-gray">
            Virtuix Terms of Use
          </a>
          , the{" "}
          <a
            href="https://virtuix.com/omni-online-membership-terms"
            className="copy-link-gray"
          >
            Omni Online Membership Terms
          </a>
          , the{" "}
          <a
            href="https://virtuix.com/community-standards"
            className="copy-link-gray"
          >
            Community Standards
          </a>
          , the{" "}
          <a
            href="https://virtuix.com/privacy-policy"
            className="copy-link-gray"
          >
            Virtuix Privacy Policy
          </a>
          , and any other terms that may be presented and must be agreed to by a
          user prior to using the Product.
        </p>
        <h3 className="copy-subheader">11. CONSUMERS ONLY</h3>
        <p className="copy-body">
          You may only purchase Products for personal use or to give as a gift,
          unless otherwise expressly permitted by us pursuant to other written
          terms. You may not purchase products from us for commercial use or
          resale, and we reserve the right to refuse or cancel your order if we
          suspect that you are purchasing products for such purpose. However,
          you may use Products to develop and test content, software or
          applications intended for distribution by us through the Store,
          subject to the terms of the applicable{" "}
          <a
            href="https://virtuix.com/omni-one-sdk-eula"
            className="copy-link-gray"
          >
            SDK license agreement
          </a>
          .
        </p>
        <h3 className="copy-subheader">12. ACCOUNT</h3>
        <p className="copy-body">
          When you attempt to place an order, certain features in the Store may
          automatically be populated with your information. You are responsible
          for the accuracy of any populated information.
        </p>
        <p className="copy-body">
          Products purchased in the Store may require the use of an active user
          account. In order to use the products, a user may be required to log
          in to the product with an active user account. In the event that your
          user account is suspended, disabled or deleted, the Product may not be
          usable by such user until the user has an active user account again.
          You are responsible for all charges incurred in connection with your
          account. Virtuix may attempt to collect unpaid charges, including by
          attempting to make additional charges to your payment instrument,
          through the use of collections agencies and any other legal means. If
          you decide to cancel your account, Virtuix reserves the right, subject
          to any limitations under applicable laws, to collect fees, surcharges
          or costs incurred before cancellation. Any overdue or unpaid accounts
          must be settled before you will be allowed to register again for a
          Virtuix or Virtuix account.
        </p>
        <h3 className="copy-subheader">13. LIMITED LICENSE</h3>
        <p className="copy-body">
          Virtuix grants you a non-exclusive right, non-transferable, revocable
          and limited license to use any purchased Products (“
          <b>Acquired Content</b>”), but subject to the restrictions set out in
          these Terms. You may not copy, transfer, transmit, sublicense or
          assign the Acquired Content and the accompanying rights in this
          limited license, except as expressly permitted in these Terms.
        </p>
        <p className="copy-body">
          Further, and as a condition of your use of the Acquired Content, you
          warrant to Virtuix that you are 18 years of age or older, and you will
          not use the Acquired Content for any purpose that is unlawful, not
          allowed, or prohibited by the Terms or by law. You agree that you do
          not acquire any intellectual property or ownership rights in any
          Acquired Content, or in any protected content or your ideas submitted
          to us or your Submissions as set out below. We do not grant you any
          licenses, express or implied, to the intellectual property of Virtuix
          and/or our licensors or licensees except as expressly authorized in
          these Terms.
        </p>
        <p className="copy-body">
          To be clear, the Acquired Content is licensed, not sold, to you, and
          you hereby acknowledge that no title or ownership in the Acquired
          Content is being transferred or assigned and these Terms should not be
          construed as a sale of any rights in the Acquired Content. The
          publisher of the Acquired Content (“Publisher”) retains all right,
          title, and interest to the Acquired Content, including, but not
          limited to, all copyrights, trademarks, trade secrets, trade names,
          proprietary rights, patents, titles, computer codes, audiovisual
          effects, themes, characters, character names, stories, dialog,
          settings, artwork, sounds effects, musical works, and moral rights.
          The Acquired Content is protected by U.S. copyright and trademark law
          and applicable laws and treaties throughout the world. The Acquired
          Content may not be copied, reproduced, or distributed in any manner or
          medium, in whole or in part, without prior written consent from
          Virtuix. Any persons copying, reproducing, or distributing all or any
          portion of the Acquired Content in any manner or medium, will be
          willfully violating the copyright laws and may be subject to civil and
          criminal penalties in the U.S. or their local country. Be advised that
          U.S. copyright violations are subject to statutory penalties of up to
          $150,000 per violation. The Acquired Content contains certain licensed
          materials and Virtuix’s licensors and licensees may also protect their
          rights in the event of any violation of these Terms. All rights not
          expressly granted under these Terms are reserved by Virtuix and, as
          applicable, its licensors and/or licensees.
        </p>
        <h3 className="copy-subheader">
          14. RESTRICTIONS TO THE LIMITED LICENSE
        </h3>
        <p className="copy-body">
          This limited license does not include the right to, and you agree not
          to (a) sublicense the Acquired Content or make it available on a
          network to other users; (b) create derivative works from the Acquired
          Content; or (c) copy, publicly perform or broadcast the Acquired
          Content in any manner not permitted by these Terms.
        </p>
        <p className="copy-body">
          Further, you and any other person you allow to use the Acquired
          Content may not decompile, disassemble, reverse-engineer or otherwise
          display the Acquired Content in human-readable form. You may not
          modify, translate, rent, lease, distribute, lend or sell the Acquired
          Content, or provide the right to use the Acquired Content to others,
          except your guests you allow to use your Acquired Content for
          personal, non-commercial use. You void any rights to the Acquired
          Content for any commercial use of the Acquired Content. You agree that
          these Terms apply to the Acquired Content and all portions of it,
          whether owned by Virtuix or its third party licensors or licensees.
        </p>
        <p className="copy-body">
          For the avoidance of doubt, you agree not to:
        </p>
        <p className="copy-body">
          <ol className="copy-list-lower-alpha copy-list-indent">
            <li>
              distribute, lease, license, sell, rent, or otherwise transfer or
              assign the Acquired Content, or any copies of the Acquired
              Content;
            </li>
            <li>make a copy of the Acquired Content or any part thereof;</li>
            <li>
              make a copy of the Acquired Content available on a network for use
              or download by multiple users;
            </li>
            <li>
              except as otherwise specifically provided by the Acquired Content
              or these Terms, use or install the Acquired Content (or permit
              others to do same) on a network, for public on-line use, or on
              more than one computer or gaming unit at the same time;
            </li>
            <li>
              copy the Acquired Content onto a hard drive or other storage
              device in order to bypass the requirement to run the Acquired
              Content;
            </li>
            <li>
              use the Acquired Content at a computer gaming center, any other
              location-based site, or commercially; provided, that Virtuix may
              offer you a separate license agreement to make the Acquired
              Content available for commercial use;
            </li>
            <li>
              restrict or inhibit any other user from using and enjoying any
              online features of the Acquired Content;
            </li>
            <li>
              cheat or utilize any unauthorized robot, spider, or other program
              in connection with any online feature of the Acquired Content;
            </li>
            <li>
              violate any terms, policies, licenses, or code of conduct for any
              online features of the Acquired Content; or
            </li>
            <li>
              transport, export, or re-export (directly or indirectly) into any
              country forbidden to receive the Acquired Content by any U.S.
              export laws or regulations or U.S. economic sanctions or otherwise
              violate any laws or regulations, or the laws of the country in
              which the Acquired Content was obtained, which may be amended from
              time to time.
            </li>
          </ol>
        </p>
        <p className="copy-body">
          WE DO NOT AND CANNOT CONTROL THE FLOW OF DATA TO OR FROM OUR NETWORK
          AND OTHER PORTIONS OF THE INTERNET, WIRELESS NETWORKS, OR OTHER
          THIRD-PARTY NETWORKS. SUCH FLOW DEPENDS IN LARGE PART ON THE
          PERFORMANCE OF THE INTERNET AND WIRELESS SERVICES PROVIDED OR
          CONTROLLED BY THIRD PARTIES. AT TIMES, ACTIONS OR INACTIONS OF SUCH
          THIRD PARTIES MAY IMPAIR OR DISRUPT YOUR CONNECTIONS TO THE INTERNET,
          WIRELESS SERVICES, OR PORTIONS THEREOF. WE CANNOT GUARANTEE THAT SUCH
          EVENTS WILL NOT OCCUR. ACCORDINGLY, WE DISCLAIM ANY AND ALL LIABILITY
          RESULTING FROM OR RELATED TO THIRD-PARTY ACTIONS OR INACTIONS THAT
          IMPAIR OR DISRUPT YOUR CONNECTIONS TO THE INTERNET, WIRELESS SERVICES,
          OR PORTIONS THEREOF OR THE USE OF THE ACQUIRED CONTENT AND RELATED
          SERVICES AND PRODUCTS.
        </p>
        <h3 className="copy-subheader">15. INTERNATIONAL USE</h3>
        <p className="copy-body">
          Although the Acquired Content may be accessible worldwide, we make no
          representation that the Acquired Content or related materials are
          appropriate or available for use in locations outside the United
          States, and accessing them from territories where the content is
          illegal is prohibited. Those who choose to access the Acquired Content
          from other locations do so on their own initiative and are responsible
          for compliance with local laws, if and to the extent local laws are
          applicable. Any offer and/or information made in connection with the
          Acquired Content is void where prohibited. Without limiting the
          foregoing, the Acquired Content may not be exported or re-exported (a)
          into (or to a national resident of) any U.S. embargoed countries or
          (b) to anyone on the U.S. Treasury Department's list of Specially
          Designated Nationals or the U.S. Department of Commerce Denied
          Person's List or Entity List. By accessing and using the Acquired
          Content, you represent and warrant that you are not located in, under
          control of, or a national resident of any such country or on any such
          list.
        </p>
        <h3 className="copy-subheader">16. OPEN SOURCE SOFTWARE</h3>
        <p className="copy-body">
          The Acquired Content may contain or link to certain software including
          without limitation "open source software" that is licensed under other
          software license terms ("Third Party Software"). You agree to any such
          additional license terms for such Third Party Software and any other
          content, source code or other materials provided to you as part of the
          Acquired Content.
        </p>
        <h3 className="copy-subheader">17. COLLECTION OF DATA</h3>
        <p className="copy-body">
          As set out in the{" "}
          <a
            href="https://virtuix.com/privacy-policy"
            className="copy-link-gray"
          >
            Virtuix Privacy Policy
          </a>
          , which is hereby incorporated by reference, Acquired Content may
          collect data while you use such content. By your use of the Acquired
          Content, you consent to the collection and use of any and all data, in
          adherence to the terms of the Privacy Policy, collected from your use
          of the Acquired Content. Data that is collected and stored by the
          Acquired Content could include certain metrics, including but not
          limited to gameplay performance, achievements, and friends you played
          with. In addition, the Acquired Content will have access to certain
          information of your User Profile, as defined in the Privacy Policy,
          including but not limited to your username, your friends, and
          performance metrics.
        </p>
        <h3 className="copy-subheader">18. SUBMISSIONS</h3>
        <p className="copy-body">
          You agree that any feedback, experiences, responses, creative
          suggestions, ideas, notes, drawings, concepts, or other information
          via email, via user forums, on Discord, or via any other means of
          communication about a Product (collectively, the "Submissions") shall
          be deemed, and shall remain, the intellectual property of whomever
          owns that Product’s intellectual property rights, and you shall have
          no ownership or rights to the Submissions except the use as set forth
          herein.
        </p>
        <p className="copy-body">
          FOR THE AVOIDANCE OF DOUBT, ANY AND ALL OF YOUR SUBMISSIONS SHALL BE
          THE SOLE AND EXCLUSIVE PROPERTY OF VIRTUIX AND ITS AFFILIATES AND/OR
          ITS LICENSORS OR LICENSEES, AND YOU HEREBY ASSIGN ALL OF YOUR RIGHT,
          TITLE AND INTEREST IN THE SUBMISSIONS, AND ALL INTELLECTUAL PROPERTY
          RIGHTS RELATED THERETO AS SET FORTH HEREIN. WITHOUT LIMITING THE
          GENERALITY OF THE FOREGOING, YOU AGREE THAT VIRTUIX AND ITS AFFILIATES
          AND/OR ITS LICENSORS OR LICENSEES SHALL HAVE THE PERPETUAL AND
          IRREVOCABLE RIGHT TO USE, MODIFY, AND OTHERWISE EXPLOIT ALL OR PART OF
          THE YOUR SUBMISSIONS OR ANY DERIVATIVE THEREOF IN ANY MANNER OR MEDIA
          NOW KNOWN OR HEREAFTER DEVISED WITHOUT ANY REMUNERATION, COMPENSATION
          OR CREDIT TO YOU. You hereby represent and warrant that you have the
          right to assign and grant the rights set forth herein, and that any
          Submission which is provided by you hereunder is original work made
          solely by you and does not infringe any third-party intellectual
          property rights.
        </p>
        <h3 className="copy-subheader">
          19. ENFORCEMENT RIGHTS AND TERMINATION{" "}
        </h3>
        <p className="copy-body">
          We are not obligated to monitor access or use of the Acquired Content,
          or your Submissions, but we have the right to do so for the purpose of
          ensuring compliance with these Terms, and to comply with applicable
          law or other legal requirements. We may consult with and disclose
          unlawful conduct to law enforcement authorities; and pursuant to valid
          legal process, and we may cooperate with law enforcement authorities
          to prosecute users who violate the law. We reserve the right to remove
          or disable access to the Acquired Content, or your Submissions at any
          time and without notice, and at our sole discretion, if we determine
          that your Submissions, or your use of any Acquired Content is
          objectionable or in violation of these Terms, or if the Acquired
          Content itself is in violation of any terms. We will not incur any
          liability or responsibility if we choose to remove, disable or delete
          your access or ability to use any or all portion(s) of the Acquired
          Content.
        </p>
        <h3 className="copy-subheader">20. DISCLAIMERS</h3>
        <p className="copy-body">
          ALL ACQUIRED CONTENT IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY
          KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET
          ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF
          COURSE OF DEALING OR USAGE OF TRADE. We do not offer any warranties or
          guarantees in relation to the Acquired Content’s installation,
          configuration or error/defect correction. We make no warranty that any
          Acquired Content will meet your requirements or be available on an
          uninterrupted, secure, or error-free basis. We make no warranty
          regarding the quality, accuracy, timeliness, truthfulness,
          completeness or reliability of any Acquired Content. You acknowledge
          and agree that if you rely on any Acquired Content, you do so solely
          at your own risk.
        </p>
        <p className="copy-body">
          THIS DISCLAIMER SHALL NOT BE APPLICABLE SOLELY TO THE EXTENT THAT ANY
          SPECIFIC PROVISION OF THIS DISCLAIMER IS PROHIBITED BY ANY FEDERAL,
          STATE, OR MUNICIPAL LAW, WHICH CANNOT BE PRE-EMPTED.
        </p>
        <h3 className="copy-subheader">21. SEIZURE WARNING</h3>
        <p className="copy-body">
          In rare instances, some users may experience seizures when exposed to
          certain light patterns or flashing lights associated with the Acquired
          Content. Exposure to certain light patterns or backgrounds on a video
          monitor on in a virtual reality headset may induce an epileptic
          seizure, even in persons who have no history of prior seizures or
          epilepsy. You should carefully review all health and safety warnings
          and/or instructions from the Acquired Content’s publisher as well as
          Omni One’s Health and Safety Warnings and Instructions, which you can
          read{" "}
          <a
            href="https://virtuix.com/health-and-safety-warnings-and-instructions"
            className="copy-link-gray"
          >
            here
          </a>{" "}
          and which are hereby incorporated by reference.
        </p>
        <h3 className="copy-subheader">22. CONTACT US</h3>
        <p className="copy-body">
          If you have any questions or concerns regarding these Terms or our
          Store, please contact us at help@virtuix.com.
        </p>
        <p className="copy-body">
          <b>Last Updated: November 26, 2022</b>
        </p>
      </div>
    </>
  );
}

export default OmniOneStoreTerms;
