import React from "react";
import "./OpResourcesOmniverseCopy.css";
import "./RequiredThirdPartyCopy.css";
import LazyAnimation from "./LazyAnimation";

function RequiredThirdPartyCopy() {
  return (
    <>
      <div className="ov-resources-copy-container">
        <div className="req-third-party-copy-grid">
          <h2 className="ov-resources-copy-header ov-resources-copy-margin-top-light req-third-party-copy-grid-item-1">
            VR Ready PC
          </h2>
          <div className="ov-resources-flex-no-pad req-third-party-copy-grid-item-2">
            <p className="ov-resources-copy-body">
              Minimum:
              <ul className="ov-resources-copy-list-disc copy-list-indent">
                <li>Operating System: Windows 10</li>
                <li>Processor: Intel Core i5-6600K @ 3.5 GHz</li>
                <li>Memory: 8 GB RAM</li>
                <li>Graphics: NVIDIA GeForce GTX 1070 8GB</li>
                <li>Hard Drive: 1TB (SSD or HDD)</li>
                <li>
                  <a
                    href="https://www.avadirect.com/avatar-series-virtual-reality-computers"
                    target="_blank"
                    rel="noreferrer"
                    className="ov-resources-copy-link-gray"
                  >
                    Purchasing Link
                  </a>
                </li>
              </ul>
            </p>
            <p className="ov-resources-copy-body">
              Recommended:
              <ul className="ov-resources-copy-list-disc copy-list-indent">
                <li>Operating System: Windows 10</li>
                <li>Processor: Intel i7-6700</li>
                <li>Memory: 16 GB RAM</li>
                <li>Graphics: NVIDIA Geforce RTX 2070</li>
                <li>Hard Drive: 1TB (SSD or HDD)</li>
                <li>
                  <a
                    href="https://www.avadirect.com/avatar-series-virtual-reality-computers"
                    target="_blank"
                    rel="noreferrer"
                    className="ov-resources-copy-link-gray"
                  >
                    Purchasing Link
                  </a>
                </li>
              </ul>
            </p>
          </div>
          <p className="ov-resources-copy-body req-third-party-copy-grid-item-3">
            <em>
              Note: The purchasing link is an example. Any PC with these specs
              will work.
            </em>
          </p>
          <img
            className="req-third-party-copy-grid-item-4"
            src="/images/products_vrpc_01.png"
            alt="VR PC"
          />
        </div>
        <div className="req-third-party-copy-grid">
          <h2 className="ov-resources-copy-header ov-resources-copy-margin-top-light req-third-party-copy-grid-item-1">
            HTC Vive Pro - Full Kit
          </h2>
          <div className="req-third-party-copy-grid-item-2">
            <p className="ov-resources-copy-body">
              <ul className="ov-resources-copy-list-disc copy-list-indent">
                <li>VR headset with 360-degree tracking system and fully tracked controllers</li>
                <li>Integrated headphones</li>
                <li><em>Enterprise Advantage</em> is required for commercial use and includes commercial licensing and support</li>
                <li>
                  <a
                    href="https://business.vive.com/us/"
                    target="_blank"
                    rel="noreferrer"
                    className="ov-resources-copy-link-gray"
                  >
                    Purchasing Link
                  </a>
                </li>
              </ul>
            </p>
          </div>
          <img
            className="req-third-party-copy-grid-item-5"
            src="/images/products_vivepro_01.png"
            alt="VR PC"
          />
        </div>
        <div className="req-third-party-copy-grid">
          <h2 className="ov-resources-copy-header ov-resources-copy-margin-top-light req-third-party-copy-grid-item-1">
          Wireless Touch Keyboard (Optional)
          </h2>
          <div className="req-third-party-copy-grid-item-2">
            <p className="ov-resources-copy-body">
              <ul className="ov-resources-copy-list-disc copy-list-indent">
                <li>Integrated wireless keyboard with touchpad</li>
                <li>10-meter wireless range</li>
                <li>Plug-and-play setup</li>
                <li>
                  <a
                    href="https://www.logitech.com/en-us/product/wireless-touch-keyboard-k400-plus"
                    target="_blank"
                    rel="noreferrer"
                    className="ov-resources-copy-link-gray"
                  >
                    Purchasing Link
                  </a>
                </li>
              </ul>
            </p>
          </div>
          <img
            className="req-third-party-copy-grid-item-5"
            src="/images/products_keyboard_01.png"
            alt="Wireless Touch Keyboard"
          />
        </div>
        <div className="req-third-party-copy-grid">
          <h2 className="ov-resources-copy-header ov-resources-copy-margin-top-light req-third-party-copy-grid-item-1">
          VIVE Pro Face Cover
          </h2>
          <div className="req-third-party-copy-grid-item-2">
            <p className="ov-resources-copy-body">
              <ul className="ov-resources-copy-list-disc copy-list-indent">
                <li>Soft and durable foam replacement</li>
                <li>Prevent the build-up of bacteria and dirt with these easy-to-clean pads</li>
                <li>This item is not compatible with VIVE Business Edition</li>
                <li>
                  <a
                    href="https://www.amazon.com/TamBee-Vive-Replacement-Leather-Cover-PC/dp/B07KWYNR45/"
                    target="_blank"
                    rel="noreferrer"
                    className="ov-resources-copy-link-gray"
                  >
                    Purchasing Link
                  </a>
                </li>
              </ul>
            </p>
          </div>
          <img
            className="req-third-party-copy-grid-item-5"
            src="/images/products_vrcover_01.png"
            alt="VIVE Pro Face Cover"
          />
        </div>
        <div className="req-third-party-copy-grid">
          <h2 className="ov-resources-copy-header ov-resources-copy-margin-top-light req-third-party-copy-grid-item-1">
          VIVE Pro Head Cushion
          </h2>
          <div className="req-third-party-copy-grid-item-2">
            <p className="ov-resources-copy-body">
              <ul className="ov-resources-copy-list-disc copy-list-indent">
                <li>Soft and durable foam replacement</li>
                <li>Prevent the build-up of bacteria and dirt with these easy-to-clean pads</li>
                <li>This item is not compatible with VIVE Business Edition</li>
                <li>
                  <a
                    href="https://vrcover.com/product/htc-vive-pro-headstrap-foam/"
                    target="_blank"
                    rel="noreferrer"
                    className="ov-resources-copy-link-gray"
                  >
                    Purchasing Link
                  </a>
                </li>
              </ul>
            </p>
          </div>
          <img
            className="req-third-party-copy-grid-item-5"
            src="/images/products_vrheadcushion_01.png"
            alt="Vivr Pro Head Cushion"
          />
        </div>
        <div className="req-third-party-copy-grid">
          <h2 className="ov-resources-copy-header ov-resources-copy-margin-top-light req-third-party-copy-grid-item-1">
          Microfiber Lens Cleaning Cloth
          </h2>
          <div className="req-third-party-copy-grid-item-2">
            <p className="ov-resources-copy-body">
              <ul className="ov-resources-copy-list-disc copy-list-indent">
                <li>Scratch-free, lint-free, microfiber cleaning cloths</li>
                <li>Safe to use on HTC Vive lenses</li>
                <li>
                  <a
                    href="https://www.amazon.com/MagicFiber-Microfiber-Cleaning-Cloths-PACK/dp/B0050R67U0/"
                    target="_blank"
                    rel="noreferrer"
                    className="ov-resources-copy-link-gray"
                  >
                    Purchasing Link (U.S. example)
                  </a>
                </li>
              </ul>
            </p>
          </div>
          <img
            className="req-third-party-copy-grid-item-5"
            src="/images/products_microfibercloth.png"
            alt="Micro Fiber Lens Cloths"
          />
        </div>
        <h1 className="ov-resources-copy-title ov-resources-copy-margin-top-light">
          Optional Third-Party Products
        </h1>
        <LazyAnimation />
        <p className="ov-resources-copy-body req-third-party-copy-margin">
          The following products can enhance the excitement of the Omni setup.
        </p>
        <div className="req-third-party-copy-grid">
          <h2 className="ov-resources-copy-header ov-resources-copy-margin-top-light req-third-party-copy-grid-item-1">
          SubPac M2X Wearable Audio System
          </h2>
          <div className="req-third-party-copy-grid-item-2">
            <p className="ov-resources-copy-body">
              <ul className="ov-resources-copy-list-disc copy-list-indent">
                <li>A transformative wearable audio vest that converts sound into high fidelity vibrations that players can feel</li>
                <li>Sound pulses through the player’s body, increasing the thrill and immersion of the Omni experience</li>
                <li>However, the vest increases the setup time and hassle when preparing a player for Omni gameplay</li>
                <li>
                  <a
                    href="https://subpac.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="ov-resources-copy-link-gray"
                  >
                    Purchasing Link
                  </a>
                </li>
              </ul>
            </p>
          </div>
          <img
            className="req-third-party-copy-grid-item-6"
            src="/images/products_subpac_01.png"
            alt="SubPac Audio System"
          />
        </div>
        <div className="req-third-party-copy-grid">
          <h2 className="ov-resources-copy-header ov-resources-copy-margin-top-light req-third-party-copy-grid-item-1">
          X-Rover H7 VR Game Controller
          </h2>
          <div className="req-third-party-copy-grid-item-2">
            <p className="ov-resources-copy-body">
              <ul className="ov-resources-copy-list-disc copy-list-indent">
                <li>Holds a Vive controller for positional tracking of the gun in-game</li>
                <li>Increases the optical spectacle when showcasing the Omni in front of an audience</li>
                <li>Increases the immersion for the player when playing TRAVR: Shadow Ops</li>
                <li>However, this gun has limited use for most Omniverse games (most games use two hand controllers instead of one single gun)</li>
                <li>Ask Virtuix about pricing and availability</li>
              </ul>
            </p>
          </div>
          <img
            className="req-third-party-copy-grid-item-5"
            src="/images/products_xrover_01.png"
            alt="VR Game Controller"
          />
        </div>
        <div className="req-third-party-copy-grid">
          <h2 className="ov-resources-copy-header ov-resources-copy-margin-top-light req-third-party-copy-grid-item-1">
          Flight Case
          </h2>
          <div className="req-third-party-copy-grid-item-2">
            <p className="ov-resources-copy-body">
              <ul className="ov-resources-copy-list-disc copy-list-indent">
                <li>Protective case for transportation of the Omni</li>
                <li>Useful for customers who want to take the Omni to shows and events</li>
                <li>Ask Virtuix about pricing and availability</li>
              </ul>
            </p>
          </div>
          <img
            className="req-third-party-copy-grid-item-7"
            src="/images/products_transportcase.png"
            alt="Flight Case"
          />
        </div>
      </div>
    </>
  );
}

export default RequiredThirdPartyCopy;
