import React from "react";
import { Link } from "react-router-dom";
import "../../Copy.css";

function OnlineMemberTerms() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">
          Omni Online Membership Terms
        </h1>
        <p className="copy-body">
        By purchasing or using an Omni Online membership (“Membership”), you agree to the following terms and conditions (“Membership Terms”), which supplement and form a part of the Virtuix <Link className="copy-link-to-gray" to="/terms-of-use">
            Terms of Use
          </Link> (the “Terms”). Capitalized terms not defined here have the meaning given to them in the Virtuix Terms of Use.
        </p>
        <p className="copy-body">
        A “Member” is any individual who has an Omni One system for personal, non-commercial use and who activates and pays the monthly membership fees for an Omni Online Membership. 
            </p>
        <p className="copy-body">
        We reserve the right to change or modify these Membership Terms on a go-forward basis at any time and at our sole discretion. If we make changes to these Membership Terms, we may provide notice of such changes as appropriate, such as by sending an email notification to the address you've provided, or we may provide notice by updating the "Last Updated" date at the bottom of these Membership Terms, which are available at <a href="#lastupdated" className="copy-link-gray">https://virtuix.com/omni-online-membership-terms</a>. Your continued Membership will confirm your acceptance of the revised Membership Terms. If you do not agree to the revised Membership Terms, you must cancel your Membership. We encourage you to review the Membership Terms from time to time to ensure that you understand the terms and conditions that apply to your access to, and use of, the Membership.
        </p>
        <p className="copy-body">
        <b>1. Membership</b>
        </p>
        <p className="copy-body">
        An Omni Online Membership provides you and members of your household who use the same Omni One treadmill access to online content features including the ability to play online multiplayer games, get featured on game leaderboards, store saved games, and more (“Membership Services”). The Member is responsible for all activity under their Membership.
        </p>
        <p className="copy-body">
        <b>2. Billing Cycles</b>
        </p>
        <p className="copy-body">An Omni Online Membership recurs on a monthly, annual, or other cyclical basis until it is canceled in accordance with these Membership Terms. Billing occurs at the beginning of the Membership cycle. </p>
        <p className="copy-body">By purchasing a Membership, you acknowledge that your Membership has recurring payment features and you accept responsibility for all recurring payment obligations prior to cancellation of your Membership by you or Virtuix. You can change or terminate your Membership at any time by going to your account settings.</p>
        <p className="copy-body">
        <b>3. Prepaid Periods</b>
        </p>
        <p className="copy-body">
        You may be given the opportunity to prepay for a period of one or more months of your Membership. At the conclusion of a prepaid period, unless you cancel prior to renewal or unless otherwise selected, and to the extent permitted by applicable law, your Membership will automatically continue on a month-to-month basis at the then-existing non-promotional price for your Membership.
        </p>
        <p className="copy-body">
        <b>4. Other Offers</b>
        </p>
        <p className="copy-body">
        Virtuix may offer additional promotions or discounts related to Membership from time to time. Please read the details of those offers carefully, as any additional terms presented to you during the signup process will form part of these Membership Terms. Unless specified in writing, all discount offers that require a payment are non-refundable. Any free trial or other promotion must be used within the specified time frame of the trial or promotion. You may be required to have a valid payment method on file in order to initiate a free trial; in this case, if you do not cancel before your free trial period ends, your account will be converted to a paid Membership and will be charged in accordance with these Membership Terms.
        </p>
        <p className="copy-body">
        <b>5. Free Trial</b>
        </p>
        <p className="copy-body">
        We may permit you to sign up for a free trial of Omni Online (“Trial”). Your Trial period starts on the day you activate your Membership and lasts for the number of days indicated by Virtuix. Each user account is limited to one Trial, and each Omni One system is limited to one Trial. You may cancel your Trial at any time until the last day of your Trial. If you timely cancel your Trial, you acknowledge and agree that we may delete all of your content or data associated with your Trial at the end of such Trial period, and you acknowledge and agree that you will not be able to resume the Trial at a later date. If you do not timely cancel your Trial in accordance with these Membership Terms, you acknowledge that you will be charged, and you authorize us to charge the credit card or other payment information you provided for the membership amount. If your Trial is converted into a paid Membership, you may cancel such Membership in accordance with these Membership Terms.
        </p>
        <p className="copy-body">
        <b>6. Termination or Cancellation of Membership</b>
        </p>
        <p className="copy-body">
        If you terminate your Membership, you may use your Membership until the end of the then-current period and your Membership will not be renewed after that period expires. However, you will not be eligible for a prorated refund of any portion of the Membership fee paid for the then-current Membership period. To avoid future Membership charges, you must cancel your Membership at least one (1) day before the Membership period renewal.
        </p>
        <p className="copy-body">
        Virtuix may immediately terminate or suspend your account, and all or a portion of your Membership, without notice if:
        </p>
        <p className="copy-body">
            <ol className="copy-list-lower-alpha copy-list-indent">
                <li>
                you fail to provide a valid payment method and other billing information necessary to process the payment for your Membership at the end of your free Trial period;
                </li>
                <li>
                your payment is overdue (provided that we will use reasonable efforts to notify you of the overdue payment before we terminate or suspend);
                </li>
                <li>
                you provide false or inaccurate information;
                </li>
                <li>
                you violate these Membership Terms, the <Link className="copy-link-to-gray" to="/terms-of-use">Terms of Use</Link>, <Link className="copy-link-to-gray" to="/community-standards">Community Standards</Link>, or any other Virtuix rules or agreements then in effect;
                </li>
                <li>
                you engage in conduct that is a violation of any applicable law or tariff (including, without limitation, copyright and intellectual property laws); or
                </li>
                <li>
                if you engage in conduct that is threatening, abusive or harassing to Virtuix employees, agents, or other Virtuix users, including, for example, making threats to physically harm or damage property.
                </li>
            </ol>
        </p>
        <p className="copy-body">
        In the event that Virtuix determines, in its sole discretion, that your Membership is being accessed in a commercial setting or is for commercial use, Virtuix reserves the right to terminate or suspend your Membership at any time by writing to you at the email address associated with your Membership.
        </p>
        <p className="copy-body">
        If we terminate or suspend your Membership, your license to use any software or content provided in connection with the Membership is also terminated or suspended (as applicable). If your Membership is terminated, Virtuix has the right to immediately delete all data, files, and other information stored in or for your account without further notice to you. You must pay all charges up to and including the date of termination. Should you wish to resume your Membership after any suspension, a restoration of service fee may apply. This fee is in addition to all past due unpaid charges and other fees.
        </p>
        <p className="copy-body">
        <b>7. Payment</b>
        </p>
        <p className="copy-body">
        Members agree to pay the recurring fee specified when you purchased your Membership (plus any applicable taxes and other charges). If the amount to be charged varies from the amount you pre-authorized (other than due to the imposition of, or change in, applicable sales tax), you have the right to receive, and we will provide, notice of the amount to be charged and the date of the charge before the scheduled date of the transaction, unless applicable law requires you to expressly consent to the change in price. Any agreement you have with your payment provider governs your use of your specified payment method. You agree that we may accumulate charges incurred and submit them as one or more aggregate charges during or at the end of each billing cycle. Your failure to terminate and/or continued use of your Membership reaffirms that we are authorized to charge you for that Membership. We may submit those charges for payment and you will be responsible for such charges. This does not waive our right to seek payment directly from you.
        </p>
        <p className="copy-body">
        You may be asked to provide a payment method (such as a credit, charge or debit card number) that we accept in order to activate your Membership, along with other payment information. You represent and warrant that you have the legal right to use all payment method(s) that you provide to us. Your authorizations in this section also apply to our payment processors and any other companies who act as billing agents for us. You hereby authorize us to charge your provided payment method on a recurring basis in line with your Membership cycle, in advance, for your Membership(s) and/or to place a hold on your payment method with respect to any unpaid charges for your Membership(s). You authorize the issuer of your provided payment method to pay any amounts described herein without requiring a signed receipt, and you agree that these Membership Terms shall be accepted as authorization to the issuer of the payment method to pay any amounts described herein, without requiring a signed receipt from you. You authorize us to continue to attempt to charge and/or place holds with respect to all sums described herein, or any portion thereof, to your payment method until such amounts are paid in full. You agree to provide updated payment information upon request and any time the information you previously provided is no longer valid. You acknowledge and agree that neither Virtuix, nor any Virtuix agent, will have any liability whatsoever for any insufficient funds or other charges incurred by you as a result of attempts to charge, and/or place holds on, your specified payment method as contemplated by these Membership Terms. If you provide a debit card number instead of a credit card number, you authorize all charges described herein to be applied to such debit card unless and until you provide a credit card number.
        </p>
        <p className="copy-body">
        You agree to pay us, through our payment processors or financing partners (as applicable), all charges at the prices then in effect for any purchase in accordance with the applicable payment terms presented to you at the time of purchase. You agree to make payment using the payment method you provide when you set up your account. We reserve the right to correct, or to instruct our payment processors or financing partners to correct, any errors or mistakes, even if payment has already been requested or received.
        </p>
        <p className="copy-body">
        If you believe you have been billed in error for a Membership, please notify us within 60 days of the billing date by emailing <a href="mailto:help@virtuix.com" className="copy-link-to-gray">help@virtuix.com</a>. Virtuix will not issue refunds or credits after the expiration of this 60-day period, except where required by applicable law.
        </p>
        <p className="copy-body">
        <b>8. Interruption of Membership Services</b>
        </p>
        <p className="copy-body">
        The Membership services provided by the Membership, including the ability to play online multiplayer games, get featured on game leaderboards, or store saved games, may be unavailable from time to time due to technical issues. Virtuix does not warrant that the Membership Services will be uninterrupted or error-free, and Virtuix is not liable for any damages of any kind resulting from loss of use, loss of data, or loss of revenue arising out of your inability to use the Membership Services. Virtuix will not issue refunds or credits, except where required by applicable law. 
        </p>
        <p id="lastupdated" className="copy-body">
          <b>Last Updated: November 26, 2022</b>
        </p>
      </div>
    </>
  );
}

export default OnlineMemberTerms;
