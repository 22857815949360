import React from "react";
import { Link } from "react-router-dom";
import "./CareersSection1.css";
import { Button } from "./Button";
import LazyAnimation from "./LazyAnimation";

function CareersSection1() {
  return (
    <div className="careers-one-dark-bg">
      <div className="careers-one-container">
        <div className="careers-one-flex ">
          <img
            className="careers-one-img careers-one-col-01"
            src="/images/oa_enterprise_team_12.jpg"
            alt="Team working"
          />
          <div className="careers-one-col-02">
            <LazyAnimation />
            <h1 className="careers-one-heading">Why Virtuix?</h1>
            <div className="careers-one-text">
              We are a high-performant team driven by our desire to bring full-body VR to life. We maintain a strong team
              culture with no appetite for mediocrity. We believe in empowering
              our skilled and passionate team members to create exciting VR
              hardware and software.
              <br />
              &nbsp;
              <br />
              At Virtuix, you'll join a talented team that is delivering a new
              wave of immersive entertainment. You'll have a unique chance to
              influence the entertainment industry and become part of our story
              as a venture-funded tech startup.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareersSection1;
