import React from "react";
import "./DevelopersCopy.css";

function DevelopersCopy() {
  return (
    <>
      <div className="developers-copy-container">
        <h1 className="developers-copy-title">OMNI PRO SDK</h1>
        <p className="developers-copy-body">
        Omni applications enable players to walk around and explore VR in ways never seen before:
        </p>
        <h2 className="developers-copy-header developers-copy-indent">Full-Body VR</h2>
        <p className="developers-copy-body developers-copy-indent">
        Immersion through physical walking and running in 360 degrees inside a game or VR application.
        </p>
        <h2 className="developers-copy-header developers-copy-indent">De-coupled Looking / Walking Direction</h2>
        <p className="developers-copy-body developers-copy-indent">
        The ability to walk in one direction while looking around independently in any other direction gives the player the natural experience of looking around for danger or observing the surroundings while moving.
        </p>
        <h2 className="developers-copy-header developers-copy-indent">Natural Speed</h2>
        <p className="developers-copy-body developers-copy-indent">
        Analog walking and running speed in-game matches the player’s physical movement speed on the Omni.
        </p>
        <p className="developers-copy-body">
        The Omni Pro Software Development Kit (Omni Pro SDK) is the development gateway to full-body VR experiences with the Omni Pro. Request our SDKs for Unity and Unreal Engine 4 via the link below. After submitting the form and review by our team, you will receive an email with a link to download our SDKs.
        </p>
        <p className="developers-copy-body">
        To use the Omni's default navigation mode, in which the user's walking orientation is “de-coupled” from his looking orientation, the Omni unit will need to be calibrated via the Omni calibration application. This calibration application is included in the SDK package, or you can download it separately via the download link below. If you own the Omni Controller Mount, this application will also automatically complete the Vive “Room Setup” calibration.
        </p>
        <p className="developers-copy-body">
        Download our <a className="developers-copy-link" href="https://dl.dropboxusercontent.com/s/ntzn18524xt34ue/Omni%20Application%20Design%20Guide.pdf?dl=1">Omni Design Guide</a> with useful tips and guidance on how to create a comfortable Omni experience.
        </p>
        <p className="developers-copy-body-italic">
        Note: If you plan to develop your own software application for the Omni Pro, your Omni Pro unit will need to be “unsecured”. If your unit appears “locked”, please contact your Virtuix representative to request that your unit be unsecured. Unsecured Omni Pro units will not be compatible with our Omniverse content platform.
        </p>
        <h1 className="developers-copy-title">Omni Training Game</h1>
        <p className="developers-copy-body">
        We created a brief training experience in VR that teaches new users how to walk on the Omni Pro in less than a minute via an interactive VR game. We recommend you use this training game to make players feel comfortable on the Omni Pro before you launch them into your game or application. You can download the standalone Omni training game via the resource link below.
        </p>
      </div>
    </>
  );
}

export default DevelopersCopy;
