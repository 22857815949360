import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { render } from "react-dom";
import "./Investors.css";
import { Button } from "../../Button";
import LazyAnimation from "../../LazyAnimation";
import InvestorsForm from "../../InvestorsForm";

function Investors() {
  return (
    <>
      <div className="investors-hero-bg">
        <div className="investors-container">
        <div className="investors-hero">
          <h1 className="investors-title">Investors</h1>
          <LazyAnimation></LazyAnimation>
          <h2 className="investors-subtitle">
          Thank you for being a Virtuix investor!
          </h2>
        </div>
        </div>
      </div>
      <InvestorsForm />
    </>
  );
}

export default Investors;
