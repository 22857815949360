import React from "react";
import "./NewsArticles.css";
import ReactPlayer from "react-player";

function NewsArticle12() {
  return (
    <>
      <div className="news-articles-container">
        <img
          className="news-article-featured-img"
          src="/images/omni_game_announce_031324.jpg"
          alt="Omni One Launch Games Announced"
        />
        <h1 className="news-articles-title">
          Virtuix Unveils 35 Launch Titles for Omni One, Including{" "}
          <i>Ghostbusters: Rise of the Ghost Lord</i> and <i>Peaky Blinders</i>
        </h1>
        <p className="news-articles-byline">by Virtuix | March 13, 2024</p>
        <p className="news-articles-body">
          <b>AUSTIN, TEXAS</b> (March 13, 2024) – Virtuix, the developer of the
          “Omni One” full-body VR gaming system, is thrilled to unveil an
          impressive lineup of 35 games for Omni One’s highly anticipated
          consumer launch in late Q2 2024. Omni One is a complete entertainment
          system featuring a proprietary 360-degree treadmill that enables
          players to run around inside virtual reality games, a customized Pico
          4 Enterprise headset, and a dedicated game store with titles optimized
          for Omni One.
        </p>
        <p className="news-articles-body">
          Omni One’s launch lineup includes top VR games like Ghostbusters: Rise
          of the Ghost Lord, Peaky Blinders: The King’s Ransom, Hubris, and
          Breachers. Each game has been adapted for movement on Omni One through
          close collaboration with the game’s developers, ensuring seamless and
          highly immersive gameplay.
        </p>
        <p className="news-articles-body">
          Virtuix CEO Jan Goetgeluk expressed excitement about Omni One’s
          upcoming launch, stating, "The high caliber of our launch games
          showcases how Omni One will take virtual reality to the next level. We
          are proud to partner with so many award-winning studios and offer our
          users an exceptional selection of games that will redefine their
          gaming experiences."
        </p>
        <p className="news-articles-body">
          Jake Zim, Senior Vice President of Virtual Reality at Sony Pictures
          Entertainment and publisher of the recently released game
          Ghostbusters: Rise of the Ghost Lord, said: "As Sony Pictures VR
          continues to expand the game with new updates and content for fans, we
          are eager to collaborate with Virtuix. Bringing the game to the Omni
          One enables us to offer even more groundbreaking ways for players to
          dive deep into the evolving Ghostbusters universe. This initiative
          aligns with the theatrical release of Ghostbusters: Frozen Empire,
          ensuring our game evolves in tandem with the saga’s cinematic
          journey."
        </p>
        <p className="news-articles-body">
          The complete lineup of 35 games includes a wide range of offerings,
          from action-packed adventures to immersive simulations, ensuring
          there's something for every gaming enthusiast. While these game titles
          mark the initial wave, Virtuix will continuously enrich the Omni One
          experience by introducing additional titles post-launch. To learn more
          about Omni One’s launch games and explore the full lineup, visit{" "}
          <a
            href="https://shop.virtuix.com/games"
            className="news-articles-link"
            target="_blank"
            rel="noreferrer"
          >
            shop.virtuix.com/games
          </a>
          .
        </p>
        <p className="news-articles-body">
          Virtuix has garnered significant interest with several thousand
          preorders for Omni One, priced at $2,595 inclusive of the customized
          VR headset. The company is on track to produce all preorders during Q1
          and Q2 of this year, leading up to the full launch of Omni One in late
          Q2 2024. For more information and to preorder your Omni One unit,
          visit{" "}
          <a
            href="https://shop.virtuix.com/"
            className="news-articles-link"
            target="_blank"
            rel="noreferrer"
          >
            shop.virtuix.com
          </a>
          .
        </p>
        <p className="news-articles-body">
          <b>Complete Game Lineup for Omni One Launch:</b>
        </p>
        <p className="news-articles-body">
          <ol className="news-articles-list-indent">
            <li className="news-articles-margin">AFFECTED: The Manor</li>
            <li className="news-articles-margin">Ancient Dungeon</li>
            <li className="news-articles-margin">ARK and ADE</li>
            <li className="news-articles-margin">Breachers</li>
            <li className="news-articles-margin">Craft Wars</li>
            <li className="news-articles-margin">Crimen - Mercenary Tales</li>
            <li className="news-articles-margin">Compound</li>
            <li className="news-articles-margin">
              David Slade Mysteries: Case Files
            </li>
            <li className="news-articles-margin">Dead of the Sea</li>
            <li className="news-articles-margin">Dead Zone</li>
            <li className="news-articles-margin">Death Horizon: Reloaded</li>
            <li className="news-articles-margin">
              Doctor Who: The Edge of Time
            </li>
            <li className="news-articles-margin">Darksword: Battle Eternity</li>
            <li className="news-articles-margin">
              DYSCHRONIA: Chronos Alternate
            </li>
            <li className="news-articles-margin">Elite Force</li>
            <li className="news-articles-margin">Eolia</li>
            <li className="news-articles-margin">First Steps</li>
            <li className="news-articles-margin">
              Ghostbusters: Rise of the Ghost Lord
            </li>
            <li className="news-articles-margin">Hubris</li>
            <li className="news-articles-margin">Hunt Together</li>
            <li className="news-articles-margin">Hyperblast</li>
            <li className="news-articles-margin">In Death: Unchained</li>
            <li className="news-articles-margin">Ionia</li>
            <li className="news-articles-margin">Journey To Foundation</li>
            <li className="news-articles-margin">
              Peaky Blinders: The King's Ransom
            </li>
            <li className="news-articles-margin">Primal Hunt</li>
            <li className="news-articles-margin">RUINSMAGUS</li>
            <li className="news-articles-margin">The Exorcist: Legion VR</li>
            <li className="news-articles-margin">The Jade Cipher</li>
            <li className="news-articles-margin">The Patcher</li>
            <li className="news-articles-margin">The Secret Pyramid</li>
            <li className="news-articles-margin">The Twilight Zone VR</li>
            <li className="news-articles-margin">Titanic: A Space Between</li>
            <li className="news-articles-margin">TOTALLY BASEBALL!</li>
            <li className="news-articles-margin">
              Wanderer: The Fragments of Fate
            </li>
          </ol>
        </p>
        <p className="news-articles-body">
          <b>About Virtuix:</b>
        </p>
        <p className="news-articles-body">
          Virtuix Inc. is the developer of “Omni,” the premier brand of
          omni-directional treadmills that enable players to walk and run in 360
          degrees inside video games and other virtual reality applications.
          With a commitment to innovation, Virtuix continues to push the
          boundaries of VR gaming, delivering immersive experiences to users
          worldwide. Founded in 2013 and headquartered in Austin, Texas, Virtuix
          has raised more than $40 million from individual and institutional
          investors. For more information, visit{" "}
          <a
            href="https://www.virtuix.com/"
            className="news-articles-link"
            target="_blank"
            rel="noreferrer"
          >
            www.virtuix.com
          </a>.
        </p>
        <p className="news-articles-body">
        <b>For media inquiries, please contact:</b>
        <br />
        Lauren Premo
        <br />
        Head of Marketing, Virtuix
        <br />
          <a
            href="mailto:press@virtuix.com"
            className="news-articles-link"
            target="_blank"
            rel="noreferrer"
          >
            press@virtuix.com
          </a>
        </p>
        <div className="player-wrapper news-articles-extra-margin-bottom">
          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=4PmrgPpmF4Q"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </>
  );
}

export default NewsArticle12;
