import React from "react";
import { Link } from "react-router-dom";
import "./Hiring.css";
import { Button } from "./Button";

function HiringSection() {
    return (
        <div className="hiring-section-dark-bg">
            <div className="hiring-section-container">
                <h1 className="hiring-section-heading">
                    We are hiring
                </h1>
                <div className="hiring-section-subtitle">
                Join a talented team that is delivering a new wave of immersive entertainment.
                </div>
                <div className="hiring-section-btn-container">
                    <Link to="/careers">
                <Button buttonSize="btn-wide" buttonColor="green">
                    Join the team
                    <div className="button-right-arrow">
                      <img src="/images/arrow-icon-now.svg" alt="arrow" />
                    </div>
                  </Button>
                  </Link>
                </div>
                <img className="hiring-section-img-large" src="/images/oa_enterprise_team_30.jpg" alt="Team working" />
            </div>            
            <hr className="footer-hr" />
        </div>
);
}

export default HiringSection;