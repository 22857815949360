import React from "react";
import "../../Copy.css";

function TermsOfUse() {
  return (
    <>
      <div className="copy-container">
        <h1 className="copy-title">Virtuix Terms of Use</h1>
        <h3 className="copy-subheader">1. General</h3>
        <p className="copy-body">
        Virtuix Inc. (“Virtuix”, “we”, or “us”) provides a variety of products, services, content, and features, including through our website (<a href="https://virtuix.com/" className="copy-link-gray">www.virtuix.com</a>) and its various	subdomains (the "Website"), the Omni One omni-directional treadmill and virtual reality headset and its software platform on which users can download and interact with content and other users (the “Product”), the mobile device application (including Android and IOS applications) (the “App”), the Omni Online membership service (“Omni Online”), Omni One games and other content (“Content”), through certain Virtuix-controlled social media pages (including Virtuix, Instagram, Twitter, TikTok, and YouTube), and through online forums, blogs, and other services (including Discord) (all such products, services, content and features collectively described herein as the “Services”). These Terms of Use (the “Terms”) apply to all Services.
        </p>
        <p className="copy-body">
        When an adult user (or if a user is under the age of majority in the user’s place of residence, the user’s parent or legal guardian on behalf of the minor user, hereinafter “you” or “your”) purchases products from Virtuix, registers an account with Virtuix, or uses any of the Services, you are entering into this agreement with Virtuix, a corporation in the United States, and you agree that your personal information will be processed by Virtuix in order to process your order and provide the Services to you.
        </p>
        <p className="copy-body">
        BY USING THE SERVICES IN ANY WAY, YOU: (A) ACCEPT, AND AGREE TO BE BOUND BY ALL OF THE PROVISIONS OF THESE TERMS, WHICH FORM A BINDING AGREEMENT BETWEEN YOU AND VIRTUIX, AND (B) REPRESENT TO US THAT YOU ARE LEGALLY COMPETENT TO ENTER INTO AND AGREE TO THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS, THEN YOU ARE NOT AUTHORIZED TO USE ANY OF VIRTUIX'S SERVICES. THESE TERMS INCLUDE (1) AN ARBITRATION PROVISION; (2) A WAIVER OF RIGHTS TO BRING A CLASS ACTION AGAINST US; AND (3) A RELEASE BY YOU OF CERTAIN CLAIMS FOR DAMAGE AGAINST US THAT MAY ARISE OUT OF YOUR USE OF THE SERVICES. BY USING ANY OF THE SERVICES, YOU AGREE TO THESE PROVISIONS. <span className="copy-underline">You have the right to opt-out of arbitration as explained in Section 19.</span> 
        </p>
        <p className="copy-body">
        For purposes of these Terms, the user of the Services shall herein be addressed as you and your. If you use the Services on behalf of a company, organization, or other entity, then (a) you includes you and that entity, and (b) you represent and warrant that you are an authorized representative of that entity with the authority to bind that entity to these Terms, and that you agree to these Terms of Use on that entity’s behalf, and (c) your entity is legally and financially responsible for your use of the Services as well as for the use of your account by others affiliated with your entity, including any employees, agents, or contractors. 
        </p>
        <p className="copy-body">
        Please review the following carefully so that you understand these Terms. These Terms describe your responsibilities, Virtuix’s liability, and the liability of third parties related to the Services. All users of Virtuix's Services must accept and comply with the terms and conditions set forth in these Terms. If you do not wish to be bound by these Terms, you may not access or use the Services. Certain portions of the Services may be subject to additional terms and conditions specified by us from time to time; your use of those portions of the Services is subject to those additional terms and conditions. If you have any questions regarding these Terms, please contact us via email at <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a>.
        </p>
        <p className="copy-body">
        We reserve the right to change or modify these Terms on a go-forward basis at any time and at our sole discretion. If we make changes to these Terms, we may provide notice of such changes as appropriate, such as by sending an email notification to the address you've provided, or we may provide notice by updating the "Last Updated" date at the bottom of these Terms, which are available at  <a href="#lastupdated" className="copy-link-gray">https://virtuix.com/terms-of-use</a>.
        </p>
        <p className="copy-body">
        Your continued use of the Services will confirm your acceptance of the revised Terms. If you do not agree to the revised Terms, you must stop using the Services and delete your account. Please review the Terms from time to time to ensure that you understand the terms and conditions that apply to your access to, and use of, the Services.
        </p>
        <p className="copy-body">
        To provide the Services, we must collect and use your personal data as described in our Privacy Policy, which is available <a href="https://virtuix.com/privacy-policy" className="copy-link-gray">here</a>.
        </p>
        <h3 className="copy-subheader">2. Eligibility, Registration, and Account</h3>
        <p className="copy-body">
        The Services are intended solely for users who are aged 13 years or older. Any registration for, or use of, the Services by anyone under the age of 13 years is unauthorized and unlicensed and breaches these Terms. If you are at least 13 but under the age of majority in your jurisdiction, your parent or guardian must agree to these Terms on your behalf before you use the Services. Access to, or use of the Services by anyone under the age of 18 without the express permission of their parent or legal guardian is unauthorized, unlicensed, and in violation of these Terms. By using the Services you represent and warrant that you are 18 or older, or that your parent or guardian has registered an Account, has agreed to these Terms,  has expressly given you permission to use the Services and that you agree to abide by these Terms.
        </p>
        <p className="copy-body">
        You represent and warrant that you (a) are not identified on the Office of Foreign Assets Control's Specially Designated Nationals List ("SDN List"), (b) are not placed on the US Department of Commerce's Denied Persons List or Entity List or any other US export control list, (c) will not use IP proxying or other methods to disguise the place of your residence, (d) will not use the Services if any applicable laws in your country prohibit you from doing so in accordance with these Terms and (e) have not previously had your right to use the Services suspended or terminated.
        </p>
        <p className="copy-body">
        To access and use certain features of the Services, you will be required to register for an account. By creating an account, you agree to: (i) provide accurate, current and complete account information; (ii) maintain the security of your password, not share your password with any other person and accept all risks of unauthorized access to your account; and (iii) promptly provide notice at <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a> if you discover or otherwise suspect any security breaches related to the Services.
        </p>
        <p className="copy-body">
        We want everyone to feel safe while they enjoy an immersive virtual experience. When accessing and using the Services, you agree to adhere to our community standards (the “Community Standards”), which outline what is and is not allowed when using the Services. Please read our Community Standards <a href="https://virtuix.com/community-standards" className="copy-link-gray">here</a>. You represent and warrant that you have read, understand, and agree with our Community Standards. 
        </p>
        <p className="copy-body">
        The Services are to be used solely for personal and non-commercial use and as demonstrated and intended, in accordance with its authorized and accompanying instruction materials, including its requirements regarding weight and height of the user of the Product. You acknowledge that the Product is a physical device, and using the Product requires physical exercise.
        </p>
        <p className="copy-body">
        <b>THERE ARE IMPORTANT HEALTH AND SAFETY WARNINGS AND INSTRUCTIONS (THE “INSTRUCTIONS”), WHICH ARE AVAILABLE <a href="https://virtuix.com/health-and-safety-warnings-and-instructions" className="copy-link-gray">HERE</a>, THAT YOU MUST READ BEFORE USING THE SERVICES. BY USING THE SERVICES, YOU REPRESENT AND WARRANT THAT YOU HAVE READ AND UNDERSTAND THE INSTRUCTIONS. IF YOU PERMIT ANY HOUSEHOLD MEMBERS OR GUESTS OR ANYONE ELSE OTHER THAN YOU (“GUEST USERS”) TO USE YOUR PRODUCT, YOU REPRESENT AND WARRANT THAT YOU WILL INFORM THEM OF THESE INSTRUCTIONS AND ENFORCE THAT THEY ADHERE TO THEM.</b>
        </p>
        <p className="copy-body">
        For the avoidance of doubt, you agree that you or any Guest Users will not use the Product if you or they are under the age of 13, pregnant, intoxicated, epileptic, or recovering from major surgery, illness, or a heart attack, and you acknowledge that the Product is intended only for healthy users over the age of 13 who have a height of between 4’4” (132 cm) and 6’4” (192 cm) and weigh not more than 250 lbs (113 kg). You agree that you or any Guest Users will not use the Product if you or they do not meet the height and weight requirements, or if you or they are not healthy enough to engage in moderate to heavy physical exercise. You or they should consult a physician or general practitioner before beginning to use the Product in any way.
        </p>
        <p className="copy-body">
        You represent, warrant, and agree to never allow other people (particularly children) and pets in the immediate vicinity of the Product while you or any Guest Users are using the Product. You understand that people or animals may be struck with a handheld controller or with the rotating arm of the Omni One treadmill, or they may attempt to climb onto the platform.
        </p>
        <p className="copy-body">
        Virtuix may from time to time recommend or even require that you replace certain parts of the Omni One product when reaching certain usage milestones. You agree to replace those parts promptly per Virtuix’s instructions, and you acknowledge that not doing so will void any product warranties and may result in an unsafe and malfunctioning Product that may cause serious bodily injury.
        </p>
        <p className="copy-body">
        <i>If we determine, at our sole discretion, that you use the Services in a commercial setting or for commercial use, or that you present a health and safety risk, or that you violate our Community Standards, any laws or regulations, agreements, or any other Terms, we reserve the right to terminate or suspend your account and access to the Services at any time.</i>
        </p>
        <p className="copy-body">
        <i>If we terminate or suspend your account, your license to use any software or content provided in connection with the Services is also terminated or suspended (as applicable). If your account is terminated, Virtuix has the right to immediately delete all data, files, and other information stored in or for your account without further notice to you. By using the Services, you agree Virtuix will not incur any liability or responsibility if it chooses to remove, disable or delete your access or ability to use any or all portion(s) of the Services. You must pay all charges up to and including the date of termination. Should you wish to resume your account after any suspension, a restoration of service fee may apply. This fee is due in addition to all past due unpaid charges and other fees.</i>
        </p>
        <h3 className="copy-subheader">3. Equipment, Software and Updates</h3>
        <p className="copy-body">
        Certain equipment and software may be required to access and use the Services. In addition, Virtuix may need to automatically update some of the software you obtain through the Services or provide you with new software to keep the Services functioning properly, which could include bug fixes, patches, enhanced features, missing plug-ins and new versions. By using the Services, you agree to such automatic updating.
        </p>
        <h3 className="copy-subheader">4. Your Use of the Services and Content</h3>
        <p className="copy-body">
        4.1 <span className="copy-underline">Content and Software License</span>. Except as otherwise agreed upon, if Virtuix enables the use of software, content, virtual items or other materials owned or licensed by us ("Software and Content"), we hereby grant you a limited, non-exclusive, non-sublicensable license to access, install and use the Software and Content solely for personal and non-commercial purposes, conditioned on your compliance with these Terms. You will not use, copy, adapt, modify, decompile, reverse-engineer, disassemble, decrypt, attempt to derive the source code of, prepare derivative works based upon, distribute, license, sell, rent, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Software and Content, except as expressly permitted by us or as permitted under applicable law. Any unauthorized use of the Services is strictly prohibited and will terminate the license granted in these Terms. No licenses or rights are granted to you by implication or otherwise, except for the licenses and rights expressly granted to you.
        </p>
        <p className="copy-body">
        4.2 <span className="copy-underline">Third-party Content</span>. Your use of services, applications or content provided by third parties ("Third-party Content") made available through the Services may be subject to additional end-user agreements. In the event that these agreements conflict with the provisions of these Terms, these Terms will govern. Virtuix has no responsibility or liability with respect to your access to or use of the Third-party Content, or any content or functionality contained in such Third-party Content, your rights to which are solely provided pursuant to a license between you and the provider of such Third-party Content. In no event shall Virtuix be considered the licensor of the Third-party Content, to have granted any rights to use the Third-party Content, to have assumed any obligations with respect to the Third-party Content or to have made any representations or warranties with respect to the Third-party Content.
        </p>
        <p className="copy-body">
        If you are not presented with an end-user license agreement when you acquire Third-party Content, the following license terms apply to your use of such Third-party Content: (a) the third party providing the Third-party Content (and not Virtuix) is the licensor of such Third-party Content; (b) such party grants you a limited, non-transferable license to access and use the Third-party Content only for your personal and non-commercial purposes; and (c) you may not modify, decompile or disassemble the Third-party Content in whole or in part, or create any derivative works from or sublicense any rights in or to the Third-party Content, unless otherwise expressly authorized by the third party or as permitted under applicable law.
        </p>
        <p className="copy-body">
        If a provider of Third-party Content violates any of our policies, we may remove or disable that Third-party Content. To the extent permitted by applicable law, we will not incur any liability or responsibility for such removal or disabling of Third-party Content.
        </p>
        <p className="copy-body">
        4.3 <span className="copy-underline">Trial Access to Services</span>. We may offer free trials or other limited versions of Services so that you can preview Services before you purchase the full version. These versions may have limited features, restrict the permitted time of use and contain other limitations.
        </p>
        <p className="copy-body">
        4.4 <span className="copy-underline">Availability of Services After Purchase and Updates</span>. Some Services may rely on services provided by third parties for some or all of their functionality. Such Services may not function properly or may become inoperable if these third parties discontinue their services.
        </p>
        <p className="copy-body">
        4.5 <span className="copy-underline">Support</span>. Virtuix will be responsible for all billing questions related to the purchase of Services and for support with respect to the Virtuix platform. Virtuix will provide customer support during normal business hours and in the U.S. only. Virtuix has no obligation to provide support for Third-party Content. Please contact us at <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a> for assistance.
        </p>
        <p className="copy-body">
        4.6 <span className="copy-underline">Availability</span>. The Services and content may not be available in all territories and jurisdictions, and we may restrict or prohibit use of all or a portion of the Services and content in certain territories and jurisdictions.
        </p>
        <p className="copy-body">
        4.7 <span className="copy-underline">Network Costs</span>. You may be charged by your network provider for data services or any other third-party charges that may arise while using the Services, and you accept responsibility for such charges. If you are not the bill payer, you must have received prior permission from the bill payer.
        </p>
        <p className="copy-body">
        4.8 <span className="copy-underline">Developer Portal</span>. The Website includes a portal where content developers can submit content to Virtuix to make such content available in our Store (as defined in the Store Terms) and to manage such submitted content (the “Developer Portal”). All content submissions to the Developer Portal are subject to our <a href="https://virtuix.com/content-submission-terms" className="copy-link-gray">Content Submission Terms</a>. Any content that will be published in our Store is subject to the Omni One Publisher Licensing Agreement.
        </p>
        <h3 className="copy-subheader">5. Purchasing Services</h3>
        <p className="copy-body">
        5.1 <span className="copy-underline">Purchasing Services From Virtuix</span>. You may only purchase Services for your personal use or to give as a gift unless otherwise expressly permitted in these Terms. You may not purchase Services from Virtuix for commercial use or resale, although you may use the Services to develop and test content, software or applications intended for distribution by Virtuix and our affiliates.
        </p>
        <p className="copy-body">
        When placing an order or making a purchase, you will be required to provide us with information, such as your address and billing information. You represent and warrant that all such information is accurate, and you will ensure that such information is kept up to date. We will have no responsibility or liability for information you provide that is inaccurate or that later becomes outdated, and we will have no obligation to make efforts to determine the correct contact or delivery information. For most Services, you can manage your information within your account settings. 
        </p>
        <p className="copy-body">
        5.2 <span className="copy-underline">Pre-orders</span>. In advance of a new product launch, it may be possible to place pre-orders. The price of the product you pre-order will be as quoted to you at the time you submit your pre-order, and may include tax and delivery when applicable. Placing a pre-order does not guarantee delivery of the product.
        </p>
        <p className="copy-body">
        5.3 <span className="copy-underline">Our Right to Reject your Order</span>. At any time prior to acceptance of the order, we reserve the right to decline or reject your order. If this occurs, we will attempt to notify you. Some reasons for rejection can include: (a) our inability to supply you with the product, for example, because of an error in the price at the point of sale; (b) your non-residence in a country or region from which the product may be purchased; or (c) your ordering more than the permitted maximum number of Services. If you have already paid, we will refund you the full amount, including any delivery costs charged.
        </p>
        <p className="copy-body">
        5.4 <span className="copy-underline">Content Transactions</span>. You may have the ability to purchase digital content through the Services, including on a membership or subscription basis. You also may have the ability to purchase additional or enhanced functionality or media content within certain Services (collectively, "In-app Purchases"). Except as described in these Terms, we have no responsibility for any transactions you enter into with a third party for Third-party Content including subscriptions or In-app Purchases and assume no liability for Third-party Content or Third-party In-app Purchases that occur within Third-party Content.
        </p>
        <p className="copy-body">
        The terms of all content transactions are governed by the Omni One Store Terms, which can be found <a href="https://virtuix.com/omni-one-store-terms" className="copy-link-gray">here</a> and which are hereby incorporated by reference into these Terms.
        </p>
        <p className="copy-body">
        5.5 <span className="copy-underline">Errors</span>. We attempt to be as accurate as possible and to eliminate errors in relation to our Services; however, we do not represent or warrant that any product descriptions, pricing or other information are accurate, complete, reliable, current or error-free. In the event of an error, we reserve the right to correct such error and revise your order accordingly (which includes charging the correct price) or to cancel the order and refund any amount charged. If we discover a pricing or other material error related to a product that has yet to be dispatched or delivered, we will contact you to inform you of this error and give you the option of continuing to purchase at the correct price or cancelling your order. If we are not able to contact you using the contact details you provided during the order process, we will treat the order as cancelled.
        </p>
        <p className="copy-body">
        5.6 <span className="copy-underline">Account</span>. You must be a registered user to purchase Services. You are responsible for all charges incurred in connection with your account. We may attempt to collect unpaid charges, including by attempting additional charges to your payment instrument, the use of collections agencies and any other legal means. If you decide to cancel your account, we reserve the right, subject to any limitations under applicable laws, to collect fees, surcharges or costs incurred before cancellation. Any overdue or unpaid accounts must be settled before we will allow you to register again.
        </p>
        <p className="copy-body">
        5.7 <span className="copy-underline">Pricing and Payment</span>. We may accept various forms of payment, including credit and debit cards and payments made through PayPal. Additional terms with your payment provider may apply.
        </p>
        <p className="copy-body">
        By submitting an order or pre-order, you acknowledge that you are authorized to use the designated payment method and you authorize us to charge your order to that payment method. When you provide your payment information, you authorize us (or a third-party payment processor) to process and store your payment and related information. Depending on where you are located or ask to have the Services dispatched or delivered, Virtuix may utilize an agent, subsidiary or affiliate to process payment and delivery. In the event that the payment method you designate cannot be verified, is invalid or is not otherwise acceptable, we may suspend or cancel your order. You are responsible for resolving any problems we encounter in order to proceed with your order.
        </p>
        <p className="copy-body">
        Prices are subject to change without notice. We reserve the right to refuse or cancel orders at any time and at our sole discretion.
        </p>
        <p className="copy-body">
        5.8 <span className="copy-underline">Taxes</span>. If your purchase or use of the Services is subject to any type of use or sales tax, duty or other governmental tax or fee ("Taxes"), then we may charge you for those Taxes. Applicable Taxes may be presented at checkout. You are responsible for any Taxes due with respect to your purchases and use of the Services.
        </p>
        <p className="copy-body">
        5.9 <span className="copy-underline">Content Cancellations and Returns</span>. All purchases of digital content are final, except as required by law, or as described in our Refund Policy. Once you have purchased content, we encourage you to download, install and/or access it promptly. You consent that the supply of the digital content may begin immediately following the completion of your purchase, and you acknowledge that you will therefore lose any statutory rights you may have to withdraw and receive a refund. The laws of some jurisdictions do not allow the disclaimer or removal of certain statutory rights, so this waiver of statutory rights may not apply to you. If you are not able to download, install or access purchased content, please contact us at <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a>. 
        </p>
        <p className="copy-body">
        5.10 <span className="copy-underline">Promotional Codes</span>. We may from time to time offer certain promotional codes, referral codes, discount codes, coupon codes or similar offers (“Promotional Codes”) that may be redeemed for discounts on certain of the Services, subject to any additional terms that Virtuix establishes. You agree that Promotional Codes: (a) must be used in a lawful manner; (b) must be used for the intended audience and purpose; (c) may not be duplicated, sold or transferred in any manner, or made publicly available by you (whether posted to a public forum, coupon collecting service, or otherwise), unless expressly permitted by Virtuix; (d) may be disabled or have additional conditions applied to them by Virtuix at any time for any reason without liability to Virtuix; (e) may only be used pursuant to the specific terms that Virtuix establishes for such Promotional Code; (f) are not valid for cash or other credits or points.
        </p>
        <p className="copy-body">
        5.11 <span className="copy-underline">Gift Cards</span>. Digital gift cards containing stored money value, may be offered by us for the purchase of Services (“Gift Cards”). You acknowledge that Virtuix does not make any warranties with respect to your Gift Card balance and is not responsible for any unauthorized access to, or alteration, theft, or destruction of a Gift Card or Gift Card code that results from any action by you or a third party. You also acknowledge that we may suspend or prohibit use of your Gift Card if your Gift Card or Gift Card code has been reported lost or stolen, or if we believe your Gift Card balance is being used suspiciously, fraudulently, or in an otherwise unauthorized manner. If your Gift Card code stops working, your only remedy is for us to issue you a replacement Gift Card code. By purchasing a Gift Card, you represent and warrant to Virtuix that your possession and use of the Gift Card, and the possession and use of the Gift Card by any person you permit to possess or use the Gift Card, will in each case comply with these Terms and all applicable laws, rules and regulations, and you will not use, or permit any other person to use, any Gift Card in any manner that is misleading, deceptive, unfair or otherwise harmful to consumers. Gift Cards cannot be used to purchase other gift cards, resold, used for the purchase of Products outside of the Services, used for unauthorized marketing, sweepstakes, advertising, or other promotional purposes, redeemed for more than face value, transferred for value, redeemed for cash, or returned for a cash refund (except to the extent required by law). 
        </p>
        <p className="copy-body">
        5.12 <span className="copy-underline">Omni Online</span>. If you purchase an Omni Online membership or otherwise enroll in Omni Online, you agree to the <a href="https://virtuix.com/omni-online-membership-terms" className="copy-link-gray">Omni Online Membership Terms</a>, which supplement and form a part of these Terms.
        </p>
        <h3 className="copy-subheader">6. Acceptable Use</h3>
        <p className="copy-body">
        By accessing or using the Services, you agree that you will not: (a) access or use the Services in any manner that could interfere with, disrupt, negatively affect or inhibit anyone from fully enjoying the Services, including, but not limited to, defamatory, harassing, threatening, bigoted, hateful, vulgar, obscene, pornographic or otherwise offensive behavior or content; (b) damage, disable, overburden or impair the functionality of the Services in any manner; (c) access or use the Services for any illegal or unauthorized purpose or engage in, encourage or promote any illegal activity, or any activity that breaches or violates these Terms, our <a href="https://virtuix.com/community-standards" className="copy-link-gray">Community Standards</a>, or any other terms or policies provided in connection with the Services; (d) use or attempt to use another user's account without authorization from such user; (e) modify, adapt, hack or emulate the Services; (f) use any robot, spider, crawler, scraper or other automated means or interface not provided or authorized by us to access the Services or to extract data; (g) circumvent or attempt to circumvent any filtering, security measures or other features designed to protect the Services or third parties; or (h) infringe upon or violate our rights or the rights of our users or any third party.
        </p>
        <h3 className="copy-subheader">7. User Content</h3>
        <p className="copy-body">
        Our Services may include interactive features and areas where you may submit, post, upload, publish, email, send, otherwise transmit or interact with content, including, but not limited to, text, images, photos, videos, sounds, virtual reality environments or features, software and other information and materials (collectively, "User Content"). For the avoidance of doubt, User Content does not include any content submitted via the Developer Portal (“Developer Content”). Unless otherwise agreed, we do not claim any ownership rights in or to your User Content. If you use content covered by intellectual property rights that we have made available through our Services (for example, images, designs, videos or sounds we provide that you add to content you create or share on Services), we retain all rights to that content (but not yours). 
        </p>
        <p className="copy-body">
        By submitting User Content through the Services, you grant us a worldwide, non-exclusive, transferable, perpetual, royalty-free and fully sublicensable (i.e. we can grant this right to others) right to use, copy, display, store, adapt, publicly perform and distribute such User Content in connection with the Services (subject to applicable Privacy Settings). 
        </p>
        <p className="copy-body">
        We may also use your or your Guest Users’ gameplay footage, including your or your Guest Users’ gameplay recording, voice recording, visual images, and likeness (“Gameplay Footage”) for marketing purposes. You grant us permission to use Gameplay Footage, and such permission extends into perpetuity. For a valuable consideration, receipt of which is hereby acknowledged, you hereby grant us all ownership rights and the absolute and irrevocable right and permission to copyright, use and publish your Gameplay Footage which has been obtained pursuant to this paragraph. The Gameplay Footage may be copyrighted, used and/or published individually or in conjunction with other photography or video works, and in any medium and for any lawful purpose, including without limitation, trade, exhibition, illustration, promotion, publicity, advertising and electronic publication.
        </p>
        <p className="copy-body">
        You are solely responsible for the User Content you make available through the Services, and you represent and warrant that (a) either you are the sole and exclusive rights owner of all User Content that you provide, or you have obtained all rights, licenses, permissions, consents and releases that are necessary to grant to us the rights specified in this section; (b) the provision of your User Content, and our subsequent use of such User Content, will not infringe, misappropriate or violate any third party's patent, copyright, trademark, trade secret, moral rights or other proprietary or intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable laws or regulations; and (c) your User Content does not violate our <a href="https://virtuix.com/community-standards" className="copy-link-gray">Community Standards</a>.
        </p>
        <p className="copy-body">
        We do not endorse or guarantee the opinions, views, advice or recommendations posted or sent by you or any other users. Virtuix has no responsibility or liability for User Content made available through the Services, and we have no obligation to screen, edit or monitor such content. However, we do reserve the right, and have absolute discretion, to remove, screen or edit User Content at any time and for any reason, including content that infringes intellectual property rights or otherwise breaches these Terms.
        </p>
        <p className="copy-body">
        In the event that you are a developer who submits User Content to, or in connection with, the Services, you acknowledge and agree that our agreements with you as a developer may supersede this section of the Terms.
        </p>
        <h3 className="copy-subheader">8. Privacy Policy</h3>
        <p className="copy-body">
        By your use of the Services, you consent to our collection and use of your data as governed by our <a href="https://virtuix.com/privacy-policy" className="copy-link-gray">Privacy Policy</a>, which is incorporated by reference into these Terms. The Privacy Policy sets forth how we treat data, including how we collect, use and disclose information.  
        </p>
        <p className="copy-body">
        In addition, certain third parties (such as developers of Third-party Content and the manufacturers of your computer, mobile phone or other devices) may collect information about you when you use the Services. These third parties have their own privacy policies and will treat the information they collect about you according to these policies. We encourage you to review these privacy policies before providing any information to third parties. We are not responsible for the accuracy of any third party's privacy policy or for ensuring that third parties comply with their privacy policies.
        </p>
        <h3 className="copy-subheader">9. Additional Terms</h3>
        <p className="copy-body">
        These Terms are in addition to, and do not replace or change, any other agreements you enter into with us or our affiliates, which may include but are not limited to our Publisher Licensing Agreement, content end-user licensing agreements, <a href="https://virtuix.com/omni-one-sdk-eula" className="copy-link-gray">Omni One SDK end-user licensing agreement</a>, <a href="https://virtuix.com/omni-online-membership-terms" className="copy-link-gray">Omni Online Membership Agreement</a>, <a href="https://virtuix.com/community-standards" className="copy-link-gray">Community Standards</a>, <a href="https://virtuix.com/content-submission-terms" className="copy-link-gray">Content Submission Terms</a>, and contest, competition or promotion-related terms. In addition, if you purchase physical goods from us, the <a href="https://virtuix.com/terms-of-sale" className="copy-link-gray">Terms of Sale Agreement</a> for such purchase will apply.
        </p>
        <p className="copy-body">
        Your purchase and use of Third-party Content may be subject to additional terms, including but not limited to third-party end-user agreements and privacy policies. We encourage you to review any third-party agreements and policies carefully before accessing, downloading or using Third-party Content.
        </p>
        <h3 className="copy-subheader">10. Ratings</h3>
        <p className="copy-body">
        We may display age, comfort and content ratings for digital content that are based on information provided to us by the developers of such content. We cannot guarantee that digital content ratings will always be accurate, nor can we promise that you will not find some material harmful, offensive, indecent or objectionable.
        </p>
        <h3 className="copy-subheader">11. Ownership and Intellectual Property</h3>
        <p className="copy-body">
        Unless otherwise indicated, the Services are the property of Virtuix, our affiliates or our licensors and are protected by copyright, trademark and other laws of the United States and foreign countries. You will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services.
        </p>
        <p className="copy-body">
        Virtuix, Virtuix Omni, Omniverse, Omni Connect, Omni VR, Omni One, Omni Online and the Virtuix and Omni logos ("Virtuix Marks") are trademarks or registered trademarks of Virtuix. The Virtuix Marks and those of its affiliates may not be copied, imitated or used, in whole or in part, without prior written permission. All other trademarks, service marks, logos, trade names and any other proprietary designations are the trademarks or registered trademarks of their respective owners and may not be used without permission of the applicable trademark holder.
        </p>
        <h3 className="copy-subheader">12. Submissions</h3>
        <p className="copy-body">
        You may submit questions, comments, suggestions, ideas, plans, notes, drawings, original or creative materials or other feedback about us and our Services (collectively, "Submissions"). The Submissions do not include any content submitted via the Developer Portal, which is subject to the Content Submission Terms.
        </p>
        <p className="copy-body">
        Any and all Submissions, and all improvements, updates, modifications, or enhancements, whether made, created or developed by you or Virtuix or otherwise relating to the Services (collectively Revisions), are and will remain the property of Virtuix. You acknowledge and expressly agree that any contribution of Submissions or Revisions does not and will not give or grant you any right, title, or interest in the Services or in any such Submissions or Revisions. All Submissions and Revisions become the sole and exclusive property of Virtuix, and Virtuix may use and disclose Submissions and/or Revisions in any manner and for any purpose whatsoever, without further notice or compensation to you and without retention by you of any proprietary or other right or claim. You hereby assign to Virtuix any and all right, title, and interest (including, but not limited to, any patent, copyright, trade secret, trademark, show-how, know-how, moral rights, and any and all other intellectual property right) that you may have in, and to any and all, Submissions and Revisions. At Virtuix’s request, you will execute any document, registration, or filing required to give effect to the foregoing assignment.  If you fail or refuse to execute any such document, you will be deemed to have given your power of attorney to Virtuix to execute any such document on your behalf.
        </p>
        <h3 className="copy-subheader">13. Export Compliance and US Government Rights</h3>
        <p className="copy-body">
        You will comply with all applicable export control laws of the United States and any other applicable governmental authority, including without limitation, the US Export Administration Regulations ("Export Laws"). You will not, directly or indirectly, export, re-export or download the Services: (a) to any individual, entity or country prohibited by Export Laws, including by any US sanctions program; (b) to anyone on the SDN List, the US Denied Persons List or Entity List or other export control lists; or (c) for any purpose prohibited by Export Laws, including nuclear, chemical or biological weapons proliferation or the development of missile technology. You further represent and warrant that no US federal agency has suspended, revoked or denied your export privileges and you are not listed on the SDN List.
        </p>
        <p className="copy-body">
        The Services, including all related software, technical data and documentation, are "Commercially-available off-the-shelf (COTS) items" as this term is defined in Section 2.101 of Title 48 of the US Code of Federal Regulations (USC). We provide the Services for US Government end-use solely in accordance with the following: (a) the Services are licensed only as COTS items; and (b) the US Government will have the same rights as all other end users pursuant to these Terms. This customary commercial license is provided in accordance with FAR 12.211 (Technical Data) and FAR 12.212 (Software) and, for US Department of Defense transactions, DFARS 252.227-7015 (Technical Data – Commercial Items) and DFARS 227.7202-3 (Rights in Commercial Computer Software or Computer Software Documentation). If a US Government agency has a need for any additional rights, it must negotiate directly with Virtuix to determine if the parties can negotiate an acceptable amendment to these Terms that must be included in any applicable contract or agreement.
        </p>
        <h3 className="copy-subheader">14. Intellectual Property Reports</h3>
        <p className="copy-body">
        In accordance with the Digital Millennium Copyright Act ("DMCA") and other applicable laws, we have adopted a policy of terminating, in appropriate circumstances and at our sole discretion, accounts of users who are deemed to be repeat infringers. We may also, at our sole discretion, limit access to the Services and/or terminate the accounts of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.
        </p>
        <p className="copy-body">
        If you believe that anything on or available through the Services infringes upon any copyright which you own or control, you may file a notification of such infringement at <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a>. If you knowingly misrepresent in your notification that the material or activity is infringing, you will be liable for any damages, including costs and legal fees, incurred by us or the alleged infringer as the result of our relying upon such misrepresentation in removing or disabling access to the material or activity claimed to be infringing.
        </p>
        <h3 className="copy-subheader">15. Hyperlinks</h3>
        <p className="copy-body">
        You are granted a limited, non-exclusive right to create text links to our websites for non-commercial purposes; however, you may not use our logos, Virtuix Marks or other proprietary graphics to link to our sites without our express written permission.
        </p>
        <p className="copy-body">
        The Services may contain links to third-party websites, applications or other third-party services, and you understand that by accessing third-party links, you may be exposed to content that is offensive, harmful, inaccurate or otherwise inappropriate. You understand and agree that we are not responsible or liable for the availability or accuracy of such third-party properties or the content, Services or services made available through such properties. We do not endorse or control such third-party properties and we make no representations or warranties of any kind regarding such properties. If you access or use any third-party properties, you should also be aware that such third parties' terms and policies will govern.
        </p>
        <h3 className="copy-subheader">16. Disclaimers</h3>
        <p className="copy-body">
        <b>16.1 BY USING THE SERVICES, YOU REPRESENT AND WARRANT THAT YOU HAVE READ AND UNDERSTAND THE <a href="https://virtuix.com/health-and-safety-warnings-and-instructions" className="copy-link-gray">HEALTH AND SAFETY WARNINGS AND INSTRUCTIONS</a>. WE MAY UPDATE OR REVISE THESE INSTRUCTIONS, SO PLEASE REVIEW THEM PERIODICALLY. ADDITIONAL HEALTH AND SAFETY WARNINGS AND INSTRUCTIONS MAY BE PROVIDED BY DEVELOPERS IN RELATION TO SPECIFIC THIRD-PARTY CONTENT. YOU REPRESENT AND WARRANT THAT YOU WILL READ ALL HEALTH AND SAFETY WARNINGS AND INSTRUCTIONS BEFORE USING THE SERVICES. YOU REPRESENT AND WARRANT THAT YOU WILL INFORM ANY GUEST USERS OF THESE INSTRUCTIONS AND ENFORCE THAT THEY ADHERE TO THEM.</b>
        </p>
        <p className="copy-body">
        <b>16.2 YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH THE SERVICES AND WITH OTHER USERS OF THE SERVICES. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SERVICES ARE AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY, AND EFFORT IS WITH YOU. </b>
        </p>
        <p className="copy-body">
        <b>EXCEPT AS EXPRESSLY SET FORTH IN OMNI ONE’S LIMITED WARRANTY POLICY, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES (INCLUDING WITHOUT LIMITATION ANY THIRD-PARTY MATERIALS, SOFTWARE, OR SERVICES) ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND—VIRTUIX AND ITS AFFILIATES AND THIRD-PARTY SUPPLIERS HEREBY EXPRESSLY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, QUIET ENJOYMENT, TITLE, AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, AND ALL WARRANTIES IMPLIED FROM ANY COURSE OF DEALING OR USAGE OF TRADE. </b>
        </p>
        <p className="copy-body">
        <b>VIRTUIX DOES NOT WARRANT THAT (A) THE SERVICES WILL MEET YOUR REQUIREMENTS, (B) THE OPERATION OF THE SERVICES WILL BE UNINTERRUPTED OR VIRUS- OR ERROR-FREE, (C) THAT THE SERVICES WILL OPERATE OR BE COMPATIBLE WITH ANY OTHER APPLICATION OR ANY PARTICULAR SYSTEM OR DEVICE, OR (D) DEFECTS IN THE SERVICES CAN OR WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION, OR ADVICE PROVIDED BY VIRTUIX OR ITS AUTHORIZED REPRESENTATIVE SHALL BE DEEMED TO CREATE ANY WARRANTY. ANY OTHER REPRESENTATIONS OR WARRANTIES MADE BY ANY PERSON OR ENTITY, INCLUDING EMPLOYEES OF VIRTUIX, THAT ARE INCONSISTENT HEREWITH SHALL BE DISREGARDED AND SHALL NOT BE BINDING UPON VIRTUIX AND ITS AFFILIATES.</b>
        </p>
        <p className="copy-body">
        <b>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. TO THE EXTENT THAT ANOTHER PARTY MAY HAVE ACCESS TO OR VIEW CONTENT ON YOUR DEVICE, YOU ARE SOLELY RESPONSIBLE FOR INFORMING SUCH PARTY OF ALL DISCLAIMERS AND WARNINGS IN THESE TERMS.</b>
        </p>
        <p className="copy-body">
        <b>TO THE EXTENT ANY DISCLAIMER OR LIMITATION OF LIABILITY DOES NOT APPLY TO THE FULLEST EXTENT PERMITTED BY LAW, ALL APPLICABLE EXPRESS, IMPLIED, AND STATUTORY WARRANTIES WILL BE LIMITED IN DURATION TO A PERIOD OF 30 DAYS AFTER THE DATE ON WHICH YOU FIRST USED THE SERVICES, AND NO WARRANTIES SHALL APPLY AFTER SUCH PERIOD.</b>
        </p>
        <p className="copy-body">
        <b>YOU SHOULD CONSULT YOUR PHYSICIAN OR GENERAL PRACTITIONER BEFORE BEGINNING TO USE THE SERVICES IN ANY WAY. THE SERVICES OFFERS ARE DESIGNED FOR EDUCATIONAL AND ENTERTAINMENT PURPOSES ONLY. YOU SHOULD NOT RELY ON THE SERVICES, OR ANY INFORMATION CONTAINED THEREIN, AS A SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE.</b>
        </p>
        <h3 className="copy-subheader">17. Indemnification</h3>
        <p className="copy-body">
        You agree to defend, indemnify and hold harmless Virtuix and our affiliates, independent contractors and service providers, content publishers and licensors, and each of their respective directors, officers, employees and agents (collectively, "Indemnified Parties") from and against any and all claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable lawyers' fees) caused by, arising out of or related to (a) your purchase or use of, or inability to use, the Services, including any injury to person or damage to property resulting from any use of the Services whether or not caused by you or other users of the Services, including any Guest Users who use your Product; (b) your failure to maintain the Product as set forth in any instructional materials; (c) your willful misconduct or gross negligence in the use of any Product or Services or under these Terms, including but not limited to your negligence, improper use or storage or maintenance or installation in any manner inconsistent with Virtuix’s or any other manufacturer’s specifications or instructions; (d) your breach of these Terms or any other applicable terms, policies, warnings or instructions provided by Virtuix or a third party in relation to the Services; (e) your violation of any Applicable Laws or any rights of any third party, or your failure to cause employees or agents to follow all Applicable Laws; or (f) any User Content or Feedback you provide. 
        </p>
        <p className="copy-body">
        For purposes of this Section 17, “Applicable Laws” means all applicable statutes, laws, rules, court decisions and regulations of any applicable governmental authority, instrumentality or agency, whether promulgated by any state or governmental entity of an applicable territory or the government of the United States, as the same may be enacted, effectuated or updated from time to time, including but not limited to The (United States) Foreign Corrupt Practices Act (FCPA) (15 U.S.C. § 78dd-1, et seq.)  For the avoidance of doubt, you acknowledge and agrees that the indemnification provided herein to the Indemnified Parties is full and to the greatest extent allowed by all Applicable Laws.  
        </p>
        <h3 className="copy-subheader">18. Limitation of Liability</h3>
        <p className="copy-body">
        <b>IN NO EVENT WILL VIRTUIX, ITS SERVICE PROVIDERS, SUPPLIERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY ARISING OUT OF, OR IN CONNECTION WITH, YOUR USE OR GUEST USER’S USE , OR INABILITY TO USE, THE SERVICES, INCLUDING ANY DIRECT; INDIRECT; SPECIAL; INCIDENTAL; CONSEQUENTIAL; OR PUNITIVE DAMAGES. THESE INCLUDE, BUT ARE NOT LIMITED TO, PERSONAL INJURY, PAIN, AND SUFFERING; EMOTIONAL DISTRESS; LOSS OF REVENUE; LOSS OF PROFITS; LOSS OF BUSINESS OR ANTICIPATED SAVINGS; LOSS OF USE; LOSS OF GOODWILL; OR LOSS OF DATA, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE—EVEN IF FORSEEABLE OR EVEN IF AN INDEMNIFIED PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR LIABILITY, IF ANY, SHALL IN NO CIRCUMSTANCES EXTEND TO UNAUTHORIZED AND/OR COMMERCIAL USE OF THE PRODUCT OR TO ANY THIRD PARTIES. YOU AGREE THAT VIRTUIX'S TOTAL LIABILITY TO YOU OR YOUR GUEST USER(S) FOR ALL CLAIMS, IN THE AGGREGATE, SHALL NOT EXCEED THE AMOUNT ACTUALLY PAID BY YOU TO VIRTUIX FOR THE SERVICES OVER THE TWELVE (12) MONTHS PRECEDING THE DATE YOUR OR YOUR GUEST USER’S FIRST CLAIM(S) AROSE. THE FOREGOING LIMITATIONS DO NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. THE FOREGOING LIMITATIONS SHALL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</b>
        </p>
        <p className="copy-body">
        <b>Basis of the Bargain. You agree that the above limitations of liability together with the other provisions in these Terms that limit liability are essential terms of these Terms and that Virtuix would not be willing to grant you the rights set forth in these Terms but for your agreement to the above limitations of liability; you are agreeing to these limitations of liability to induce Virtuix to grant you the rights set forth in these Terms.</b>
        </p>
        <h3 className="copy-subheader">19. Dispute Resolution</h3>
        <p className="copy-body">
        <b>PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO SUBMIT TO BINDING ARBITRATION (JURY TRIAL WAIVER) OF ANY AND ALL DISPUTES (OTHER THAN SPECIFIED INTELLECTUAL PROPERTY CLAIMS AND SMALL CLAIMS) WITH VIRTUIX AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM VIRTUIX (NO CLASS ARBITRATIONS, CLASS ACTIONS OR REPRESENTATIVE ACTIONS OR ARBITRATIONS).</b>
        </p>
        <p className="copy-body">
        19.1 <span className="copy-underline">Binding Arbitration, Disputes and small claims</span>. You and Virtuix agree to waive any right to a jury trial, or the right to have any Dispute resolved in any court, and instead accept the use of binding arbitration (which is the referral of a Dispute to one or more impartial persons for a final and binding determination); provided, however, that you and we have the right to litigate any Dispute in small claims court, if all the requirements of the small claims court, including any limitations on jurisdiction and the amount at issue in the Dispute, are satisfied. You agree to bring a Dispute that qualified for the jurisdiction of a small claims court in Travis County, Texas, US. "Dispute" as used in this Section 19 means any dispute, cause of action, claim or controversy arising out of or in any way related to Virtuix in connection with these Terms, the subject matter of these Terms or access to and use of the Services, including but not limited to contract, personal injury, tort, warranty, statute or regulation, or other legal or equitable basis and disputes that involve third parties (such as developers of Third-party Content), except any dispute, cause of action, claim or controversy relating to Virtuix's intellectual property (such as trademarks, trade dress, domain names, trade secrets, copyrights and/or patents). You and Virtuix empower the arbitrator with the exclusive authority to resolve any Dispute relating to the interpretation, applicability or enforceability of these Terms or formation of this contract, including the arbitrability of any Dispute and any claim that all or any part of these Terms are void or voidable.
        </p>
        <p className="copy-body">
        19.2 <span className="copy-underline">No Class Arbitrations, Class Actions or Representative Actions</span>. You and Virtuix agree that any Dispute is personal to you and Virtuix, and that any Dispute shall only be resolved by an individual arbitration and shall not be brought as a class arbitration, a class action or any other representative proceeding. Neither party agrees to class arbitration, or an arbitration where a person brings a Dispute as a representative of any other person or persons. Neither you nor Virtuix agree that a Dispute can be brought as a class or representative action whether inside or outside arbitration or on behalf of any other person or persons.
        </p>
        <p className="copy-body">
        19.3 <span className="copy-underline">US Federal Arbitration Act</span>. You and Virtuix agree that these Terms affect interstate commerce and that the enforceability of Section 19 shall be governed by, construed, and enforced, both substantively and procedurally, by the Federal Arbitration Act, Section 1, Title 9 USC et seq. ("FAA") to the maximum extent permitted by applicable law.
        </p>
        <p className="copy-body">
        19.4 <span className="copy-underline">Confidentiality</span>. The arbitrator, Virtuix and you shall maintain the confidentiality of any proceedings, including but not limited to any and all information gathered, prepared and presented for purposes of the arbitration or related to the Dispute(s) therein. The arbitrator shall have the authority to make appropriate rulings to safeguard that confidentiality, unless the law provides to the contrary.
        </p>
        <p className="copy-body">
        19.5 <span className="copy-underline">Process</span>. Our goal is to resolve claims fairly and quickly. Accordingly, for any Dispute that you have against Virtuix, you agree to first contact Virtuix and attempt to resolve the claim informally by sending a written notice of your claim ("Notice") to Virtuix. The Notice to Virtuix must be sent by registered post addressed to: [address]. The Notice must (a) include your name, residence address and the email address and/or mobile telephone number associated with your Virtuix account; (b) describe the nature and basis of the claim; and (c) set forth the specific relief sought. If you and Virtuix cannot reach an agreement to resolve the claim within 30 days after such Notice is received, then either party may, as appropriate in accordance with this Section 19, commence an arbitration proceeding as set forth below or file a claim in small claims court (if applicable). You and Virtuix agree that any Dispute must be commenced or filed within one year after such claim arose; otherwise, the Dispute is permanently barred.
        </p>
        <p className="copy-body">
        In the event that you and Virtuix cannot resolve a Dispute and you do not or cannot pursue your claims through small claims court, you or Virtuix shall promptly submit the Dispute to binding arbitration at the office of the American Arbitration Association ("AAA"). In the event that the AAA declines or is not able to administer the arbitration, you and Virtuix agree to use an arbitration forum or arbitrator that you and Virtuix mutually agree upon. If, after making a reasonable effort, you and Virtuix are not able to agree upon an arbitration forum or arbitrator, AAA or a court having proper jurisdiction will appoint an arbitration forum or arbitrator. The arbitration will be conducted in accordance with the AAA Consumer Arbitration Rules ("AAA Rules") then in effect. The AAA Rules and other information about AAA and arbitration are readily available at <a href="https://www.adr.org" className="copy-link-gray" target="_blank" rel="noreferrer">https://www.adr.org</a>, by calling 1 800 778 7879 or by post at 120 Broadway, Floor 21, New York, NY 10271, US. By entering into these Terms, you either (1) acknowledge that you have read and understand the AAA Rules or (2) waive reading the AAA Rules and waive any claim that the AAA Rules are unfair in any way. You and Virtuix agree that these Terms govern the arbitration and that the applicable AAA Rules shall be subject to changes in procedures that AAA may make from time to time.
        </p>
        <p className="copy-body">
        As limited by the FAA, these Terms and the applicable AAA Rules, the arbitrator will have the exclusive power and jurisdiction to make all procedural and substantive decisions concerning the Dispute; provided, however, that this power does not include the power to conduct a class arbitration or a representative action, which is prohibited by these Terms (as stated above). The arbitrator may only conduct an individual arbitration, and may not consolidate more than one person's claims and may not preside over any form of representative or class proceeding, or any proceeding on behalf of or involving more than one person or persons.
        </p>
        <p className="copy-body">
        Unless the arbitrator finds that the arbitration was frivolous or brought for an improper purpose, Virtuix will pay all filing, AAA and arbitrator's fees and expenses. If the arbitrator issues you an award that is greater than the value of our last written settlement offer made before an arbitrator was selected (or if we did not make a settlement offer before an arbitrator was selected), then we will pay you the amount of the award or USD 5,000, whichever is greater, and pay your legal adviser, if any, the amount of legal fees incurred, and reimburse any expenses (including expert witness fees and costs) that you or your legal adviser reasonably accrue for investigating, preparing and pursuing your claim in arbitration. We waive any right to seek an award of legal fees and expenses in connection with any non-frivolous arbitration between you and us.
        </p>
        <p className="copy-body">
        19.6 <span className="copy-underline">Right to Opt Out</span>. You may opt out of this agreement to arbitrate. If you do so, neither you nor Virtuix can require the other to participate in an arbitration proceeding. To opt out, you must notify us in writing within 30 days of the date when you first became subject to this arbitration provision, and must include your name and residence address, the email address you use for your Virtuix account (if you have one) and a clear statement that you want to opt out of this arbitration agreement. Any requests to opt out must be sent to:
        </p>
        <p className="copy-body">
        Virtuix Inc.<br />
        1826 Kramer Lane, Suite H<br />
        Austin, TX 78758<br />
        USA 
        </p>
        <p className="copy-body">
        If a court or arbitrator decides that any of this section's limitations cannot be enforced as to a particular claim for relief or request for a remedy (such as public injunctive relief), then that claim or request for a remedy (and only that claim or request) must be severed from the arbitration and may be brought in court. Unless you choose to opt out, this dispute resolution provision in Section 19 shall survive termination of these Terms.
        </p>
        <p className="copy-body">
        The courts in some countries may not permit you to consent to arbitration. If you reside in one of those countries, your country's laws will apply.
        </p>
        <h3 className="copy-subheader">20. Governing Law</h3>
        <p className="copy-body">
        20.1 The laws of the State of Texas, excluding its conflicts of law rules, govern your access to and use of the Services. Your access to and use of the Services may also be subject to other local, regional, national or international laws. You agree that the United Nations Convention on Contracts for the International Sale of Goods will not apply to the interpretation or construction of these Terms.
        </p>
        <p className="copy-body">
        20.2 To the extent that the provisions in Section 19 do not apply, you further agree that all disputes, causes of action, claims or controversies arising under these Terms that cannot be settled through informal negotiation will be resolved exclusively in the United States District Court for the Western District of Texas sitting in Travis County, Texas, and you agree to submit to the personal jurisdiction of such courts for the purposes of litigating all such claims to the extent allowed by law. The courts in some countries may not apply the law of Texas to some types of disputes or allow you to consent to jurisdiction in Texas. If you reside in one of these countries, your country's laws will apply to such disputes related to the Services. In particular, if you are a consumer and habitually reside in a Member State of the European Union, the laws of that Member State will apply to any claim, cause of action or dispute between us that arises out of or relates to these Terms or the Services and the claim, cause of action or dispute may be resolved in any competent court in that country that has jurisdiction over the claim. In all other cases, you agree that the claim must be resolved as described in these Terms.
        </p>
        <h3 className="copy-subheader">21. Modifications to the Services</h3>
        <p className="copy-body">
        We reserve the right to change, suspend, remove, discontinue or disable access to the Services or particular portions thereof, at any time and without notice. In no event will Virtuix be liable for the removal or disabling of access to any portion or feature of the Services.
        </p>
        <h3 className="copy-subheader">22. Termination</h3>
        <p className="copy-body">
        We reserve the right to terminate your right to access and use the Services if you breach these Terms or any other terms or policies referenced herein, or if you otherwise create risk or possible legal exposure for us.
        </p>
        <h3 className="copy-subheader">23. Severability</h3>
        <p className="copy-body">
        You acknowledge that these Terms supersede and cancel all previous contracts, agreements and working arrangements whether oral or written, express or implied, between us. These Terms prevail over any other terms or conditions contained in or referred to elsewhere or implied by trade, custom or course of dealing. Any purported terms or conditions to the contrary are hereby excluded to the fullest extent legally permitted. If any provision of these Terms is deemed unlawful, void or for any reason unenforceable, then that provision will be deemed severable from these Terms and will not affect the validity and enforceability of any remaining provisions.
        </p>
        <h3 className="copy-subheader">24. Assignment</h3>
        <p className="copy-body">
        You may not assign these Terms or any of the rights granted hereunder without the prior written consent of Virtuix, and any attempted assignment without such consent shall be void. Subject to the foregoing restriction, these Terms will be fully binding upon, inure to the benefit of and be enforceable by us and our respective successors and assignees.
        </p>
        <h3 className="copy-subheader">25. Non-waiver</h3>
        <p className="copy-body">
        Any failure by Virtuix to insist upon or enforce performance by you of any of the provisions of these Terms or to exercise any rights or remedies under these Terms or otherwise by law will not be construed as a waiver or relinquishment of any right to assert or rely upon the provision, right or remedy in that or any other instance; rather, the provision, right or remedy will be and remain in full force and effect.
        </p>
        <h3 className="copy-subheader">26. Injunctive Relief</h3>
        <p className="copy-body">
        You agree that a breach of these Terms will cause irreparable injury to Virtuix for which monetary damages would not be an adequate remedy, and in such event, Virtuix shall be entitled to equitable relief in addition to any remedies it may have hereunder or at law without a bond, other security or proof of damages.
        </p>
        <h3 className="copy-subheader">27. Construction</h3>
        <p className="copy-body">
        The headings and captions contained herein will not be considered to be part of the Terms and are for convenience only. These Terms do not, and shall not be construed to, create any partnership, joint venture, employer-employee, agency or franchisor-franchisee relationship between you and Virtuix.
        </p>
        <h3 className="copy-subheader">28. Contact us</h3>
        <p className="copy-body">
          If you have any questions or concerns regarding these Terms or our
          Services, please contact us at{" "}
          <a href="mailto:help@virtuix.com" className="copy-link-gray">
            help@virtuix.com
          </a>
          .
        </p>
        <p id="lastupdated" className="copy-body">
          <b>Last Updated: November 26, 2022</b>
        </p>
      </div>
    </>
  );
}

export default TermsOfUse;
