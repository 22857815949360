import React from "react";
import "./HomeHeroSection.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./Button.css";
import LazyAnimation from "./LazyAnimation";

function HomeHeroSection({
  lightBg,
  lightText,
  lightTextDesc,
  headline,
  subTitle,
  buttonLabel,
}) {
  return (
    <>
      <div className="home-hero-section-bg">
        <div className="home-hero-video-overlay">
          <video
            autoPlay
            playsInline
            muted="true"
            loop
            className="home-hero-video-position home-hero-bg-video home-hero-section-display"
            poster="/video/omnione_ultimategaming_short_hero_teaser.jpg"
          >
            <source
              src="/video/omnione_ultimategaming_short_hero_1280x720.webm"
              type="video/webm"
            />
            <source
              src="/video/omnione_ultimategaming_short_hero_1280x720.mp4"
              type="video/mp4"
            />
            Sorry, your browser doesn't support embedded videos.
          </video>
          <video
            autoPlay
            playsInline
            muted="true"
            loop
            className="home-hero-video-position home-hero-bg-video home-hero-section-display-mobile"
            poster="/video/omnione_ultimategaming_short_hero_teaser_600px.jpg"
          >
            <source
              src="/video/omnione_ultimategaming_short_hero_520x600.webm"
              type="video/webm"
            />
            <source
              src="/video/omnione_ultimategaming_short_hero_520x600.mp4"
              type="video/mp4"
            />
            Sorry, your browser doesn't support embedded videos.
          </video>
          <video
            autoPlay
            playsInline
            muted="true"
            loop
            className="home-hero-video-position home-hero-bg-video home-hero-section-display-mobile-sm"
            poster="/video/omnione_ultimategaming_short_hero_teaser_600px.jpg"
          >
            <source
              src="/video/omnione_ultimategaming_short_hero_350x600.webm"
              type="video/webm"
            />
            <source
              src="/video/omnione_ultimategaming_short_hero_350x600.mp4"
              type="video/mp4"
            />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </div>
      <div className="home-hero-section-container home-hero-section-text-light-nopad">
        <div className="home-hero-section-col-text">
          <div className="home-hero-section-text-wrapper">
            <h1
              className={
                lightText
                  ? "home-hero-section-heading"
                  : "home-hero-section-heading home-hero-section-text-dark"
              }
            >
              {headline}
            </h1>
            <LazyAnimation />
            <p
              className={
                lightTextDesc
                  ? "home-hero-section-subtitle"
                  : "home-hero-section-subtitle home-hero-section-text-dark"
              }
            >
              {subTitle}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeHeroSection;
